import React, { useState, useEffect, useContext } from 'react'
import HeaderCommunity from '../componentsCommunity/headerCommunity';
import PostOnCommunity from '../componentsCommunity/postOnCommunity';
import './style.css'

import { UserContext } from '../../components/UserProvider';
import { db } from '../../auth/config/firebase';
import { getDocs, collection, query, limit } from 'firebase/firestore';
import Loading from '../../components/loading';
import UserAvatar from '../../components/UserAvatar';

export default function Activity() {
    const [showPostOnCommunity, setShowPostOnCommunity] = useState(false)
    const [selectedPost, setSelectedPost] = useState(null);
    const [selectedEditPost, setSelectedEditPost] = useState(null);

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true)

    const { user } = useContext(UserContext)

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            const usersCollectionRef = collection(db, 'community');
            const q = query(usersCollectionRef, limit(20));
            const usersSnapshot = await getDocs(q);
            const usersList = usersSnapshot.docs
                .map(doc => ({ uid: doc.id, ...doc.data() }))
                .filter(u => u.uid !== user.uid); // Exclui o usuário com o uid igual a user.uid
            setLoading(false);
            setUsers(usersList);
        };
    
        fetchUsers();
    }, [user.uid]); // Adicionado user.uid como dependência do useEffect
    
    

    return (
        <>
            <PostOnCommunity showPostOnCommunity={showPostOnCommunity} setShowPostOnCommunity={setShowPostOnCommunity} setSelectedPost={setSelectedPost} setSelectedEditPost={setSelectedEditPost} />

            <div className="allCommunity">
                <div className="positionFixed">
                    <HeaderCommunity showPostOnCommunity={showPostOnCommunity} setShowPostOnCommunity={setShowPostOnCommunity} />
                </div>

                <div className='activityCommunity'>
                    <div className='containerWidthCommunity'>
                        <div className='centerWidthCommunity' style={{ padding: 0 }}>
                            {!loading
                                ?
                                <div className='contentCommunityActivity'>
                                    {users.map((user, index) => (
                                        <div className='itemActivity' key={index}>
                                            <div className='imagemActionActivity'>
                                                <UserAvatar photoURL={user?.photoURL} userName={user?.userNick} size={50} />
                                            </div>
                                            <div className='contentItemActivity'>
                                                <div className='activityPerson'>
                                                    <div className='contentInformarionActivity'>
                                                        <h3>{user?.userNick}</h3>
                                                        <span>1w</span>
                                                    </div>
                                                    <span>Followed you</span>
                                                </div>
                                                <div className='buttonActionItemActivity'>
                                                    <button>Follow back</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                :
                                <div className='loadingComponent'>
                                    <Loading size={25} color={'#AAAAAA'} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}