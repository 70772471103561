import { useState, useEffect, useRef } from 'react';
import { collection, addDoc, doc, updateDoc, getDocs, onSnapshot, deleteDoc } from "firebase/firestore";
import { db } from '../../auth/config/firebase';
import { auth } from '../../auth/config/firebase';
import './style.css';

function Vocabulary() {
    const [decks, setDecks] = useState([]);
    const [currentDeck, setCurrentDeck] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState("");
    const [currentAnswer, setCurrentAnswer] = useState("");
    const [showAnswer, setShowAnswer] = useState(false);
    const [displayableCards, setDisplayableCards] = useState([]);
    const [currentDisplayableCardIndex, setCurrentDisplayableCardIndex] = useState(0);
    const [updateIndexCallback, setUpdateIndexCallback] = useState(null);
    const [newDeckName, setNewDeckName] = useState("");
    const [renamingDeck, setRenamingDeck] = useState(null);
    const [createDeckName, setCreateDeckName] = useState("");
    const [openedMenuIndex, setOpenedMenuIndex] = useState(null);
    const [showCreateDeck, setShowCreateDeck] = useState(false);
    const [newQuestion, setNewQuestion] = useState("");
    const [newAnswer, setNewAnswer] = useState("");

    const [showEditCard, setShowEditCard] = useState("");
    const [showAddCard, setShowAddCard] = useState("");

    const [nextIntervals, setNextIntervals] = useState({
        'again': '',
        'hard': '',
        'good': '',
        'easy': ''
    });


    const addDeck = async (name) => {
        if (name) {
            const existingDeck = decks.find(deck => deck.name.toLowerCase() === name.toLowerCase());

            if (existingDeck) {
                alert('Um deck com este nome já existe. Por favor, escolha um nome diferente.');
                return;
            }

            const newDeck = { name, cards: [], userId: auth.currentUser.uid };
            const docRef = await addDoc(collection(db, "decks"), newDeck);
            setDecks(decks.concat({ ...newDeck, id: docRef.id }));
            setCreateDeckName("");
        }
    };

    const addCard = async (event) => {
        event.preventDefault();
        const newCard = {
            question: currentQuestion,
            answer: currentAnswer,
            confidence: null,
            nextReview: new Date().getTime(),
            easeFactor: 2.5, // adicionando o easeFactor inicial
            interval: 1, // adicionando o intervalo inicial
            firstReview: true // adicionando firstReview inicial
        };

        const updatedDeck = { ...currentDeck, cards: currentDeck.cards.concat(newCard) };

        await updateDoc(doc(db, "decks", currentDeck.id), updatedDeck);

        setDecks(decks.map(deck => deck.id === currentDeck.id ? updatedDeck : deck));

        setCurrentDeck(updatedDeck);
        setCurrentQuestion("");
        setCurrentAnswer("");
        setShowAddCard(false)
    };

    const nextCard = async (confidence) => {
        let easeFactor, interval;
        const currentCard = displayableCards[currentDisplayableCardIndex];
        const performance = currentCard.performance || { again: 0, hard: 0, good: 0, easy: 0 }; // pegar performance da base de dados, ou inicializar com 0 se não existir

        performance[confidence] += 1; // incrementar o contador para a confiança dada pelo usuário

        switch (confidence) {
            // case 'again':
            //     easeFactor = Math.max(currentCard.easeFactor - 0.2, 1.3);
            //     interval = 1 / 60 / 24; // 1 minuto em dias
            //     break;
            case 'again':
                // Não altere o easeFactor
                interval = 1 / 60 / 24; // 1 minuto em dias
                break;
            case 'hard':
                easeFactor = Math.max(currentCard.easeFactor - 0.15, 1.3);
                if (performance.hard > performance.good) { // se o usuário está achando 'hard' mais vezes do que 'good'
                    interval = (currentCard.firstReview ? 6 / 60 / 24 : Math.max(currentCard.interval * 1.2, 1)) / 2; // reduza o intervalo para a metade
                } else {
                    interval = currentCard.firstReview ? 6 / 60 / 24 : Math.max(currentCard.interval * 1.2, 1); // senão, mantenha o intervalo normal
                }
                break;
            case 'good':
                easeFactor = currentCard.easeFactor;
                if (performance.good > performance.easy) { // se o usuário está achando 'good' mais vezes do que 'easy'
                    interval = (currentCard.firstReview ? 10 / 60 / 24 : currentCard.interval * easeFactor) / 2; // reduza o intervalo para a metade
                } else {
                    interval = currentCard.firstReview ? 10 / 60 / 24 : currentCard.interval * easeFactor; // senão, mantenha o intervalo normal
                }
                break;
            case 'easy':
                easeFactor = currentCard.easeFactor + 0.1;
                interval = currentCard.firstReview ? 4 : 3.0 * currentCard.interval * easeFactor; // 4 dias ou 3 vezes o intervalo 'good'
                break;
            default:
                throw new Error("Confidence level not recognized");
        }

        const nextReview = new Date().getTime() + interval * 24 * 60 * 60 * 1000; // converter intervalo de dias para milissegundos

        if (displayableCards.length > 0 && currentDisplayableCardIndex < displayableCards.length) {
            const updatedCard = {
                ...displayableCards[currentDisplayableCardIndex],
                confidence,
                nextReview,
                firstReview: false, // A revisão foi feita, então não é mais a primeira revisão
            };

            const updatedDeck = {
                ...currentDeck,
                cards: currentDeck.cards.map((card, index) => card.question === updatedCard.question ? updatedCard : card)
            };
            await updateDoc(doc(db, "decks", currentDeck.id), updatedDeck);

            setDecks(decks.map(deck => deck.id === currentDeck.id ? updatedDeck : deck));
            setCurrentDeck(updatedDeck);

            // Atualize displayableCards para remover o cartão que foi revisado
            setDisplayableCards(updatedDeck.cards.filter(card => new Date() >= new Date(card.nextReview)));

            if (currentDisplayableCardIndex >= displayableCards.length - 1) {
                setCurrentDisplayableCardIndex(0);
            } else {
                setCurrentDisplayableCardIndex(currentDisplayableCardIndex + 1);
            }
        }
    };

    const calculateNextIntervals = (confidence, currentCard) => {
        let interval;
        const performance = currentCard.performance || { again: 0, hard: 0, good: 0, easy: 0 }; // pegar performance da base de dados, ou inicializar com 0 se não existir

        switch (confidence) {
            case 'again':
                interval = 1 / (60 * 24); // 1 minuto
                break;
            case 'hard':
                if (performance.hard > performance.good) { // se o usuário está achando 'hard' mais vezes do que 'good'
                    interval = (currentCard.firstReview ? 6 / (60 * 24) : Math.max(currentCard.interval * 1.2, 1)) / 2; // reduza o intervalo para a metade
                } else {
                    interval = currentCard.firstReview ? 6 / (60 * 24) : Math.max(currentCard.interval * 1.2, 1); // senão, mantenha o intervalo normal
                }
                break;
            case 'good':
                if (performance.good > performance.easy) { // se o usuário está achando 'good' mais vezes do que 'easy'
                    interval = (currentCard.firstReview ? 10 / (60 * 24) : currentCard.interval * currentCard.easeFactor) / 2; // reduza o intervalo para a metade
                } else {
                    interval = currentCard.firstReview ? 10 / (60 * 24) : currentCard.interval * currentCard.easeFactor; // senão, mantenha o intervalo normal
                }
                break;
            case 'easy':
                interval = currentCard.firstReview ? 4 : 3.0 * currentCard.interval * (currentCard.easeFactor + 0.1); // 4 dias ou 3 vezes o intervalo 'good'
                break;
            default:
                throw new Error("Confidence level not recognized");
        }

        return formatInterval(interval);
    };



    useEffect(() => {
        if (displayableCards.length > 0 && currentDisplayableCardIndex < displayableCards.length) {
            const currentCard = displayableCards[currentDisplayableCardIndex];

            setNextIntervals({
                'again': calculateNextIntervals('again', currentCard),
                'hard': calculateNextIntervals('hard', currentCard),
                'good': calculateNextIntervals('good', currentCard),
                'easy': calculateNextIntervals('easy', currentCard),
            });
        }
    }, [displayableCards, currentDisplayableCardIndex]);

    function formatInterval(interval) {
        const minutes = interval * 24 * 60;
        const hours = interval * 24;
        const days = interval;
        const months = interval / 30;
        const years = interval / 365;

        if (minutes < 60) {
            return `${Math.round(minutes)} min`;
        } else if (hours < 24) {
            return `${Math.round(hours)} h`;
        } else if (days < 30) {
            return `${Math.round(days)} d`;
        } else if (months < 12) {
            return `${Math.round(months)} mo`;
        } else {
            return `${Math.round(years)} yr`;
        }
    }


    useEffect(() => {
        const getDecks = async () => {
            const uid = auth.currentUser.uid;
            const deckSnapshot = await getDocs(collection(db, 'decks'));
            let userDecks = deckSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            userDecks = userDecks.map(deck => ({
                ...deck,
                cards: deck.cards.map(card => ({
                    ...card,
                    easeFactor: card.easeFactor || 2.5,
                    interval: card.interval || 1
                }))
            }));
            userDecks = userDecks.filter(deck => deck.userId === uid);
            setDecks(userDecks);
        };
        getDecks();
    }, []);


    useEffect(() => {
        if (currentDeck !== null) {
            setDisplayableCards(currentDeck.cards.filter(card => new Date() >= new Date(card.nextReview)));
        }
    }, [decks, currentDeck]);

    useEffect(() => {
        setCurrentDisplayableCardIndex(prevIndex => (prevIndex >= displayableCards.length - 1) ? 0 : prevIndex + 1);
    }, [displayableCards]);

    useEffect(() => {
        if (updateIndexCallback !== null) {
            updateIndexCallback();
            setUpdateIndexCallback(null);
        }
    }, [displayableCards]);

    const deleteDeck = async (deckId) => {

        if (!window.confirm('Are you sure you want to delete this deck?')) {
            return;
        }

        await deleteDoc(doc(db, "decks", deckId));
        setDecks(decks.filter(deck => deck.id !== deckId));
    };

    const renameDeck = async () => {
        const updatedDeck = { ...renamingDeck, name: newDeckName };
        await updateDoc(doc(db, "decks", renamingDeck.id), updatedDeck);
        setDecks(decks.map(deck => deck.id === renamingDeck.id ? updatedDeck : deck));
        setRenamingDeck(null);
        setNewDeckName("");
    };

    const toggleMenu = (index) => {
        if (openedMenuIndex === index) {
            setOpenedMenuIndex(null); // Fecha o menu se ele já está aberto
        } else {
            setOpenedMenuIndex(index); // Abre o menu
        }
    };

    const handleClickOutside = (event) => {
        if (event.target.closest('.menuDeck') === null) {
            setOpenedMenuIndex(-1);
        }
    }

    useEffect(() => {
        window.addEventListener('click', handleClickOutside);

        // Quando o componente é desmontado, o "event listener" é removido
        return () => {
            window.removeEventListener('click', handleClickOutside);
        }
    }, []);

    const popupRef = useRef();  // definição da referência

    useEffect(() => {
        // Função para verificar se um clique foi feito fora do popup
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowCreateDeck(false);
            }
        }

        // Adiciona o ouvinte de evento ao documento quando o popup é mostrado
        if (showCreateDeck) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        // Remove o ouvinte de evento quando o componente é desmontado
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showCreateDeck]);

    // --------------------------------------------------------------------------------

    const handleEditCard = async (event, newQuestion, newAnswer) => {
        // Previne o comportamento padrão do evento (neste caso, recarregar a página)
        event.preventDefault();

        const originalCard = displayableCards[currentDisplayableCardIndex];
        const updatedCard = { ...originalCard, question: newQuestion, answer: newAnswer };

        const updatedDeck = {
            ...currentDeck,
            cards: currentDeck.cards.map((card) => card.question === originalCard.question ? updatedCard : card)
        };

        await updateDoc(doc(db, "decks", currentDeck.id), updatedDeck);

        setDecks(decks.map(deck => deck.id === currentDeck.id ? updatedDeck : deck));
        setCurrentDeck(updatedDeck);

        // Atualize displayableCards para incluir o cartão que foi atualizado
        setDisplayableCards(updatedDeck.cards.filter(card => new Date() >= new Date(card.nextReview)));

        // Fechar o modal de edição (ou qualquer que seja a sua implementação)
        // ...
        setShowEditCard(false)
    };


    const handleDeleteCard = async () => {
        // Confirme a ação com o usuário
        if (!window.confirm('Are you sure you want to delete this card?')) {
            return;
        }

        const currentCard = displayableCards[currentDisplayableCardIndex];

        const updatedDeck = {
            ...currentDeck,
            cards: currentDeck.cards.filter(card => card.question !== currentCard.question)
        };

        await updateDoc(doc(db, "decks", currentDeck.id), updatedDeck);

        setDecks(decks.map(deck => deck.id === currentDeck.id ? updatedDeck : deck));
        setCurrentDeck(updatedDeck);

        // Atualize displayableCards para excluir o card que foi deletado
        setDisplayableCards(updatedDeck.cards.filter(card => new Date() >= new Date(card.nextReview)));

        if (currentDisplayableCardIndex >= displayableCards.length - 1) {
            setCurrentDisplayableCardIndex(0);
        } else {
            setCurrentDisplayableCardIndex(currentDisplayableCardIndex + 1);
        }
    };

    const [showBlueTalking, setShowBlueTalking] = useState(false);

    const handleSpeech = (textToSpeak) => {
        var msg = new SpeechSynthesisUtterance();
        msg.text = textToSpeak;
        msg.lang = 'en-US';

        msg.onstart = () => {
            setShowBlueTalking(true);
        };

        msg.onend = () => {
            setShowBlueTalking(false);
        };

        window.speechSynthesis.speak(msg);
    }


    // let nextReview = new Date(1685626680356);
    // console.log(nextReview);

    return (
        <div className="containerVocabulary">
            {currentDeck === null ? (
                <div className='baseVocabulary'>
                    <div className='containerDecks'>
                        <h2>Decks</h2>
                        {decks.map((deck, index) => (
                            <div key={deck.id} onClick={() => setCurrentDeck(deck)} className='deckTalkIA'>
                                <div className='flexOptionsH23'>
                                    <h3>{deck.name}</h3>
                                    <h3>({deck.cards.length})</h3>
                                </div>
                                {renamingDeck === deck ? (
                                    <div className='containerEdit'>
                                        <input type="text" value={newDeckName} onChange={e => setNewDeckName(e.target.value)} onClick={(e) => e.stopPropagation()} className='input-small' />
                                        <button onClick={(e) => { e.stopPropagation(); renameDeck(); }} className='button-small'>Salvar</button>
                                        <button onClick={(e) => { e.stopPropagation(); setRenamingDeck(null); setNewDeckName(""); }} className='button-small'>Cancelar</button>
                                    </div>
                                ) : (
                                    <div className={`bottom-content-decks${openedMenuIndex === index ? ' show' : ''}`}>
                                        <button onClick={(e) => { e.stopPropagation(); toggleMenu(index); }} className='button-small' >Options</button>
                                        <div className='menuDeck'>
                                            <li onClick={(e) => { e.stopPropagation(); setRenamingDeck(deck); setNewDeckName(deck.name); }}>
                                                <i className="fa-solid fa-pen"></i>Rename
                                            </li>
                                            <li onClick={(e) => { e.stopPropagation(); deleteDeck(deck.id); }}>
                                                <i className="fa-solid fa-trash"></i>Delete
                                            </li>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}

                        {showCreateDeck && (
                            <div className='popupDeck' ref={popupRef}>  {/* adiciona a referência ao elemento */}
                                <div className='containerCreateDeck'>
                                    <label>Create a new deck</label>
                                    <div>
                                        <input type="text" value={createDeckName} onChange={e => setCreateDeckName(e.target.value)} placeholder='Deck name' />
                                        <button onClick={() => [addDeck(createDeckName), setShowCreateDeck(false)]}>Criar deck</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='containerButtonCreateDeck'>
                        <button onClick={() => setShowCreateDeck(true)}>Create deck</button>
                    </div>
                </div>
            ) : (
                <div className='continanerDeck'>
                    <div className='flexHeaderDeck'>
                        <button onClick={() => setCurrentDeck(null)}><i className="fa-solid fa-arrow-left"></i>Back to decks</button>
                        <button onClick={() => setShowAddCard(true)}>Add card</button>
                    </div>
                    {showAddCard && (
                        <div className='showAddCard'>
                            <div className='containerX' onClick={() => setShowAddCard(false)}>
                                <i className="fa-solid fa-xmark"></i>
                            </div>
                            <h3>Add Card</h3>
                            <form onSubmit={addCard} className='addCard'>
                                <div>
                                    <label>Word in English:</label>
                                    <input type="text" value={currentQuestion} onChange={e => setCurrentQuestion(e.target.value)} placeholder='In english' required />
                                </div>
                                <div>
                                    <label>Word in your language:</label>
                                    <input type="text" value={currentAnswer} onChange={e => setCurrentAnswer(e.target.value)} placeholder='In your native language' required />
                                </div>
                                <button type="submit">Add card</button>
                            </form>
                        </div>
                    )}
                    {showEditCard && (
                        <div className='showEditCard'>
                            <div className='containerX' onClick={() => setShowEditCard(false)}>
                                <i className="fa-solid fa-xmark"></i>
                            </div>
                            <h3>Edit Card</h3>
                            <form className='inputsEditCard'>
                                <div>
                                    <label>New Word</label>
                                    <input
                                        type="text"
                                        value={newQuestion}
                                        onChange={e => setNewQuestion(e.target.value)}
                                        placeholder="New word in English"
                                        required
                                    />
                                </div>
                                <div>
                                    <label>New Word</label>
                                    <input
                                        type="text"
                                        value={newAnswer}
                                        onChange={e => setNewAnswer(e.target.value)}
                                        placeholder="New word in your language"
                                        required
                                    />
                                </div>
                                <button onClick={(event) => handleEditCard(event, newQuestion, newAnswer)}>Edit Card</button>
                            </form>
                        </div>
                    )}
                    <div className='allContainerCard'>
                        {displayableCards.length === 0 ? (
                            <div>
                                {currentDeck.cards.length <= 0
                                    ?
                                    <h2>Oops! Looks like you don't have any cards in this deck.</h2>
                                    :
                                    <h2>Congratulations! You have finished this deck for now.</h2>
                                }
                            </div>
                        ) : (
                            <div className='flexOptionShow'>
                                <div className='infoAndOptionCard'>
                                    <div className='containerButtonOptionCard'>
                                        <button onClick={() => setShowEditCard(true)}>Edit Card</button>
                                        <button onClick={handleDeleteCard}>Delete Card</button>
                                    </div>
                                    <p>Total: {currentDeck?.cards.length || 0}</p>
                                </div>
                                <div className='showCards'>
                                    {currentDeck !== null && displayableCards.length > 0 && displayableCards[currentDisplayableCardIndex] && (
                                        <div className="showCard">
                                            {/* <div className="showCard" onClick={() => setShowAnswer(!showAnswer)}> */}
                                            <h3> {displayableCards[currentDisplayableCardIndex].question}</h3>
                                            {showBlueTalking
                                                ?
                                                <div className='ContainerSpeechButton'><button className="speech-button" onClick={() => handleSpeech(displayableCards[currentDisplayableCardIndex].question)}><span style={{ color: "#478DE9" }} className="material-symbols-outlined">volume_up</span></button></div>
                                                :
                                                <div className='ContainerSpeechButton'><button className="speech-button" onClick={() => handleSpeech(displayableCards[currentDisplayableCardIndex].question)}><span className="material-symbols-outlined">volume_up</span></button></div>
                                            }
                                            {showAnswer && <h3 className='answer'> {displayableCards[currentDisplayableCardIndex].answer}</h3>}
                                        </div>
                                    )}
                                </div>
                                <div className='showCardOptions'>
                                    {showAnswer ? (
                                        <div className='allButtonShow'>
                                            <div className='flexInfoButton'>
                                                {nextIntervals.again}
                                                <br />
                                                <button onClick={() => { nextCard('again'); setShowAnswer(false); }} className='notSelected'>
                                                    Again
                                                </button>
                                            </div>
                                            <div className='flexInfoButton'>
                                                {nextIntervals.hard}
                                                <br />
                                                <button onClick={() => { nextCard('hard'); setShowAnswer(false); }} className='notSelected'>
                                                    Hard
                                                </button>
                                            </div>
                                            <div className='flexInfoButton'>
                                                {nextIntervals.good}
                                                <br />
                                                <button onClick={() => { nextCard('good'); setShowAnswer(false); }} className='selectedButton'>
                                                    Good
                                                </button>
                                            </div>
                                            <div className='flexInfoButton'>
                                                {nextIntervals.easy}
                                                <br />
                                                <button onClick={() => { nextCard('easy'); setShowAnswer(false); }} className='notSelected'>
                                                    Easy
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <button onClick={() => setShowAnswer(true)} className='showAnswer'>Show Answer</button>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Vocabulary;





















// import { useState, useEffect, useRef } from 'react';
// import { collection, addDoc, doc, updateDoc, query, where, getDocs, onSnapshot, deleteDoc } from "firebase/firestore";
// import { db } from '../../config/firebase';
// import { auth } from '../../config/firebase';
// import './style.css';

// function Vocabulary() {
//     const [decks, setDecks] = useState([]);
//     const [currentDeck, setCurrentDeck] = useState(null);
//     const [currentQuestion, setCurrentQuestion] = useState("");
//     const [currentAnswer, setCurrentAnswer] = useState("");
//     const [showAnswer, setShowAnswer] = useState(false);
//     const [displayableCards, setDisplayableCards] = useState([]);
//     const [currentDisplayableCardIndex, setCurrentDisplayableCardIndex] = useState(0);
//     const [updateIndexCallback, setUpdateIndexCallback] = useState(null);
//     const [newDeckName, setNewDeckName] = useState("");
//     const [renamingDeck, setRenamingDeck] = useState(null);
//     const [createDeckName, setCreateDeckName] = useState("");
//     const [openedMenuIndex, setOpenedMenuIndex] = useState(null);
//     const [showCreateDeck, setShowCreateDeck] = useState(false);
//     const [newQuestion, setNewQuestion] = useState("");
//     const [newAnswer, setNewAnswer] = useState("");

//     const [showEditCard, setShowEditCard] = useState("");
//     const [showAddCard, setShowAddCard] = useState("");

//     const addDeck = async (name) => {
//         if (name) {
//             const existingDeck = decks.find(deck => deck.name.toLowerCase() === name.toLowerCase());

//             if (existingDeck) {
//                 alert('Um deck com este nome já existe. Por favor, escolha um nome diferente.');
//                 return;
//             }

//             const newDeck = { name, cards: [], userId: auth.currentUser.uid };
//             const docRef = await addDoc(collection(db, "decks"), newDeck);
//             setDecks(decks.concat({ ...newDeck, id: docRef.id }));
//             setCreateDeckName("");
//         }
//     };

//     const addCard = async (event) => {
//         event.preventDefault();
//         const newCard = { question: currentQuestion, answer: currentAnswer, confidence: null, nextReview: new Date().getTime() };

//         const updatedDeck = { ...currentDeck, cards: currentDeck.cards.concat(newCard) };

//         await updateDoc(doc(db, "decks", currentDeck.id), updatedDeck);

//         setDecks(decks.map(deck => deck.id === currentDeck.id ? updatedDeck : deck));

//         setCurrentDeck(updatedDeck);
//         setCurrentQuestion("");
//         setCurrentAnswer("");
//         setShowAddCard(false)
//     };


//     const nextCard = async (confidence) => {
//         let delay;
//         switch (confidence) {
//             case 'again':
//                 delay = 60000;
//                 break;
//             case 'hard':
//                 delay = 600000;
//                 break;
//             case 'good':
//                 delay = 24 * 60 * 60 * 1000;
//                 break;
//             case 'easy':
//                 delay = 4 * 24 * 60 * 60 * 1000;
//                 break;
//             default:
//                 throw new Error("Confidence level not recognized");
//         }
//         const nextReview = new Date().getTime() + delay;

//         if (displayableCards.length > 0 && currentDisplayableCardIndex < displayableCards.length) {
//             const updatedCard = {
//                 ...displayableCards[currentDisplayableCardIndex],
//                 confidence,
//                 nextReview
//             };

//             const updatedDeck = {
//                 ...currentDeck,
//                 cards: currentDeck.cards.map((card, index) => card.question === updatedCard.question ? updatedCard : card)
//             };
//             await updateDoc(doc(db, "decks", currentDeck.id), updatedDeck);

//             setDecks(decks.map(deck => deck.id === currentDeck.id ? updatedDeck : deck));
//             setCurrentDeck(updatedDeck);

//             // Atualize displayableCards para remover o cartão que foi revisado
//             setDisplayableCards(updatedDeck.cards.filter(card => new Date() >= new Date(card.nextReview)));

//             if (currentDisplayableCardIndex >= displayableCards.length - 1) {
//                 setCurrentDisplayableCardIndex(0);
//             } else {
//                 setCurrentDisplayableCardIndex(currentDisplayableCardIndex + 1);
//             }
//         }
//     };



//     useEffect(() => {
//         const getDecks = async () => {
//             const uid = auth.currentUser.uid;
//             const deckSnapshot = await getDocs(collection(db, 'decks'));
//             let userDecks = deckSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//             userDecks = userDecks.filter(deck => deck.userId === uid);
//             setDecks(userDecks);
//         };
//         getDecks();
//     }, []);

//     useEffect(() => {
//         if (currentDeck !== null) {
//             setDisplayableCards(currentDeck.cards.filter(card => new Date() >= new Date(card.nextReview)));
//         }
//     }, [decks, currentDeck]);

//     useEffect(() => {
//         setCurrentDisplayableCardIndex(prevIndex => (prevIndex >= displayableCards.length - 1) ? 0 : prevIndex + 1);
//     }, [displayableCards]);

//     useEffect(() => {
//         if (updateIndexCallback !== null) {
//             updateIndexCallback();
//             setUpdateIndexCallback(null);
//         }
//     }, [displayableCards]);

//     const delayToString = (delay) => {
//         if (delay < 60 * 60 * 1000) { // menos de uma hora
//             return `${Math.round(delay / (60 * 1000))} m`;
//         } else if (delay < 24 * 60 * 60 * 1000) { // menos de um dia
//             return `${Math.round(delay / (60 * 60 * 1000))} h`;
//         } else { // mais de um dia
//             return `${Math.round(delay / (24 * 60 * 60 * 1000))} d`;
//         }
//     }

//     const deleteDeck = async (deckId) => {

//         if (!window.confirm('Are you sure you want to delete this deck?')) {
//             return;
//         }

//         await deleteDoc(doc(db, "decks", deckId));
//         setDecks(decks.filter(deck => deck.id !== deckId));
//     };

//     const renameDeck = async () => {
//         const updatedDeck = { ...renamingDeck, name: newDeckName };
//         await updateDoc(doc(db, "decks", renamingDeck.id), updatedDeck);
//         setDecks(decks.map(deck => deck.id === renamingDeck.id ? updatedDeck : deck));
//         setRenamingDeck(null);
//         setNewDeckName("");
//     };

//     const toggleMenu = (index) => {
//         if (openedMenuIndex === index) {
//             setOpenedMenuIndex(null); // Fecha o menu se ele já está aberto
//         } else {
//             setOpenedMenuIndex(index); // Abre o menu
//         }
//     };

//     const handleClickOutside = (event) => {
//         if (event.target.closest('.menuDeck') === null) {
//             setOpenedMenuIndex(-1);
//         }
//     }

//     useEffect(() => {
//         window.addEventListener('click', handleClickOutside);

//         // Quando o componente é desmontado, o "event listener" é removido
//         return () => {
//             window.removeEventListener('click', handleClickOutside);
//         }
//     }, []);

//     const popupRef = useRef();  // definição da referência

//     useEffect(() => {
//         // Função para verificar se um clique foi feito fora do popup
//         function handleClickOutside(event) {
//             if (popupRef.current && !popupRef.current.contains(event.target)) {
//                 setShowCreateDeck(false);
//             }
//         }

//         // Adiciona o ouvinte de evento ao documento quando o popup é mostrado
//         if (showCreateDeck) {
//             document.addEventListener("mousedown", handleClickOutside);
//         } else {
//             document.removeEventListener("mousedown", handleClickOutside);
//         }

//         // Remove o ouvinte de evento quando o componente é desmontado
//         return () => {
//             document.removeEventListener("mousedown", handleClickOutside);
//         };
//     }, [showCreateDeck]);

//     // --------------------------------------------------------------------------------

//     const handleEditCard = async (event, newQuestion, newAnswer) => {
//         // Previne o comportamento padrão do evento (neste caso, recarregar a página)
//         event.preventDefault();

//         const originalCard = displayableCards[currentDisplayableCardIndex];
//         const updatedCard = { ...originalCard, question: newQuestion, answer: newAnswer };

//         const updatedDeck = {
//             ...currentDeck,
//             cards: currentDeck.cards.map((card) => card.question === originalCard.question ? updatedCard : card)
//         };

//         await updateDoc(doc(db, "decks", currentDeck.id), updatedDeck);

//         setDecks(decks.map(deck => deck.id === currentDeck.id ? updatedDeck : deck));
//         setCurrentDeck(updatedDeck);

//         // Atualize displayableCards para incluir o cartão que foi atualizado
//         setDisplayableCards(updatedDeck.cards.filter(card => new Date() >= new Date(card.nextReview)));

//         // Fechar o modal de edição (ou qualquer que seja a sua implementação)
//         // ...
//         setShowEditCard(false)
//     };


//     const handleDeleteCard = async () => {
//         // Confirme a ação com o usuário
//         if (!window.confirm('Are you sure you want to delete this card?')) {
//             return;
//         }

//         const currentCard = displayableCards[currentDisplayableCardIndex];

//         const updatedDeck = {
//             ...currentDeck,
//             cards: currentDeck.cards.filter(card => card.question !== currentCard.question)
//         };

//         await updateDoc(doc(db, "decks", currentDeck.id), updatedDeck);

//         setDecks(decks.map(deck => deck.id === currentDeck.id ? updatedDeck : deck));
//         setCurrentDeck(updatedDeck);

//         // Atualize displayableCards para excluir o card que foi deletado
//         setDisplayableCards(updatedDeck.cards.filter(card => new Date() >= new Date(card.nextReview)));

//         if (currentDisplayableCardIndex >= displayableCards.length - 1) {
//             setCurrentDisplayableCardIndex(0);
//         } else {
//             setCurrentDisplayableCardIndex(currentDisplayableCardIndex + 1);
//         }
//     };


//     return (
//         <div className="containerVocabulary">
//             {currentDeck === null ? (
//                 <div className='baseVocabulary'>
//                     <div className='containerDecks'>
//                         <h2>Decks</h2>
//                         {decks.map((deck, index) => (
//                             <div key={deck.id} onClick={() => setCurrentDeck(deck)} className='deckTalkIA'>
//                                 <div className='flexOptionsH23'>
//                                     <h3>{deck.name}</h3>
//                                     <h3>({deck.cards.length})</h3>
//                                 </div>
//                                 {renamingDeck === deck ? (
//                                     <div className='containerEdit'>
//                                         <input type="text" value={newDeckName} onChange={e => setNewDeckName(e.target.value)} onClick={(e) => e.stopPropagation()} className='input-small' />
//                                         <button onClick={(e) => { e.stopPropagation(); renameDeck(); }} className='button-small'>Salvar</button>
//                                         <button onClick={(e) => { e.stopPropagation(); setRenamingDeck(null); setNewDeckName(""); }} className='button-small'>Cancelar</button>
//                                     </div>
//                                 ) : (
//                                     <div className={`bottom-content-decks${openedMenuIndex === index ? ' show' : ''}`}>
//                                         <button onClick={(e) => { e.stopPropagation(); toggleMenu(index); }} className='button-small' >Options</button>
//                                         <div className='menuDeck'>
//                                             <li onClick={(e) => { e.stopPropagation(); setRenamingDeck(deck); setNewDeckName(deck.name); }}>
//                                                 <i className="fa-solid fa-pen"></i>Rename
//                                             </li>
//                                             <li onClick={(e) => { e.stopPropagation(); deleteDeck(deck.id); }}>
//                                                 <i className="fa-solid fa-trash"></i>Delete
//                                             </li>
//                                         </div>
//                                     </div>
//                                 )}
//                             </div>
//                         ))}

//                         {showCreateDeck && (
//                             <div className='popupDeck' ref={popupRef}>  {/* adiciona a referência ao elemento */}
//                                 <div className='containerCreateDeck'>
//                                     <label>Create a new deck</label>
//                                     <div>
//                                         <input type="text" value={createDeckName} onChange={e => setCreateDeckName(e.target.value)} placeholder='Deck name' />
//                                         <button onClick={() => [addDeck(createDeckName), setShowCreateDeck(false)]}>Criar deck</button>
//                                     </div>
//                                 </div>
//                             </div>
//                         )}
//                     </div>
//                     <div className='containerButtonCreateDeck'>
//                         <button onClick={() => setShowCreateDeck(true)}>Create deck</button>
//                     </div>
//                 </div>
//             ) : (
//                 <div className='continanerDeck'>
//                     <div className='flexHeaderDeck'>
//                         <button onClick={() => setCurrentDeck(null)}><i className="fa-solid fa-arrow-left"></i>Back to decks</button>
//                         <button onClick={() => setShowAddCard(true)}>Add card</button>
//                     </div>
//                     {showAddCard && (
//                         <div className='showAddCard'>
//                             <div className='containerX' onClick={() => setShowAddCard(false)}>
//                                 <i class="fa-solid fa-xmark"></i>
//                             </div>
//                             <h3>Add Card</h3>
//                             <form onSubmit={addCard} className='addCard'>
//                                 <div>
//                                     <label>Word in English:</label>
//                                     <input type="text" value={currentQuestion} onChange={e => setCurrentQuestion(e.target.value)} placeholder='In english' required />
//                                 </div>
//                                 <div>
//                                     <label>Word in your language:</label>
//                                     <input type="text" value={currentAnswer} onChange={e => setCurrentAnswer(e.target.value)} placeholder='In your native language' required />
//                                 </div>
//                                 <button type="submit">Add card</button>
//                             </form>
//                         </div>
//                     )}
//                     {showEditCard && (
//                         <div className='showEditCard'>
//                             <div className='containerX' onClick={() => setShowEditCard(false)}>
//                                 <i class="fa-solid fa-xmark"></i>
//                             </div>
//                             <h3>Edit Card</h3>
//                             <form className='inputsEditCard'>
//                                 <div>
//                                     <label>New Word</label>
//                                     <input
//                                         type="text"
//                                         value={newQuestion}
//                                         onChange={e => setNewQuestion(e.target.value)}
//                                         placeholder="New word in English"
//                                         required
//                                     />
//                                 </div>
//                                 <div>
//                                     <label>New Word</label>
//                                     <input
//                                         type="text"
//                                         value={newAnswer}
//                                         onChange={e => setNewAnswer(e.target.value)}
//                                         placeholder="New word in your language"
//                                         required
//                                     />
//                                 </div>
//                                 <button onClick={(event) => handleEditCard(event, newQuestion, newAnswer)}>Edit Card</button>
//                             </form>
//                         </div>
//                     )}
//                     <div className='allContainerCard'>
//                         {displayableCards.length === 0 ? (
//                             <div>
//                                 {currentDeck.cards.length <= 0
//                                     ?
//                                     <h2>Oops! Looks like you don't have any cards in this deck.</h2>
//                                     :
//                                     <h2>Congratulations! You have finished this deck for now.</h2>
//                                 }
//                             </div>
//                         ) : (
//                             <div className='flexOptionShow'>
//                                 <div className='infoAndOptionCard'>
//                                     <div className='containerButtonOptionCard'>
//                                         <button onClick={() => setShowEditCard(true)}>Edit Card</button>
//                                         <button onClick={handleDeleteCard}>Delete Card</button>
//                                     </div>
//                                     <p>Total: {currentDeck?.cards.length || 0}</p>
//                                 </div>
//                                 <div className='showCards'>
//                                     {currentDeck !== null && displayableCards.length > 0 && displayableCards[currentDisplayableCardIndex] && (
//                                         <div className="showCard" onClick={() => setShowAnswer(!showAnswer)}>
//                                             <h3> {displayableCards[currentDisplayableCardIndex].question}</h3>
//                                             {showAnswer && <h3 className='answer'> {displayableCards[currentDisplayableCardIndex].answer}</h3>}
//                                         </div>
//                                     )}
//                                 </div>
//                                 <div className='showCardOptions'>
//                                     {showAnswer ? (
//                                         <div className='allButtonShow'>
//                                             <div className='flexInfoButton'>
//                                                 {`<`} {delayToString(60000)}
//                                                 <br />
//                                                 <button onClick={() => { nextCard('again'); setShowAnswer(false); }} className='notSelected'>
//                                                     Again
//                                                 </button>
//                                             </div>
//                                             <div className='flexInfoButton'>
//                                                 {`<`} {delayToString(600000)}
//                                                 <br />
//                                                 <button onClick={() => { nextCard('hard'); setShowAnswer(false); }} className='notSelected'>
//                                                     Hard
//                                                 </button>
//                                             </div>
//                                             <div className='flexInfoButton'>
//                                                 {`<`} {delayToString(24 * 60 * 60 * 1000)}
//                                                 <br />
//                                                 <button onClick={() => { nextCard('good'); setShowAnswer(false); }} className='selectedButton'>
//                                                     Good
//                                                 </button>
//                                             </div>
//                                             <div className='flexInfoButton'>
//                                                 {`<`} {delayToString(4 * 24 * 60 * 60 * 1000)}
//                                                 <br />
//                                                 <button onClick={() => { nextCard('easy'); setShowAnswer(false); }} className='notSelected'>
//                                                     Easy
//                                                 </button>
//                                             </div>
//                                         </div>
//                                     ) : (
//                                         <button onClick={() => setShowAnswer(true)} className='showAnswer'>Show Answer</button>
//                                     )}
//                                 </div>
//                             </div>
//                         )}
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// }

// export default Vocabulary;
