import React, { useState } from 'react';
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons';

const Calendar = () => {

    const [currentMonth, setCurrentMonth] = useState(new Date());

    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const daysInMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();

    const today = new Date();
    const isToday = (day) => {
        return today.getDate() === day &&
            currentMonth.getMonth() === today.getMonth() &&
            currentMonth.getFullYear() === today.getFullYear();
    };

    const renderMonthYearHeader = () => (
        <div className="month-year-header">
            <FontAwesomeIcon icon={faChevronLeft} onClick={prevMonth} />
            <span>{monthNames[currentMonth.getMonth()]} {currentMonth.getFullYear()}</span>
            <FontAwesomeIcon icon={faChevronRight} onClick={nextMonth} />
        </div>
    );

    const prevMonth = () => {
        setCurrentMonth(prev => new Date(prev.getFullYear(), prev.getMonth() - 1, 1));
    };

    const nextMonth = () => {
        setCurrentMonth(prev => new Date(prev.getFullYear(), prev.getMonth() + 1, 1));
    };

    const renderHeader = () => (
        <div className="calendar-header">
            {days.map((day, index) => (
                <div key={index}>{day}</div>
            ))}
        </div>
    );

    // const renderDays = () => {
    //     const daysArray = [];
    //     const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1).getDay();
    
    //     for (let i = 0; i < firstDayOfMonth; i++) {
    //         daysArray.push(<div key={`empty-${i}`} className="empty-day"></div>);
    //     }
    
    //     for (let i = 1; i <= daysInMonth; i++) {
    //         daysArray.push(<div key={i} className={isToday(i) ? 'today' : ''}><span>{i}</span></div>);
    //     }
    
    //     return (
    //         <div className="calendar-days">
    //             {daysArray}
    //         </div>
    //     );
    // };

    const renderDays = () => {
        const daysArray = [];
        const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1).getDay();
        const prevMonthLastDay = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 0).getDate();
        
        // Adicionar os dias do mês anterior
        for (let i = 0; i < firstDayOfMonth; i++) {
            daysArray.push(<div key={`prev-${i}`} className="invalid-day"><span>{prevMonthLastDay - firstDayOfMonth + i + 1}</span></div>);
        }
        
        // Dias do mês atual
        for (let i = 1; i <= daysInMonth; i++) {
            daysArray.push(<div key={i} className={isToday(i) ? 'today' : ''}><span>{i}</span></div>);
        }
    
        // Adicionar os dias do próximo mês APENAS para completar a semana
        let nextDayCount = 1;
        while (daysArray.length % 7 !== 0) {  // Adiciona os dias até completar a última semana
            daysArray.push(<div key={`next-${nextDayCount}`} className="invalid-day"><span>{nextDayCount}</span></div>);
            nextDayCount++;
        }
        
        return (
            <div className="calendar-days">
                {daysArray}
            </div>
        );
    };
       


    return (
        <div className={`calendar`}>
            {renderMonthYearHeader()}
            {renderHeader()}
            {renderDays()}
        </div>
    );
};

export default Calendar;
