import React, { useState, useEffect } from 'react';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faPhoneVolume, faBook } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export default function StartCommunity() {

    const [currentMessage, setCurrentMessage] = useState('');
    const [index, setIndex] = useState(0);
    const [isTyping, setIsTyping] = useState(true);
    const messages = ['Meet new people', 'Find support', 'Share your knowledge', 'Learn something new', 'Make friends'];

    useEffect(() => {
        let typed = currentMessage;
        let typingTimer;

        const startTyping = () => {
            typingTimer = setInterval(() => {
                if (isTyping) {
                    if (typed.length < messages[index].length) {
                        typed += messages[index].charAt(typed.length);
                        setCurrentMessage(typed);
                    } else {
                        clearInterval(typingTimer);
                        setTimeout(() => {
                            setIsTyping(false);
                            startTyping();
                        }, 3000); // tempo de espera depois de terminar de digitar
                    }
                } else {
                    if (typed.length > 0) {
                        typed = typed.slice(0, -1);
                        setCurrentMessage(typed);
                    } else {
                        clearInterval(typingTimer);
                        setIsTyping(true);
                        setIndex((prevIndex) => (prevIndex + 1) % messages.length);
                    }
                }
            }, isTyping ? 100 : 50); // intervalo entre cada letra sendo "digitada" ou "apagada"
        };

        startTyping();

        return () => clearInterval(typingTimer);
    }, [index, isTyping]);


    return (
        <div className="startCommunityContainer">
            <div className='containrAnimationStartContiner'>
                <div className='contentAnimationStartContiner'>
                    <div className='containerStartText' id='first'>
                        <h1>Join the Talk2MeIA community and <span>{currentMessage}</span></h1>
                        <Link to='/interview'>Join now</Link>
                    </div>
                    <div className='containerStartImage'>
                        <div className='contentStartImage'>
                            <img src={require('../../assets/images/community.png')} alt="home community Talk2MeIA" draggable={false} />
                        </div>
                    </div>
                    <div className='containerStartText' id='second'>
                        <h1>Join the Talk2MeIA community and <span>{currentMessage}</span></h1>
                        <Link to='/interview'>Join now</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}