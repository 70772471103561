import React, { useState, useEffect } from 'react'
import './style.css'
import logo from '../assets/images/logo.png'
import logoGoogle from '../assets/images/logo-google.png'
import logoApple from '../assets/images/Apple_logo_black.svg.webp'
import ilustration from '../assets/images/ilustração-login-signup.svg'
import animation from "../assets/images/email-verification.gif"
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword, sendPasswordResetEmail, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../auth/config/firebase';
import { FaInfoCircle } from "react-icons/fa";
import { db } from '../auth/config/firebase'
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { addNotification } from '../components/notification'
import { doc, updateDoc, arrayUnion, getDoc, setDoc } from "firebase/firestore";
import CryptoJS from 'crypto-js';
import { useContext } from 'react';
import { LoginContext } from '../index';

const texts = [
    { title: 'AI-Driven Lessons', description: 'Learn English smarter, not harder.' },
    { title: 'Comprehensive Metrics', description: 'Track your progress with data.' },
    { title: 'Vocabulary Tests', description: 'Boost your word power daily.' },
    { title: 'Converse with AI', description: 'Improve speaking with our bot.' },
    { title: 'Four Skill Training', description: 'Read, write, speak, listen.' },
    { title: 'Dynamic Exercises', description: 'Custom tasks adapt to you.' },
    { title: 'Instant Feedback', description: 'Get quick tips and tricks.' },
    { title: 'Progress Charts', description: 'Visualize your language growth.' },
    { title: 'Interactive UI', description: 'Learn with ease and fun.' },
    { title: 'Community Support', description: 'Engage in group challenges.' }
];

export default function Login() {

    const { setIsLoginComplete } = useContext(LoginContext);

    const [sessionInfo, setSessionInfo] = useState({})

    // Função para obter o IP do usuário
    const getUserIP = async () => {
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            return response.data.ip;
        } catch (error) {
            console.error("Erro ao obter o IP:", error);
            return null;
        }
    };

    // Função para obter detalhes de localização
    const getLocationDetails = async (ip, retries = 3) => {
        try {
            const response = await axios.get(`https://get.geojs.io/v1/ip/geo/${ip}.json`);
            return response.data;
        } catch (error) {
            console.error(`Erro ao obter detalhes de localização: ${error}`);
            if (retries > 0) {
                console.log(`Tentando novamente em 5 segundos. Restam ${retries} tentativas.`);
                await new Promise(resolve => setTimeout(resolve, 5000));
                return getLocationDetails(ip, retries - 1);
            }
            return null;
        }
    };

    const getBrowserName = () => {
        const userAgent = navigator.userAgent;
        let browserName = "Unknown";

        if (userAgent.includes("Firefox")) {
            browserName = "Mozilla Firefox";
        } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
            browserName = "Opera";
        } else if (userAgent.includes("Edg")) {
            browserName = "Microsoft Edge";
        } else if (userAgent.includes("Chrome")) {
            browserName = "Google Chrome";
        } else if (userAgent.includes("Safari")) {
            browserName = "Apple Safari";
        } else if (userAgent.includes("MSIE") || !!document.documentMode === true) {
            browserName = "Internet Explorer";
        }

        return browserName;
    };


    const getOperatingSystem = () => {
        const userAgent = navigator.userAgent;
        const platform = navigator.platform;
        let os = "Unknown";

        if (platform.indexOf("Mac") !== -1) {
            os = "macOS";
            if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
                os = "iOS";
            }
            if (userAgent.match(/Macintosh/i) && userAgent.match(/AppleWebKit/i) && !userAgent.match(/Safari/i)) {
                os = "iPadOS";
            }
        } else if (platform.indexOf("Win") !== -1) {
            os = "Windows";
        } else if (platform.indexOf("Linux") !== -1) {
            os = "Linux";
            if (userAgent.indexOf("Android") !== -1) {
                os = "Android";
            }
        } else if (platform.indexOf("iPhone") !== -1) {
            os = "iOS";
        }

        return os;
    };


    const saveSessionToFirestore = async (uid, sessionInfo) => {
        const userDocRef = doc(db, 'users', uid);

        try {
            // Verifique se o uniqueID já existe no local storage
            let uniqueID = localStorage.getItem('uniqueID');

            // Se não existir, gere um novo e salve no local storage
            if (!uniqueID) {
                uniqueID = generateUniqueID();
                localStorage.setItem('uniqueID', uniqueID);
            }

            // Adicione o uniqueID ao objeto sessionInfo antes de salvar no Firestore
            sessionInfo.uniqueID = uniqueID;

            // Verificar se o documento existe
            const docSnap = await getDoc(userDocRef);

            try {
                if (docSnap.exists()) {
                    // Se o documento existir, atualize-o
                    await updateDoc(userDocRef, {
                        sessionsDevice: arrayUnion(sessionInfo)
                    });
                } else {
                    // Se o documento não existir, crie um novo
                    await setDoc(userDocRef, {
                        sessionsDevice: [sessionInfo] // Crie um array com sessionInfo
                    });
                }

                setIsLoginComplete(true);
            } catch (e) {
                console.error("Erro ao adicionar a sessão: ", e);
            }

            console.log("Sessão adicionada com sucesso");
        } catch (e) {
            console.error("Erro ao adicionar a sessão: ", e);
        }
    };


    const generateUniqueID = () => {
        const navigatorInfo = window.navigator;
        const screenInfo = window.screen;

        const uidParts = [
            navigatorInfo.userAgent,
            navigatorInfo.language,
            screenInfo.height.toString(),
            screenInfo.width.toString(),
            screenInfo.colorDepth.toString(),
            new Date().getTimezoneOffset().toString()
        ];

        const uid = uidParts.join('-');

        // Criando um hash SHA-256 do UID
        const hash = CryptoJS.SHA256(uid);

        // Convertendo o hash em uma string hexadecimal
        const hashString = hash.toString(CryptoJS.enc.Hex);

        // Embaralhando a string hash
        const shuffledHash = hashString.split('').sort(() => Math.random() - 0.5).join('');

        return shuffledHash;
    };


    const run = async () => {
        // Obtém o IP do usuário e detalhes da localização
        const userIP = await getUserIP();
        if (userIP) {
            console.log("IP do usuário é:", userIP);
            const location = await getLocationDetails(userIP);
            if (location) {
                console.log("Detalhes da localização:", location);
            }

            const browserName = getBrowserName();
            const operatingSystem = getOperatingSystem();

            const newSessionInfo = {
                ip: userIP,
                country: location.country,
                city: location.city,
                region: location.region,
                browserName: browserName,
                operatingSystem: operatingSystem,
                createdAt: Date.now(),
            };

            setSessionInfo(newSessionInfo);

            // console.log("Navegador:", browserName);
            // console.log("Sistema Operacional:", operatingSystem);
        }

    };

    useEffect(() => {
        run();
    }, [])

    const googleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            saveSessionToFirestore(user.uid, sessionInfo)
            // console.log("Usuário autenticado:", user);
        } catch (error) {
            const errorMessageMap = {
                'Firebase: Error (auth/unauthorized-domain).': 'Sorry, but it looks like your domain is not authorized.',
                'Firebase: Error (auth/network-request-failed).': 'Check your internet connection and try again.',
                'Firebase: Error (auth/cancelled-popup-request).': 'Multiple popup requests cancelled. Please try again.',
                'Firebase: Error (auth/popup-blocked).': 'Popup blocked. Enable popups in your browser settings.',
                'Firebase: Error (auth/popup-closed-by-user).': 'Popup closed before completing authentication. Try again.',
            };


            const message = errorMessageMap[error.message];
            addNotification(message, 'error3');
        }
    };

    const registerWithEmail = async (email, password, passwordConfirm) => {

        if (password.length < 6 || passwordConfirm.length < 6) {
            addNotification('Password must be at least 6 characters long', 'error3');
            return;
        }

        if (password !== passwordConfirm) {
            addNotification('Passwords do not match', 'error3');
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            saveSessionToFirestore(user.uid, sessionInfo)
            await sendEmailVerification(user);
            addNotification('Usuário cadastrado com sucesso', 'success3');
        } catch (error) {
            const errorMessageMap = {
                'Firebase: Error (auth/network-request-failed).': 'There was a network error while making the request. Please check your internet connection.',
                'Firebase: Error (auth/email-already-in-use).': `The email address ${email} is already in use by another account.`,
                'Firebase: Error (auth/invalid-email).': 'The email address is not valid.',
                'Firebase: Error (auth/operation-not-allowed).': 'Email/password accounts are not enabled. Enable email/password accounts',
                'Firebase: Error (auth/weak-password).': 'The password is too weak.',
                'Firebase: Error (auth/too-many-requests).': "Access to this account has been temporarily disabled due to too many failed login attempts. You can restore it immediately by resetting your password, or you can try again later.",
                'Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).': "Access to this account has been temporarily disabled due to too many failed login attempts. You can restore it immediately by resetting your password, or you can try again later.",
            };

            const message = errorMessageMap[error.message];
            addNotification(message, 'error3');
        }
    };

    const loginWithEmail = async (email, password) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            saveSessionToFirestore(user.uid, sessionInfo)
            addNotification('Successfully logged in!', 'success3');
        } catch (error) {
            // console.log(error)
            const errorMessageMap = {
                'Firebase: Error (auth/user-not-found).': "User not found, check that the email was entered correctly!",
                'Firebase: Error (auth/wrong-password).': "Incorrect password!",
                'Firebase: Error (auth/network-request-failed).': "Check your internet connection and try again!",
                'Firebase: Error (auth/too-many-requests).': "Access to this account has been temporarily disabled due to too many failed login attempts. You can restore it immediately by resetting your password, or you can try again later.",
                'Firebase: Error (auth/invalid-email).': "Invalid email address",
                'Firebase: Error (auth/weak-password).': 'The password is too weak.',
                'Firebase: Error (auth/too-many-requests).': "Access to this account has been temporarily disabled due to too many failed login attempts. You can restore it immediately by resetting your password, or you can try again later.",
                'Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).': "Access to this account has been temporarily disabled due to too many failed login attempts. You can restore it immediately by resetting your password, or you can try again later.",
            };

            const message = errorMessageMap[error.message];
            addNotification(message, 'error3');
        }
    };

    const [showSecondForm, setShowSecondForm] = useState(false);
    const [login, setLogin] = useState(true);

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword02, setShowPassword02] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const [index, setIndex] = useState(0);
    const [visible, setVisible] = useState(true);
    const [typingText, setTypingText] = useState('');

    useEffect(() => {
        const timer = setInterval(() => {
            setVisible(false);
            setTimeout(() => {
                setIndex((prevIndex) => (prevIndex + 1) % texts.length);
                setVisible(true);
            }, 500);
        }, 6000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        let typed = '';
        const typingTimer = setInterval(() => {
            if (typed.length < texts[index].description.length) {
                typed += texts[index].description.charAt(typed.length);
                setTypingText(typed);
            } else {
                clearInterval(typingTimer);
            }
        }, 100);

        return () => clearInterval(typingTimer);
    }, [index]);


    const handleSubmit = (e) => {
        e.preventDefault();
        setShowSecondForm(true);
    };

    return (
        <main className='logAndReg'>
            <div id="notifications3"></div>
            <div className='imageLogUser'>
                <div className='logoUserWebSite'>
                    <img src={logo} alt='logo Talk2MeIA' />
                    <span>Talk2MeIA</span>
                </div>
                <div className='contentAnimateText'>
                    <div className='animateText'>
                        <AnimatePresence>
                            {visible && (
                                <motion.div
                                    initial={{ opacity: 0, y: -30 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: 30 }}
                                    transition={{ type: 'spring', stiffness: 50, damping: 8 }}
                                >
                                    <h2>{texts[index].title}</h2>
                                    <motion.p
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{
                                            delay: 0.5,
                                            duration: 1,
                                        }}
                                    >
                                        {typingText}
                                    </motion.p>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                </div>
            </div>
            <div className='logUserDetails'>

                <AnimatePresence>
                    {!showSecondForm ? (
                        <motion.div
                            initial={{ opacity: 0, y: -50 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 50 }}
                            transition={{ duration: 0.5 }}
                        >
                            <div className='formTalk2Me'>
                                <h2>Sign up or log in</h2>
                                <div className='containerLogAndRegister'>
                                    <div className='containerStores'>
                                        <div className='containerStore' onClick={googleSignIn}>
                                            <img src={logoGoogle} alt='logo Google' />
                                            <span>Continue with Google</span>
                                        </div>
                                        <div className='containerStore'>
                                            <img src={logoApple} alt='logo Apple' />
                                            <span>Continue with Apple</span>
                                        </div>
                                    </div>
                                    <form>
                                        <div className='containerSignWithEmail'>
                                            <span>Email</span>
                                            <input
                                                type='email'
                                                id='email'
                                                placeholder='Enter your email'
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                        <div className='continueButton'>
                                            <button onClick={() => {
                                                if (email.includes("@")) {
                                                    setShowSecondForm(true);
                                                } else {
                                                    addNotification('Please provide a valid email address.', 'error3');
                                                }
                                            }}>
                                                Continue with email
                                            </button>
                                        </div>
                                    </form>
                                    <div className='termsAndCondition'>
                                        <span>By continuing with Google, Apple or Email, you agree to Twist's <a href='#'>Terms of Service</a> and <a href='#'>Privacy Policy</a>.</span>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    ) : (
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -50 }}
                            transition={{ duration: 0.5 }}
                        >
                            <div className='logUserDetailsForm'>
                                <h2>Complete your {login ? "log in" : "profile"} </h2>
                                <span id='emailUser'>With <span id='emailUserStrong'>{email}</span> <br /> or <a href='#' onClick={() => setShowSecondForm(false)}>Edit it</a></span>
                                <form className='formUser' onSubmit={handleSubmit}>
                                    <div className='containerSignWithEmail'>
                                        <span>Password</span>
                                        <div className='flexEye'>
                                            <input type={showPassword ? 'text' : 'password'} id='password' placeholder='Enter your password' value={password} onChange={(e) => setPassword(e.target.value)} />
                                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={() => setShowPassword(!showPassword)} />
                                        </div>
                                    </div>
                                    {!login && (
                                        <span id='messageInput'>Your password to log into your account</span>
                                    )}
                                    {!login && (
                                        <div className='containerSignWithEmail'>
                                            <span>Confirm password</span>
                                            <div className='flexEye'>
                                                <input type={showPassword02 ? 'text' : 'password'} id='password' value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} placeholder='Confirm your password' />
                                                <FontAwesomeIcon icon={showPassword02 ? faEyeSlash : faEye} onClick={() => setShowPassword02(!showPassword02)} />
                                            </div>
                                        </div>
                                    )}
                                    {!login && (
                                        <a onClick={() => setLogin(!login)} id='loginSignUp'>{!login ? "Already have an account?" : "New here? sign up"}</a>
                                    )}
                                    {!login && (
                                        <div className='containerConfirmPassword'>
                                            <div className='confirmPassword'>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <span>Password must be at last 6 characters long.</span>
                                            </div>
                                            <div className='confirmPassword'>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <span>Avoid 123456, abcdef, password or similar.</span>
                                            </div>
                                        </div>
                                    )}
                                    {login && (
                                        <div className='flexOptionsLink'>
                                            <span className='forgotPassword'>Forgot your password?</span>
                                            <span onClick={() => setLogin(!login)} className='forgotPassword'>{!login ? "Already have an account?" : "New here?"}</span>
                                        </div>
                                    )}
                                    <div className='continueButton02'>
                                        <button onClick={() => { login ? loginWithEmail(email, password) : registerWithEmail(email, password, passwordConfirm) }}>{login ? "Log in" : "Sign up"}</button>
                                    </div>
                                </form>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </main >
    )
}

