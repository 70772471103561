// PostInteractionContext.js
import React, { createContext, useState } from 'react';

export const PostInteractionContext = createContext();

export const PostInteractionProvider = ({ children }) => {
    const [interactions, setInteractions] = useState({}); // Estrutura: {postId: { liked: boolean, vote: string }}

    // Função para atualizar interações
    const updateInteraction = (postId, interaction) => {
        setInteractions(prevInteractions => {
            // Atualiza o post específico com a nova interação mantendo as outras interações intactas
            return {...prevInteractions, [postId]: {...prevInteractions[postId], ...interaction}};
        });
    }

    return (
        <PostInteractionContext.Provider value={{ interactions, updateInteraction }}>
            {children}
        </PostInteractionContext.Provider>
    );
}
