import React, { useState, useEffect } from 'react';
import './style.css';

function PhrasalVerbs() {

    function onClose() {

    }

    const phrasalVerbsQuiz = [
        {
            phrasalVerb: 'break down',
            correct: 'When the car started to break down',
            options: [
                { phrase: 'When the car started to break down', correct: true },
                { phrase: 'I break down my lunch every day at noon.', correct: false },
                { phrase: 'She broke down the door with a smile on her face.', correct: false },
                { phrase: 'I\'m going to break down my dog for a walk.', correct: false },
            ],
        },
        {
            phrasalVerb: 'take off',
            correct: 'The plane took off smoothly and reached its destination.',
            options: [
                { phrase: 'I take off my shoes when entering the house.', correct: false },
                { phrase: 'She took off her glasses to clean them.', correct: false },
                { phrase: 'He takes off his hat as a sign of respect.', correct: false },
                { phrase: 'The plane took off smoothly and reached its destination.', correct: true },
            ],
        },
        {
            phrasalVerb: 'look after',
            correct: 'She looks after her younger siblings while their parents are at work.',
            options: [
                { phrase: 'I look after my garden and water the plants every day.', correct: false },
                { phrase: 'He looks after his car by regularly servicing it.', correct: false },
                { phrase: 'They look after their pet dog by taking it for walks.', correct: false },
                { phrase: 'She looks after her younger siblings while their parents are at work.', correct: true },
            ],
        },
        {
            phrasalVerb: 'call off',
            correct: 'They had to call off the meeting due to bad weather.',
            options: [
                { phrase: 'I call off my friends when I need help.', correct: false },
                { phrase: 'He called off the dog to stop barking.', correct: false },
                { phrase: 'She called off the wedding at the last minute.', correct: false },
                { phrase: 'They had to call off the meeting due to bad weather.', correct: true },
            ],
        },
        {
            phrasalVerb: 'look up',
            correct: 'I always look up new words in the dictionary.',
            options: [
                { phrase: 'He looks up at the sky to see the stars.', correct: false },
                { phrase: 'She looked up from her book when I entered the room.', correct: false },
                { phrase: 'We look up to our parents for guidance.', correct: false },
                { phrase: 'I always look up new words in the dictionary.', correct: true },
            ],
        },
        {
            phrasalVerb: 'give up',
            correct: 'She didn\'t want to give up on her dream.',
            options: [
                { phrase: 'He gives up smoking to improve his health.', correct: false },
                { phrase: 'They gave up searching for the lost keys.', correct: false },
                { phrase: 'I give up my seat to the elderly lady.', correct: false },
                { phrase: 'She didn\'t want to give up on her dream.', correct: true },
            ],
        },
    ];

    const [quiz, setQuiz] = useState([...phrasalVerbsQuiz]); // Cria uma cópia do array phrasalVerbsQuiz
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(() => Math.floor(Math.random() * quiz.length));
    const [selectedOption, setSelectedOption] = useState(null);
    const [gameOver, setGameOver] = useState(false);
    const [answeredQuestions, setAnsweredQuestions] = useState([]);
    const [counter, setCounter] = useState(15);
    const [gameStart, setGameStart] = useState(true);
    const [correctOption, setCorrectOption] = useState(null);
    const [screen, setScreen] = useState("instructions"); // "instructions", "quiz", "results"
    const [correctCount, setCorrectCount] = useState(0);
    const [incorrectCount, setIncorrectCount] = useState(0);
    const [optionSelected, setOptionSelected] = useState(false);
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState(1);


    const currentQuestion = quiz[currentQuestionIndex];

    const handleOptionClick = (option) => {
        setOptionSelected(true);
        setSelectedOption(option);
        setAnsweredQuestions(prevState => [...prevState, { question: currentQuestion, selectedOption: option }]);

        if (!currentQuestion) {
            // Aqui você pode lidar com o caso quando `currentQuestion` é indefinido.
            // Por exemplo, você pode retornar null, ou mostrar uma mensagem para o usuário, etc.
            return null;
        }

        if (!option.correct) {
            setIncorrectCount(prevCount => prevCount + 1);
        }
        if (option.correct) {
            setCorrectCount(prevCount => prevCount + 1);
            if (currentQuestionIndex < quiz.length - 1) {
                // removendo a mudança automática para a próxima pergunta
            } else {
                setGameOver(true);
            }
        } else {
            setCorrectOption(currentQuestion.options.find((option) => option.correct));
        }
    };

    const [timerOver, setTimerOver] = useState(false);

    useEffect(() => {
        if (counter > 0 && gameStart && !gameOver && screen === "quiz" && !timerOver && !optionSelected) {
            const timer = setTimeout(() => {
                setCounter((prevCounter) => prevCounter - 1);
            }, 1000);
            return () => clearTimeout(timer);
        } else if (!gameStart || screen !== "quiz") {
            setCounter(15);
        } else {
            if (counter === 0) {
                const correct = currentQuestion.options.find((option) => option.correct);
                setSelectedOption(correct);
                setCorrectOption(correct);
                setTimerOver(true);
            }
        }
    }, [counter, gameStart, gameOver, currentQuestion, selectedOption, timerOver, screen, optionSelected]);


    function handleNextClick() {
        if (currentQuestionNumber < quiz.length - 1) {
            setCurrentQuestionNumber(currentQuestionNumber + 1);
            setCurrentQuestionIndex(currentQuestionNumber + 1);
        }
        setSelectedOption(null);
        setCorrectOption(null);
        setTimerOver(true); // Parar o contador quando uma opção é selecionada
        setCounter(15);
        setTimerOver(false); // Reiniciar o timer para a próxima pergunta
        setGameOver(false);
        setTimerOver(false); // Reiniciar o timer para a próxima pergunta
        setOptionSelected(false);
    }

    const handleStopClick = () => {
        setGameOver(true);
        setScreen("results");
    };


    const handleRestartGame = () => {
        setQuiz([...phrasalVerbsQuiz]); // Reset quiz
        setCurrentQuestionIndex(Math.floor(Math.random() * quiz.length)); // Reset current question index
        setSelectedOption(null); // Reset selected option
        setGameOver(false); // Reset game over state
        setAnsweredQuestions([]); // Reset answered questions
        setCounter(15); // Reset counter
        setGameStart(true); // Start game
        setCorrectOption(null); // Reset correct option
        setScreen("instructions"); // Reset screen to instructions
        setCorrectCount(0); // Reset correct count
        setIncorrectCount(0); // Reset incorrect count
        setOptionSelected(false); // Reset option selected state
        setCurrentQuestionNumber(1); // Reset current question number
    };

    return (
        <div className='contaierPhrasalVerbs'>
            {(() => {
                switch (screen) {
                    case "instructions":
                        return (
                            <div className="info_box">
                                <div className="info-title">
                                    <span>Regras do jogo</span>
                                </div>
                                <div className="info-list">
                                    <div className="info">1. You will have only <span>15 seconds</span> per each question.</div>
                                    <div className="info">2. Once you select your answer, it can't be undone.</div>
                                    <div className="info">3. You can't select any option once time goes off.</div>
                                    <div className="info">4. You can't exit from the Quiz while you're playing.</div>
                                    <div className="info">5. You'll get points on the basis of your correct answers.</div>
                                </div>
                                <div className="buttons">
                                    <button className="quit" onClick={() => onClose()}>Exit Game</button>
                                    <button className="restart" onClick={() => setScreen("quiz")}>Next</button>
                                </div>
                            </div>
                        );
                    case "quiz":
                        return currentQuestion ? (
                            <div className='contaierPhrasalVerbs'>
                                <header className='headerPhrasalVerb'>
                                    <h3>Phrasal Verb: <span>{currentQuestion?.phrasalVerb}</span></h3>
                                    <div className="timer">
                                        <div className="time_left_txt">Time:</div>
                                        <div className="timer_sec">{counter}</div>
                                    </div>
                                </header>
                                <section>
                                    <div className='option_list'>
                                        {currentQuestion?.options.map((option, index) => (
                                            <div
                                                key={index}
                                                onClick={() => handleOptionClick(option)}
                                                className={
                                                    selectedOption
                                                        ? option.phrase === selectedOption.phrase
                                                            ? option.correct
                                                                ? "option correct"
                                                                : "option incorrect"
                                                            : correctOption && option.phrase === correctOption.phrase
                                                                ? "option correct disabled"
                                                                : "option disabled"
                                                        : "option"
                                                }
                                            >
                                                <span>{option.phrase}</span>
                                                {selectedOption
                                                    ? option.phrase === selectedOption.phrase
                                                        ? option.correct
                                                            ? <i className="fas fa-check"></i>
                                                            : <i className="fas fa-times"></i>
                                                        : correctOption && option.phrase === correctOption.phrase
                                                            ? <i className="fas fa-check"></i>
                                                            : null
                                                    : null
                                                }
                                            </div>
                                        ))}
                                    </div>
                                    <footer>
                                        <div className="total_que">
                                            <span>Question number: {currentQuestionNumber}</span>
                                        </div>
                                        {(selectedOption || timerOver) && (
                                            <div className="action-buttons">
                                                {currentQuestionNumber !== quiz.length - 1 && (
                                                    <button className="next_btn" onClick={handleNextClick}>Next</button>
                                                )}
                                                <button className="quit" onClick={handleStopClick}>Stop</button>
                                            </div>
                                        )}
                                    </footer>
                                </section>
                            </div>
                        ) : (
                            <div className="result_box">
                                <div className="icon">
                                    <i className="fas fa-crown"></i>
                                </div>
                                <div className="complete_text">Result!</div>
                                <div className="score_text">
                                    <p>Você acertou {correctCount} perguntas!</p>
                                    <p>Você errou {incorrectCount} perguntas.</p>
                                </div>
                                <div className="buttons">
                                    <button className="restart">Replay Game</button>
                                    <button className="quit">Quit Game</button>
                                </div>
                            </div>
                        );
                    case "results":
                        return (
                            <div className="result_box">
                                <div className="icon">
                                    <i className="fas fa-crown"></i>
                                </div>
                                <div className="complete_text">Result!</div>
                                <div className="score_text">
                                    <p>Você acertou {correctCount} perguntas!</p>
                                    <p>Você errou {incorrectCount} perguntas.</p>
                                </div>
                                <div className="buttons">
                                    <button className="restart" onClick={handleRestartGame}>Replay Game</button>
                                    <button className="quit" onClick={() => onClose()}>Quit Game</button>
                                </div>
                            </div>
                        );
                    default:
                        return null;
                }
            })()}
        </div >
    )

}

export default PhrasalVerbs;
