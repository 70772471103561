import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh } from '@fortawesome/free-solid-svg-icons';
import { Box, Typography } from '@mui/material';
import { AudioButton, OptionButton } from '../../StyledComponents';
import { sendTextToSpeechRequest } from '../../../../componentsTalkIA/IAtalk2ME/Talk2MeVoice';
import { useAllTheme } from '../../../../components/themes/ThemeContext';

function AudioExercise({ exercicio, setCheckFunction, setProgress, setControls, controls }) {
    const [respostaSelecionada, setRespostaSelecionada] = useState(null);
    const respostaSelecionadaRef = useRef(null);

    useEffect(() => {
        setCheckFunction(() => check);
    }, [setCheckFunction]);

    const selecionaResposta = (indice) => {
        setRespostaSelecionada(indice);
        respostaSelecionadaRef.current = indice;
        setControls((prev) => ({ ...prev, isDisabled: false }));
    };

    const check = () => {
        const selectedAnswer = respostaSelecionadaRef.current;
        if (selectedAnswer === exercicio.data.correct_answer) {
            setProgress({
                type: 'correct',
                explanation: 'Congratulations! You got the answer right.',
                userResponse: selectedAnswer
            });
        } else {
            setProgress({
                type: 'incorrect',
                explanation: `The correct answer is "${exercicio.data.correct_answer}".`,
                userResponse: selectedAnswer
            });
        }
        setControls((prev) => ({ ...prev, isChecked: true, isDisabled: true }));
    };

    const [audioUrl, setAudioUrl] = useState('');
    const audioRef = useRef(null);

    const speak = async () => {
        const url = await sendTextToSpeechRequest(exercicio.data.correct_answer, "Danielle");
        setAudioUrl(url);
    };

    useEffect(() => {
        if (audioUrl && audioRef.current) {
            audioRef.current.play();
        }
    }, [audioUrl]);

    const { theme, isDark } = useAllTheme();

    return (
        <Box sx={{ display: 'flex', maxWidth: '650px', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
            {audioUrl && (
                <audio
                    src={audioUrl}
                    ref={audioRef}
                    style={{ display: 'none' }}
                />
            )}
            <Typography variant="h4" sx={{ fontFamily: "SF-Pro-Display, sans-serif", color: theme.color }} gutterBottom>
                What do you listen?
            </Typography>
            <Box display="flex" sx={{ width: '100%' }} justifyContent="center" alignItems="center" flexWrap="wrap" py={4} gap={2}>
                <AudioButton onClick={speak}>
                    <FontAwesomeIcon icon={faVolumeHigh} size="50px" />
                </AudioButton>
                <Box display="flex" justifyContent="space-between" flexWrap="wrap" alignItems="center" sx={{ flex: 1 }}>
                    {exercicio.data.choices.map((option, index) => (
                        <OptionButton
                            key={index}
                            theme={theme}
                            onClick={() => selecionaResposta(option)}
                            disabled={controls.isDisabled}
                            className={respostaSelecionada === option ? 'optionSelected' : ''}
                            sx={{ width: 'calc(50% - 3px)', }}
                        >
                            <Box className="buttonOptionListeningNumber">
                                {index + 1}
                            </Box>
                            <Typography variant="body1" textAlign="center" sx={{ width: 'calc(100% - 38px)' }}>{option}</Typography>
                        </OptionButton>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}

export default AudioExercise;
