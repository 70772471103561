import { useEffect, useState, useRef } from "react";
import React from "react";
import TextBubble from "../ChatComponents/TextBubble";
import ImageBubble from "../ChatComponents/ImageBubble";
import LessonBubble from "../ChatComponents/LessonBubble";
import { Box } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import OptionsMenu from "../OptionsMenu";
import './style.css'

export default function ScrollMessage({ messageRef, messages, sx, boxes, setInteractLesson }) {

    const [selectedText, setSelectedText] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedTextWithTypes, setSelectedTextWithTypes] = useState([]);

    useEffect(() => {
        const handleMouseUp = (event) => {
            const selection = window.getSelection();
            const text = selection.toString().trim();
            if (text) {
                setSelectedText(text);
                setAnchorEl(event.target);

                const range = selection.getRangeAt(0);
                const container = range.commonAncestorContainer;
                const selectedElements = [];

                const findElementsInRange = (node, range, parentType = null) => {
                    if (node.nodeType === Node.ELEMENT_NODE) {
                        let elementType = node.nodeName.toLowerCase();
                        if (elementType === 'div' && parentType === null) {
                            // Traverse div to find actual content elements like h1, p, etc.
                            node.childNodes.forEach((childNode) => {
                                if (childNode.nodeType === Node.ELEMENT_NODE) {
                                    findElementsInRange(childNode, range, null);
                                }
                            });
                            return;
                        }

                        let currentBlock = { type: mapBlockType(elementType), children: [] };

                        node.childNodes.forEach((childNode, index, array) => {
                            if (childNode.nodeType === Node.TEXT_NODE && range.intersectsNode(childNode) && childNode.textContent.trim()) {
                                currentBlock.children.push({ text: childNode.textContent.trim() });
                            } else if (childNode.nodeType === Node.ELEMENT_NODE) {
                                if (['strong', 'em', 'u', 's'].includes(childNode.nodeName.toLowerCase()) && range.intersectsNode(childNode)) {
                                    const previousText = array[index - 1]?.textContent.trim() ? " " : "";
                                    const nextText = array[index + 1]?.textContent.trim() ? " " : "";
                                    currentBlock.children.push({ text: previousText });
                                    currentBlock.children.push(mapInlineStyle(childNode.nodeName.toLowerCase(), childNode.textContent.trim()));
                                    currentBlock.children.push({ text: nextText });
                                } else {
                                    if (currentBlock.children.length > 0) {
                                        selectedElements.push(currentBlock);
                                    }
                                    findElementsInRange(childNode, range, elementType);
                                    currentBlock = { type: mapBlockType(elementType), children: [] };
                                }
                            }
                        });

                        if (currentBlock.children.length > 0) {
                            selectedElements.push(currentBlock);
                        }
                    }
                };

                if (container.nodeType === Node.TEXT_NODE) {
                    findElementsInRange(container.parentNode, range);
                } else {
                    findElementsInRange(container, range);
                }

                setSelectedTextWithTypes(selectedElements);
            } else {
                setSelectedText('');
                setAnchorEl(null);
                setSelectedTextWithTypes([]);
            }
        };

        if (messageRef.current) {
            messageRef.current.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            if (messageRef.current) {
                messageRef.current.removeEventListener('mouseup', handleMouseUp);
            }
        };
    }, []);

    const mapInlineStyle = (type, text) => {
        switch (type) {
            case 'strong':
                return { text, bold: true };
            case 'em':
                return { text, italic: true };
            case 'u':
                return { text, underline: true };
            case 's':
                return { text, strikethrough: true };
            default:
                return { text };
        }
    };

    const mapBlockType = (type) => {
        switch (type) {
            case 'h1':
                return 'title';
            case 'h2':
                return 'heading';
            case 'h3':
                return 'subheading';
            case 'p':
                return 'body';
            case 'pre':
            case 'code':
                return 'monospaced';
            case 'li':
                return 'bulleted';
            case 'ul':
                return 'bulleted';
            default:
                return 'paragraph';
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedText('');
    };

    useEffect(() => {
        const lastMessageElement = messageRef.current.querySelector('.last-message');
        if (lastMessageElement) {
            lastMessageElement.classList.add('scaleBlurMessage');
            const handleAnimationEnd = () => {
                lastMessageElement.classList.remove('scaleBlurMessage');
            };
            lastMessageElement.addEventListener('animationend', handleAnimationEnd);
            return () => {
                lastMessageElement.removeEventListener('animationend', handleAnimationEnd);
            };
        }
    }, [messages]);

    const [showScrollButton, setShowScrollButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (messageRef.current) {
                const scrollTop = messageRef.current.scrollTop;
                const { scrollHeight, clientHeight } = messageRef.current;

                // Verificar se o usuário está no final da lista
                const atBottom = scrollTop + clientHeight >= scrollHeight - 1;

                // Mostrar o botão de rolagem se não estiver no final e se scrollTop for maior que 0
                setShowScrollButton(scrollTop <= 0 && !atBottom);
            }
        };

        if (messageRef.current) {
            messageRef.current.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (messageRef.current) {
                messageRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const scrollToBottom = () => {
        if (messageRef.current) {
            messageRef.current.scrollTo({ top: messageRef.current.scrollHeight, behavior: 'smooth' });
        }
    };

    return (
        <Box ref={messageRef} className='scrollMessage' sx={sx}>
            {messages
                .reduce((acc, message, index, arr) => {
                    const isFirstInGroup = index === 0 || message.role !== arr[index - 1].role;
                    if (isFirstInGroup) {
                        acc.push([]);
                    }
                    acc[acc.length - 1].push(message);
                    return acc;
                }, [])
                .reverse() // Inverter a ordem dos grupos para trabalhar com column-reverse
                .map((group, groupIndex, groupsArray) => (
                    <Box
                        key={`group-${groupIndex}`}
                        sx={{
                            mb: '8px',
                            mt: groupIndex === 0 ? '8px' : 0,
                            display: 'flex',
                            flexDirection: 'column-reverse',
                            gap: '2px',
                        }}
                    >
                        {group
                            .reverse() // Reverter a ordem das mensagens dentro do grupo
                            .map((message, messageIndex) => {
                                const isLastMessage = groupIndex === 0 && messageIndex === 0;
                                return (
                                    <Box
                                        key={`${groupIndex}-${messageIndex}`}
                                        className={isLastMessage ? 'last-message' : ''}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: message.role === 'user' ? 'flex-end' : 'flex-start',
                                            gap: '2px',
                                            position: 'relative',
                                            transformOrigin: message.role === 'user' ? 'right' : 'left',
                                        }}
                                    >
                                        {Array.isArray(message.content) ? (
                                            message.content.map((content, index) => {
                                                if (typeof content === 'string') {
                                                    return (
                                                        <TextBubble key={index} content={content} role={message.role} messageIndex={messageIndex} />
                                                    );
                                                } else if (typeof content === 'object' && content.text) {
                                                    return (
                                                        <TextBubble key={index} content={content.text} role={message.role} messageIndex={messageIndex} />
                                                    );
                                                } else if (content.type === 'image_url') {
                                                    return <ImageBubble key={index} url={content.image_url.url} />;
                                                }
                                                return null;
                                            })
                                        ) : (
                                            <TextBubble key={message.id} content={message.content} role={message.role} messageIndex={messageIndex} />
                                        )}
                                        {message.tool_calls && message.tool_calls.map((toolCall, index) => (
                                            <LessonBubble key={index} content={toolCall} setInteractLesson={setInteractLesson} status={toolCall.lessonStatus} id={toolCall.id} />
                                        ))}
                                    </Box>
                                );
                            })}
                    </Box>
                ))}

            {showScrollButton && (
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 'calc(60.8px + 20px)',
                        left: '50%',
                        width: '20px',
                        height: '20px',
                        backgroundColor: '#FFFFFF',
                        borderRadius: '50%',
                        transform: 'translateX(-50%)',
                        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        transition: 'all 0.3s ease',
                        animation: 'scaleBlur 0.3s ease-out',
                        ':hover': {
                            backgroundColor: "#F5F5F5",
                        },
                    }}
                    onClick={scrollToBottom}
                >
                    <ArrowDownwardIcon style={{ fontSize: '15px', color: '#007aff' }} />
                </Box>
            )}
            <OptionsMenu anchorEl={anchorEl} handleClose={handleClose} selectedText={selectedText} boxes={boxes} selectedTextWithTypes={selectedTextWithTypes} />
        </Box>
    )
}