import "./style.css"
import { FaCamera } from "react-icons/fa";
import UserAvatar from '../../components/UserAvatar';

import { UserContext } from '../../components/UserProvider';
import { useContext } from 'react';

export default function Start() {

    const { user, photoURL, displayName, email } = useContext(UserContext)

    return (
        <div className='settingsUser'>
            <div className='settingsUserAll'>
                <div className='headerSettings'>
                    <div className='photoUser'>
                        {user && <UserAvatar userName={displayName} email={email} photoURL={photoURL} size={96} />}
                        <div className='cameraMude'>
                            <FaCamera />
                        </div>
                    </div>
                    <div className='contentSettingsUserHeader'>
                        <h2>Welcome, {displayName}</h2>
                        <p>Manage your information, privacy, and security so that EnglishFlu meets your needs.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}