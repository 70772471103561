import React, { useState, useRef, useEffect } from "react";
import { Box, Grid, IconButton, LinearProgress } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import './style.css'
import BallTalkAI from "../../componentsTalkIA/talkBallAI";
import { CaptureWrapper } from "../Capture/CaptureWrapper";

const CenterContent = ({ handleDelete, addBoxCenter, boxes, setBoxes, addBox, theme, isDark }) => {

    const [activeBox, setActiveBox] = useState(null);
    const [progress, setProgress] = useState(0);
    const containerRef = useRef(null);
    const progressRef = useRef(null);
    const [isVoiceActive, setIsVoiceActive] = useState(null);

    const handleMouseDown = (type) => {
        if (window.event.ctrlKey) {
            setActiveBox(type);
            progressRef.current = setInterval(() => {
                setProgress((prevProgress) => {
                    if (prevProgress >= 140) {
                        clearInterval(progressRef.current);
                        // Adicionar classe de remoção antes de remover a caixa
                        setBoxes((prevBoxes) =>
                            prevBoxes.map((box) =>
                                box.type === type ? { ...box, removing: true } : box
                            )
                        );
                        setTimeout(() => {
                            setBoxes((prevBoxes) => prevBoxes.filter(box => box.type !== type));
                        }, 400);
                        setActiveBox(null);
                        return 0;
                    }
                    return prevProgress + 1;
                });
            }, 10); // 10ms interval to fill the progress in 2 seconds
        }
    };

    const handleMouseUp = () => {
        clearInterval(progressRef.current);
        setActiveBox(null);
        setProgress(0);
    };

    const handleMouseClick = (type, e) => {
        if (e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();
            const box = boxes.find(box => box.type === type);
            if (box && typeof addBoxCenter === 'function') {
                addBoxCenter(box.Component);
            }
        } else {
            handleMouseDown(type);
        }
    };

    useEffect(() => {
        setIsVoiceActive(boxes.some(box => box.type === 'voice' || box.type === 'avatar'));
    }, [boxes]);

    function addVoice() {
        addBox(
            () => (
                <Box sx={{ padding: '20px' }}>
                    <BallTalkAI
                        color={isDark ? "#FFFFFF" :  "#1D1D1F"}
                        size={280}
                    />
                </Box>
            ),
            'voice'
        );
    }

    return (
        // <Box ref={containerRef} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', padding: 2, borderRadius: '10px', position: 'relative', backgroundImage: "url('https://static.videezy.com/system/resources/thumbnails/000/004/950/original/snow-day-4k-living-background.jpg')", objectFit: 'cover' }}>
        <Box ref={containerRef} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', padding: 2, borderRadius: '10px', position: 'relative' }}>

            {!isVoiceActive && (
                <Box sx={{ position: 'absolute', top: 0, left: 0, padding: '2px', borderRadius: '4px', backgroundColor: theme.boxBackground, animation: 'scaleBlur 0.3s ease-out', }}>
                    <IconButton size="small" onClick={addVoice}>
                        <Box sx={{ borderRadius: '50%', width: '15px', height: '15px', backgroundColor: isDark ? "#FFFFFF" :  "#1D1D1F" }}></Box>
                    </IconButton>
                </Box>
            )}

            <Grid className="scrollOverflowCenterBox" container spacing={2} justifyContent="center" alignItems="center" sx={{ overflow: 'auto', minHeight: '100%', gap: 3, paddingTop: '10px', paddingBottom: '10px' }}>
                {boxes.map((box, index) => (
                    <CaptureWrapper name={box.type} key={box.id}>
                        <Grid item>
                            <Box
                                className={box.removing ? 'removing' : ''}
                                sx={{
                                    backgroundColor: boxes.length > 1 ? theme.boxBackground : 'transparent',
                                    maxWidth: '900px',
                                    maxHeight: '900px',
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    transition: 'all 0.3s ease',
                                    position: 'relative',
                                    overflow: 'visible', // Permitir overflow visível
                                    animation: 'scaleBlur 0.3s ease-out',
                                    boxShadow: box.type !== 'voice' && box.type !== 'avatar' ? '0px 2px 5px rgba(0, 0, 0, 0.4)' : 'none',
                                    '&:hover .delete-button': {
                                        opacity: 1,
                                    }
                                }}
                                onMouseDown={(e) => handleMouseClick(box.type, e)}
                                onMouseUp={handleMouseUp}
                                onMouseLeave={handleMouseUp}
                            >
                                {activeBox === box.type && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: theme.activeBoxOverlay,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backdropFilter: 'blur(5px)',
                                            zIndex: 10,
                                        }}
                                    >
                                        <LinearProgress variant="determinate" value={progress} sx={{ width: '80%' }} />
                                    </Box>
                                )}
                                <Box
                                    className="delete-button"
                                    onClick={() => handleDelete(box.type)}
                                    sx={{
                                        position: 'absolute',
                                        top: '-8px',
                                        right: '-8px',
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: theme.deleteButtonBackground,
                                        borderRadius: '50%',
                                        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        opacity: 0,
                                        transition: 'all 0.3s ease',
                                        zIndex: 11,
                                        ':hover': {
                                            backgroundColor: theme.deleteButtonHoverBackground,
                                        },
                                    }}
                                >
                                    <CloseIcon style={{ fontSize: '15px', color: theme.deleteButtonIconColor }} />
                                </Box>
                                <box.Component />
                            </Box>
                        </Grid>
                    </CaptureWrapper>
                ))}
            </Grid>
        </Box>
    );
};

export default CenterContent;