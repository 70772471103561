import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB29rGTUGBx1dtaGKXA8ObpS9QAE05p5IE",
  authDomain: "talk2meia.firebaseapp.com",
  projectId: "talk2meia",
  storageBucket: "talk2meia.appspot.com",
  messagingSenderId: "775539750363",
  appId: "1:775539750363:web:f156bc59dc67a8798ca3fe",
  measurementId: "G-9855NTZML6"
};

initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore();
export const storage = getStorage();