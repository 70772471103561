import React, { createContext, useState, useContext } from 'react';

// Criando o contexto
const CallContext = createContext();

export const useCall = () => useContext(CallContext);

// Provedor do contexto
export const CallProvider = ({ children }) => {
    const [calling, setCalling] = useState(null);

    const setCall = (post) => {
        setCalling(post);
    };

    return (
        <CallContext.Provider value={{ calling, setCall }}>
            {children}
        </CallContext.Provider>
    );
};
