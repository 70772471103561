import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"
import HeaderCommunity from "../componentsCommunity/headerCommunity"
import './styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChevronDown, faChevronUp, faStar } from '@fortawesome/free-solid-svg-icons';
import { FaStar } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper/core';
import { db } from "../../auth/config/firebase";
import { collection, query, where, getDocs, limit, getDoc, doc, updateDoc, setDoc, onSnapshot } from 'firebase/firestore';
import { auth } from "../../auth/config/firebase";

import robotNoFavorites from '../images/robo-sem-fundo.png'
import PostOnCommunity from "../componentsCommunity/postOnCommunity";
import UserAvatar from "../../components/UserAvatar";

export default function UsersCommunity() {

    const [showPostOnCommunity, setShowPostOnCommunity] = useState(false)
    const [selectedPost, setSelectedPost] = useState(null);
    const [selectedEditPost, setSelectedEditPost] = useState(null);

    const UID = auth.currentUser.uid

    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            const usersCollectionRef = collection(db, 'community');
            const q = query(usersCollectionRef, limit(20)); // Limita a consulta a 20 documentos
            const usersSnapshot = await getDocs(q);
            const usersList = usersSnapshot.docs.map(doc => {
                return { uid: doc.id, ...doc.data() }; // Incluindo o UID
            });
            setUsers(usersList);
        };

        fetchUsers();
    }, []);


    const [isOpen, setIsOpen] = useState(false);
    const [showOptionLevel, setShowOptionLevel] = useState(false)

    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (searchTerm) {
                const cleanedSearchTerm = searchTerm.toLowerCase().replace(/\s+/g, ''); // Removendo os espaços e convertendo para minúsculas
                const usersRef = collection(db, 'community');

                // Adiciona os caracteres Unicode para definir o range da busca
                const startAt = cleanedSearchTerm;
                const endAt = cleanedSearchTerm + '\uf8ff';

                const q = query(
                    usersRef,
                    where('normalizedUserNick', '>=', startAt),
                    where('normalizedUserNick', '<=', endAt),
                    limit(10)  // Limite o número de resultados retornados
                );

                const snapshot = await getDocs(q);
                const users = snapshot.docs.map(doc => doc.data());
                setResults(users);
            } else {
                setResults([]);
            }
        }, 300);

        return () => clearTimeout(timer);
    }, [searchTerm]);


    const allSkills = [
        "🎓 IELTS",
        "💼 business",
        "🎤 interview",
        "🔧 engineer",
        "💰 finance",
        "⚕️ medical",
        "✍ writing",
        "⚖️ law",
        "👥 human resources",
        "📈 marketing",
        "🎶 music",
        "🎨 art",
        "⚽ football",
        "✈️ aviation",
        "🔬 science",
        "🌠 anime",
        "🎨 design",
        "🌍 travel",
        "📜 history",
        "🎬 movie",
        "🏅 sports",
        "💻 information technology",
        "🏫 education",
        "🩺 health care",
        "📖 Literature",
        "🔭 Astronomy",
        "🏢 Real Estate",
        "🧪 Chemistry",
        "🔍 Forensic Science",
        "🍽️ Culinary Arts",
        "📷 Photography",
        "🧘 Yoga",
        "👗 Fashion",
        "🌱 Environment",
        "🏛️ Politics",
        "🧑‍🚒 Firefighting",
        "🚜 Agriculture",
        "🕵️ Detective",
        "🪙 Cryptocurrency",
        "🎭 Theater",
        "🧠 Psychology",
        "🐕 Pet Care",
        "📊 Statistics",
        "🏊 Swimming",
        "🙏 Gospel",
        "🎪 Circus Arts",
        "🪁 Kite Flying",
        "🛹 Skateboarding",
        "💃 Dance",
        "👾 Video Games",
        "🌠 Astronomy",
        "🛠️ DIY",
        "🔭 Physics",
        "🗺️ Geography",
        "🏞️ National Parks",
        "🧩 Puzzles",
        "📸 Photography",
        "🍽️ Gastronomy",
        "🍶 Ceramics",
        "🕍 Religious Studies",
        "🎎 Puppetry",
        "🌐 Environmentalism",
        "🧁 Baking",
        "📚 Literature",
        "🔍 Detective Stories",
        "🤖 Robotics",
        "👽 UFOlogy",
        "🚀 Space Exploration",
        "🍀 Gardening",
        "🍷 Winemaking",
        "🌱 Veganism",
        "🎯 Archery",
        "📻 Podcasting",
        "🏰 Medieval History",
        "💫 Astrology",
        "🦖 Paleontology",
        "🐍 Herpetology",
        "🎢 Roller Coasters",
        "💡 Philosophy",
        "🐦 Bird Watching",
        "🎖️ Military History",
        "🎲 Board Games",
        "🎣 Fishing",
        "🌋 Geology",
        "🧵 Sewing",
        "🔖 Bibliophile",
        "🦺 Safety & First Aid",
        "🦠 Microbiology",
        "🎌 Vexillology (study of flags)",
        "💎 Gemology",
        "🐟 Ichthyology",
        "🛶 Kayaking",
        "🎮 Game Development",
        "🏹 Hunting",
        "🧊 Ice Sculpting",
        "👩‍🌾 Homesteading",
        "👢 Cowboy Culture",
        "🍂 Autumn Enthusiast",
        "🌸 Flower Arranging",
        "🐝 Beekeeping"
    ]

    const [currentUserFavorites, setCurrentUserFavorites] = useState([]);

    const toggleFavorite = async (currentUserId, favoriteUserId) => {
        const userRef = doc(db, 'community', currentUserId);
        const userSnapshot = await getDoc(userRef);
        const userData = userSnapshot.data();

        // Atualização otimista: Mude o ícone imediatamente
        let newFavorites;
        if (userData.favorites && userData.favorites.includes(favoriteUserId)) {
            newFavorites = userData.favorites.filter(id => id !== favoriteUserId);
        } else {
            newFavorites = userData.favorites ? [...userData.favorites, favoriteUserId] : [favoriteUserId];
        }
        setCurrentUserFavorites(newFavorites);

        try {
            // Tentativa de atualizar o banco de dados
            await updateDoc(userRef, { favorites: newFavorites });
        } catch (error) {
            console.error("Erro ao atualizar os favoritos: ", error);

            // Reverter a mudança na UI se a operação falhar
            setCurrentUserFavorites(userData.favorites);

            // Mostrar um alerta para o usuário
            alert("Ocorreu um erro ao atualizar seus favoritos. Por favor, tente novamente.");
        }
    }


    useEffect(() => {
        const loadFavorites = async () => {
            const userRef = doc(db, 'community', UID);
            const userSnapshot = await getDoc(userRef);
            if (userSnapshot.exists()) {
                const userData = userSnapshot.data();
                setCurrentUserFavorites(userData.favorites || []);
            }
        };

        loadFavorites();
    }, [UID]);

    const [favorites, setFavorites] = useState([]);
    const [favoritesUIDs, setFavoritesUIDs] = useState([]);

    useEffect(() => {
        const userRef = doc(db, 'community', UID);

        const unsubscribe = onSnapshot(userRef, (doc) => {
            if (doc.exists()) {
                const userData = doc.data();
                setFavoritesUIDs(userData.favorites || []);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchFavoritesData = async () => {
            const favoritesData = await Promise.all(
                favoritesUIDs.map(async (favoriteUID) => {
                    const favoriteUserRef = doc(db, 'community', favoriteUID);
                    const favoriteUserDoc = await getDoc(favoriteUserRef);

                    if (favoriteUserDoc.exists()) {
                        return { uid: favoriteUID, ...favoriteUserDoc.data() };
                    }
                    return null;
                })
            );

            setFavorites(favoritesData.filter(favorite => favorite !== null));
        };

        if (favoritesUIDs.length > 0) {
            fetchFavoritesData();
        }
        else {
            setFavorites([])
        }
    }, [favoritesUIDs]);

    const [selectedFilter, setSelectedFilter] = useState('All');

    const [activeSkills, setActiveSkills] = useState([]);

    const handleSkillClick = (skill) => {
        if (activeSkills.includes(skill)) {
            // Remova a habilidade do array se ela já estiver ativa
            setActiveSkills(activeSkills.filter(activeSkill => activeSkill !== skill));
        } else {
            if (activeSkills.length < 5) {
                // Adicione a habilidade ao array se ela não estiver ativa e menos de 5 habilidades estiverem ativas
                setActiveSkills([...activeSkills, skill]);
            } else {
                alert('Você só pode selecionar até 5 habilidades.');
            }
        }
    };

    const [usersWithSkills, setUsersWithSkills] = useState([]);

    useEffect(() => {
        const fetchUsersWithSkills = async () => {
            if (activeSkills.length === 0) {
                setUsersWithSkills([]);
                return;
            }

            const usersRef = collection(db, 'community');
            const usersQuery = query(usersRef, where('selectedSkills', 'array-contains-any', activeSkills), limit(20));

            const usersSnapshot = await getDocs(usersQuery);
            const usersData = usersSnapshot.docs.map(doc => ({ uid: doc.id, ...doc.data() }));

            setUsersWithSkills(usersData);
        };

        fetchUsersWithSkills();
    }, [activeSkills]);


    let usersToDisplay;

    switch (selectedFilter) {
        case "All":
            if (activeSkills.length >= 1) {
                usersToDisplay = usersWithSkills;  // Adicionando essa condição para verificar se existem habilidades ativas
            } else {
                usersToDisplay = users;
            }
            break;
        case "Favorites":
            usersToDisplay = favorites;
            break;
        default:
            usersToDisplay = []; // ou null, dependendo de como você quer lidar com casos não esperados
    }

    const [filterUserLevel, setFilterUserLevel] = useState(true)

    return (
        <>
            <PostOnCommunity showPostOnCommunity={showPostOnCommunity} setShowPostOnCommunity={setShowPostOnCommunity} setSelectedPost={setSelectedPost} setSelectedEditPost={setSelectedEditPost} />

            <div className="allCommunity">
                <div className="positionFixed">
                    <HeaderCommunity showPostOnCommunity={showPostOnCommunity} setShowPostOnCommunity={setShowPostOnCommunity} />
                </div>
                <div className='containerCommunity'>
                    <div className="containerWidthCommunity" style={{ marginTop: 75 }}>
                        <div className="centerWidthCommunity">
                            <div className="someoneToTalkCommunity">
                                <h2>Someone to talk</h2>
                                <div className="filterSomeoneToTalkCommunity">
                                    <Link
                                        className={selectedFilter === 'All' ? 'active' : ''}
                                        onClick={() => setSelectedFilter('All')}
                                    >
                                        All
                                    </Link>
                                    {/* <Link
                                    className={selectedFilter === 'Online' ? 'active' : ''}
                                    onClick={() => setSelectedFilter('Online')}
                                >
                                    Online
                                </Link> */}
                                    <Link
                                        className={selectedFilter === 'Favorites' ? 'active' : ''}
                                        onClick={() => setSelectedFilter('Favorites')}
                                    >
                                        Favorites
                                    </Link>
                                </div>
                            </div>
                            <div className="userInputSearch">
                                <div className={results.length > 0 ? 'inputSearchSomeoneCommunity active' : 'inputSearchSomeoneCommunity'}>
                                    <input type="text" placeholder='Albert lehi' value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
                                    <FontAwesomeIcon icon={faSearch} />
                                </div>
                                {results.length > 0 && (
                                    <div className="usersSearch">
                                        <ul>
                                            {results.map((user, index) => (
                                                <Link key={index} to={`/community/@${user?.userNick.toLowerCase().replace(/\s+/g, '')}`}>
                                                    <div className="liUserSearch">
                                                        <div className="flexUserSearch">
                                                            <div>
                                                                <UserAvatar userName={user.userNick} photoURL={user.photoURL} size={40} />
                                                            </div>
                                                            <div className="flexInfoUser">
                                                                <Link to={`/community/@${user?.userNick.toLowerCase().replace(/\s+/g, '')}`}>{user?.userNick.toLowerCase().replace(/\s+/g, '')}</Link>
                                                                <p>{user.userNick}</p>
                                                            </div>
                                                        </div>
                                                        <div className="buttonCallSearchUser">
                                                            <Link to={`/community/@${user?.userNick.toLowerCase().replace(/\s+/g, '')}`}>Profile</Link>
                                                        </div>
                                                    </div>
                                                    <p className="countryUser">{user.selectedCountry}</p>
                                                </Link>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div className="flexTopicsFilter">
                                <div className={`topicsFilter ${isOpen ? 'open' : ''}`}>
                                    {allSkills.map(skill => (
                                        <li
                                            key={skill}
                                            className={activeSkills.includes(skill) ? 'active' : ''}
                                            onClick={() => handleSkillClick(skill)}
                                        >
                                            {skill}
                                        </li>
                                    ))}
                                </div>
                                <div className="moreTopicsFilter" onClick={() => setIsOpen(!isOpen)}>
                                    <span>{isOpen ? 'less' : 'more'}</span>
                                    <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
                                </div>
                            </div>

                            <div className="filterUsers">
                                <h3>Filter by</h3>
                                <div className="filterUsersOptions">
                                    <div className="flexMenuFilterUser">
                                        <div className="optionFilterUser" onClick={() => setShowOptionLevel(!showOptionLevel)}>
                                            <span>{filterUserLevel.length > 0 ? filterUserLevel : 'User level'}</span>
                                            <FontAwesomeIcon icon={faChevronDown} />
                                        </div>
                                        <div className={`menuOptionFilter ${!showOptionLevel ? 'hide' : 'active'}`}>
                                            <span onClick={() => [setFilterUserLevel('Beginner'), setShowOptionLevel(false)]}>Beginner</span>
                                            <span onClick={() => [setFilterUserLevel('Intermediate'), setShowOptionLevel(false)]}>Intermediate</span>
                                            <span onClick={() => [setFilterUserLevel('Advanced'), setShowOptionLevel(false)]}>Advanced</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='containerBottomCommunity noBlur'>
                    <div className="containerWidthCommunity">
                        <div className="centerWidthCommunity">
                            {usersToDisplay.length > 0 && (
                                <div className="usersCommunity">
                                    <h2>{selectedFilter === 'Favorites' ? 'Favorite users' : 'Users community'}</h2>
                                </div>
                            )}

                            <div className="usersCommunityContent">
                                {usersToDisplay && usersToDisplay.map((user, index) => {
                                    const isFavorite = currentUserFavorites.includes(user.uid);
                                    return (
                                        <div className="boxUserCommunity" key={index}>
                                            <div className="flexUserInfoCommunity">
                                                <Link className="avatarUserCommunity" to={`/community/@${user.normalizedUserNick}`}>
                                                    <UserAvatar userName={user.userNick} photoURL={user.photoURL} size={130} />
                                                </Link>
                                                <div className="contentInfoUserCommunity">
                                                    <div className="flexNameUserCommunity">
                                                        <Link to={`/community/@${user.normalizedUserNick}`}>{user.userNick}</Link>
                                                        {isFavorite
                                                            ?
                                                            <FontAwesomeIcon icon={faStar} onClick={() => toggleFavorite(UID, user.uid)} />
                                                            :
                                                            <i className='bx bx-star' onClick={() => toggleFavorite(UID, user.uid)}></i>
                                                        }
                                                    </div>
                                                    <span>Beginner</span>
                                                    <div className="flagAndCountryUserCommunity">
                                                        <img src={"https://www.gov.br/agricultura/pt-br/assuntos/relacoes-internacionais/agro-mais-investimentos/imagens/bandeira-dos-estados-unidos.png/@@images/image.png"} alt="" />
                                                        <span>{user.selectedCountry}</span>
                                                    </div>
                                                    <Swiper slidesPerView='auto' spaceBetween={10} className="swipperInfoTopicsUserCommunity" loop={false} modules={[Autoplay, Pagination, Navigation]}>
                                                        {user.selectedSkills && user.selectedSkills.map((skill, skillIndex) => (
                                                            <SwiperSlide className="slideTopicsUserCommunity" key={skillIndex}>{skill}</SwiperSlide>
                                                        ))}
                                                    </Swiper>
                                                </div>
                                            </div>
                                            <div className="buttonMoreInfoUserCommunity">
                                                <Link to={`/community/@${user.normalizedUserNick}`}>Profile</Link>
                                                <Link to={`/community/chat/@${user.normalizedUserNick}`}>Chat</Link>
                                            </div>
                                        </div>
                                    )
                                })}

                                {selectedFilter === 'Favorites' && usersToDisplay.length <= 0 && (
                                    <div className="noFavorites">
                                        <img src={robotNoFavorites} />
                                        <span>It looks like you don't have any favorite users yet</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}