import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh } from '@fortawesome/free-solid-svg-icons';
import './styles.css';
import { Box, Typography } from '@mui/material';
import { sendTextToSpeechRequest } from '../../../../componentsTalkIA/IAtalk2ME/Talk2MeVoice';
import { AudioButton, StyledTextarea } from '../../StyledComponents';
import { useAllTheme } from '../../../../components/themes/ThemeContext';

export default function WriteExercise({ exercicio, setCheckFunction, setProgress, setControls, controls }) {
    const inputValueRef = useRef('');

    useEffect(() => {
        setCheckFunction(() => check);
        // console.log('setCheckFunction configurada');
    }, [setCheckFunction]);

    useEffect(() => {
        if (!controls.isChecked) {
            inputValueRef.current = '';
            // console.log("Estado resetado");
        }
    }, [controls.isChecked]);

    const words = exercicio.data.shuffled_sentence.split(' ').map((word, index) => {
        let className = '';
        if ((` ${inputValueRef.current.toLowerCase()} `).includes(` ${word.toLowerCase()} `)) {
            className = 'highlighted-word';
        }

        return (
            <span key={index} className={className}>{word} </span>
        );
    });

    const handleInputChange = (e) => {
        inputValueRef.current = e.target.value;
        setControls((prev) => ({ ...prev, isDisabled: false }));
    };

    const check = () => {
        if (inputValueRef.current.trim().toLowerCase() === exercicio.data.original_sentence.trim().toLowerCase()) {
            setProgress({
                type: 'correct',
                explanation: 'Parabéns! Você acertou.',
                userResponse: inputValueRef.current.trim().toLowerCase()
            });
        } else {
            setProgress({
                type: 'incorrect',
                explanation: `A resposta correta é "${exercicio.data.original_sentence}".`,
                userResponse: inputValueRef.current.trim().toLowerCase()
            });
        }
        setControls((prev) => ({ ...prev, isChecked: true }));
    };

    const [audioUrl, setAudioUrl] = useState('');
    const audioRef = useRef(null);

    const handleSpeak = async () => {
        const url = await sendTextToSpeechRequest(exercicio.data.original_sentence, "Danielle");
        setAudioUrl(url);
    };

    useEffect(() => {
        if (audioUrl && audioRef.current) {
            audioRef.current.play();
        }
    }, [audioUrl]);

    const { theme, isDark } = useAllTheme();

    return (
        <Box>
            {audioUrl && (
                <audio
                    src={audioUrl}
                    ref={audioRef}
                    controls
                    style={{ display: 'none' }}
                />
            )}
            <Typography variant="h4" sx={{ mb: 2, fontFamily: "SF-Pro-Display, sans-serif", color: theme.color }}>
                Listening and write the phrase
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                <AudioButton onClick={handleSpeak}>
                    <FontAwesomeIcon icon={faVolumeHigh} size='50px' />
                </AudioButton>
                <Box sx={{ ml: 2 }}>
                    <Box sx={{
                        padding: '20px', border: `2px solid ${theme.border}`, borderRadius: '10px', position: 'relative', zIndex: 10, color: theme.color, fontWeight: 400, mt: 2, '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: '50%',
                            left: '-10px',
                            width: 0,
                            height: 0,
                            borderTop: '10px solid transparent',
                            borderBottom: '10px solid transparent',
                            borderRight: '10px solid #1899D6',
                            transform: 'translateY(-50%)',
                        }
                    }}>
                        <Box>
                            <Typography component="span" style={{ color: theme.color }}>
                                {words}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <StyledTextarea
                theme={theme}
                isDark={isDark}
                sx={{ width: '100%' }}
                value={inputValueRef.current}
                onChange={handleInputChange}
                disabled={controls.isChecked}
                placeholder='Digite aqui'
                minRows={5}
            />
        </Box>
    );
}
