import React, {  useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Scenarios from '../scenarios';
import ChatScenarios from '../ChatScenarios';
import { db } from '../../auth/config/firebase';
import { collection, getDocs } from 'firebase/firestore';

function MainScenarios() {
    
    const location = useLocation();
    const { id } = useParams();

    const [scenarios, setScenarios] = useState([]);

    useEffect(() => {
        const fetchScenarios = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'scenarios'));
                const scenariosData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setScenarios(scenariosData);
            } catch (error) {
                console.error('Erro ao buscar dados:', error);
            }
        };

        fetchScenarios();
    }, []);

    return (
        <div>
            {location.pathname == ('/scenarios') && (
                <Scenarios scenarios={scenarios} />
            )}

            {id != null && (
                <ChatScenarios scenarios={scenarios} />
            )}
        </div>
    );

    // return (
    //     <div>
    //         <div sx={{ display: location.pathname == ('/scenarios') ? 'flex' : 'none'}}>
    //             <Scenarios scenarios={scenarios} />
    //         </div>
    //         <div sx={{ display: id ? 'flex' : 'none'}}>
    //             <ChatScenarios scenarios={scenarios} />
    //         </div>
    //     </div>
    // );
}

export default MainScenarios;
