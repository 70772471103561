import React, { useState } from "react"
import './style.css'
import { auth } from "../../../auth/config/firebase";
import { EmailAuthProvider, reauthenticateWithCredential, updateEmail, updatePassword } from 'firebase/auth';
import { Link } from "react-router-dom";
import { addNotification } from "../../../components/notification";

export default function ChangeEmail({ setBarLoad }) {

    const [password, setPassword] = useState({ value: '', error: '' })
    const [email2, setEmail2] = useState({ value: '', error: '' })
    const [retypeEmail, setRetypeEmail] = useState({ value: '', error: '' })

    const reauthenticate = (currentPassword) => {
        var user = auth.currentUser;
        var cred = EmailAuthProvider.credential(user.email, currentPassword);
        return reauthenticateWithCredential(user, cred);
    }

    const changeEmail = (newEmail, currentPassword) => {


        if (!password.value || !email2.value || !retypeEmail.value) {
            addNotification('Todos os campos devem estar preenchidos', 'error3');
            return;
        }

        // Primeiro, verifique se os dois campos de email são iguais
        if (email2.value !== retypeEmail.value) {
            addNotification('Os e-mails não coincidem', 'error3');
            return;
        }

        reauthenticate(currentPassword).then(() => {

            setBarLoad(50)

            var user = auth.currentUser;
            updateEmail(user, newEmail).then(() => {
                setBarLoad(100)
                addNotification('Email atualizado com sucesso!', 'success3');
                setBarLoad(0)
            }).catch((error) => {
                setBarLoad(0)
                switch (error.code) {
                    case 'auth/invalid-email':
                        addNotification('O endereço de e-mail está mal formatado.', 'error3');
                        break;
                    case 'auth/email-already-in-use':
                        addNotification('O endereço de e-mail já está sendo usado por outra conta.', 'error3');
                        break;
                    case 'auth/requires-recent-login':
                        addNotification('Essa operação é sensível e requer autenticação recente. Efetue login novamente antes de tentar essa solicitação.', 'error3');
                        break;
                    default:
                        addNotification('Erro ao atualizar o e-mail', 'error3');
                        break;
                }
                
            });
        }).catch((error) => {
            // console.error('Error reauthenticating: ', error);
            setBarLoad(0)
            switch (error.code) {
                case 'auth/user-mismatch':
                    addNotification('As credenciais fornecidas não correspondem ao usuário que está logado.', 'error3');
                    break;
                case 'auth/user-not-found':
                    addNotification('Nenhuma conta corresponde às credenciais fornecidas.', 'error3');
                    break;
                case 'auth/invalid-credential':
                    addNotification('As credenciais fornecidas são inválidas, expiraram ou o método de autenticação usado não é mais disponibilizado.', 'error3');
                    break;
                case 'auth/invalid-email':
                    addNotification('O endereço de e-mail está mal formatado.', 'error3');
                    break;
                case 'auth/wrong-password':
                    addNotification('Senha inválida. Por favor, insira a senha correta.', 'error3');
                    break;
                case 'auth/too-many-requests':
                    addNotification('Acesso a esta conta foi temporariamente desativado devido a muitas tentativas de login falhadas. Você pode restaurá-la imediatamente redefinindo sua senha ou pode tentar novamente mais tarde.', 'error3');
                    break;
                default:
                    addNotification('Erro na reautenticação', 'error3');
                    break;
            }
        });
    }

    return (
        <div className="allSetting">

            <div id="notifications3"></div>
            <div className="containerSetting">
                <div className="changeEmail">
                    <h2>Change email</h2>

                    <div className="changeEmailOptions">
                        <div className="inputsChangeEmail">
                            <div className="inputChangeEmail">
                                <label>Password</label>
                                <input
                                    type="password"
                                    value={password.value}
                                    onChange={(event) => setPassword({ value: event.target.value, error: '' })}
                                    required
                                />
                                <Link>Forgot password?</Link>
                            </div>
                            <div className="inputChangeEmail">
                                <label>New email</label>
                                <input
                                    type="email"
                                    value={email2.value}
                                    onChange={(event) => setEmail2({ value: event.target.value, error: '' })}
                                    required
                                />
                            </div>
                            <div className="inputChangeEmail">
                                <label>Retype new email</label>
                                <input
                                    type="email"
                                    value={retypeEmail.value}
                                    onChange={(event) => setRetypeEmail({ value: event.target.value, error: '' })}
                                    required
                                />
                            </div>

                            <button onClick={() => changeEmail(email2.value, password.value)}>Change</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}