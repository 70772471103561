import React, { createContext, useContext, useState } from 'react';

// Criação do contexto
const BallTalkContext = createContext();

// Provedor do contexto
export const BallTalkProvider = ({ children }) => {
    const [audioUrl, setAudioUrl] = useState('');
    const [showLoadingMessage, setShowLoadingMessage] = useState(false);
    const [talkingMessageIndex, setTalkingMessageIndex] = useState(0);
    const [showInteractElements, setShowInteractElements] = useState(false);
    const [interrupt, setInterrupt] = useState(false);
    const [selectedSpeed, setSelectedSpeed] = useState("1x");
    const [scale, setScale] = useState(1);

    return (
        <BallTalkContext.Provider value={{ audioUrl, setAudioUrl, showLoadingMessage, setShowLoadingMessage, talkingMessageIndex, setTalkingMessageIndex, showInteractElements, setShowInteractElements, interrupt, setInterrupt, selectedSpeed, setSelectedSpeed, scale, setScale }}>
            {children}
        </BallTalkContext.Provider>
    );
};

// Hook para usar o contexto
export const useBallTalkContext = () => {
    return useContext(BallTalkContext);
};
