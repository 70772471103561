import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { OptionButton } from '../../StyledComponents';
import { useAllTheme } from '../../../../components/themes/ThemeContext';

export default function MultipleChoiceExercise({ exercicio, setCheckFunction, setProgress, setControls, controls }) {
    const respostaSelecionadaRef = useRef(null);

    useEffect(() => {
        setCheckFunction(() => check);
    }, [setCheckFunction]);

    useEffect(() => {
        if (!controls.isChecked) {
            respostaSelecionadaRef.current = null;
        }
    }, [controls.isChecked]);

    const selecionaResposta = (opcao) => {
        respostaSelecionadaRef.current = opcao;
        setControls((prev) => ({ ...prev, isDisabled: false }));
    }

    const check = () => {
        if (respostaSelecionadaRef.current === exercicio.data.correct_answer) {
            setProgress({
                type: 'correct',
                explanation: 'Parabéns! Você acertou a resposta.',
                userResponse: respostaSelecionadaRef.current
            });
        } else {
            setProgress({
                type: 'incorrect',
                explanation: `A resposta correta é "${exercicio.data.correct_answer}".`,
                userResponse: respostaSelecionadaRef.current
            });
        }
        setControls((prev) => ({ ...prev, isChecked: true }));
    }

    const { theme, isDark } = useAllTheme();

    return (
        <Box sx={{ maxWidth: '600px', width: '100%' }}>
            <Typography variant="h4" textAlign="center" sx={{ mb: 2, fontFamily: "SF-Pro-Display, sans-serif", color: theme.color }}>
                {exercicio.data.question}
            </Typography>
            {exercicio.data.options.map((opcao, index) => (
                <OptionButton
                    key={index}
                    theme={theme}
                    onClick={() => selecionaResposta(opcao)}
                    disabled={controls.isChecked}
                    className={respostaSelecionadaRef.current === opcao ? 'optionSelected' : ''}
                >
                    <Box className="buttonOptionListeningNumber">
                        {index + 1}
                    </Box>
                    <Typography variant="body1" textAlign="center" sx={{ width: 'calc(100% - 38px)' }}>{opcao}</Typography>
                </OptionButton>
            ))}
        </Box>
    );
}
