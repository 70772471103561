import React, { useEffect, useRef, useState } from "react";
import { useBallTalkContext } from "../../componentsTalkIA/BallTalkContext";

import video from '../../assets/VideoAI/movendo.mp4';
// import videoSpeaking from '../../assets/VideoAI/falandoo.mp4';
import videoSpeaking from '../../assets/VideoAI/falandoo.mp4';

export default function Avatar() {
    const { audioUrl, interrupt, showLoadingMessage, selectedSpeed } = useBallTalkContext();
    const idleVideoRef = useRef(null);
    const speakingVideoRef = useRef(null);
    const audioRef = useRef(null);

    const [isSpeaking, setIsSpeaking] = useState(false);

    useEffect(() => {
        if (audioRef.current) {
            switch (selectedSpeed) {
                case "0.2x":
                    audioRef.current.playbackRate = 0.2;
                    break;
                case "0.5x":
                    audioRef.current.playbackRate = 0.5;
                    break;
                case "1x":
                    audioRef.current.playbackRate = 1;
                    break;
                case "1.2x":
                    audioRef.current.playbackRate = 1.2;
                    break;
                case "1.5x":
                    audioRef.current.playbackRate = 1.5;
                    break;
                default:
                    audioRef.current.playbackRate = 1;
                    break;
            }
        }
    }, [selectedSpeed, audioUrl]);

    useEffect(() => {
        if (audioUrl != '' && interrupt) {
            const interruptAudio = async () => {
                try {
                    await audioRef.current.pause();
                } catch (e) {
                    console.error("Erro ao pausar áudio:", e);
                }
            };
            interruptAudio();
        }
    }, [interrupt])

    useEffect(() => {
        if (idleVideoRef.current) {
            idleVideoRef.current.play().catch(error => {
                console.error("Failed to auto-play idle video:", error);
            });
        }
        if (speakingVideoRef.current) {
            speakingVideoRef.current.play().catch(error => {
                console.error("Failed to auto-play speaking video:", error);
            });
        }
    }, []);

    const handleAudioPlay = () => {
        setIsSpeaking(true);
    };

    const handleAudioPause = () => {
        setIsSpeaking(false);
    };

    useEffect(() => {
        if (audioUrl && audioRef.current) {
            const playAudio = async () => {
                try {

                    await audioRef.current.play();
                } catch (e) {
                    console.error("Erro ao reproduzir áudio:", e);
                }
            };

            playAudio();
        }
    }, [audioUrl]);

    return (
        <>
            {/* <div style={{ position: 'relative', width: '280px', height: '280px', borderRadius: '50%',  }}> */}
            <div style={{ position: 'relative', width: '140px', height: '140px', borderRadius: '50%',  }}>
                <video
                    ref={idleVideoRef}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        opacity: isSpeaking ? 0 : 1,
                        transition: 'opacity 0.3s',
                        objectFit: 'cover'
                    }}
                    loop
                    autoPlay
                    muted
                    src={video}
                />
                <video
                    ref={speakingVideoRef}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        opacity: isSpeaking ? 1 : 0,
                        transition: 'opacity 0.3s',
                        objectFit: 'cover'
                    }}
                    loop
                    autoPlay
                    muted
                    src={videoSpeaking}
                />
            </div>
            {audioUrl && (
                <audio
                    ref={audioRef}
                    src={audioUrl}
                    style={{ display: 'none' }}
                    controls
                    onPlay={handleAudioPlay}
                    onPause={handleAudioPause}
                />
            )}
        </>
    );
}
