import SimpleMenu from '../components/SimpleMenu';
import { Box, Container, Button } from '@mui/material';
import HeadComponent from '../home/headComponent';
import PayComponent from '../home/PayComponent';
import AddFriend from '../home/addFriend';
import AdComponent from '../home/AdComponent';
import LessonTrack from './components/LessonTrack';
import { useAllTheme } from '../components/themes/ThemeContext';

export default function LessonScreen() {

  const { theme, isDark } = useAllTheme();

  return (
    <div className={isDark ? 'allHome dark' : 'allHome'}>
      <SimpleMenu />
      <Box className="home" sx={{ padding: '24px' }}>
        <Container sx={{ display: 'flex', gap: '48px', width: '100%' }}>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '41px' }}>
            <LessonTrack />
          </Box>
          <Box sx={{ width: '360px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px', position: 'sticky', top: 0, }}>
              <HeadComponent />
              <PayComponent />
              <AddFriend />
              <AdComponent />
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  );
}