import React, { useState, useEffect } from 'react';
import './styles.css'

import Start from './start';
import PersonalDetails from './personalDetails';
import PrivacySettings from './privacySettings';
import ChangeEmail from './privacySettings/changeEmail';
import ChangePassword from './privacySettings/changePassword';
import Sessions from './sessions';

import { UserContext } from '../components/UserProvider';
import { useContext } from 'react';
import Menu from './menuSettings';
import { useLocation } from 'react-router-dom';
import ChangeIdiom from './appIdiom';
import Subscription from './subscription';
import SimpleMenu from '../components/SimpleMenu';
import { Box } from '@mui/material';
import PostOnCommunity from '../community/componentsCommunity/postOnCommunity';
import EditProfile from './editProfile';

export default function Config() {

    const [showPostOnCommunity, setShowPostOnCommunity] = useState(false)
    const [selectedPost, setSelectedPost] = useState(null);
    const [selectedEditPost, setSelectedEditPost] = useState(null);

    const handleOpenEditPostOnCommunity = (selectedPost) => {
        setSelectedEditPost(selectedPost);
        setShowPostOnCommunity(true);
    };

    const handleOpenPostOnCommunity = (selectedPost) => {
        setSelectedPost(selectedPost);
        setShowPostOnCommunity(true);  // abre o popup
    };

    const { user, photoURL, displayName, email } = useContext(UserContext)

    const [barLoad, setBarLoad] = useState(0)

    let location = useLocation();

    const renderScreen = () => {
        switch (location.pathname) {
            case '/settings':
                return <Start />;
            // case '/settings/edit':
            //     return <PersonalDetails setBarLoad={setBarLoad} />;
            case '/settings/edit':
                return <EditProfile handleOpenEditPostOnCommunity={handleOpenEditPostOnCommunity} handleOpenPostOnCommunity={handleOpenPostOnCommunity} />;
            case '/settings/privacy':
                return <PrivacySettings />;
            case '/settings/privacy/email':
                return <ChangeEmail setBarLoad={setBarLoad} />;
            case '/settings/privacy/password':
                return <ChangePassword setBarLoad={setBarLoad} />;
            case '/settings/sessions':
                return <Sessions setBarLoad={setBarLoad} />;
            case '/settings/idiom':
                return <ChangeIdiom />;
            case '/settings/subscription':
                return <Subscription />;
            default:
                return null;

        }
    };

    return (
        <div className='allHome'>
            <PostOnCommunity showPostOnCommunity={showPostOnCommunity} setShowPostOnCommunity={setShowPostOnCommunity} post={selectedPost} setSelectedPost={setSelectedPost} selectedEditPost={selectedEditPost} setSelectedEditPost={setSelectedEditPost} />
            <SimpleMenu />
            <Box className="home" >
                <div className='allSettings'>
                    <div className='settings'>
                        <Menu currentScreen={location.pathname} />
                    </div>

                    <section className='contentInfo'>
                        <div className={barLoad ? "barLoad active" : "barLoad"}>
                            <div className="barLoadProgress" style={{ width: `${barLoad}%` }}></div>
                        </div>
                        <div className='contentInfoContent'>
                            {renderScreen()}
                        </div>
                    </section>
                </div>
            </Box>
        </div>
    )
}