import React, { useEffect } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useAllTheme } from '../../../../components/themes/ThemeContext';

function Explanation({ exercicio, setControls, nextExercise, setCheckFunction }) {

    useEffect(() => {
        setControls((prev) => ({ ...prev, isDisabled: false }));
        setCheckFunction(() => nextExercise);
    }, [setControls, setCheckFunction, nextExercise]);

    const { theme, isDark } = useAllTheme();

    return (
        <Box sx={{ py: 4, height: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%' }}>
                <Box sx={{ maxWidth: '800px', width: '100%' }}>
                    <Typography variant="h5" component="h2" sx={{ fontSize: '19px', fontFamily: "SF-Pro-Text-Regular, sans-serif", lineHeight: '130%', mb: 2, color: theme.color }}>
                        {exercicio.data.explanation_text}
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        border: `1px solid ${theme.border}`,
                        borderRadius: '10px',
                        width: '100%'
                    }}>
                        {exercicio.data.examples.map((word, index) => (
                            <Paper
                                key={index}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '100%',
                                    padding: '1em',
                                    backgroundColor: 'transparent',
                                    borderBottom: index !== exercicio.data.examples.length - 1 ? `1px solid ${theme.border}` : 'none',
                                    borderRadius: index === exercicio.data.examples.length - 1 ? '0 0 10px 10px' : '0'
                                }}
                                elevation={0}
                            >
                                <Typography variant="body1" textAlign='center' sx={{ color: theme.color }}>{word}</Typography>
                            </Paper>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default Explanation;
