import SimpleMenu from '../../components/SimpleMenu';
import HeadComponent from '../../home/headComponent';
import PayComponent from '../../home/PayComponent';
import AddFriend from '../../home/addFriend';
import AdComponent from '../../home/AdComponent';
import ScenariosCard from '../ScenariosCard';

import ItemSituation from '../ItemSituation';

import { Box, Typography, styled, Container } from '@mui/material';
import { useAllTheme } from '../../components/themes/ThemeContext';

const DividerContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    height: '2px',
    backgroundColor: theme.border,
    borderRadius: '20px',
    marginTop: '20px', // Ajuste para espaçamento superior
    marginBottom: '20px', // Ajuste para espaçamento inferior
}));

const DividerText = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.border,
    paddingLeft: '15px',
    paddingRight: '15px',
    backgroundColor: theme.background,
    fontFamily: 'Din-Round-Bold, sans-serif',
    fontSize: '16px',
    fontWeight: 'bold',
}));

export default function Scenarios({ scenarios }) {

    const { theme, isDark } = useAllTheme();


    return (
        <div className={isDark ? 'allHome dark' : 'allHome'}>
            <SimpleMenu />
            <Box className="home" sx={{ padding: '24px' }}>
                <Container sx={{ display: 'flex', gap: '48px', width: '100%' }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '41px' }}>
                        <ScenariosCard />
                        <DividerContainer theme={theme}>
                            <DividerText theme={theme}>Missões</DividerText>
                        </DividerContainer>
                        <ItemSituation situations={scenarios} theme={theme} />
                    </Box>
                    <Box sx={{ width: '360px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px', position: 'sticky', top: 0, }}>
                            <HeadComponent />
                            <PayComponent />
                            <AddFriend />
                            <AdComponent />
                        </Box>
                    </Box>
                </Container>
            </Box>
        </div>
    );
}