import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { Box, Typography } from '@mui/material';
import { sendVoiceToText } from '../../../../componentsTalkIA/IAtalk2ME/Talk2MeSTT';
import { sendTextToSpeechRequest } from '../../../../componentsTalkIA/IAtalk2ME/Talk2MeVoice';
import { AudioButton, StyledButton } from '../../StyledComponents';
import { useAllTheme } from '../../../../components/themes/ThemeContext';

function SpeakExercise({ exercicio, setCheckFunction, setProgress, setControls, controls }) {
    const [speechResult, setSpeechResult] = useState('');
    const [correctWords, setCorrectWords] = useState([]);
    const [listening, setListening] = useState(false);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);

    useEffect(() => {
        setCheckFunction(() => check);
        // console.log('setCheckFunction configurada');
    }, [setCheckFunction]);

    useEffect(() => {
        if (!controls.isChecked) {
            setSpeechResult('');
            setCorrectWords([]);
            setListening(false);
        }
    }, [controls.isChecked]);

    useEffect(() => {
        if (speechResult) {
            check();
        }
    }, [speechResult]);

    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                setListening(true);
                mediaRecorderRef.current = new MediaRecorder(stream);
                mediaRecorderRef.current.start();
                mediaRecorderRef.current.ondataavailable = (event) => {
                    audioChunksRef.current.push(event.data);
                };
            })
            .catch(err => console.error('Error accessing media devices:', err));
    };

    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current.onstop = async () => {
            setListening(false);
            const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
            audioChunksRef.current = [];
            if (audioBlob) {
                const response = await sendVoiceToText(audioBlob);
                setSpeechResult(response);
                checkWords(response);
                setControls((prev) => ({ ...prev, isDisabled: false }));
            }
        };
    };

    const checkWords = (speech) => {
        const cleanedSpeech = speech.replace(/[.,\/#!$%\^&\*;:{}=\-_`~\\()?]/g, "").trim().toLowerCase();
        const cleanedPhrase = exercicio.data.phrase_to_say.replace(/[.,\/#!$%\^&\*;:{}=\-_`~\\()?]/g, "").trim().toLowerCase();
        const words = cleanedPhrase.split(' ');
        const userWords = cleanedSpeech.split(' ');

        let newCorrectWords = [];

        for (let i = 0; i < userWords.length; i++) {
            if (words[i] && userWords[i] === words[i]) {
                newCorrectWords.push(words[i]);
            } else {
                break;
            }
        }

        setCorrectWords(newCorrectWords);
    };

    const check = () => {
        const cleanedPhrase = exercicio.data.phrase_to_say.replace(/[.,\/#!$%\^&\*;:{}=\-_`~\\()?]/g, "").toLowerCase();
        const cleanedResult = speechResult.replace(/[.,\/#!$%\^&\*;:{}=\-_`~\\()?]/g, "").toLowerCase();
        
        if (cleanedResult === cleanedPhrase) {
            console.log("Resposta correta");
            setProgress({
                type: 'correct',
                explanation: 'Congratulations! You got the answer right.',
                userResponse: cleanedResult
            });
        } else {
            console.log("Resposta incorreta");
            setProgress({
                type: 'incorrect',
                explanation: `We couldn't understand you`,
                userResponse: cleanedResult
            });
        }
        setControls((prev) => ({ ...prev, isChecked: true, isDisabled: true }));
    };

    const handleSpeak = () => {
        if (!listening) {
            startRecording();
        } else {
            stopRecording();
        }
    };

    const [audioUrl, setAudioUrl] = useState('');
    const audioRef = useRef(null);

    const handleSpeakPhrase = async () => {
        const url = await sendTextToSpeechRequest(exercicio.data.phrase_to_say, "Danielle");
        setAudioUrl(url);
    };

    useEffect(() => {
        if (audioUrl && audioRef.current) {
            audioRef.current.play();
        }
    }, [audioUrl]);

    const [currentPhrase, setCurrentPhrase] = useState(exercicio.data.phrase_to_say.toLowerCase());
    const words = currentPhrase.split(' ');

    useEffect(() => {
        setCurrentPhrase(exercicio.data.phrase_to_say);
    }, [exercicio.data.phrase_to_say]);

    const { theme, isDark } = useAllTheme();

    return (
        <Box>
            {audioUrl && (
                <audio
                    src={audioUrl}
                    ref={audioRef}
                    controls
                    style={{ display: 'none' }}
                />
            )}

            <Typography variant="h4" sx={{ mb: 2, fontFamily: "SF-Pro-Display, sans-serif", color: theme.color }}>
                Speak the phrase
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                <AudioButton onClick={handleSpeakPhrase}>
                    <FontAwesomeIcon icon={faVolumeHigh} size='50px' />
                </AudioButton>
                <Box sx={{ ml: 2 }}>
                    <Box sx={{
                        padding: '20px', border: `2px solid ${theme.border}`, borderRadius: '10px', position: 'relative', zIndex: 10, color: theme.color, fontWeight: 400,  mt: 2, '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: '50%',
                            left: '-10px',
                            width: 0,
                            height: 0,
                            borderTop: '10px solid transparent',
                            borderBottom: '10px solid transparent',
                            borderRight: '10px solid #1899D6',
                            transform: 'translateY(-50%)',
                        }
                    }}>
                        <Box>
                            {words.map((word, index) => (
                                <Typography
                                    component="span"
                                    key={index}
                                    sx={{ color: correctWords.includes(word.replace(/[.,\/#!$%\^&\*;:{}=\-_`~\\()?]/g, "").toLowerCase()) ? '#1899D6' : 'inherit' }}
                                >
                                    {word + ' '}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <StyledButton theme={theme} actived={listening} sx={{ width: '100%', margin: 'auto' }} onClick={handleSpeak}>
                <FontAwesomeIcon icon={faMicrophone} />
                <Typography sx={{ ml: 1, mr: 0 }}>{listening ? 'Stop speaking' : 'Press to speak'}</Typography>
            </StyledButton>
        </Box>
    );
}

export default SpeakExercise;
