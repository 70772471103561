import React from 'react';
import { Box } from "@mui/material";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import LinkifyIt from 'linkify-it';

const linkify = LinkifyIt();

const splitParagraphs = (text) => {
    return text.split('\n').filter((paragraph) => paragraph.trim() !== '');
};

const components = {
    a: ({ node, ...props }) => {
        return (
            <a {...props} target="_blank" rel="noopener noreferrer" className="truncate-link" style={{ color: "#607D8B" }} />
        );
    },
};

const TextBubble = ({ content, role, messageIndex }) => {

    if (content != ' ') {
        return (
            <Box
                sx={{
                    p: 1,
                    backgroundColor: role === 'user' ? '#007aff' : '#f1f0f0',
                    color: role === 'user' ? '#fff' : '#000',
                    borderRadius: '20px',
                    maxWidth: '75%',
                    wordWrap: 'break-word',
                    position: 'relative', // Para posicionar a "gotinha"
                    '&:after': {
                        content: '""',
                        position: 'absolute',
                        width: 0,
                        height: 0,
                        border: '10px solid transparent',
                        borderTopColor: messageIndex === 0 ? (role === 'user' ? '#007aff' : '#f1f0f0') : 'transparent',
                        top: '10px',
                        right: role === 'user' ? '-10px' : 'auto',
                        left: role === 'user' ? 'auto' : '-10px',
                    }
                }}
            >
                {splitParagraphs(content).map((paragraph, paragraphIndex) => {
                    const matches = linkify.match(paragraph);
                    if (matches) {
                        matches.forEach((match) => {
                            const url = match.url.startsWith("http") ? match.url : "http://" + match.url;
                            paragraph = paragraph.replace(match.raw, `[${match.raw}](${url})`);
                        });
                    }
                    return <ReactMarkdown key={paragraphIndex} className="markdown-body" rehypePlugins={[rehypeRaw]} components={components}>{paragraph}</ReactMarkdown>
                })}
            </Box>
        );
    }
};

export default TextBubble;
