import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
    Typography,
    Box,
    Container
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh } from '@fortawesome/free-solid-svg-icons';
import { AudioButton, LineBox, StyledButton } from '../../StyledComponents';
import { sendTextToSpeechRequest } from '../../../../componentsTalkIA/IAtalk2ME/Talk2MeVoice';
import { useAllTheme } from '../../../../components/themes/ThemeContext';

const SentenceBuilder = ({ exercicio, setCheckFunction, setProgress, setControls, controls, audio = false }) => {
    const linesRef = useRef([]);
    const sentencesRef = useRef([[], [], []]);
    const availableWordsRef = useRef([...exercicio.data.word_pool]);

    const [sentences, setSentences] = useState(sentencesRef.current);
    const [availableWords, setAvailableWords] = useState(availableWordsRef.current);
    const [typedWord, setTypedWord] = useState('');
    const [highlightedWords, setHighlightedWords] = useState([]);

    useEffect(() => {
        setCheckFunction(() => check);
        // console.log('setCheckFunction configurada');
    }, [setCheckFunction]);

    useEffect(() => {
        if (!controls.isChecked) {
            sentencesRef.current = [[], [], []];
            availableWordsRef.current = [...exercicio.data.word_pool];
            setSentences(sentencesRef.current);
            setAvailableWords(availableWordsRef.current);
            setControls((prev) => ({ ...prev, isDisabled: true }));
            setTypedWord('');
            setHighlightedWords([]);
            console.log("Estado resetado");
        }
    }, [controls.isChecked]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [availableWords, typedWord]);

    const handleKeyDown = (event) => {
        if (event.key.length === 1 && event.key.match(/[a-z]/i)  && !['INPUT', 'TEXTAREA', 'SELECT', 'CONTENTEDITABLE'].includes(event.target.tagName.toUpperCase()) && !(event.target.getAttribute("contenteditable") === "true")) {
            const newTypedWord = typedWord + event.key.toLowerCase();

            const matchingWords = availableWords.filter(word => 
                word.toLowerCase().startsWith(newTypedWord)
            );

            if (matchingWords.length > 0) {
                setTypedWord(newTypedWord);
                setHighlightedWords(matchingWords);

                if (matchingWords.length === 1) {
                    const wordIndex = availableWords.findIndex(word => 
                        word.toLowerCase() === matchingWords[0].toLowerCase()
                    );
                    addWordToSentence(matchingWords[0], wordIndex);
                    setTypedWord('');
                    setHighlightedWords([]);
                }
            } else {
                // Se não houver palavras correspondentes, zera o typedWord
                setTypedWord('');
                setHighlightedWords([]);
            }
        } else if (event.key === 'Backspace' && !['INPUT', 'TEXTAREA', 'SELECT', 'CONTENTEDITABLE'].includes(event.target.tagName.toUpperCase()) && !(event.target.getAttribute("contenteditable") === "true")) {
            setTypedWord(prev => prev.slice(0, -1));
        }
    };

    const addWordToSentence = (word, index) => {
        const wordElement = document.getElementById(`word-${index}`);
        const lineIndex = sentencesRef.current.findIndex(line => line.length < 5);
        if (lineIndex === -1) return;

        const lineRect = linesRef.current[lineIndex].getBoundingClientRect();
        const wordRect = wordElement.getBoundingClientRect();

        const newWord = {
            id: `${word}-${Date.now()}`,
            text: word,
            initialX: wordRect.left - lineRect.left,
            initialY: wordRect.top - lineRect.top,
        };

        sentencesRef.current[lineIndex] = [...sentencesRef.current[lineIndex], newWord];
        availableWordsRef.current = availableWordsRef.current.filter((_, i) => i !== index);

        setSentences([...sentencesRef.current]);
        setAvailableWords([...availableWordsRef.current]);
        setControls((prev) => ({ ...prev, isDisabled: false }));
    };

    const removeWordFromSentence = (lineIndex, wordIndex) => {
        const removedWord = sentencesRef.current[lineIndex][wordIndex].text;
        sentencesRef.current[lineIndex] = sentencesRef.current[lineIndex].filter((_, j) => j !== wordIndex);
        availableWordsRef.current = [...availableWordsRef.current, removedWord];

        setSentences([...sentencesRef.current]);
        setAvailableWords([...availableWordsRef.current]);
    };

    useEffect(() => {
        const reorganizeSentences = () => {
            let changed = false;
            const newSentences = [...sentencesRef.current];
            for (let i = 1; i < newSentences.length; i++) {
                while (newSentences[i].length > 0 && newSentences[i - 1].length < 5) {
                    const word = newSentences[i].shift();
                    newSentences[i - 1].push(word);
                    changed = true;
                }
            }
            if (changed) {
                sentencesRef.current = newSentences;
                setSentences(newSentences);
            }
        };
        reorganizeSentences();
    }, [sentences]);

    const check = () => {

        const userSentence = sentencesRef.current.flat().map(word => word.text);
        if (JSON.stringify(userSentence) === JSON.stringify(exercicio.data.correct_sentence)) {
            setProgress({
                type: 'correct',
                explanation: 'Congratulations! You got the answer right.',
                userResponse: userSentence.join(' ')
            });
        } else {
            setProgress({
                type: 'incorrect',
                explanation: `The correct sentence is "${exercicio.data.correct_sentence.join(' ')}".`,
                userResponse: userSentence.join(' ')
            });
        }
        setControls((prev) => ({ ...prev, isChecked: true }));
    };

    const [audioUrl, setAudioUrl] = useState('');
    const audioRef = useRef(null);

    const speak = async () => {
        const url = await sendTextToSpeechRequest(exercicio.data.correct_sentence.join(' '), "Danielle");
        setAudioUrl(url);
    };

    useEffect(() => {
        if (audioUrl && audioRef.current) {
            audioRef.current.play();
        }
    }, [audioUrl]);

    const { theme } = useAllTheme();

    return (
        <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {audioUrl && (
                <audio
                    src={audioUrl}
                    ref={audioRef}
                    style={{ display: 'none' }}
                />
            )}
            <Box sx={{ my: 4, width: '600px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    {!audio
                        ?
                        <AudioButton sx={{ mb: 2 }} onClick={speak}>
                            <FontAwesomeIcon size={50} icon={faVolumeHigh} />
                        </AudioButton>
                        :
                        <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontFamily: "SF-Pro-Display, sans-serif", }}>
                            Construa a frase
                        </Typography>
                    }
                </Box>
                {sentences.map((line, lineIndex) => (
                    <LineBox theme={theme} key={lineIndex} ref={el => linesRef.current[lineIndex] = el}>
                        <AnimatePresence>
                            {line.map((word, wordIndex) => (
                                <motion.div
                                    key={word.id}
                                    initial={{ x: word.initialX, y: word.initialY }}
                                    animate={{ x: 0, y: 0 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ type: "spring", stiffness: 500, damping: 25 }}
                                    layout
                                >
                                    <StyledButton
                                        theme={theme}
                                        onClick={() => removeWordFromSentence(lineIndex, wordIndex)}
                                    >
                                        {word.text}
                                    </StyledButton>
                                </motion.div>
                            ))}
                        </AnimatePresence>
                    </LineBox>
                ))}
                <Box sx={{ mt: 4, width: '600px' }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: 'center' }}>
                        {availableWords.map((word, index) => (
                            <motion.div
                                key={index}
                                id={`word-${index}`}
                                layout
                            >
                                <StyledButton
                                    theme={theme}
                                    onClick={() => addWordToSentence(word, index)}
                                    disabled={sentences.every(line => line.length >= 5)}
                                >
                                    {word.split('').map((char, i) => (
                                        <span
                                            key={i}
                                            style={{
                                                color: i < typedWord.length && word.toLowerCase().startsWith(typedWord.toLowerCase())
                                                    ? 'blue'
                                                    : 'inherit',
                                                fontWeight: i < typedWord.length && word.toLowerCase().startsWith(typedWord.toLowerCase())
                                                    ? 'bold'
                                                    : 'normal'
                                            }}
                                        >
                                            {char}
                                        </span>
                                    ))}
                                </StyledButton>
                            </motion.div>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default SentenceBuilder;