import { Box, Typography } from '@mui/material'
import react, { useContext } from 'react'

import fire from '../../assets/fire.svg'
import fire_dark from '../../assets/fire_dark.svg'
import { UserContext } from '../../components/UserProvider'

import './style.css'
import UserAvatar from '../../components/UserAvatar'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAllTheme } from '../../components/themes/ThemeContext'

export default function HeadComponent() {

    const { displayName, photoURL, email } = useContext(UserContext)
    const firstName = displayName.split(' ')[0];

    const { theme, isDark } = useAllTheme();

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                <img src={isDark ? fire_dark : fire} />
                <Typography variant="h5" component="div" sx={{ fontFamily: 'Din-Round-Bold, sans-serif', color: theme.border, fontSize: '15px' }}>
                    0
                </Typography>
            </Box>
            <Box className='profile' style={{ border: `2px solid ${theme.border}` }}>
                <UserAvatar userName={displayName} photoURL={photoURL} email={email} size={25} />
                <Typography variant="h5" component="div" sx={{ fontFamily: 'SF-Pro-Text-Regular, sans-serif', color: '#b0b1b5', fontSize: '16px' }}>
                    Hello, <span style={{ color: isDark ? '#FFFFFF' : '#999aa3', fontFamily: 'SF-Pro-Display, sans-serif' }}>{firstName}</span>
                </Typography>
                <ExpandMoreIcon style={{ color: '#b0b1b5' }} />
            </Box>
        </Box>
    )
}