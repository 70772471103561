import React from 'react';
import { Box, Typography } from '@mui/material';

const LoadingComponent = () => {
    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
                color: '#fff',
            }}
        >
            <Typography variant="h4" sx={{ mb: 2 }}>
                Searching for session...
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                    sx={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        animation: 'loadingAnimation 1.5s infinite',
                        mx: 0.5,
                    }}
                />
                <Box
                    sx={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        animation: 'loadingAnimation 1.5s infinite 0.3s',
                        mx: 0.5,
                    }}
                />
                <Box
                    sx={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        animation: 'loadingAnimation 1.5s infinite 0.6s',
                        mx: 0.5,
                    }}
                />
            </Box>
            <style>{`
        @keyframes loadingAnimation {
          0%, 100% { opacity: 0; }
          50% { opacity: 1; }
        }
      `}</style>
        </Box>
    );
};

export default LoadingComponent;
