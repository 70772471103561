import { useState } from 'react';
import './style.css'

// hooks/useNotificationQueue.js

let notificationQueue = [];
let setNotificationData;

export const useNotificationQueue = () => {
    const [notifications, setNotifications] = useState([]);

    // Ao iniciar o hook, configure a função de atualização de estado
    setNotificationData = setNotifications;

    // Retorne apenas o estado (notificações), não queremos expor a função de atualização
    return notifications;
};

export const addNotification = (message, styleClass = 'error3', duration = 6000) => {
    createNotification(message, styleClass);
};

// const createNotification = (message, className) => {
//     const notification = document.createElement("div");

//     // Dividindo a string de classes e adicionando-as ao elemento
//     const classes = className.split(" ");
//     notification.classList.add("notification3", ...classes);

//     notification.innerHTML = message;

//     const progress = document.createElement("div");
//     progress.classList.add("notification-progress3");
//     notification.appendChild(progress);

//     const notificationsContainer = document.getElementById("notifications3");
//     notificationsContainer.appendChild(notification);

//     setTimeout(() => {
//         notification.classList.add("show3");
//     }, 100);

//     setTimeout(() => {
//         notification.classList.remove("show3");
//     }, 6000);

//     setTimeout(() => {
//         notificationsContainer.removeChild(notification);
//     }, 6000);
// };

const createNotification = (message, className) => {
    const notification = document.createElement("div");
    const classes = className.split(" ");
    notification.classList.add("notification3", ...classes);

    const contentContainer = document.createElement('span');

    // Usamos uma expressão regular para identificar se há texto entre **
    const match = message.match(/\*\*(.*?)\*\*/);
    if (match) {
        // Se houver, criamos um elemento <strong> e inserimos o texto nele
        const strong = document.createElement('strong');
        strong.innerText = match[1];
        contentContainer.appendChild(strong); // Adicionamos o elemento <strong> ao DOM
        message = message.replace(/\*\*(.*?)\*\*/, ''); // Removemos os ** da string
    }
    
    contentContainer.appendChild(document.createTextNode(message));
    notification.appendChild(contentContainer);

    const progress = document.createElement("div");
    progress.classList.add("notification-progress3");
    notification.appendChild(progress);

    const notificationsContainer = document.getElementById("notifications3");
    notificationsContainer.appendChild(notification);

    setTimeout(() => {
        notification.classList.add("show3");
    }, 100);

    setTimeout(() => {
        notification.classList.remove("show3");
    }, 6000);

    setTimeout(() => {
        notificationsContainer.removeChild(notification);
    }, 7000);
};
