import React from 'react';
import { Box, Typography } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const LessonBubble = ({ content, setInteractLesson, status, id }) => {

    const handleClick = () => {
        const data = JSON.parse(content.function.arguments);
        const formattedData = typeof data === 'object' && data !== null ? data : {};
        setInteractLesson({ type: content.function.name, data: formattedData, id: id });
    };

    const getStatusIcon = (status) => {
        switch (status) {
            case 'correct':
                return <CheckIcon sx={{ color: '#3399ff', fontSize: '12px' }} />; // Azul escuro
            case 'incorrect':
                return <CloseIcon sx={{ color: '#ff3333', fontSize: '12px' }} />; // Vermelho escuro
            default:
                return <HourglassEmptyIcon sx={{ color: '#78909C', fontSize: '12px' }} />; // Cinza
        }
    };

    const getStyles = (status) => {
        switch (status) {
            case 'correct':
                return {
                    boxShadow: '0px 2px 0px #3399ff', // Sombra azul escuro
                    boxShadowActive: '0px 0px 0px #3399ff', // Sombra azul escura ativa
                    border: '2px solid #3399ff', // Borda azul escuro
                    backgroundColor: '#cce6ff', // Fundo azul claro
                    iconColor: '#FFFFFF', // Ícone branco
                    iconBgColor: '#007AFF', // Fundo do ícone azul escuro
                    textColor: '#3399ff' // Texto azul escuro
                };
            case 'incorrect':
                return {
                    boxShadow: '0px 2px 0px #ff3333', // Sombra vermelho escuro
                    boxShadowActive: '0px 0px 0px #ff3333', // Sombra vermelho escura ativa
                    border: '2px solid #ff3333', // Borda vermelho escuro
                    backgroundColor: '#ffcccc', // Fundo vermelho claro
                    iconColor: '#FFFFFF', // Ícone branco
                    iconBgColor: '#ff3333', // Fundo do ícone vermelho escuro
                    textColor: '#ff3333' // Texto vermelho escuro
                };
            default:
                return {
                    boxShadow: '0px 2px 0px #78909C', // Sombra cinza
                    boxShadowActive: '0px 0px 0px #78909C', // Sombra cinza ativa
                    border: '2px solid #78909C', // Borda cinza
                    backgroundColor: '#eceff1', // Fundo cinza claro
                    iconColor: '#FFFFFF', // Ícone branco
                    iconBgColor: '#78909C', // Fundo do ícone cinza
                    textColor: '#78909C' // Texto cinza
                };
        }
    };

    const styles = getStyles(status);

    return (
        <Box
            onClick={handleClick}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                backgroundColor: styles.backgroundColor,
                borderRadius: '10px',
                padding: '10px',
                maxWidth: '300px',
                cursor: 'pointer',
                userSelect: 'none',
                transition: 'all 0.1s ease-in-out',
                boxShadow: styles.boxShadow,
                border: styles.border,
                marginBottom: '8px',
                '&:active': {
                    boxShadow: styles.boxShadowActive,
                    transform: 'translateY(2px)',
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: styles.iconBgColor,
                        borderRadius: '10px',
                        width: '40px',
                        height: '40px',
                    }}
                >
                    <SchoolIcon sx={{ color: styles.iconColor }} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6" sx={{ color: styles.textColor, fontWeight: 'bold', fontSize: '14px', display: 'flex', alignItems: 'center', gap: '5px' }}>
                        Lição {getStatusIcon(status)}
                    </Typography>
                    <Typography variant="body2" sx={{ color: styles.textColor, fontSize: '12px' }}>
                        Clique aqui para resolver a lição
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default LessonBubble;
