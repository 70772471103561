import React, { useState, useRef, useEffect } from 'react';
import './style.css'
import { useBallTalkContext } from '../BallTalkContext';

export default function BallTalkAI({ color, size }) {

    const { audioUrl, interrupt, showLoadingMessage, setTalkingMessageIndex, setShowInteractElements, selectedSpeed, scale } = useBallTalkContext();

    const [isPlaying, setIsPlaying] = useState(false);
    const [audioContext2, setAudioContext2] = useState(null);
    const [audioSourceNode, setAudioSourceNode] = useState(null);
    const audioRef = useRef(null);
    const canvasRef = useRef(null);

    useEffect(() => {
        if (audioRef.current) {
            switch (selectedSpeed) {
                case "0.2x":
                    audioRef.current.playbackRate = 0.2;
                    break;
                case "0.5x":
                    audioRef.current.playbackRate = 0.5;
                    break;
                case "1x":
                    audioRef.current.playbackRate = 1;
                    break;
                case "1.2x":
                    audioRef.current.playbackRate = 1.2;
                    break;
                case "1.5x":
                    audioRef.current.playbackRate = 1.5;
                    break;
                default:
                    audioRef.current.playbackRate = 1;
                    break;
            }
        }
    }, [selectedSpeed, audioUrl]);

    useEffect(() => {
        if (audioUrl != '' && interrupt) {
            const interruptAudio = async () => {
                try {
                    await audioRef.current.pause();
                } catch (e) {
                    console.error("Erro ao pausar áudio:", e);
                }
            };
            interruptAudio();
        }
    }, [interrupt])

    let canvasCtx;

    // Definindo o tamanho base e o fator de escala com base no 'size' recebido
    const baseSize = 100; // Tamanho base considerado para os cálculos
    const scaleFactor = size / baseSize; // Fator de escala baseado no 'size' desejado
    const barWidth = 20 * scaleFactor;
    const gap = 5 * scaleFactor;
    const bigBallRadius = 50 * scaleFactor; // Raio da bola grande ajustado
    const barCircleRadius = 10 * scaleFactor; // Raio das "cabeças" das barras ajustado

    const handleAudioPlay = () => {
        setIsPlaying(true);
        if (audioRef.current && audioContext2) {
            try {
                if (!audioSourceNode) {
                    const newAudioSource = audioContext2.createMediaElementSource(audioRef.current);
                    setAudioSourceNode(newAudioSource);
                    const analyzer = audioContext2.createAnalyser();
                    newAudioSource.connect(analyzer);
                    analyzer.connect(audioContext2.destination);

                    analyzer.fftSize = 256;
                    const bufferLength = analyzer.frequencyBinCount;
                    const dataArray = new Uint8Array(bufferLength);
                    canvasCtx = canvasRef.current.getContext("2d");

                    let transitionProgress = 0;
                    const transitionSpeed = 0.1;

                    const draw = () => {
                        const WIDTH = canvasRef.current?.width;
                        const HEIGHT = canvasRef.current?.height;

                        if (!WIDTH || !HEIGHT) return;

                        canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);
                        analyzer.getByteFrequencyData(dataArray);

                        const isAudioEnded = audioRef.current.paused || audioRef.current.ended;
                        transitionProgress = isAudioEnded ? Math.min(transitionProgress + transitionSpeed, 1) : Math.max(transitionProgress - transitionSpeed, 0);

                        if (transitionProgress < 1) {
                            const totalBarWidth = 4 * barWidth + 3 * gap;
                            let xStart = (WIDTH - totalBarWidth) / 1;
                            // let xStart = (WIDTH - totalBarWidth) / 2;

                            for (let i = 0; i < 4; i++) {
                                let barHeight = dataArray[Math.floor(bufferLength / 8 * i + bufferLength / 32)];
                                barHeight = Math.max(20, barHeight * HEIGHT / 256) / 2;
                                barHeight = Math.min(barHeight, (HEIGHT * 0.9) / 2);

                                let xPos = lerp(xStart + i * (barWidth + gap), WIDTH / 2, transitionProgress);
                                let yPosTop = HEIGHT / 2 - barHeight;
                                let yPosBottom = HEIGHT / 2 + barHeight;

                                canvasCtx.fillStyle = color;
                                canvasCtx.beginPath();
                                canvasCtx.arc(xPos, yPosTop, barCircleRadius, 0, Math.PI, true); // Usando barCircleRadius
                                canvasCtx.fill();
                                canvasCtx.fillRect(xPos - barWidth / 2, yPosTop, barWidth, barHeight * 2);
                                canvasCtx.beginPath();
                                canvasCtx.arc(xPos, yPosBottom, barCircleRadius, Math.PI, 0, true); // Usando barCircleRadius
                                canvasCtx.fill();
                            }
                        }

                        if (isAudioEnded && transitionProgress === 1) {
                            canvasCtx.fillStyle = color;
                            canvasCtx.beginPath();
                            canvasCtx.arc(WIDTH / 2, HEIGHT / 2, bigBallRadius, 0, 2 * Math.PI);
                            canvasCtx.fill();
                        }

                        requestAnimationFrame(draw);
                    };

                    draw();
                }
            } catch (error) {
                console.error("Erro ao criar fonte de áudio:", error);
            }
        }
    };

    useEffect(() => {
        if (canvasRef.current) {
            canvasCtx = canvasRef.current.getContext('2d');
            drawInitialBigBall();
        }
    }, []);

    const drawInitialBigBall = () => {
        if (canvasRef.current) {
            const WIDTH = canvasRef.current?.width;
            const HEIGHT = canvasRef.current?.height;

            if (WIDTH && HEIGHT) {
                canvasCtx.fillStyle = color;
                canvasCtx.beginPath();
                canvasCtx.arc(WIDTH / 2, HEIGHT / 2, bigBallRadius, 0, 2 * Math.PI);
                canvasCtx.fill();
            }
        }
    };

    useEffect(() => {
        return () => {
            if (audioSourceNode) {
                audioSourceNode.disconnect();
            }
        };
    }, [audioSourceNode]);

    useEffect(() => {
        setAudioContext2(new (window.AudioContext || window.webkitAudioContext)());
    }, []);

    useEffect(() => {
        if (audioUrl && audioRef.current && audioContext2) {
            const playAudio = async () => {
                try {
                    if (audioContext2.state === 'suspended') {
                        await audioContext2.resume();
                    }
                    await audioRef.current.play();
                } catch (e) {
                    console.error("Erro ao reproduzir áudio:", e);
                }
            };

            playAudio();
        }
    }, [audioUrl, audioContext2]);

    useEffect(() => {
        if (showLoadingMessage && audioRef.current) {
            const pauseAudio = async () => {
                await audioRef.current.pause();
            }

            pauseAudio();
        }

    }, [showLoadingMessage, audioRef])

    function lerp(start, end, amt) {
        return (1 - amt) * start + amt * end;
    }

    const canvasSize = size + 35

    const handleAudioPause = () => {
        setIsPlaying(false);
    };

    const handleAudioEnded = () => {
        setIsPlaying(false);
        setTalkingMessageIndex(null);
        setShowInteractElements(true);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', transition: 'transform 0.1s ease-out', transform: `scale(${scale})` }}>
            <canvas ref={canvasRef} width={canvasSize} height={canvasSize}></canvas>
            <div style={{ backgroundColor: color, width: size + 10, height: size + 10 }} className={`shape ${!showLoadingMessage || isPlaying ? 'hiddenShape' : ''}`}></div>
            {audioUrl && (
                <audio
                    src={audioUrl}
                    ref={audioRef}
                    onPlay={handleAudioPlay}
                    onPause={handleAudioPause}
                    onEnded={handleAudioEnded}
                    style={{ display: 'none' }}
                    controls
                />
            )}
        </div>
    );
}
