import './style.css';
import Lottie from 'lottie-react';
import finishSuccess from '../../assets/animation/finishSuccess.json';
import finishFaild from '../../assets/animation/finishFaild.json';
import CustomConfetti from '../CustomConfetti';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { useAllTheme } from '../../components/themes/ThemeContext';

export default function AfterMission({ setMissionFinalized, data }) {

    const dataEnglish = JSON.parse(data);

    // console.log("Mission Success:", dataEnglish.mission_success);
    // console.log("Performance Rating:", dataEnglish.performance_rating);
    // console.log("Contextual Errors:", dataEnglish.contextual_errors);
    // console.log("Proficiency Levels:", dataEnglish.proficiency_levels);
    // console.log("Language Feedback:", dataEnglish.language_feedback);
    // console.log("Used Non-English:", dataEnglish.used_non_english);

    const animationData = dataEnglish.mission_success ? finishSuccess : finishFaild;

    const { isDark } = useAllTheme();

    const missionSuccessStyle = dataEnglish.mission_success === true
        ? (isDark
            ? 'linear-gradient(270deg, #FFFFFF, #FFFFFF 50%, transparent 50%, transparent)'
            : 'linear-gradient(270deg, #00A3FF, #00A3FF 50%, transparent 50%, transparent)')
        : 'linear-gradient(270deg, #FF5F6D, #FFC371 50%, transparent 50%, transparent)'; // Gradiente vermelho estilizado

    return (
        <div className='AllAfterTask'>
            <div className='AfterTask' style={{
                backgroundColor: isDark ? '#1D1D1F' : 'white'
            }}>
                <div className={`closeBannerAfterTask ${isDark ? 'dark' : ''}`} onClick={() => setMissionFinalized(false)}>
                    <FontAwesomeIcon icon={faX} />
                </div>
                {dataEnglish.mission_success && (
                    <CustomConfetti
                        numberOfPieces={100}
                        duration={5000}
                        position="top"
                        colors={['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']}
                    />
                )}
                <div className='animationTask'>
                    <Lottie
                        animationData={animationData}
                        loop={false}
                        autoplay={true}
                        style={{ height: 200, width: 200 }}
                    />
                </div>
                <div className='textAfterTask'>
                    <h2 style={{ backgroundImage: missionSuccessStyle }}>
                        {dataEnglish.mission_success ? "Mission completed" : "Mission failed"}
                    </h2>
                    {!dataEnglish.mission_success && (
                        <p>{dataEnglish?.failure_reason}</p>
                    )}
                </div>
            </div>
        </div>
    );
}
