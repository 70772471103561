import axios from 'axios';
import { openaiApiKey } from '../../../auth/config/apiConfig';

export async function sendVoiceToText(audioBlob) {
    const formData = new FormData();
    formData.append('file', audioBlob);
    formData.append('model', 'whisper-1'); // Especifica o modelo do Whisper

    try {
        const response = await axios.post('https://api.openai.com/v1/audio/transcriptions', formData, {
            headers: {
                'Authorization': `Bearer ${openaiApiKey}`, // Substitua pela sua chave de API
                'Content-Type': 'multipart/form-data'
            }
        });

        const transcription = response.data.text; // Supondo que a resposta contenha a transcrição
        return transcription;
    } catch (error) {
        console.error("Erro ao enviar áudio para transcrição:", error);
        throw error;
    }
}
