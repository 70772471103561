export const sendLexiRequest = async (messages, selectedProfessor) => {
    try {
        // const response = await fetch('https://talk2meia.uc.r.appspot.com/chat', {
            const response = await fetch('https://talk2meia.uc.r.appspot.com/chat', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ messages, selectedProfessor })
        });
        return await response.json();
    } catch (error) {
        console.error("Failed to send request:", error);
        return { error: error.message };
    }
};
