import React, { useState, useEffect, useRef } from 'react'
import logo from '../../../assets/images/logo.png'
import { Link, useLocation } from 'react-router-dom';

import { UserContext } from '../../../components/UserProvider';
import { useContext } from 'react';
import UserAvatar from '../../../components/UserAvatar';

export default function HeaderCommunity({ showPostOnCommunity, setShowPostOnCommunity, mainContainerRef }) {

    const { user, photoCommunity, newNameCommunity } = useContext(UserContext)

    const location = useLocation();
    const currentPath = location.pathname;

    const handleLinkClick = (e) => {
        if (currentPath === "/community/feed") {
            e.preventDefault();
            if (mainContainerRef.current) {
                mainContainerRef.current.scrollTop = 0;
            }
        }
    }

    return (
        <div className='allHeaderCommunity'>
            <header className="headerCommunity">
                <div className='containerLeftLogo'>
                    <Link className='logoContainer' to={`/`}>
                        <img src={logo} alt="logo" className="logo" />
                    </Link>
                    {/* <div className={contentLeftLogo === false ? 'contentLeftLogo noContent' : 'contentLeftLogo'}>
                        <h3>Talk2MeIA</h3>
                        <p>Community</p>
                    </div> */}
                </div>
                <div className='cotainerRightSearchBarCommunity'>
                    <Link className='conatinerButtonGlass' to={`/community`}>
                        <div className='buttonGlassIcon'>
                            {/* <FontAwesomeIcon icon={faHome} /> */}
                            <svg aria-label="Home" fill={currentPath === "/community" ? "#1D1D1F" : "rgb(184, 184, 184)"} role="img" viewBox="0 0 26 26">
                                <title>Home</title>
                                <path d="M2.25 12.8855V20.7497C2.25 21.8543 3.14543 22.7497 4.25 22.7497H9.25C9.52614 22.7497 9.75 22.5258 9.75 22.2497V17.6822V16.4997C9.75 14.7048 11.2051 13.2497 13 13.2497C14.7949 13.2497 16.25 14.7048 16.25 16.4997V17.6822V22.2497C16.25 22.5258 16.4739 22.7497 16.75 22.7497H21.75C22.8546 22.7497 23.75 21.8543 23.75 20.7497V12.8855C23.75 11.3765 23.0685 9.94814 21.8954 8.99882L16.1454 4.34539C14.3112 2.86094 11.6888 2.86094 9.85455 4.34539L4.10455 8.99882C2.93153 9.94814 2.25 11.3765 2.25 12.8855Z"
                                    fill={currentPath === "/community" ? "#1D1D1F" : "rgb(184, 184, 184)"}
                                    stroke={currentPath === "/community" ? "#1D1D1F" : "rgb(184, 184, 184)"}
                                    strokeLinecap="round"
                                    strokeWidth="2.5">
                                </path>
                            </svg>
                        </div>
                        {/* <span>Home</span> */}
                    </Link>
                    <Link className='conatinerButtonGlass' to={`/community/feed`} onClick={handleLinkClick}>
                        <div className='buttonGlassIcon'>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 245.000000 243.000000" preserveAspectRatio="xMidYMid meet">
                                <g transform="translate(0.000000,243.000000) scale(0.100000,-0.100000)" fill={currentPath === "/community/feed" ? "#1D1D1F" : "rgb(184, 184, 184)"} stroke="none">
                                    <path d="M714 2206 c-79 -18 -170 -55 -240 -97 -68 -41 -177 -155 -222 -231 -102 -171 -125 -402 -61 -599 43 -134 133 -252 267 -352 54 -39 97 -80 103 -95 7 -17 7 -55 0 -112 -16 -125 -15 -140 9 -140 32 0 112 45 146 82 17 18 46 59 63 91 60 107 96 137 185 157 58 13 127 -1 179 -37 l38 -27 52 26 c117 58 228 163 295 281 13 23 38 83 55 132 42 124 50 291 18 416 -33 134 -81 220 -175 315 -87 88 -162 133 -291 174 -90 29 -325 38 -421 16z m392 -446 c79 -39 122 -140 95 -223 -7 -19 -35 -58 -64 -86 -48 -47 -52 -55 -51 -95 0 -26 10 -59 23 -81 l22 -37 -28 7 c-51 12 -114 56 -146 101 l-31 44 -110 0 c-61 0 -127 4 -146 10 -78 22 -149 112 -150 189 0 73 65 159 137 181 15 4 113 9 217 9 171 1 194 -1 232 -19z" />
                                    <path d="M711 1610 c-21 -12 -5 -42 21 -38 12 2 23 8 25 15 6 16 -30 34 -46 23z" />
                                    <path d="M861 1611 c-19 -12 -6 -41 18 -41 23 0 34 23 18 39 -13 13 -18 13 -36 2z" />
                                    <path d="M1003 1609 c-16 -16 -5 -39 17 -39 25 0 35 18 20 35 -15 18 -22 19 -37 4z" />
                                    <path d="M1654 2004 c72 -86 158 -260 185 -374 93 -387 -53 -818 -353 -1039 l-51 -38 -48 -149 c-26 -82 -47 -153 -47 -159 0 -6 34 -10 79 -11 65 -1 90 -6 142 -31 68 -32 140 -96 161 -144 7 -16 15 -29 18 -29 3 1 22 37 43 81 40 86 67 175 67 222 0 32 25 86 49 104 10 8 57 12 132 13 182 0 198 12 207 147 3 52 10 87 18 94 8 6 14 29 14 51 0 31 4 41 20 45 24 6 26 26 5 43 -25 21 -17 38 40 80 30 22 55 47 55 55 0 8 -22 42 -49 75 -87 108 -108 217 -75 389 25 126 16 170 -49 238 l-51 54 18 30 c73 124 -197 296 -498 316 l-89 6 57 -69z" />
                                </g>
                            </svg>
                        </div>
                        {/* <span>Feed</span> */}
                    </Link>
                    <div className='conatinerButtonGlass' onClick={() => setShowPostOnCommunity(!showPostOnCommunity)}>
                        <div className='buttonGlassIcon'>
                            <svg aria-label="Create" fill="transparent" height="19" role="img" viewBox="0 0 26 26" width="19">
                                <title>Create</title>
                                <path d="M22.75 13.15C22.75 16.5103 22.75 18.1905 22.096 19.4739C21.5208 20.6029 20.6029 21.5208 19.4739 22.096C18.1905 22.75 16.5103 22.75 13.15 22.75L12.85 22.75C9.48969 22.75 7.80953 22.75 6.52606 22.096C5.39708 21.5208 4.4792 20.6029 3.90396 19.4739C3.25 18.1905 3.25 16.5103 3.25 13.15L3.25 12.85C3.25 9.48968 3.25 7.80953 3.90396 6.52606C4.4792 5.39708 5.39708 4.4792 6.52606 3.90396C7.80953 3.25 9.48968 3.25 12.85 3.25L13 3.25"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeWidth="2.5"
                                    strokeLinejoin="round" />
                                <path d="M21 5L14 12"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeWidth="2.5" />
                            </svg>
                        </div>
                        {/* <span>Create</span> */}
                    </div>
                    <Link className='conatinerButtonGlass' to={`/community/activity`}>
                        <div className='buttonGlassIcon'>
                            <svg aria-label="Notifications" role="img" viewBox="0 0 26 26" class="x1lliihq xffa9am x1jwls1v x1n2onr6 x17fnjtu x3egl4o" fill={currentPath === "/community/activity" ? "#1D1D1F" : "transparent"} style={{height: 26, width: 26}}><title>Notifications</title><path d="M2.5 9.85683C2.5 14.224 6.22178 18.5299 12.0332 22.2032C12.3554 22.397 12.7401 22.5909 13 22.5909C13.2703 22.5909 13.655 22.397 13.9668 22.2032C19.7782 18.5299 23.5 14.224 23.5 9.85683C23.5 6.11212 20.8698 3.5 17.4599 3.5C15.4847 3.5 13.9356 4.39792 13 5.74479C12.0851 4.40812 10.5257 3.5 8.5401 3.5C5.14059 3.5 2.5 6.11212 2.5 9.85683Z" stroke={currentPath === "/community/activity" ? "#1D1D1F" : "rgb(184, 184, 184)"} stroke-width="2.5"></path></svg>
                        </div>
                        {/* <span>Users</span> */}
                    </Link>
                    <Link className='conatinerButtonGlass' to={`/community/chat`}>
                        <div className='buttonGlassIcon'>
                            {/* <FontAwesomeIcon icon={faSearch} /> */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="29px" height="29px" viewBox="0 0 24 24" fill={currentPath.includes('chat') ? "#1D1D1F" : "transparent"}>
                                <path d="M8 18L10.29 20.29C10.514 20.5156 10.7804 20.6946 11.0739 20.8168C11.3674 20.9389 11.6821 21.0018 12 21.0018C12.3179 21.0018 12.6326 20.9389 12.9261 20.8168C13.2196 20.6946 13.486 20.5156 13.71 20.29L16 18H18C19.0609 18 20.0783 17.5786 20.8284 16.8285C21.5786 16.0783 22 15.0609 22 14V7C22 5.93913 21.5786 4.92178 20.8284 4.17163C20.0783 3.42149 19.0609 3 18 3H6C4.93913 3 3.92172 3.42149 3.17157 4.17163C2.42142 4.92178 2 5.93913 2 7V14C2 15.0609 2.42142 16.0783 3.17157 16.8285C3.92172 17.5786 4.93913 18 6 18H8Z" stroke={currentPath.includes('chat') ? "#1D1D1F" : "rgb(184, 184, 184)"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M17 9H7" stroke={currentPath.includes('chat') ? "#FFFFFF" : "rgb(184, 184, 184)"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M13 12H7" stroke={currentPath.includes('chat') ? "#FFFFFF" : "rgb(184, 184, 184)"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </Link>
                </div>
                <div className='containerRightHeader'>
                    {/* <div className='buttonGlassIconRight'>
                        <FontAwesomeIcon icon={faCalendarAlt} />
                    </div>
                    <div className='buttonGlassIconRight'>
                        <FaGlobe />
                    </div> */}
                    <Link className='containerAvatarUserCommunity' to={`/community/@${newNameCommunity.toLowerCase().replace(/\s+/g, '')}`}>
                        {user && <UserAvatar userName={newNameCommunity} photoURL={photoCommunity} size={40} />}
                    </Link>
                </div>
            </header>
        </div>
    )
}