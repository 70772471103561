import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, IconButton, Menu, Tooltip, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

import logo from '../../assets/images/logo.png';

import { TextField } from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ArrowUpward from '@mui/icons-material/ArrowUpward';

import './style.css'
import FilesList from './FilesList';
import { UserContext } from '../../components/UserProvider';
import DragOverlay from './DragOverlay';
import ScrollMessage from './scrollMessage';

export default function ChatAI({ setInteractLesson, handleToggleChat, messages, setMessages, message, setMessage, handleSendMessage, boxes, files, setFiles }) {

    const messageRef = useRef(null);

    const { user } = useContext(UserContext)

    function handleKeyPress(e) {
        const inputValue = e.target.value;

        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();

            if (inputValue.trim() !== '') {
                handleSendMessage(inputValue, files, e, false);
            }
        }
    }

    const handleFileChange = async (event) => {
        const selectedFiles = Array.from(event.target.files).filter(file =>
            ['image/png', 'image/jpeg', 'image/jpg', 'image/webp', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain'].includes(file.type)
        );

        const newFiles = await Promise.all(selectedFiles.map(async (file) => {
            const base64 = await convertToBase64(file);
            return {
                type: file.type,
                url: URL.createObjectURL(file), // Gerar uma URL temporária para o arquivo selecionado
                base64 // Adicionar a versão em base64 do arquivo
            };
        }));

        setFiles([...files, ...newFiles]);
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleFileSend = (event) => {
        handleSendMessage(message, files, event, false);
        setMessage("");
        setFiles([]);
    };

    const [isDragging, setIsDragging] = useState(false);

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        setIsDragging(false);

        const droppedFiles = Array.from(e.dataTransfer.files).filter(file =>
            ['image/png', 'image/jpeg', 'image/jpg', 'image/webp', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain'].includes(file.type)
        );

        const newFiles = await Promise.all(droppedFiles.map(async (file) => {
            const base64 = await convertToBase64(file);
            return {
                type: file.type,
                url: URL.createObjectURL(file), // Gerar uma URL temporária para o arquivo selecionado
                base64 // Adicionar a versão em base64 do arquivo
            };
        }));

        setFiles(prevFiles => [...prevFiles, ...newFiles]);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative', backgroundColor: '#FFFFFF' }} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>

            <DragOverlay isDragging={isDragging} />

            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    backdropFilter: 'blur(10px)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '8px 10px',
                    zIndex: 1,
                    borderBottom: '1px solid #E5E5E5',
                }}
            >
                <IconButton onClick={handleToggleChat} sx={{ color: '#1D1D1F' }}> {/* Azul claro */}
                    <CloseIcon />
                </IconButton>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box component="img" src={logo} alt="Logo" sx={{ height: '28px' }} />
                    <Typography
                        component="span"
                        sx={{
                            fontSize: '12px',
                            fontFamily: 'SF-Pro-Text-Regular, sans-serif',
                            color: '#1D1D1F'
                        }}
                    >
                        Live Chat
                    </Typography>
                </Box>
                <IconButton sx={{ color: '#1D1D1F' }}> {/* Azul claro */}
                    <InfoOutlined />
                </IconButton>
            </Box>
            <ScrollMessage messageRef={messageRef} messages={messages} sx={{ flex: 1, overflowY: 'auto', padding: '80px 22px 60.8px 22px', display: 'flex', flexDirection: 'column-reverse', marginRight: '2px', position: 'relativa' }} boxes={boxes} setInteractLesson={setInteractLesson} />
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    backdropFilter: 'blur(28.5px)',
                    padding: '10px',
                    display: 'flex',
                    // alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    zIndex: 1,
                    borderTop: '1px solid #E5E5E5',
                    overflow: 'visible',
                }}
            >
                {files.length > 0 && (
                    <FilesList files={files} setFiles={setFiles} />
                )}
                <TextField
                    multiline
                    placeholder="Type a message..."
                    variant="outlined"
                    value={message}
                    maxLength={200}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e)}
                    InputProps={{
                        startAdornment: (
                            <IconButton
                                component="label"
                                sx={{
                                    borderRadius: '50%',
                                    width: '30px',
                                    height: '30px',
                                }}
                            >
                                <AttachFileIcon style={{ fontSize: '20px' }} />
                                <input
                                    type="file"
                                    hidden
                                    multiple
                                    accept=".png, .jpeg, .jpg, .webp, .pdf, .doc, .docx, .txt"
                                    onChange={handleFileChange}
                                />
                            </IconButton>
                        ),
                        endAdornment: (
                            <IconButton
                                onClick={(event) => handleFileSend(event)}
                                sx={{
                                    backgroundColor: '#1D1D1F',
                                    color: '#FFFFFF',
                                    borderRadius: '50%',
                                    width: '30px',
                                    height: '30px',
                                    ":hover": {
                                        backgroundColor: '#000000',
                                    }
                                }}
                            >
                                <ArrowUpward style={{ fontSize: '18px' }} />
                            </IconButton>
                        ),
                        style: { padding: '5px ', borderColor: '#E5E5E5 !important', borderRadius: '20px', outline: 'none' }
                    }}
                    sx={{ flexGrow: 1, '& .MuiOutlinedInput-root': { padding: 0 }, width: '100%' }}
                />
            </Box>
        </Box>
    );
}
