import React, { useContext } from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
// import HomeIcon from '@mui/icons-material/Home';
import HearingIcon from '@mui/icons-material/Hearing';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { styled } from '@mui/material/styles';

import HomeIcon from '../../assets/icons/homeScreen/home.png'
import SoudsIcon from '../../assets/icons/homeScreen/souds.png'
import ScenariosIcon from '../../assets/icons/homeScreen/scenarios.png'
import CommunityIcon from '../../assets/icons/homeScreen/community.png'
import BarbellIcon from '../../assets/icons/homeScreen/barbell.png'
import NotesIcon from '../../assets/icons/homeScreen/notes.png'
import MoreIcon from '../../assets/icons/homeScreen/more.png'
import UserAvatar from '../UserAvatar';
import BookIcon from '../../assets/icons/homeScreen/book.png'
import { UserContext } from '../UserProvider';

import logo from '../../assets/images/logo.png'

import './style.css'
import { Link, useLocation } from 'react-router-dom';
import { useAllTheme } from '../themes/ThemeContext';


const MenuContainer = styled(Box)(({ theme }) => ({
  width: '240px',
  backgroundColor: theme.background,
  borderRight: `2px solid ${theme.border}`,
  height: '100vh',
  padding: '0 16px',
  '& ul': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  paddingTop: '16px',
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: `${theme.backgroundMenu} !important`,
    color: theme.colorMenuSelected,
    border: `2px solid ${theme.borderMenu}`,
  },
  '&.Mui-selected .MuiListItemIcon-root': {
    color: theme.colorMenuSelected,
  },
  color: theme.colorMenu,
  padding: '8px 14px',
  borderRadius: '12px',
}));

const IconStyled = styled(ListItemIcon)(({ theme }) => ({
  color: theme.color,
}));

const SimpleMenu = () => {

  const { userName, photoURL, email } = useContext(UserContext)

  const { theme, isDark } = useAllTheme();

  const location = useLocation();
  const currentPath = location.pathname;

  const menuItems = [
    { text: 'APRENDER', icon: <img src={HomeIcon} style={{ width: '30px', height: '30px' }} />, to: '/' },
    { text: 'SONS', icon: <img src={SoudsIcon} style={{ width: '30px', }} />, to: '/sounds' },
    { text: 'MISSÕES', icon: <img src={ScenariosIcon} style={{ width: '30px', height: '30px' }} />, to: '/scenarios' },
    { text: 'LIÇÕES', icon: <img src={BookIcon} style={{ width: '30px' }} />, to: '/lessons' },
    { text: 'COMUNIDADE', icon: <img src={CommunityIcon} style={{ width: '30px', }} />, to: '/community' },
    { text: 'Notas', icon: <img src={NotesIcon} style={{ width: '30px', height: '30px' }} />, to: '/notes' },
    { text: 'MAIS', icon: <img src={MoreIcon} style={{ width: '30px', height: '30px' }} />, to: ['/settings', '/settings/edit', '/settings/privacy', '/settings/privacy/email', '/settings/privacy/password', '/settings/sessions', '/settings/idiom', '/settings/subscription'] },
  ];

  return (
    <MenuContainer theme={theme}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        marginTop: '16px',
        marginBottom: '30px',
        marginLeft: '16px',
        gap: '8px'
      }}>
        <img src={logo} alt='Logo EnglishFlu' style={{ width: 30 }} />
        <Typography
          variant="h2"
          sx={{
            fontSize: '22px',
            fontFamily: 'Din-Round-Bold, sans-serif',
            color: theme.color,
          }}
        >
          EnglishFlu
        </Typography>
      </Box>
      <List>
        {menuItems.map((item, index) => {
          const isSelected = Array.isArray(item.to)
            ? item.to.includes(currentPath)
            : currentPath === item.to;
          return (
            <Link key={index} style={{ textDecoration: 'none' }} to={Array.isArray(item.to) ? item.to[0] : item.to}>
              <ListItemStyled theme={theme} button selected={isSelected}>
                <IconStyled theme={theme} sx={{ minWidth: '45px' }}>{item.icon}</IconStyled>
                <ListItemText
                  primary={item.text}
                  sx={{
                    fontFamily: 'Din-Round-Bold, sans-serif',
                    '& span': {
                      fontFamily: 'Din-Round-Bold, sans-serif'
                    }
                  }}
                />
              </ListItemStyled>
            </Link>
          );
        })}
      </List>
    </MenuContainer>
  );
};

export default SimpleMenu;