import React, { useContext, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import "./style.css";
import ActionBar from "./ActionBar";
import MainContent from "./MainContent";
import BallTalkAI from "../componentsTalkIA/talkBallAI";
import emojiRegex from "emoji-regex";
import { sendTextToSpeechRequest, sendTextToSpeechRequestMulti } from "../componentsTalkIA/IAtalk2ME/Talk2MeVoice";
import { sendVoiceToText } from "../componentsTalkIA/IAtalk2ME/Talk2MeSTT";
import { UserContext } from "../components/UserProvider";
import { useBallTalkContext } from "../componentsTalkIA/BallTalkContext";
import Utils from "./utils";
import Lesson from "../lessons/LessonComponent";
import { sendLexiRequest } from "../componentsTalkIA/IAtalk2ME/sendLexiRequest";

import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Paper } from '@mui/material';
import { Person as PersonIcon, Star as StarIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useAlert } from "../components/AlertComponent/AlertContext";

import { useCapture } from './Capture/CaptureContext';
import { useVideoShare, VideoShareProvider } from "./Capture/VideoShareProvider";
import { useTheme } from "./themes/ThemeContext";

const ProfessorOption = styled(Paper)(({ theme, selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  border: '1px solid #E5E5E5',
  borderRadius: '30px',
  width: '150px',
  margin: theme.spacing(1),
  cursor: 'pointer',
  transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
  ...(selected && {
    borderColor: '#4FC3F7',
    boxShadow: '0 0 15px rgba(79, 195, 247, 0.7)',
    animation: 'glare 2s infinite',
  }),
  '@keyframes glare': {
    '0%': {
      boxShadow: '0 0 15px rgba(79, 195, 247, 0.7)',
    },
    '50%': {
      boxShadow: '0 0 15px rgba(79, 195, 247, 1)',
    },
    '100%': {
      boxShadow: '0 0 15px rgba(79, 195, 247, 0.7)',
    },
  },
}));

function ComponentLexi() {

  const { showAlert } = useAlert();

  const { theme, isDark } = useTheme();

  const [boxes, setBoxes] = useState([]);
  const [boxesCenter, setBoxesCenter] = useState([]);

  const [micOn, setMicOn] = useState(false);
  const [subtitlesOn, setSubtitlesOn] = useState(false);
  const [emojiOn, setEmojiOn] = useState(false);
  const [bookOn, setBookOn] = useState(false);
  const [editOn, setEditOn] = useState(false);
  const [moreOn, setMoreOn] = useState(false);

  const [chatOn, setChatOn] = useState(false);

  const [selectedVoice, setSelectedVoice] = useState("nova");

  const [selectedProfessor, setSelectedProfessor] = useState('basico');

  const handleSelectProfessor = (type) => {
    if (messages.length > 0) {
      showAlert(
        "Confirmação",
        "Tem certeza de que deseja trocar de professor? Isso reiniciará a conversa atual.",
        [
          { text: "Não", onPress: () => console.log("Cancelado") },
          {
            text: "Sim", onPress: () => {
              setSelectedProfessor(type);
              setMessages([]);
              setEmojiOn(false);
            }
          },
        ]
      );
    } else {
      setSelectedProfessor(type);
      setEmojiOn(false);
    }
  };

  const { setAudioUrl, setShowLoadingMessage, setInterrupt, scale, setScale } = useBallTalkContext();

  const [responseCaption, setResponseCaption] = useState('')
  const [userResponseCaption, setUserResponseCaption] = useState('')

  const [visionOn, setVisionOn] = useState(false)

  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState([]);

  const [onClassRoom, setOnClassRoom] = useState(null)

  const [isLessonActive, setIsLessonActive] = useState(false)
  const { isSharing } = useVideoShare();

  const { user, photoURL, displayName, email } = useContext(UserContext)

  const [interactLesson, setInteractLesson] = useState(null)

  const { captures, clearCaptures } = useCapture();

  const handleCapture = async () => {
    const captureElements = Array.from(document.querySelectorAll('[data-ref]'));
    const capturePromises = captureElements.map(el => {
      if (el.captureComponent) {
        return el.captureComponent();
      }
      return Promise.resolve();
    });

    try {
      const captures = await Promise.all(capturePromises);
      // console.log('Todas as capturas foram concluídas:', captures);
      return captures;
    } catch (error) {
      console.error('Erro durante a captura:', error);
      return Promise.reject(error);
    }
  };


  useEffect(() => {
    if (selectedProfessor == 'basico') {
      setSelectedVoice('nova')
    }
    else {
      setSelectedVoice('Danielle')
    }
  }, [selectedProfessor])

  const addBoxCenter = (Component) => {
    setBoxesCenter((prevBoxes) => [...prevBoxes, { id: prevBoxes.length, Component }]);
  };

  const addBox = (Component, type) => {
    if (boxes.some(box => box.type === type)) {
      console.warn(`Box with type "${type}" already exists.`);
      return;
    }
    setBoxes([...boxes, { id: boxes.length, Component, type }]);
  };

  const handleDelete = (type) => {
    setBoxes((prevBoxes) =>
      prevBoxes.map((box) =>
        box.type === type ? { ...box, removing: true } : box
      )
    );
    setTimeout(() => {
      setBoxes((prevBoxes) => prevBoxes.filter(box => box.type !== type));
    }, 400);
  };

  useEffect(() => {
    setEditOn(boxes.some(box => box.type === 'notes'));
  }, [boxes]);

  useEffect(() => {
    addBox(
      () => (
        <Box sx={{ padding: '20px' }}>
          <BallTalkAI
            color={isDark ? "#FFFFFF" :  "#1D1D1F"}
            size={280}
          />
        </Box>
      ),
      'voice'
    );
  }, []);

  const handleSendMessage = async (message, files, event, speak, lessonResponses = null) => {
    if (message.trim() !== '' || (files && files.length > 0) || lessonResponses) {
      event.preventDefault();
      setShowLoadingMessage(true);
      setMessage("");

      if (speak) {
        setUserResponseCaption(message);
      }

      try {

        let captures = [];
        if ((isLessonActive && visionOn) || isSharing) {
          captures = await handleCapture();
        }

        const imageContents = files
          .filter(file => file.type.startsWith('image/'))
          .map(file => ({
            type: "image_url",
            image_url: {
              url: file.base64
            }
          }));

        const contentShare = captures
          .filter(capture => capture && capture.type.startsWith('image/')) // Filtra valores nulos e verifica o tipo
          .map(capture => ({
            type: "image_screen",
            image_url: {
              url: capture.base64
            }
          }));

        const newMessage = {
          role: "user",
          content: [
            {
              type: "text",
              text: message.trim()
            },
            ...imageContents,
            ...contentShare
          ]
        };

        setFiles([])
        clearCaptures()

        let updatedMessages = null
        if (lessonResponses) {
          updatedMessages = [...messages, lessonResponses];
        } else {
          updatedMessages = [...messages, newMessage];
        }

        setMessages(updatedMessages);

        const response = await sendLexiRequest(updatedMessages, selectedProfessor);

        if (response.error) {
          setShowLoadingMessage(false);
          return;
        }

        const responseMessages = response.messages;

        if (responseMessages && responseMessages.length > 0) {
          setMessages(prevMessages => [...prevMessages, ...responseMessages]);
          setShowLoadingMessage(false);

          const lastMessage = responseMessages[responseMessages.length - 1];

          if (lastMessage.tool_calls && lastMessage.tool_calls.length > 0) {
            // Processando uma chamada de função
            const functionCallPart = lastMessage.tool_calls[0];
            const data = JSON.parse(functionCallPart.function.arguments);
            const formattedData = typeof data === 'object' && data !== null ? data : {};
            setInteractLesson({
              type: functionCallPart.function.name,
              data: formattedData,
              id: functionCallPart.id
            });

            if (formattedData.task_instruction && speak && !micOn) {
              let url;
              if (selectedProfessor !== 'avancado') {
                url = await sendTextToSpeechRequestMulti(formattedData.task_instruction, selectedVoice);
              } else {
                url = await sendTextToSpeechRequest(formattedData.task_instruction, selectedVoice);
              }
              setAudioUrl(url);
            }
          } else if (lastMessage.content && Array.isArray(lastMessage.content)) {
            const talkAiResponse = lastMessage.content
              .map(contentItem => contentItem.text || '')
              .join('\n');
            if (speak && !micOn) {
              const emojiRegEx = emojiRegex();
              const contentWithoutEmojis = talkAiResponse.replace(emojiRegEx, "");
              const urlRegEx = /(https?:\/\/[^\s]+)/g;
              const contentWithoutEmojisAndUrls = contentWithoutEmojis.replace(urlRegEx, "");
              if (selectedProfessor !== 'avancado') {
                const url = await sendTextToSpeechRequestMulti(contentWithoutEmojisAndUrls, selectedVoice);
                setAudioUrl(url);
              } else {
                const url = await sendTextToSpeechRequest(contentWithoutEmojisAndUrls, selectedVoice);
                setAudioUrl(url);
              }
              setResponseCaption(contentWithoutEmojisAndUrls);
            }
          }
        }
      } catch (error) {
        setShowLoadingMessage(false);
      }
    }
  };

  useEffect(() => {
    if (interactLesson) {
      addBox(
        () => (
          <Box sx={{ width: '800px', maxHeight: '900px', borderRadius: '10px', overflow: 'hidden' }}>
            <Lesson
              data={[interactLesson]}
              enableSkip={false}
              verifyButton={actionVerifyButton}
              messages={messages}
              setMessages={setMessages}
              handleSendMessage={handleSendMessage}
            />
          </Box>
        ),
        'interactLesson'
      );
    }
  }, [interactLesson]);


  // useEffect(() => {
  //   console.log('messages: ', messages)
  // }, [messages])

  useEffect(() => {
    setIsLessonActive(boxes.some(box => box.type === 'interactLesson'));
  }, [boxes]);

  const actionVerifyButton = () => {
    handleDelete('interactLesson')
  }

  let first = false
  useEffect(() => {
    if (micOn) {
      setInterrupt(true)
      startRecording();
    } else {
      if (first) {
        first = true
        return
      }
      setInterrupt(false)
      stopRecording();
    }
  }, [micOn]);

  const handleKeyDown = (event) => {
    if (event.key === ' ' && !['INPUT', 'TEXTAREA', 'SELECT', 'CONTENTEDITABLE'].includes(event.target.tagName.toUpperCase()) && !(event.target.getAttribute("contenteditable") === "true")) {
      setMicOn(true);
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === ' ' && !['INPUT', 'TEXTAREA', 'SELECT', 'CONTENTEDITABLE'].includes(event.target.tagName.toUpperCase()) && !(event.target.getAttribute("contenteditable") === "true")) {
      setMicOn(false);
    }
  };

  useEffect(() => {
    if (!onClassRoom) {
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('keyup', handleKeyUp);
      };
    }
  }, [onClassRoom]); // Adicione classMode como dependência para reativar os event listeners quando seu valor mudar


  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const analyserRef = useRef(null);
  const animationFrameRef = useRef(null);
  const streamRef = useRef(null);

  useEffect(() => {
    // console.log('useEffect: isRecording mudou para', isRecording);
    if (isRecording) {
      // console.log('Iniciando updateScale');
      updateScale();
    } else {
      // console.log('Parando updateScale');
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    }
  }, [isRecording]);

  const updateScale = () => {
    // console.log('updateScale chamado, isRecording:', isRecording);
    if (analyserRef.current && isRecording) {
      const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
      analyserRef.current.getByteFrequencyData(dataArray);
      const average = dataArray.reduce((sum, value) => sum + value, 0) / dataArray.length;
      // console.log('Volume médio:', average);
      let newScale = 1 + (average / 128) * 0.9;
      // newScale = Math.max(1, Math.min(newScale, 1.2));
      // console.log('Nova escala ajustada:', newScale);
      setScale(newScale);
    } else {
      // console.log('Analyser ou gravação não disponível');
    }
    if (isRecording) {
      animationFrameRef.current = requestAnimationFrame(updateScale);
    }
  };

  const startRecording = () => {
    // console.log('startRecording chamado');
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        // console.log('Stream de áudio obtido');
        streamRef.current = stream;
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.start();
        // console.log('MediaRecorder iniciado');
        setIsRecording(prevState => {
          // console.log('Definindo isRecording como true');
          return true;
        });
        mediaRecorderRef.current.ondataavailable = (event) => {
          // console.log('Dados de áudio disponíveis');
          audioChunksRef.current.push(event.data);
        };

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        // console.log('AudioContext criado');
        const source = audioContext.createMediaStreamSource(stream);
        // console.log('MediaStreamSource criado');
        analyserRef.current = audioContext.createAnalyser();
        // console.log('Analyser criado');
        analyserRef.current.fftSize = 256;
        source.connect(analyserRef.current);
        // console.log('Analyser conectado à fonte');
      })
      .catch(err => console.error('Erro ao acessar dispositivos de mídia:', err));
  };

  const stopRecording = () => {
    // console.log('Parando gravação, estado do mediaRecorder:', mediaRecorderRef.current?.state);
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      // console.log('MediaRecorder parado');
      setIsRecording(false);
      if (animationFrameRef.current) {
        // console.log('Cancelando animationFrame');
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (streamRef.current) {
        // console.log('Parando streams de áudio');
        streamRef.current.getTracks().forEach(track => {
          // console.log('Parando track:', track.kind);
          track.stop();
        });
      }
      mediaRecorderRef.current.onstop = async () => {
        setScale(1);
        // console.log('Evento onstop do MediaRecorder disparado');
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
        audioChunksRef.current = [];
        if (audioBlob) {
          // console.log('Gravação finalizada, tamanho do blob:', audioBlob.size);
          // Aqui você pode processar o audioBlob, como enviar para um servidor
          const response = await sendVoiceToText(audioBlob);
          handleSendMessage(response, [], { preventDefault: () => { } }, true)
        }
      };
    } else {
      // console.log('MediaRecorder não está ativo ou não existe');
    }
  };

  const handleToggleChat = (event) => {
    event.currentTarget.blur();
    setChatOn(!chatOn)
  };

  const handleToggle = (setter, state) => (event) => {
    event.currentTarget.blur();
    setter(!state);
  };

  const handleToggleDisconnect = () => {

  };

  return (
    <Box className="allTalkIA" sx={{ height: '100vh', maxHeight: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: theme.background }}>
      <Utils addBoxCenter={addBoxCenter} boxesCenter={boxesCenter} setBoxesCenter={setBoxesCenter} />

      <MainContent setInteractLesson={setInteractLesson} handleDelete={handleDelete} addBoxCenter={addBoxCenter} handleToggleChat={handleToggleChat} responseCaption={responseCaption} userResponseCaption={userResponseCaption} bookOn={bookOn} subtitlesOn={subtitlesOn} chatOn={chatOn} handleToggle={handleToggle(setChatOn, chatOn)} addBox={addBox} boxes={boxes} setBoxes={setBoxes} messages={messages} setMessages={setMessages} message={message} setMessage={setMessage} handleSendMessage={handleSendMessage} files={files} setFiles={setFiles} />

      <ActionBar
        handleToggle={handleToggle}
        micOn={micOn}
        subtitlesOn={subtitlesOn}
        emojiOn={emojiOn}
        bookOn={bookOn}
        editOn={editOn}
        moreOn={moreOn}
        setMicOn={setMicOn}
        setSubtitlesOn={setSubtitlesOn}
        setEmojiOn={setEmojiOn}
        setBookOn={setBookOn}
        setEditOn={setEditOn}
        setMoreOn={setMoreOn}
        handleToggleDisconnect={handleToggleDisconnect}
        chatOn={chatOn}
        handleToggleChat={handleToggleChat}
        selectedProfessor={selectedProfessor}
        selectedVoice={selectedVoice}
        setSelectedVoice={setSelectedVoice}
        handleDelete={handleDelete}
        addBox={addBox}
        visionOn={visionOn}
        setVisionOn={setVisionOn}
        isLessonActive={isLessonActive}
        boxes={boxes}
        theme={theme}
      />

      <Dialog
        open={emojiOn}
        onClose={() => setEmojiOn(false)}
        PaperProps={{
          sx: {
            borderRadius: '15px',
          },
        }}
      >
        <DialogTitle sx={{ textAlign: 'center' }}>Escolha seu professor</DialogTitle>
        <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <ProfessorOption
              selected={selectedProfessor === 'basico'}
              onClick={() => handleSelectProfessor('basico')}
            >
              <IconButton>
                <PersonIcon fontSize="large" />
              </IconButton>
              <Typography variant="subtitle1">Básico</Typography>
            </ProfessorOption>
            <ProfessorOption
              selected={selectedProfessor === 'avancado'}
              onClick={() => handleSelectProfessor('avancado')}
            >
              <IconButton>
                <StarIcon fontSize="large" />
              </IconButton>
              <Typography variant="subtitle1">Avançado</Typography>
            </ProfessorOption>
          </Box>
        </DialogContent>
        <Typography variant="body2" sx={{ maxWidth: '300px', textAlign: 'center', color: '#B0B0B0', fontSize: '12px', margin: 'auto', marginBottom: 2 }}>
          Se você já tiver iniciado uma conversa, ao trocar o professor a conversa será reiniciada.
        </Typography>
      </Dialog>

    </Box>

  );
}

export default function Lexi() {
  return (
    <VideoShareProvider>
      <ComponentLexi />
    </VideoShareProvider>
  )
}