import React, { useContext, useState, useEffect, useRef } from 'react';
import { UserContext } from '../../../components/UserProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple, faDesktop, faEllipsisH, faX } from '@fortawesome/free-solid-svg-icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay, Navigation } from 'swiper/core';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';

import { db } from '../../../auth/config/firebase'; // Adapte isso para o seu código
import { doc, getDoc, updateDoc, increment, deleteDoc, setDoc } from 'firebase/firestore';
import { useSpring, animated } from 'react-spring';
import { motion, AnimatePresence } from 'framer-motion';
import { ref, deleteObject } from 'firebase/storage';
import { storage } from '../../../auth/config/firebase';
import AudioPlayer from '../audioPlayer';
import { useNavigate } from 'react-router-dom';
import Alert from '../../../components/alert';
import { addNotification } from '../../../components/notification';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { usePost } from '../PostContext';
import { useStatus } from '../PostingContext';
import { PostInteractionContext } from '../PostInteractionContext';
import UserAvatar from '../../../components/UserAvatar';

const AnimatedProgressBar = ({ percentage }) => {
    const [style, animate] = useSpring(() => ({
        width: '0%',
    }));

    useEffect(() => {
        animate({ width: `${percentage}%` });
    }, [percentage, animate]);

    return <animated.div className="blackShowConfirm" style={style}></animated.div>;
};

const PostItem = ({ posts, setPosts, handleOpenPostOnCommunity, handleOpenEditPostOnCommunity, showElements, commmentSection, showLine, isActive, onElementClick, setViewPostDetails, expandImage = true }) => {

    const { interactions, updateInteraction } = useContext(PostInteractionContext);

    useEffect(() => {
        // Itera sobre todos os posts para atualizar com as interações globais
        posts.forEach((post, index) => {
            const interaction = interactions[post.id];
            if (interaction) {
                // Verifica se o post atual tem alguma interação de like
                if (interaction.hasOwnProperty('liked') && interaction.hasOwnProperty('likeCount')) {
                    // Atualiza o estado de likedPosts
                    const updatedLikedPosts = [...likedPosts];
                    updatedLikedPosts[index] = interaction.liked;

                    // Atualiza o estado de likesCounts
                    const updatedLikesCounts = [...likesCounts];
                    updatedLikesCounts[index] = interaction.likeCount;

                    // Seta os novos estados
                    setLikedPosts(updatedLikedPosts);
                    setLikesCounts(updatedLikesCounts);
                }
            }
        });
    }, [interactions]);

    useEffect(() => {
        // Itera sobre todos os posts para atualizar com as interações globais
        posts.forEach((post, index) => {
            const interaction = interactions[post.id];
            if (interaction) {
                // Verifica se o post atual tem alguma interação de poll
                if (interaction.hasOwnProperty('votes') && interaction.hasOwnProperty('userVote')) {
                    // Atualiza o estado local de votos para a postagem
                    const updatedVoteCounts = { ...voteCounts, [post.id]: interaction.votes };
                    const updatedUserVotes = { ...userVotes, [post.id]: interaction.userVote };

                    // Seta os novos estados
                    setVoteCounts(updatedVoteCounts);
                    setUserVotes(updatedUserVotes);
                }
            }
        });
    }, [interactions]);

    const { setStatus } = useStatus();

    const { setPost } = usePost();

    const { user } = useContext(UserContext)

    const [urlsMetadata, setUrlsMetadata] = useState({});

    useEffect(() => {
        posts?.forEach(post => {
            const words = post?.text?.split(/(\s+)/);
            let lastUrl = null;

            words?.forEach(word => {
                let cleanWord = word.replace(/[.,!?]$/, '');
                if (/^http/.test(cleanWord)) {
                    lastUrl = cleanWord;
                }
            });

            if (lastUrl) {
                fetchUrlMetadata(lastUrl, post.id); // Passamos o ID do post para a função
            }
        });
    }, [posts]);

    const fetchUrlMetadata = async (url, postId) => {
        try {
            const { data } = await axios.post('http://localhost:3001/fetch-url', { url });
            setUrlsMetadata(prev => ({
                ...prev,
                [postId]: data,
            }));
        } catch (error) {
            console.error('Error fetching URL metadata:', error);
        }
    };

    const [voteCounts, setVoteCounts] = useState({});
    const [userVotes, setUserVotes] = useState({});

    const vote = async (postId, selectedOption, postIndex) => {
        // Referências do Firestore
        const postRef = doc(db, 'posts', postId);
        const pollVoteRef = doc(postRef, 'pollVotes', user.uid);

        // Atualiza os estados para uma resposta rápida na UI
        const previousVoteCount = voteCounts[postId]?.[selectedOption] || 0;
        const newVoteCount = previousVoteCount + 1;

        const updatedVoteCounts = { ...voteCounts };
        updatedVoteCounts[postId] = {
            ...updatedVoteCounts[postId],
            [selectedOption]: newVoteCount
        };

        const updatedUserVotes = { ...userVotes, [postId]: selectedOption };
        setVoteCounts(updatedVoteCounts);
        setUserVotes(updatedUserVotes);

        updateInteraction(postId, {
            votes: updatedVoteCounts[postId], // ou apenas { [selectedOption]: newVoteCount } para atualizar a opção específica
            userVote: selectedOption
        });

        // console.log(`Voting on option: ${selectedOption} with new vote count: ${newVoteCount}`);

        try {
            // Verifica se o usuário já votou
            const pollVoteDoc = await getDoc(pollVoteRef);

            if (!pollVoteDoc.exists()) {
                // Registra o voto
                await setDoc(pollVoteRef, { vote: selectedOption });
                await updateDoc(postRef, {
                    [`poll.votes.${selectedOption}`]: increment(1)
                });
                console.log('Voto adicionado com sucesso.');

                console.log(`Updated Vote Count [${selectedOption}]: ${updatedVoteCounts[postId][selectedOption]}`);
            } else {
                console.log('Você já votou nesta enquete.');
                revertVote(postId, selectedOption, previousVoteCount); // Revertendo com a contagem anterior
            }
        } catch (error) {
            console.error('Erro ao votar na enquete:', error);
            revertVote(postId, selectedOption, previousVoteCount); // Revertendo com a contagem anterior
        }
    };

    const revertVote = (postId, selectedOption, previousVoteCount) => {
        // Reverte a contagem de votos para o valor anterior
        console.log(`Reverting vote on option: ${selectedOption} back to vote count: ${previousVoteCount}`);
        const revertedVoteCounts = { ...voteCounts };
        revertedVoteCounts[postId] = {
            ...revertedVoteCounts[postId],
            [selectedOption]: previousVoteCount
        };
        setVoteCounts(revertedVoteCounts);

        // Reverte o voto do usuário
        const revertedUserVotes = { ...userVotes };
        delete revertedUserVotes[postId]; // Remover o voto do usuário
        setUserVotes(revertedUserVotes);
    };

    const [likesCounts, setLikesCounts] = useState([]);
    const [likedPosts, setLikedPosts] = useState([]);

    const handleLike = async (postId, isLiked, postIndex) => {
        // Preparar os estados atualizados para uma resposta imediata na UI
        let updatedLikesCount = likesCounts[postIndex] + (!isLiked ? 1 : -1);
        const updatedLikedPosts = [...likedPosts];
        updatedLikedPosts[postIndex] = !isLiked;

        // Atualizar imediatamente o estado da UI para os likes
        setLikesCounts(prev => {
            const updated = [...prev];
            updated[postIndex] = updatedLikesCount;
            return updated;
        });

        setLikedPosts(updatedLikedPosts);

        updateInteraction(postId, { liked: !isLiked, likeCount: updatedLikesCount });

        console.log('O like para o postId ', postId, " mudou")

        try {
            const postRef = doc(db, 'posts', postId);
            const postSnapshot = await getDoc(postRef);

            if (!postSnapshot.exists()) {
                throw new Error("Este post não existe."); // Encerra a função se o post não existir
            }

            const likeRef = doc(postRef, 'likes', user.uid);
            const userRef = doc(db, 'community', user.uid);
            const userLikeRef = doc(userRef, 'likedPosts', postId);

            // Tentativa de atualizar banco de dados
            if (!isLiked) {
                await setDoc(likeRef, { userId: user.uid });
                await setDoc(userLikeRef, { postId: postId });
            } else {
                await deleteDoc(likeRef);
                await deleteDoc(userLikeRef);
            }
        } catch (error) {
            console.error("Erro ao curtir a postagem:", error);
            revertLikeState(isLiked, postIndex);
        }
    };

    const revertLikeState = (isLiked, postIndex) => {
        // Reverter a contagem de curtidas
        setLikesCounts(prev => {
            const reverted = [...prev];
            reverted[postIndex] = reverted[postIndex] + (isLiked ? 1 : -1); // Ajuste para reverter a operação corretamente
            return reverted;
        });

        // Reverter o estado de curtida
        setLikedPosts(prev => {
            const reverted = [...prev];
            reverted[postIndex] = isLiked; // Reverte para o estado original
            return reverted;
        });
    };

    useEffect(() => {
        // Esta função será chamada sempre que novos posts forem carregados
        const updateLikesData = () => {
            let newLikesCounts = likesCounts.slice();
            let newLikedPosts = likedPosts.slice();

            // Atualiza a contagem de likes e o estado para novos posts
            posts.forEach((post, index) => {
                const postIndex = likesCounts.length + index; // Novo índice para novos posts

                // Definindo estados padrão para novos posts
                newLikesCounts[postIndex] = post.likeCount || 0;
                newLikedPosts[postIndex] = post.isLiked || false;
            });

            setLikesCounts(newLikesCounts);
            setLikedPosts(newLikedPosts);
        };

        updateLikesData();
    }, [posts]); // Dependências: apenas os posts.

    const [isFullscreen, setIsFullscreen] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState(null);

    const [commentsCount, setCommentsCount] = useState({});

    useEffect(() => {
        const fetchCommentsCount = () => {
            const counts = {};

            posts.forEach(post => {
                counts[post.id] = post.commentCount || 0;
            });

            setCommentsCount(counts);
        };

        fetchCommentsCount();
    }, [posts]);


    function timeSince(date) {
        const seconds = Math.floor((new Date() - date) / 1000);
        let interval = Math.floor(seconds / 31536000); // 1 ano tem aproximadamente 31536000 segundos

        if (interval > 1) {
            return interval + "y"; // anos
        }
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
            return interval + "m"; // meses
        }
        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
            return interval + "d"; // dias
        }
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
            return interval + "h"; // horas
        }
        interval = Math.floor(seconds / 60);
        if (interval >= 1) {
            return interval + "m"; // minutos
        }
        return Math.floor(seconds) + "s"; // segundos
    }

    const navigate = useNavigate();

    const [activePostShareId, setActivePostShareId] = useState(null);
    const [activePostOptionsId, setActivePostOptionsId] = useState(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (!event.target.closest('.containerMenuOptionPost')) {
                setActivePostShareId(null);
                setActivePostOptionsId(null)
            }
        }
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [counters, setCounters] = useState({});

    // Atualize todos os contadores a cada segundo
    useEffect(() => {
        const interval = setInterval(() => {
            setCounters(currentCounters => {
                const newCounters = { ...currentCounters };

                posts.forEach(post => {
                    const createdAt = new Date(post.createdAt).getTime();
                    const currentTime = new Date().getTime();
                    const difference = currentTime - createdAt; // diferença em milissegundos
                    const minutesPassed = Math.floor(difference / 60000);

                    if (minutesPassed >= 5) {
                        newCounters[post.id] = 0;
                    } else {
                        const secondsPassed = Math.floor((difference % 60000) / 1000);
                        const timeRemaining = 5 * 60 - (minutesPassed * 60 + secondsPassed); // Tempo restante em segundos
                        newCounters[post.id] = timeRemaining;
                    }
                });

                return newCounters;
            });
        }, 1000);

        // Limpe o intervalo quando o componente for desmontado
        return () => clearInterval(interval);
    }, [posts]);

    const deleteMedia = async (mediaUrls) => {
        const deletePromises = mediaUrls.map((url) => {
            const mediaRef = ref(storage, url);
            return deleteObject(mediaRef);
        });

        await Promise.all(deletePromises);
    };

    const userRef = doc(db, 'community', user.uid);
    const deleteComment = async (selectedCommentForDelete) => {
        const commentRef = doc(db, 'posts', selectedCommentForDelete);
        await updateDoc(commentRef, {
            commentCount: increment(-1)
        });
        await updateDoc(userRef, {
            replies: increment(-1)
        });
    }

    // Função para apagar o post e mídias associadas
    const deletePostAndMedia = async (postId, mediaUrls, selectedCommentForDelete, callback) => {

        try {
            // // Apagar mídias (imagens e/ou áudios)
            if (mediaUrls.length > 0) {
                await deleteMedia(mediaUrls);
            }

            // Apagar o post do Firestore
            const postRef = doc(db, 'posts', postId);
            await deleteDoc(postRef);

            // Executar o callback se for fornecido
            if (callback && typeof callback === 'function') {
                callback(postId);
            }

            if (selectedCommentForDelete) {
                deleteComment(selectedCommentForDelete)
            }
            else {
                await updateDoc(userRef, {
                    posts: increment(-1)
                });
            }
        } catch (error) {

            setStatus({ value: 'Fail', url: '' })
            setTimeout(() => {
                setStatus({ value: '', url: '' })
            }, [4000])

            console.error("Error deleting post and media: ", error);
        }
    };

    // Função para atualizar o estado de posts no componente React
    const handleDeletePost = (postId, mediaUrls, selectedCommentForDelete) => {
        const callback = (removedPostId) => {
            setPosts(currentPosts => {
                setStatus({ value: 'Deleted', url: '' })
                setTimeout(() => {
                    setStatus({ value: '', url: '' })
                }, [4000])
                // Certifique-se de que currentPosts seja uma array antes de tentar filtrar
                if (Array.isArray(currentPosts)) {
                    // Se for uma array, filtre o post removido
                    return currentPosts.filter(post => post.id !== removedPostId);
                } else {
                    // Se não for uma array, ou seja, se for um post único ou undefined/null, retorne uma array vazia
                    return []; // ou você pode decidir retornar o próprio currentPosts se isso fizer sentido para sua lógica
                }
            });
        };

        deletePostAndMedia(postId, mediaUrls, selectedCommentForDelete, callback);
    };


    const [showPopupDeletePost, setShowPopupDeletePost] = useState(false)
    const [selectedPostForDelete, setSelectedPostForDelete] = useState(null);
    const [selectedCommentForDelete, setSelectedCommentForDelete] = useState(null);
    const [selectedMediaUrlsForDelete, setSelectedMediaUrlsForDelete] = useState([]);

    const copyLink = (code) => {
        let currentUrl = window.location.href;
        let modifiedUrl = currentUrl.replace('/feed', '');
        navigator.clipboard.writeText(modifiedUrl + code);
        addNotification('Link copiado com sucesso!', 'dark3');
    };

    const blockUser = async (uidToBlock, userName) => {
        // Referência ao documento do usuário bloqueado na coleção blockedUsers
        const blockedUserRef = doc(db, 'community', user.uid, 'blockedUsers', uidToBlock);

        try {
            const blockedSnapshot = await getDoc(blockedUserRef);

            if (blockedSnapshot.exists()) {
                // Se o documento existe, o usuário está atualmente bloqueado, então desbloquear
                await deleteDoc(blockedUserRef);
                addNotification(`Usuário ${userName} desbloqueado(a) com sucesso.`, "dark3");
            } else {
                // Se o documento não existe, o usuário não está bloqueado, então bloquear
                await setDoc(blockedUserRef, { blockedUid: uidToBlock });
                addNotification(`Usuário ${userName} bloqueado(a) com sucesso.`, "dark3");
            }
        } catch (error) {
            console.error("Error updating block status: ", error);
            addNotification("Houve um erro ao tentar atualizar o status de bloqueio/desbloqueio deste usuário. Por favor, tente novamente.", "dark3");
        }
    };

    const calculateTotalVotes = (votes, currentUserVote) => {
        let totalVotes = 0;

        // Soma todos os votos existentes
        for (const option in votes) {
            totalVotes += votes[option];
        }

        // Se o usuário votou nesta sessão e o voto é diferente do registrado no banco, adicionar 1 ao total para essa opção
        if (currentUserVote && (!votes[currentUserVote] || votes[currentUserVote] === 0)) {
            totalVotes += 1;
        }

        return totalVotes;
    };

    // const [viewTime, setViewTime] = useState({}); // { postId: tempoEmSegundos }
    // const [viewed, setViewed] = useState({}); // { postId: boolean }
    // const viewStartTimes = useRef({}).current; // Objeto para armazenar os tempos de início
    // const [sentPosts, setSentPosts] = useState([]);

    // useEffect(() => {
    //     const observer = new IntersectionObserver((entries) => {
    //         entries.forEach(entry => {
    //             const postId = entry.target.getAttribute('data-postid');

    //             if (entry.isIntersecting && !viewStartTimes[postId]) {
    //                 // Define um novo timeout para o post se ainda não existir um
    //                 viewStartTimes[postId] = setTimeout(() => {
    //                     const viewEndTime = Date.now();
    //                     const viewDuration = viewEndTime - (viewStartTimes[postId] - 2000);

    //                     if (viewDuration >= 2000 && !sentPosts.includes(postId)) {
    //                         setViewTime(prev => ({ ...prev, [postId]: (prev[postId] || 0) + viewDuration }));
    //                         setViewed(prev => ({ ...prev, [postId]: true }));
    //                         sendViewDataToDatabase(postId, viewDuration);
    //                         sentPosts.push(postId); // Atualiza a lista de sentPosts de forma síncrona
    //                     }

    //                     viewStartTimes[postId] = null;
    //                 }, 2000);
    //             } else if (!entry.isIntersecting && viewStartTimes[postId]) {
    //                 // Limpa o timeout se o post sair da visualização
    //                 clearTimeout(viewStartTimes[postId]);
    //                 viewStartTimes[postId] = null;
    //             }
    //         });
    //     }, { threshold: 0.7 });

    //     const postsElements = document.querySelectorAll('.boxPostFeed');
    //     postsElements.forEach(item => observer.observe(item));

    //     return () => {
    //         observer.disconnect();
    //         Object.keys(viewStartTimes).forEach(key => clearTimeout(viewStartTimes[key]));
    //     };
    // }, [posts, sentPosts]);

    // const [processedPosts, setProcessedPosts] = useState([]);

    // const sendViewDataToDatabase = async (postId, viewDuration) => {
    //     if (postId && viewDuration && !processedPosts.includes(postId)) {
    //         // console.log(`Enviando dados para o Firestore para o post: ${postId} - Duração da visualização: ${viewDuration}`);

    //         const postRef = doc(db, 'posts', postId);
    //         const viewsRef = doc(postRef, 'views', user.uid);

    //         const viewSnapshot = await getDoc(viewsRef);
    //         if (!viewSnapshot.exists()) {
    //             await setDoc(viewsRef, {
    //                 viewDuration: viewDuration,
    //                 viewedAt: new Date().toISOString()
    //             });

    //             // Adicionar o post ao estado de posts processados
    //             setProcessedPosts(prevPosts => [...prevPosts, postId]);
    //             setSentPosts(prevPosts => [...prevPosts, postId])
    //             sentPosts.push(postId);
    //         }
    //     }
    // };

    // // Chame essa função em um evento, como o desmonte do componente ou um botão de 'sair' da página
    // useEffect(() => {
    //     // Exemplo: chamada ao sair da página
    //     return () => {
    //         sendViewDataToDatabase();
    //     };
    // }, [viewTime, viewed]);


    let location = useLocation();

    return (
        <>
            {/* <div id="notifications3"></div> */}
            <Alert
                title="Are you sure?"
                paragraph="Do you want to delete this post?"
                buttons={[
                    { text: "Cancel", action: () => setShowPopupDeletePost(false) },
                    {
                        text: "Delete",
                        action: () => {
                            handleDeletePost(selectedPostForDelete, selectedMediaUrlsForDelete, selectedCommentForDelete); // Chama a função de exclusão
                            setShowPopupDeletePost(false); // Fecha o alerta
                        }
                    }
                ]}
                showAlert={showPopupDeletePost}
            />

            {posts.map((post, postIndex) => {

                // const viewDurationInSeconds = viewTime[post.id] ? Math.floor(viewTime[post.id] / 1000) : 0;

                if (!post?.user?.userNick || !post || post == []) {
                    return (
                        <div className='contentNotAvailable' key={postIndex}>
                            <h3>Content not available</h3>
                        </div>
                    );
                }
                return (
                    <div className={showLine ? 'boxPostFeed noLineBottom' : 'boxPostFeed'} data-postid={post.id} key={post.id} onClick={(e) => {
                        if (!e.target.closest('.ignore-click')) {
                            const currentVote = userVotes[post.id]; // O voto atual do usuário nesta sessão, se houver
                            const updatedVotes = {};

                            if (post?.poll?.votes) {
                                Object.keys(post.poll.votes).forEach(option => {
                                    updatedVotes[option] = post.poll.votes[option] || 0; // Assume a contagem existente ou 0
                                    // Incrementa a contagem para a opção votada se o voto atual for para esta opção e for um novo voto
                                    if (currentVote && currentVote === option && !post?.userVote) {
                                        updatedVotes[option] += 1;
                                    }
                                });
                            }

                            setPost({
                                ...post,
                                poll: {
                                    ...post?.poll,
                                    votes: updatedVotes, // Usa o objeto de votos atualizado
                                },
                                userVote: currentVote || post?.userVote, // Atualiza com o voto do usuário
                                isVoted: currentVote ? true : post?.isVoted,
                                isLiked: likedPosts[postIndex],
                                likeCount: likesCounts[postIndex],
                                // Adicione outras informações relevantes como votos, etc.
                            });
                            navigate(`/community/@${post.user?.normalizedUserNick}/post/${post.id}`);
                        }
                    }}>
                        <div className={commmentSection ? 'userPostInfo displayNone' : 'userPostInfo'}>
                            {!commmentSection && (
                                <div className='ignore-click'>
                                    <UserAvatar userName={post.user?.userNick} photoURL={post.user?.photoURL} size={35} />
                                </div>
                            )}
                            {showLine && (
                                <div className="userPostLineConnect">
                                    <svg aria-hidden="true" fill="none" viewBox="0 0 21 1" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 1L18 481" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
                                    </svg>
                                </div>
                            )}
                        </div>
                        <div className={showLine ? 'contentPostFeed marginBottomLine' : 'contentPostFeed'}>
                            <div className={commmentSection ? 'contentInfoPostUser  marginBottom' : 'contentInfoPostUser'}>
                                <div className='flexCommentSection'>
                                    {commmentSection && (
                                        <UserAvatar userName={post.user?.userNick} photoURL={post.user?.photoURL} size={35} />
                                    )}
                                    <Link className="userNickPost ignore-click" to={`/community/@${post.user?.normalizedUserNick}`}>{post.user?.userNick}</Link>
                                </div>
                                <div className="flexInfoOptionUser">
                                    {location.pathname === "/settings/edit" && post.userId === user.uid && (
                                        <div className={`showDetails ignore-click ${isActive ? 'active' : ''}`} onClick={() => [onElementClick(post.createdAt), setViewPostDetails(post)]}>
                                            <div className='showDetails'>
                                                <FontAwesomeIcon className='detailsSvg' icon={faChartSimple} />
                                            </div>
                                        </div>
                                    )}
                                    <span>
                                        {
                                            post.updatedAt && (new Date(post.updatedAt).getTime() - new Date(post.createdAt).getTime() > 60000) ? (
                                                <>
                                                    <svg aria-label="Edited" stroke="rgb(153, 153, 153)" fill="currentColor" height="16" role="img" viewBox="0 0 20.1325 21.186" width="16"><title>Edited</title><path d="M3.77951 16.9553C6.48459 19.6604 10.0002 20.471 11.7287 20.637C12.2365 20.6956 12.5197 20.3831 12.5588 20.0608C12.5979 19.719 12.383 19.3479 11.8947 19.2795C10.3322 19.0647 7.10959 18.3616 4.75607 15.9885C0.92795 12.1506 0.205293 6.34986 3.33029 3.22486C5.86936 0.695562 10.0979 1.01783 13.2131 2.69751L14.2287 1.71119C10.4397-0.564204 5.34201-0.749751 2.35373 2.2483C-1.20096 5.81275-0.732207 12.4436 3.77951 16.9553ZM7.3342 14.0354C10.5276 17.2288 15.6643 18.4885 18.5354 15.6272C20.8791 13.2737 20.5764 9.094 18.0666 5.50025L17.0705 6.49634C19.0627 9.44556 19.4533 12.7561 17.5588 14.6506C15.2541 16.9553 11.4455 15.8616 8.82834 13.3909ZM8.6135 12.5803L17.383 3.82056L15.9279 2.37525L7.16818 11.1252L6.3674 12.9905C6.27951 13.1956 6.48459 13.4104 6.69943 13.3323ZM18.0959 3.11744L18.8772 2.33619C19.2483 1.96509 19.2678 1.41822 18.8869 1.06666L18.633 0.83228C18.301 0.51978 17.7737 0.51978 17.4221 0.871343L16.6506 1.66236Z"></path></svg>
                                                    <span>{`${timeSince(new Date(post.updatedAt))}`}</span>
                                                </>
                                            ) : (
                                                <span>{timeSince(new Date(post.createdAt))}</span>
                                            )
                                        }
                                    </span>
                                    {showElements && (
                                        <div className='containerMenuOptionPost'>
                                            <div className="iconActionPost ignore-click" onClick={() => setActivePostOptionsId(post.id)}>
                                                <FontAwesomeIcon icon={faEllipsisH} className='ignore-click' />
                                            </div>
                                            <div className={activePostOptionsId === post.id ? 'menuOptionPost active ignore-click onRight' : 'menuOptionPost ignore-click onRight'}>
                                                {
                                                    post.userId === user.uid ? (
                                                        <>
                                                            <li
                                                                onClick={counters[post.id] > 0 ? () => handleOpenEditPostOnCommunity(post) : undefined}
                                                                style={{ cursor: counters[post.id] > 0 ? 'pointer' : 'not-allowed' }}
                                                            >
                                                                <span>Edit</span>
                                                                <span>
                                                                    {counters[post.id] !== undefined
                                                                        ? counters[post.id] > 0
                                                                            ? `${Math.floor(counters[post.id] / 60)
                                                                                .toString()
                                                                                .padStart(2, '0')}:${(counters[post.id] % 60)
                                                                                    .toString()
                                                                                    .padStart(2, '0')}`
                                                                            : '00:00'
                                                                        : 'Loading...'}
                                                                </span>
                                                            </li>
                                                            <li style={{ color: '#FF3847' }} onClick={() => {
                                                                const mediaUrls = [];
                                                                if (post.audio) {
                                                                    mediaUrls.push(post.audio);
                                                                }
                                                                if (post.photos && post.photos.length) {
                                                                    mediaUrls.push(...post.photos);
                                                                }
                                                                // Armazene as informações da postagem e mostre o alerta
                                                                setSelectedPostForDelete(post.id);
                                                                if (post.parentPostId) {
                                                                    setSelectedCommentForDelete(post.parentPostId)
                                                                }
                                                                setSelectedMediaUrlsForDelete(mediaUrls);
                                                                setShowPopupDeletePost(true); // Isso deve mostrar o componente Alert
                                                            }}>Delete</li>

                                                        </>
                                                    ) : (
                                                        <>
                                                            <li style={{ color: '#FF3847' }} onClick={() => blockUser(post.userId, post.user?.userNick)}>Block</li>
                                                            <li style={{ color: '#FF3847' }}>Report</li>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="contentPostFeedUser">
                                <div className="contentPostFeedUserMessage">

                                    {/* {viewing[post.id] && <span>Sendo visualizado...</span>}
                                    {viewed[post.id] && <span>Visualizado</span>} */}

                                    {/* {viewDurationInSeconds > 0 && (
                                        <div className="viewDuration">
                                            Visualizado por {viewDurationInSeconds} segundo{viewDurationInSeconds !== 1 ? 's' : ''}
                                        </div>
                                    )} */}

                                    <div style={{ whiteSpace: 'pre-line' }}>
                                        {post?.text?.split('\n').filter(line => line.trim() !== '').map((line, lineIndex, array) => (
                                            <span key={lineIndex} style={{ display: 'block', marginBottom: lineIndex !== array.length - 1 ? '1em' : '0' }}>
                                                {line.split(/(\s+)/).map((word, wordIndex) => {
                                                    let cleanWord = word.replace(/[.,!?]$/, '');
                                                    const hasAt = /^@/.test(cleanWord);
                                                    const hasHash = /^#/.test(cleanWord);
                                                    const hasHttp = /^http/.test(cleanWord);

                                                    if (hasAt) {
                                                        return (
                                                            <React.Fragment key={wordIndex}>
                                                                <Link to={`/community/@${cleanWord.substring(1)}`} className="atLink ignore-click">{cleanWord}</Link>
                                                                {cleanWord !== word && word.slice(-1)}
                                                            </React.Fragment>
                                                        );
                                                    }

                                                    if (hasHash) {
                                                        return (
                                                            <React.Fragment key={wordIndex}>
                                                                <span className="hashTag">{cleanWord}</span>
                                                                {cleanWord !== word && word.slice(-1)}
                                                            </React.Fragment>
                                                        );
                                                    }

                                                    if (hasHttp) {
                                                        return (
                                                            <React.Fragment key={wordIndex}>
                                                                <a href={cleanWord} className="httpLink ignore-click" target="_blank" rel="noopener noreferrer">{cleanWord}</a>
                                                                {cleanWord !== word && word.slice(-1)}
                                                            </React.Fragment>
                                                        );
                                                    }

                                                    return word;
                                                })}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                {post.audio && (
                                    <AudioPlayer audioSrc={post.audio} />
                                )}
                                {post?.photos && post?.photos?.length > 0 && (
                                    <div className="contentPostFeedUserImage">
                                        <div className="boxImageUserPost">
                                            <Swiper
                                                slidesPerView='auto'
                                                className="swipperPhotosUserPost"
                                                spaceBetween={10}
                                                modules={[Pagination, Navigation]}
                                            >
                                                {post.photos.map((photo, index) => (
                                                    <SwiperSlide className='photoUserPost' key={index}>
                                                        <div onClick={() => {
                                                            if (expandImage) {
                                                                setSelectedPhoto({ photo, index, postId: post.id });
                                                                setIsFullscreen(true);
                                                            }
                                                        }}>
                                                            <motion.picture
                                                                layoutId={photo}  // Adicionando a animação aqui
                                                                initial={{ opacity: 0 }}
                                                                animate={{ opacity: 1 }}
                                                                exit={{ opacity: 0 }}
                                                            >
                                                                <LazyLoadImage effect="blur" src={photo} alt="User post" className='ignore-click' />
                                                            </motion.picture>
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </div>
                                    </div>
                                )}
                                <AnimatePresence>
                                    {isFullscreen && selectedPhoto && post.photos && post.id === selectedPhoto.postId && (
                                        <motion.div
                                            className="fullscreenSwiper ignore-click"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            onClick={() => [setIsFullscreen(false), setSelectedPhoto(null)]}
                                        >
                                            <div className='closePicture'>
                                                <FontAwesomeIcon icon={faX} />
                                            </div>
                                            <Swiper
                                                initialSlide={selectedPhoto.index}  // Fazendo com que o slide inicial seja o que foi clicado
                                                slidesPerView={1}
                                                spaceBetween={0}
                                                modules={[Pagination, Navigation]}
                                            >
                                                {post.photos.map((photo, index) => (
                                                    <SwiperSlide key={index}>
                                                        <motion.picture layoutId={photo}>
                                                            <LazyLoadImage effect="blur" src={photo} alt="Fullscreen" />
                                                        </motion.picture>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </motion.div>
                                    )}
                                </AnimatePresence>

                                {post.poll && (
                                    (userVotes[post.id] || post.isVoted) ? (
                                        // Caso o usuário já tenha votado, exibir resultados
                                        <div className="containerPackage ignore-click">
                                            <div className="containerInfoAboutPackage">
                                                <p>{post.poll.question}</p>
                                            </div>
                                            <div className="contentOptionsPackage">
                                                {['optionOne', 'optionTwo', 'optionThree', 'optionFour']
                                                    .filter(option => post.poll[option]) // Filtrar apenas opções que existem
                                                    .map(option => {
                                                        const optionText = post.poll[option]; // Texto da opção

                                                        // Determina os votos totais considerando os dados locais e do banco
                                                        const bankVotes = post.poll.votes[option] || 0; // Votos do banco para esta opção
                                                        const localVotes = (voteCounts[post.id] && voteCounts[post.id][option]) || 0; // Votos locais para esta opção
                                                        const votesForOption = localVotes || bankVotes; // Usa votos locais se disponíveis, senão usa do banco

                                                        // Total de votos considerando tanto os locais quanto os do banco
                                                        const allOptions = ['optionOne', 'optionTwo', 'optionThree', 'optionFour'];
                                                        const totalVotes = allOptions.reduce((total, opt) => {
                                                            const votesBank = post.poll.votes[opt] || 0;
                                                            const votesLocal = (voteCounts[post.id] && voteCounts[post.id][opt]) || 0;
                                                            return total + (votesLocal || votesBank); // Soma usando votos locais se disponíveis, senão usa do banco
                                                        }, 0);

                                                        const percentage = totalVotes === 0 ? 0 : Math.round((votesForOption / totalVotes) * 100); // Calcula a porcentagem
                                                        const isActive = userVotes[post.id] === option || post.userVote === option; // Verifica se a opção é ativa

                                                        // Logs para diagnóstico
                                                        // console.log(`Option: ${option}, Text: ${optionText}, Total Votes: ${totalVotes}, Percentage: ${percentage}, IsActive: ${isActive}`);


                                                        return (
                                                            <div className={`inputOptionPackage ${isActive ? "active" : "disabled"}`} key={option}>
                                                                <span>
                                                                    <AnimatedProgressBar percentage={percentage} />
                                                                    <p className='marginPercentage' style={{ mixBlendMode: 'difference', color: '#FFFFFF' }}>
                                                                        {optionText}
                                                                    </p>
                                                                    <label style={{ mixBlendMode: 'difference', color: '#FFFFFF' }}>
                                                                        {`${percentage}%`}
                                                                    </label>
                                                                </span>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                            <p className='totalVotes'>{calculateTotalVotes(post.poll.votes, userVotes[post.id])} votes</p>
                                        </div>
                                    ) : (
                                        // Caso contrário, permitir que o usuário vote
                                        <div className="containerPackage ignore-click">
                                            <div className="containerInfoAboutPackage">
                                                <p>{post.poll.question}</p>
                                            </div>
                                            <div className="contentOptionsPackage">
                                                {['optionOne', 'optionTwo', 'optionThree', 'optionFour'].filter(option => post.poll[option]).map((option, optionIndex) => {
                                                    const optionText = post.poll[option];
                                                    return (
                                                        <div className="inputOptionPackage" key={option}
                                                            onClick={() => vote(post.id, option, postIndex, optionIndex)}>
                                                            <span>
                                                                <p>
                                                                    {optionText}
                                                                </p>
                                                            </span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )
                                )}
                                {urlsMetadata[post.id] && (
                                    <a className="containerDataLink ignore-click" href={urlsMetadata[post.id].url} target="_blank" rel="noopener noreferrer" >
                                        <div className="containerImageDataLink">
                                            <img src={urlsMetadata[post.id].image} alt="Thumbnail" draggable={false} />
                                        </div>
                                        <div className="contentDataImageLink">
                                            <span>{urlsMetadata[post.id].domain}</span>
                                            <p>{urlsMetadata[post.id].title}</p>
                                        </div>
                                    </a>
                                )}
                            </div>

                            {showElements && (
                                <>
                                    <div className="containerButtonActionPost">
                                        <div className="buttonActionPost">
                                            {likedPosts[postIndex] ? (
                                                <div className="iconActionPost ignore-click" onClick={() => handleLike(post.id, true, postIndex, post)}>
                                                    {/* <svg aria-label="Unlike" className="x1lliihq x1n2onr6 xensgl6 redHeart" fill="currentColor" height="19" role="img" viewBox="0 0 20 20" width="19"><title>Unlike</title><path d="M1.2 7.328c0 3.66 3.118 7.269 7.99 10.347.27.163.592.325.81.325.226 0 .548-.162.81-.325 4.87-3.078 7.99-6.687 7.99-10.347C18.8 4.189 16.595 2 13.737 2c-1.655 0-2.953.753-3.738 1.881C9.233 2.761 7.926 2 6.262 2c-2.85 0-5.063 2.19-5.063 5.328Z" fill="currentColor" stroke="currentColor" strokeWidth="1.75"></path></svg> */}
                                                    <svg aria-label="Diferente" role="img" viewBox="0 0 20 20" className="like redHeart" style={{ fill: "currentColor", height: 22, width: 22 }}><title>Unlike</title><path d="M1.2 7.328c0 3.66 3.118 7.269 7.99 10.347.27.163.592.325.81.325.226 0 .548-.162.81-.325 4.87-3.078 7.99-6.687 7.99-10.347C18.8 4.189 16.595 2 13.737 2c-1.655 0-2.953.753-3.738 1.881C9.233 2.761 7.926 2 6.262 2c-2.85 0-5.063 2.19-5.063 5.328Z"></path></svg>
                                                </div>
                                            ) : (
                                                <div className="iconActionPost ignore-click" onClick={() => handleLike(post.id, false, postIndex, post)}>
                                                    {/* <svg aria-label="Like" className="x1lliihq x1n2onr6 x1yxark7" fill="transparent" height="19" role="img" viewBox="0 0 24 22" width="19"><title>Like</title><path d="M1 7.66c0 4.575 3.899 9.086 9.987 12.934.338.203.74.406 1.013.406.283 0 .686-.203 1.013-.406C19.1 16.746 23 12.234 23 7.66 23 3.736 20.245 1 16.672 1 14.603 1 12.98 1.94 12 3.352 11.042 1.952 9.408 1 7.328 1 3.766 1 1 3.736 1 7.66Z" stroke="currentColor" strokeWidth="2"></path></svg> */}
                                                    <svg aria-label="Diferente" strokeWidth="1.5" role="img" viewBox="0 0 20 20" className="like" style={{ height: 22, width: 22 }}><title>Unlike</title><path d="M1.2 7.328c0 3.66 3.118 7.269 7.99 10.347.27.163.592.325.81.325.226 0 .548-.162.810-.325 4.87-3.078 7.99-6.687 7.99-10.347C18.8 4.189 16.595 2 13.737 2c-1.655 0-2.953.753-3.738 1.881C9.233 2.761 7.926 2 6.262 2c-2.85 0-5.063 2.19-5.063 5.328Z" fill="none" stroke="#1D1D1F"></path></svg>
                                                </div>
                                            )}
                                        </div>

                                        <div className="buttonActionPost">
                                            <div className="iconActionPost ignore-click" onClick={() => handleOpenPostOnCommunity(post)}>
                                                <svg aria-label="Reply" className="x1lliihq x1n2onr6 x1yxark7" fill="currentColor" height="20" role="img" viewBox="0 0 24 24" width="20"><title>Reply</title><path d="M20.656 17.008a9.993 9.993 0 1 0-3.59 3.615L22 22Z" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2"></path></svg>
                                            </div>
                                        </div>

                                        <div className="buttonActionPost">
                                            <div className='containerMenuOptionPost'>
                                                <div className="iconActionPost ignore-click" onClick={() => setActivePostShareId(post.id)}>
                                                    <svg aria-label="Share" className="x1lliihq x1n2onr6 x1yxark7" fill="currentColor" height="20" role="img" viewBox="0 0 24 24" width="20"><title>Share</title><line fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2" x1="22" x2="9.218" y1="3" y2="10.083"></line><polygon fill="none" points="11.698 20.334 22 3.001 2 3.001 9.218 10.084 11.698 20.334" stroke="currentColor" strokeLinejoin="round" strokeWidth="2"></polygon></svg>
                                                </div>
                                                <div className={activePostShareId === post.id ? 'menuOptionPost active ignore-click onLeft' : 'menuOptionPost ignore-click onLeft'}>
                                                    <li onClick={() => copyLink(`/@${post.user?.normalizedUserNick}/post/${post.id}`)}>Copy link</li>
                                                    <li>Copy embed code</li>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="containerInfoNumberPostUser">
                                        {likesCounts[postIndex] >= 1 && (
                                            <Link>{likesCounts[postIndex]} {likesCounts[postIndex] == 1 ? ' like' : ' likes'}</Link>
                                        )}
                                        {likesCounts[postIndex] >= 1 && commentsCount[post.id] >= 1 && (
                                            <span> · </span>
                                        )}
                                        {commentsCount[post.id] >= 1 && (
                                            <Link>{commentsCount[post.id]} {commentsCount[post.id] == 1 ? ' reply' : ' replies'}</Link>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )

            })}
        </>
    );
};

export default PostItem;
