import React, { useState, useRef, useEffect, useContext } from "react";
import { Box, IconButton, TextField } from "@mui/material";
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ScrollMessage from '../../talk2IA/ChatAI/scrollMessage';

import { Mic } from "@mui/icons-material";
import { styled } from '@mui/material/styles';

export const CommonButton = styled(IconButton)(({ active }) => ({
    color: '#FFFFFF',
    backgroundColor: '#3F8CE8',
    transition: 'all 0.1s ease-in-out',
    boxShadow: active
        ? `0 0px 0 #1A73E8` // Sombra mais escura para efeito pressionado
        : `0px 4px 0px #1A73E8`, // Sombra 3D com cinza bem escuro
    border: `1px solid #3F8CE8`, // Bordas com cor baseada no estado ativo
    transform: active ? 'translateY(6px)' : 'none', // Movimentação para efeito de pressão
    '&:hover': {
        backgroundColor: '#3F8CE8',
    },
    '&:active': {
        boxShadow: `0 0px 0 #1A73E8`, // Remover sombra ao clicar
        transform: 'translateY(6px)' // Movimento para efeito de pressão
    }
}));

export default function ChatInteractions({ messageRef, messages, setInteractLesson, theme, handleSendMessage, message, setMessage, micOn, setMicOn }) {

    function handleKeyPress(e) {
        const inputValue = e.target.value;

        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();

            if (inputValue.trim() !== '') {
                handleSendMessage(inputValue, [], e, true);
                setMessage("");
            }
        }
    }

    const handleSend = (event) => {
        handleSendMessage(message, [], event, true);
        setMessage("");
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', maxHeight: '435px', position: 'relative' }}>
            <ScrollMessage messageRef={messageRef} messages={messages} sx={{ height: '435px', maxWidth: '500px', overflowY: 'auto', padding: '0px 10px 0px 10px', display: 'flex', flexDirection: 'column-reverse', marginRight: '2px', position: 'relativa' }} boxes={[]} setInteractLesson={setInteractLesson} />
            <Box sx={{ display: 'flex', gap: '10px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CommonButton theme={theme} active={micOn} onClick={() => setMicOn(!micOn)}>
                        <Mic />
                    </CommonButton>
                </Box>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <TextField
                        multiline
                        placeholder="Type a message..."
                        variant="outlined"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    onClick={(event) => handleSend(event)}
                                    sx={{
                                        backgroundColor: theme.color,
                                        color: theme.border,
                                        borderRadius: '50%',
                                        width: '30px',
                                        height: '30px',
                                        '&:hover': {
                                            backgroundColor: theme.color
                                        }
                                    }}
                                >
                                    <ArrowUpward style={{ fontSize: '18px' }} />
                                </IconButton>
                            ),
                            style: { padding: '5px ', borderRadius: '20px', outline: 'none', color: theme.color }
                        }}
                        sx={{
                            flexGrow: 1,
                            '& .MuiOutlinedInput-root': { padding: 0 },
                            '& .MuiInputBase-input': { paddingLeft: '10px' },
                            width: '100%',
                            // border: `2px solid #0C78E8`,
                            border: `2px solid ${theme.border}`,
                            borderRadius: '20px',
                            color: theme.color,
                            backgroundColor: theme.background,
                            '& .MuiOutlinedInput-notchedOutline': { display: 'none' },
                            '& .css-ihdtdm': {
                                display: 'none'
                            }
                        }}
                    />
                </Box>
            </Box>
        </Box>
    )
}