import React, { useState } from "react";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { Translate, MenuBook, Fullscreen, FullscreenExit, RecordVoiceOver, Speed, Settings } from "@mui/icons-material";
import { useBallTalkContext } from "../../../componentsTalkIA/BallTalkContext";
import Dictionary from "../../../componentsTalkIA/dictionary";
import Translator from "../../../componentsTalkIA/translator";
import ConfigLexi from "../../ConfigLexi";

const MoreOptionsMenu = ({ anchorEl, open, handleMenuClose, selectedProfessor, selectedVoice, setSelectedVoice, handleDelete, addBox }) => {
    const [voiceAnchorEl, setVoiceAnchorEl] = useState(null);
    const [speedAnchorEl, setSpeedAnchorEl] = useState(null);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const [openConfigLexi, setOpenConfigLexi] = useState(false);
    const handleOpen = () => setOpenConfigLexi(true);

    const { selectedSpeed, setSelectedSpeed } = useBallTalkContext();

    const multiVoices = ["alloy", "echo", "fable", "onyx", "nova", "shimmer"];
    const normalVoices = ["Joanna", "Matthew", "Ivy", "Brian", "Emma", "Danielle"];
    const speeds = ["0.2x", "0.5x", "1x", "1.2x", "1.5x"];

    const openVoiceMenu = Boolean(voiceAnchorEl);
    const openSpeedMenu = Boolean(speedAnchorEl);

    const handleVoiceMenuOpen = (event) => {
        setVoiceAnchorEl(event.currentTarget);
    };

    const handleVoiceMenuClose = () => {
        setVoiceAnchorEl(null);
    };

    const handleSpeedMenuOpen = (event) => {
        setSpeedAnchorEl(event.currentTarget);
    };

    const handleSpeedMenuClose = () => {
        setSpeedAnchorEl(null);
    };

    const toggleFullscreen = () => {
        if (!isFullscreen) {
            document.documentElement.requestFullscreen();
        } else {
            if (document.fullscreenElement) {
                document.exitFullscreen();
            }
        }
        setIsFullscreen(!isFullscreen);
    };

    const showDictionary = () => {
        addBox(
            () => (
                <Dictionary />
            ),
            'dictionary'
        );
        handleMenuClose()
    }

    const showTranslator = () => {
        addBox(
            () => (
                <Translator />
            ),
            'translator'
        );
        handleMenuClose()
    }

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            PaperProps={{
                style: {
                    borderRadius: 10,
                    minWidth: '250px',
                },
            }}
            
        >
            <ConfigLexi handleDelete={handleDelete} addBox={addBox} open={openConfigLexi} setOpen={setOpenConfigLexi} />

            <MenuItem onClick={showTranslator} sx={{ paddingY: 1.2 }}>
                <ListItemIcon>
                    <Translate fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Open Translator" />
            </MenuItem>
            <MenuItem onClick={showDictionary} sx={{ paddingY: 1.2 }}>
                <ListItemIcon>
                    <MenuBook fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Open Dictionary" />
            </MenuItem>
            <MenuItem onClick={() => { toggleFullscreen(); handleMenuClose(); }} sx={{ paddingY: 1.2 }}>
                <ListItemIcon>
                    {isFullscreen ? <FullscreenExit fontSize="small" /> : <Fullscreen fontSize="small" />}
                </ListItemIcon>
                <ListItemText primary={isFullscreen ? "Exit Fullscreen" : "Fullscreen"} />
            </MenuItem>
            <MenuItem onClick={handleVoiceMenuOpen} sx={{ paddingY: 1.2 }}>
                <ListItemIcon>
                    <RecordVoiceOver fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Voices" />
            </MenuItem>
            <Menu
                anchorEl={voiceAnchorEl}
                open={openVoiceMenu}
                onClose={handleVoiceMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {(selectedProfessor === "basico" ? multiVoices : normalVoices).map((voice, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => { setSelectedVoice(voice); handleVoiceMenuClose(); handleMenuClose(); }}
                        sx={{ color: selectedVoice === voice ? 'blue' : 'inherit' }}
                    >
                        {voice}
                    </MenuItem>
                ))}
            </Menu>
            <MenuItem onClick={handleSpeedMenuOpen} sx={{ paddingY: 1.2 }}>
                <ListItemIcon>
                    <Speed fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Voice Speed" />
            </MenuItem>
            <Menu
                anchorEl={speedAnchorEl}
                open={openSpeedMenu}
                onClose={handleSpeedMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {speeds.map((speed, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => { setSelectedSpeed(speed); handleSpeedMenuClose(); handleMenuClose(); }}
                        sx={{ color: selectedSpeed === speed ? 'blue' : 'inherit' }}
                    >
                        {speed}
                    </MenuItem>
                ))}
            </Menu>
            <MenuItem onClick={handleOpen} sx={{ paddingY: 1.2, paddingBottom: '9.6px !important', }}>
                <ListItemIcon>
                    <Settings fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Settings" />
            </MenuItem>
        </Menu>
    );
};

export default MoreOptionsMenu;
