import React, { useState } from 'react';
import axios from 'axios';
import { openaiApiKey } from '../../../auth/config/apiConfig';
import AWS from 'aws-sdk';

const baseURL = "https://api.openai.com/v1/audio/speech";

const axiosInstance = axios.create({
    baseURL,
    headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${openaiApiKey}`,
    },
});

async function sendRequestWithExponentialBackoff(fn, maxRetries = 5) {
    for (let i = 0; i < maxRetries; i++) {
        try {
            return await fn();
        } catch (error) {
            if (error.response && error.response.status === 429) {
                // Espera antes de tentar novamente
                await new Promise(resolve => setTimeout(resolve, Math.pow(2, i) * 1000));
            } else {
                throw error;
            }
        }
    }
    throw new Error('Max retries exceeded');
}

export async function sendTextToSpeechRequestMulti(text, voice) {

    try {
        const response = await sendRequestWithExponentialBackoff(() =>
            axiosInstance.post("", {
                model: "tts-1",
                input: text,
                voice: voice
            }, {
                responseType: 'blob' // Isso garante que a resposta é tratada como um Blob
            })
        );

        const audioUrl = URL.createObjectURL(response.data);
        return audioUrl;
    } catch (error) {
        console.error("Erro ao chamar a API de texto para fala:", error);
        throw error;
    }
}


// Configurações do AWS SDK
// AWS.config.update({
//     region: 'us-east-1', // Escolha a região apropriada
//     accessKeyId: 'AKIARHFOGZNHLZJN45PL', // Substitua com sua AWS Access Key
//     secretAccessKey: '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', // Substitua com sua AWS Secret Key
// });

AWS.config.update({
    region: 'us-east-1', // Escolha a região apropriada
    accessKeyId: 'AKIAZI2LDADXVOVSC64M', // Substitua com sua AWS Access Key
    secretAccessKey: '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', // Substitua com sua AWS Secret Key
});

const polly = new AWS.Polly({
    apiVersion: '2016-06-10'
});

export async function sendTextToSpeechRequest(text, voice) {

    const params = {
        'Text': text,
        'OutputFormat': 'mp3', // Você pode escolher outro formato se necessário
        'VoiceId': voice, // Por exemplo, 'Joanna', 'Matthew', etc.
        'Engine': 'neural'
    };

    try {
        const result = await polly.synthesizeSpeech(params).promise();

        const audioUrl = URL.createObjectURL(new Blob([result.AudioStream], { type: 'audio/mp3' }));
        return audioUrl;
    } catch (error) {
        console.error("Erro ao chamar o Amazon Polly:", error);
        throw error;
    }
}
