import React, { useContext, useState, useEffect, useRef } from "react";
import logo from '../../assets/images/logo.png';
import './style.css';
import { Box, Button, Card, CardContent, CardHeader, Typography, CircularProgress } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { UserContext } from "../../components/UserProvider";
import { stripePromise } from "../..";
import { Link } from "react-router-dom";
import { useAllTheme } from "../../components/themes/ThemeContext";

export default function Plans() {

    const { theme, isDark } = useAllTheme();

    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const { user, email } = useContext(UserContext)

    const [isLoading, setIsLoading] = useState(false);

    const swiperRef = useRef(null);

    useEffect(() => {
        async function fetchPlans() {
            try {
                const response = await fetch('http://localhost:3009/plans');
                if (response.ok) {
                    const data = await response.json();
                    setPlans(data.data);
                } else {
                    console.error('Erro ao buscar planos:', response.statusText);
                }
            } catch (error) {
                console.error('Erro na requisição:', error);
            }
        }

        fetchPlans();
    }, []);

    // Função para selecionar um plano
    const handleSelectPlan = (plan) => {
        setSelectedPlan(plan);
    };


    // Função para processar o checkout
    const handleCheckout = async () => {
        if (!selectedPlan || !user.uid || !email) return;


        setIsLoading(true)

        try {
            // Faça a requisição ao backend com o preço, UID e email
            const response = await fetch('http://localhost:3009/create-checkout-session', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    priceId: selectedPlan.id,
                    uid: user.uid,
                    email: email,
                }),
            });

            // Obtenha a sessão de checkout da resposta
            const { id } = await response.json();

            // Redirecione para a página de checkout da Stripe
            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({ sessionId: id });
            setIsLoading(false)
            if (error) {
                console.error('Erro ao redirecionar:', error);
                setIsLoading(false)
            }
        } catch (error) {
            console.error('Erro na requisição:', error);
            setIsLoading(false)
        }
    };

    return (
        <div className="plans">
            <header>
                <div className="logo-wrapper">
                    <img src={logo} alt='Logo app' />
                </div>
                <Box>
                    <Link to='/settings/subscription'>
                        <Button>Sair</Button>
                    </Link>
                </Box>
            </header>
            <main>
                <div className="hero-section">
                    <Swiper className="steps-payment" allowTouchMove={false} ref={swiperRef}>
                        <SwiperSlide>
                            <Box
                                padding={4}
                                width={400}
                                margin="auto"
                                display="flex"
                                flexDirection="column"
                            >
                                <CheckCircleOutlineIcon style={{ fontSize: 60, color: '#007BFF', alignSelf: 'center' }} />
                                <Typography variant="h6" sx={{ color: theme.color }} component="div" marginTop={2} align="center">
                                    PASSO 1 DE 3
                                </Typography>
                                <Typography variant="h4" sx={{ color: theme.color }} component="div" marginTop={1} align="center">
                                    Escolha seu plano.
                                </Typography>
                                <Box marginTop={2}>
                                    <Typography variant="body1" sx={{ color: theme.color }} component="p" display="flex" alignItems="center">
                                        <CheckCircleOutlineIcon style={{ color: '#007BFF', marginRight: 8 }} />
                                        Sem compromisso, cancele quando quiser.
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: theme.color }} component="p" display="flex" alignItems="center" marginTop={1}>
                                        <CheckCircleOutlineIcon style={{ color: '#007BFF', marginRight: 8 }} />
                                        Entretenimento sem fim, por um preço baixo.
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: theme.color }} component="p" display="flex" alignItems="center" marginTop={1}>
                                        <CheckCircleOutlineIcon style={{ color: '#007BFF', marginRight: 8 }} />
                                        Divirta-se com a Netflix em todos os seus aparelhos.
                                    </Typography>
                                </Box>
                                <Button
                                    variant="contained"
                                    style={{ marginTop: 20, width: '100%', padding: 15, backgroundColor: '#007BFF' }}
                                    onClick={() => swiperRef.current.swiper.slideNext()}
                                >
                                    Próximo
                                </Button>
                            </Box>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Box
                                padding={4}
                                width="80%"
                                margin="auto"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                <Box
                                    display="flex"
                                    flexWrap="wrap"
                                    justifyContent="center"
                                    gap={4}
                                    width="100%"
                                >
                                    {plans.map((plan) => (
                                        <Card
                                            key={plan.id}
                                            onClick={() => handleSelectPlan(plan)}
                                            className={`plan-card ${selectedPlan && selectedPlan.id === plan.id ? 'selected' : ''}`}
                                        >
                                            <CardHeader
                                                title={plan.product.name}
                                                subheader={plan.product.description}
                                                style={{ textAlign: 'center' }}
                                            />
                                            <CardContent>
                                                <Typography variant="h6" color="textSecondary" component="p" sx={{ textAlign: 'center' }}>
                                                    {plan.currency.toUpperCase()} {plan.unit_amount / 100}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    {plan.billing_scheme === 'per_unit' ? 'Per Unit' : 'Other Scheme'}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    Interval: {plan.recurring.interval_count} {plan.recurring.interval}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    ))}
                                </Box>
                                <Button
                                    variant="contained"
                                    style={{
                                        marginTop: 20,
                                        width: '100%',
                                        maxWidth: '400px',
                                        padding: 15,
                                        backgroundColor: selectedPlan ? '#007BFF' : '#B0B0B0',
                                    }}
                                    onClick={handleCheckout}
                                    disabled={!selectedPlan || isLoading}
                                >
                                    {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Próximo'}
                                </Button>
                            </Box>
                        </SwiperSlide>

                    </Swiper>
                </div>
            </main>
        </div>
    );
}
