import { Box } from '@mui/material'
import SimpleMenu from '../components/SimpleMenu'
import NotesComponent from '../talk2IA/Notes'

export default function Notes () {
    return (
        <div className='allHome'>
            <SimpleMenu />
            <Box className="home" >
                <NotesComponent />
            </Box>
        </div>
    )
}