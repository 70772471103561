export default function Loading({size, color}) {

    return (
        <div style={{ width: size, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <svg aria-label="Loading..." className="xemfg65 xa4qsjk xs51kk x2a5n4e spin" role="img" viewBox="0 0 100 100">
                <rect className="fade" style={{ animationDelay: '0s' }} fill={color} height="10" rx="5" ry="5" transform="rotate(-90 50 50)" width="28" x="67" y="45"></rect>
                <rect className="fade" style={{ animationDelay: '0.1s' }} fill={color} height="10" rx="5" ry="5" transform="rotate(-45 50 50)" width="28" x="67" y="45"></rect>
                <rect className="fade" style={{ animationDelay: '0.2s' }} fill={color} height="10" rx="5" ry="5" transform="rotate(0 50 50)" width="28" x="67" y="45"></rect>
                <rect className="fade" style={{ animationDelay: '0.3s' }} fill={color} height="10" rx="5" ry="5" transform="rotate(45 50 50)" width="28" x="67" y="45"></rect>
                <rect className="fade" style={{ animationDelay: '0.4s' }} fill={color} height="10" rx="5" ry="5" transform="rotate(90 50 50)" width="28" x="67" y="45"></rect>
                <rect className="fade" style={{ animationDelay: '0.5s' }} fill={color} height="10" rx="5" ry="5" transform="rotate(135 50 50)" width="28" x="67" y="45"></rect>
                <rect className="fade" style={{ animationDelay: '0.6s' }} fill={color} height="10" rx="5" ry="5" transform="rotate(180 50 50)" width="28" x="67" y="45"></rect>
                <rect className="fade" style={{ animationDelay: '0.7s' }} fill={color} height="10" rx="5" ry="5" transform="rotate(225 50 50)" width="28" x="67" y="45"></rect>
            </svg>
        </div>
    )
}