import React from 'react';
import { Card, CardContent, Typography, Box, List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchIcon from '@mui/icons-material/Search';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useNavigate } from 'react-router-dom';
import { useAllTheme } from '../../components/themes/ThemeContext';

const CardContainer = styled(Card)(({ theme }) => ({
    borderRadius: '16px',
    background: theme.background,
    color: theme.color,
    padding: 0,
    position: 'relative',
    overflow: 'hidden',
    minWidth: '300px',
    boxShadow: 'none',
    border: `2px solid ${theme.border}`,
}));

const AddFriend = () => {

    const navigate = useNavigate();

    const { theme, isDark } = useAllTheme();

    const shareText = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Texto Compartilhado',
                text: `Venha conhecer o EnglishFlu e aprender um novo idioma comigo! \n https://app.englishflu.com/community/@isaquesangley`
            }).then(() => {
                console.log('Texto compartilhado com sucesso');
            }).catch((err) => {
                console.error('Erro ao compartilhar texto: ', err);
            });
        } else {
            console.warn('A API de compartilhamento não está disponível neste dispositivo');
        }
    };

    return (
        <CardContainer theme={theme}>
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '16px',
                    boxShadow: 'none',
                    '&:last-child': {
                        paddingBottom: '16px',
                    },
                }}
            >
                <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{ fontFamily: 'Din-Round-Bold, sans-serif', color: theme.color }}
                >
                    Adicionar amigos
                </Typography>
                <List>
                    <ListItem button sx={{ borderRadius: '10px' }} onClick={() => navigate('/community')}>
                        <ListItemIcon>
                            <SearchIcon sx={{ color: isDark ? theme.color : ''}} />
                        </ListItemIcon>
                        <ListItemText
                            primary="Encontrar amigos"
                            primaryTypographyProps={{ fontFamily: 'Din-Round-Bold, sans-serif', color: theme.color }}
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="arrow">
                                <ArrowForwardIosIcon sx={{ color: isDark ? theme.color : ''}} />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem button sx={{ borderRadius: '10px' }} onClick={shareText} >
                        <ListItemIcon>
                            <MailOutlineIcon sx={{ color: isDark ? theme.color : ''}} />
                        </ListItemIcon>
                        <ListItemText
                            primary="Convidar amigos"
                            primaryTypographyProps={{ fontFamily: 'Din-Round-Bold, sans-serif', color: theme.color }}
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="arrow">
                                <ArrowForwardIosIcon sx={{ color: isDark ? theme.color : ''}} />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </CardContent>
        </CardContainer>
    );
};

export default AddFriend;
