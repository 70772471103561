export const themes = {
  light: {
    background: '#FFFFFF', // Fundo branco para o tema claro

    topBarBackground: '#F0F0F0', // Fundo cinza claro para o TopBarContent
    buttonText: '#FFFFFF', // Texto preto para botões
    buttonBackground: '#1D1D1F', // Fundo cinza claro para botões
    buttonHoverBackground: '#111111', // Fundo um pouco mais escuro ao passar o mouse

    boxBackground: '#E0E0E0', // Fundo cinza médio para caixas dinâmicas com múltiplas caixas
    activeBoxOverlay: 'rgba(0, 0, 0, 0.1)', // Sobreposição escura para caixas ativas
    deleteButtonBackground: '#FFFFFF', // Fundo branco para o botão de deletar
    deleteButtonHoverBackground: '#E5E5E5', // Fundo cinza claro para o botão de deletar ao passar o mouse
    deleteButtonIconColor: '#000000', // Ícone preto para o botão de deletar

    actionBarButtonInactiveBackground: '#1D1D1F', // Fundo escuro para botões inativos no ActionBar
    actionBarButtonInactiveColor: '#FFFFFF', // Texto branco para botões inativos no ActionBar
    actionBarButtonActiveBackground: '#007AFF', // Fundo azul para botões ativos no ActionBar
    actionBarButtonActiveColor: '#FFFFFF', // Texto branco para botões ativos no ActionBar
    actionBarButtonShadowColor: '#444444', // Sombra cinza escura para o efeito 3D

    redButtonColor: '#FFFFFF', // Texto branco para o RedButton
    redButtonBackground: '#FF0000', // Fundo vermelho para o RedButton
    redButtonBorder: '#FF0000', // Borda vermelha para o RedButton
    redButtonShadowColor: '#990000', // Sombra vermelha escura para o RedButton

    chatButtonColor: '#000000', // Texto preto para o botão de chat
    chatButtonFontSize: '20px', // Tamanho da fonte para o botão de chat

    timerTextColor: '#000000', // Texto preto para o timer
    timerFontFamily: 'SF-Pro-Display-Bold, sans-serif', // Família de fontes para o timer
    timerFontSize: 17, // Tamanho da fonte para o timer
  },

  dark: {
    background: '#202124', // Cor de fundo para o pai de todos os componentes
    topBarBackground: '#434649', // Cor de fundo para o TopBarContent
    buttonText: '#FFFFFF', // Cor do texto dos botões
    buttonBackground: '#4A4E51', // Cor de fundo dos botões
    buttonHoverBackground: '#5C5F62', // Cor de fundo dos botões ao passar o mouse

    boxBackground: '#3C4043', // Cor de fundo para caixas dinâmicas com múltiplas caixas
    activeBoxOverlay: 'rgba(0, 0, 0, 0.7)', // Cor de fundo sobreposta para caixas ativas
    deleteButtonBackground: '#FFFFFF', // Cor de fundo para o botão de deletar
    deleteButtonHoverBackground: '#E5E5E5', // Cor de fundo do botão de deletar ao passar o mouse
    deleteButtonIconColor: '#000000', // Cor do ícone do botão de deletar

    actionBarButtonActiveColor: '#202124', // Cor do texto para botões ativos no ActionBar
    actionBarButtonInactiveColor: '#FFFFFF', // Cor do texto para botões inativos no ActionBar
    actionBarButtonActiveBackground: '#FFFFFF', // Cor de fundo para botões ativos no ActionBar
    actionBarButtonInactiveBackground: '#434649', // Cor de fundo para botões inativos no ActionBar
    actionBarButtonShadowColor: '#333333', // Cor da sombra para botões no ActionBar

    redButtonColor: '#FFFFFF', // Cor do texto para o RedButton
    redButtonBackground: 'red', // Cor de fundo para o RedButton
    redButtonBorder: 'red', // Cor da borda para o RedButton
    redButtonShadowColor: 'darkred', // Cor da sombra para o RedButton

    chatButtonColor: '#FFFFFF', // Cor do texto para o botão de chat
    chatButtonFontSize: '20px', // Tamanho da fonte para o botão de chat

    timerTextColor: '#FFFFFF', // Cor do texto do timer
    timerFontFamily: 'SF-Pro-Display-Bold, sans-serif', // Família de fontes do texto do timer
    timerFontSize: 17, // Tamanho da fonte do texto do timer

    // outras propriedades de cor...
  },
};
