import React, { useContext, useState, useEffect, useRef } from 'react';
import { UserContext } from '../../components/UserProvider';
import './style.css'
import PostOnCommunity from '../componentsCommunity/postOnCommunity';
import HeaderCommunity from '../componentsCommunity/headerCommunity';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faSearch, faVideo, faPaperclip, faMicrochip, faMicrophone, faEllipsisH, faTrash, faTrashAlt, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import { db } from '../../auth/config/firebase';
import { collection, query, orderBy, onSnapshot, addDoc, where, getDocs, limit, getDoc, doc, updateDoc, startAfter, deleteDoc } from 'firebase/firestore';

import crown from '../../assets/icons/crown.png'
import { FaVideo } from 'react-icons/fa';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import animation from '../../assets/animation/animationConversationCommunity.json'
import { addNotification } from '../../components/notification';
import Picker from 'emoji-picker-react';
import InputEmoji from 'react-input-emoji';
import twemoji from 'twemoji';
import emojiRegex from 'emoji-regex';

import loader from './mn224E_W9XQ.gif'
import Alert from '../../components/alert';
import { useCall } from '../componentsCommunity/CallContext';
import Loading from '../../components/loading';
import UserAvatar from '../../components/UserAvatar';

export default function Chat() {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animation
    };

    const { calling, setCall } = useCall();

    const { conversationId } = useParams();
    const chatContainerRef = useRef(null);
    const { user, photoCommunity, newNameCommunity } = useContext(UserContext);
    const [showPostOnCommunity, setShowPostOnCommunity] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [messages, setMessages] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const navigate = useNavigate();
    const [activeMenuMessageId, setActiveMenuMessageId] = useState(null);
    const [inputText, setInputText] = useState('');

    const [selectedPost, setSelectedPost] = useState(null);
    const [selectedEditPost, setSelectedEditPost] = useState(null);

    const [showAlert, setShowAlert] = useState(false)

    const [gettingInCall, setGettingInCall] = useState(false)

    const canSendNewVideoCall = (messages) => {
        const currentTime = new Date().getTime();
        const userId = user.uid;

        console.log("Iniciando verificação para nova chamada de vídeo - Hora atual:", currentTime);

        // Verifica se existem mensagens suficientes: pelo menos uma do usuário e uma de outro usuário
        const hasOwnMessage = messages.some(message => message.fromUserId === userId);
        const hasOtherUserMessage = messages.some(message => message.fromUserId !== userId);

        if (!hasOwnMessage || !hasOtherUserMessage) {
            console.log("É necessário que haja pelo menos uma conversa entre os dois usuários antes de iniciar uma chamada de vídeo.");
            addNotification("Para iniciar uma chamada de vídeo, ambos os usuários devem ter participado da conversa!", 'dark3');
            return false;
        }

        // Continua com a verificação normal das mensagens de vídeo chamada
        const allMessagesValid = messages.every(message => {
            if (message.type === "videoCall") {
                const isExpired = currentTime > message.validUntil;  // true se a chamada expirou
                let isCancelled = message.userCancel;  // true se a chamada foi cancelada
                const isUserIn = message.userIn; // true se o usuário já participou da chamada

                // Se isCancelled é undefined, considera como não cancelada (false)
                isCancelled = isCancelled === true;

                console.log(`Verificando mensagem de vídeo chamada: ID ${message.id}`);
                console.log(`   Expirada: ${isExpired}, Cancelada: ${isCancelled}, Usuário Participou: ${isUserIn}`);

                // Se a chamada não está expirada e não está cancelada e o usuário não entrou na chamada, retorna false
                if (!isExpired && !isCancelled && !isUserIn) {
                    addNotification('Já existe uma chamada de vídeo ativa ou pendente.', 'dark3');
                    return false;
                }
                return true; // Retorna true se a chamada estiver expirada, cancelada ou o usuário já entrou
            }
            return true; // Retorna verdadeiro para mensagens não vídeo chamada
        });

        console.log("Todos as mensagens são válidas para nova chamada de vídeo:", allMessagesValid);
        return allMessagesValid;
    };




    const handleSendVideoCallRequest = (messages) => {
        if (canSendNewVideoCall(messages)) {
            setShowAlert(true)
        }
    };

    useEffect(() => {
        const handleBeforeUnload = async (e) => {
            const currentTime = new Date().getTime();

            const pendingVideoCallMessage = selectedUser?.messages?.find(message =>
                message.type === "videoCall" && // É uma chamada de vídeo
                message.fromUserId === user.uid && // Foi enviada pelo próprio usuário
                currentTime < message.validUntil && // Ainda está no prazo de validade
                !message.expired && // Não expirou
                !message.userIn // O usuário ainda não entrou
            );


            if (pendingVideoCallMessage) {
                const messageRef = doc(db, "conversations", selectedUser.id, "messages", pendingVideoCallMessage.id);
                await updateDoc(messageRef, {
                    userCancel: true
                });
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [selectedUser, user.uid]);

    const sendVideoCallRequest = async () => {
        setShowAlert(false)
        let convId = selectedUser?.id;
        let otherUserUid = selectedUser?.otherUser?.uid;
        const callId = "xrx-oaui-ooe";
        const currentTime = new Date().getTime();
        const twoMinutesLater = currentTime + 120000;

        let messageData = {
            id: Date.now().toString(),
            fromUserId: user.uid,
            toUserId: otherUserUid,
            type: "videoCall",
            message: `Participe de uma vídeo chamada`,
            callId: callId,
            timestamp: currentTime,
            validUntil: twoMinutesLater,
            userIn: false,
            userCancel: false
        };

        if (!convId && otherUserUid) {
            // Cria uma nova conversa
            const newConversationData = {
                lastUpdated: messageData.timestamp,
                participants: [user.uid, otherUserUid],
            };
            const newConversationRef = await addDoc(collection(db, "conversations"), newConversationData);
            convId = newConversationRef.id;

            // Atualiza 'selectedUser' com os dados da nova conversa
            setSelectedUser({ id: convId, ...newConversationData, otherUser: selectedUser?.otherUser });
        } else if (convId) {
            // Atualiza a conversa existente com o timestamp da última mensagem
            const conversationDocRef = doc(db, "conversations", convId);
            await updateDoc(conversationDocRef, {
                lastUpdated: messageData.timestamp
            });
        }

        if (convId) {
            const docRef = await addDoc(collection(db, "conversations", convId, "messages"), messageData);

            listenToVideoCallMessage(docRef.id, callId);
        }
    };

    const listenToVideoCallMessage = (messageId, callId) => {
        const messageRef = doc(db, "conversations", selectedUser.id, "messages", messageId);

        const unsubscribe = onSnapshot(messageRef, (doc) => {
            const message = doc.data();

            if (new Date().getTime() > message.validUntil && !message.expired) {
                updateMessageState(messageId, { ...message, expired: true });
                unsubscribe();
            }

            if (message.userIn) {
                setGettingInCall(true)
                setTimeout(() => {
                    navigate(`/community/call/${callId}`);
                }, 5000);
                updateMessageState(messageId, { ...message, connecting: true });
                unsubscribe();
            }
        });

        setTimeout(() => {
            unsubscribe();
        }, 120000);
    };

    const updateMessageState = (messageId, updatedMessage) => {
        setMessages(prevMessages => prevMessages.map(group =>
            Array.isArray(group)
                ? group.map(message => {
                    if (message.id === messageId) {
                        return { ...message, ...updatedMessage };
                    }
                    return message;
                })
                : group
        ));
    };

    const joinVideoCall = async (messageId, callId) => {
        try {
            // Atualiza o estado da mensagem para indicar que o usuário entrou
            const messageRef = doc(db, "conversations", selectedUser.id, "messages", messageId);
            await updateDoc(messageRef, {
                userIn: true
            });

            // Redireciona para a página da chamada de vídeo
            navigate(`/community/call/${callId}`);
        } catch (error) {
            console.error("Erro ao entrar na chamada de vídeo:", error);
        }
    };

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (searchTerm) {
                const cleanedSearchTerm = searchTerm.toLowerCase().replace(/\s+/g, '');
                const usersRef = collection(db, 'community');
                const q = query(
                    usersRef,
                    where('normalizedUserNick', '>=', cleanedSearchTerm),
                    where('normalizedUserNick', '<=', cleanedSearchTerm + '\uf8ff'),
                    limit(10)
                );

                const snapshot = await getDocs(q);
                const users = snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id }));
                setResults(users);
            } else {
                setResults([]);
            }
        }, 300);

        return () => clearTimeout(timer);
    }, [searchTerm]);

    const sendMessage = async (messageText, currentUserUid) => {
        setInputText('')
        let convId = selectedUser?.id;
        let otherUserUid = selectedUser?.otherUser?.uid; // Obtendo o UID do outro usuário do estado 'selectedUser'

        let messageData = {
            id: Date.now().toString(), // Usando timestamp como ID simples
            fromUserId: currentUserUid,
            toUserId: otherUserUid,
            message: messageText,
            timestamp: new Date().getTime()
        };

        if (!convId && otherUserUid) {
            // Cria uma nova conversa
            const newConversationData = {
                lastUpdated: messageData.timestamp,
                participants: [currentUserUid, otherUserUid],
            };
            const newConversationRef = await addDoc(collection(db, "conversations"), newConversationData);
            convId = newConversationRef.id;

            // Atualiza 'selectedUser' com os dados da nova conversa
            setSelectedUser({ id: convId, ...newConversationData, otherUser: selectedUser?.otherUser });
        } else if (convId) {
            // Atualiza a conversa existente com o timestamp da última mensagem
            const conversationDocRef = doc(db, "conversations", convId);
            await updateDoc(conversationDocRef, {
                lastUpdated: messageData.timestamp
            });
        }

        // Adiciona a mensagem na subcoleção "messages" da conversa
        if (convId) {
            await addDoc(collection(db, "conversations", convId, "messages"), messageData);
        }
    };

    useEffect(() => {
        if (!user.uid) return;

        const conversationsRef = collection(db, 'conversations');
        const allConversationsQuery = query(conversationsRef, where('participants', 'array-contains', user.uid), orderBy('lastUpdated', 'desc'));

        const allConversationsUnsubscribe = onSnapshot(allConversationsQuery, async (querySnapshot) => {
            const updatedConversations = await Promise.all(querySnapshot.docs.map(async (doco) => {
                const conversation = { id: doco.id, ...doco.data() };
                const otherUserUid = conversation.participants.find(uid => uid !== user.uid);

                let otherUserData = {};
                if (otherUserUid) {
                    const otherUserDoc = await getDoc(doc(db, 'community', otherUserUid));
                    otherUserData = otherUserDoc.exists() ? otherUserDoc.data() : {};
                }

                // Buscar a última mensagem
                const lastMessageQuery = query(collection(db, "conversations", conversation.id, "messages"), orderBy("timestamp", "desc"), limit(1));
                const lastMessageSnapshot = await getDocs(lastMessageQuery);
                const lastMessageData = lastMessageSnapshot.docs.length > 0 ? lastMessageSnapshot.docs[0].data() : null;

                return {
                    ...conversation,
                    otherUser: { uid: otherUserUid, ...otherUserData },
                    lastMessage: lastMessageData
                };
            }));

            setMessages(updatedConversations);
        });

        const fetchAndSetSelectedConversation = async () => {
            if (conversationId?.startsWith('@')) {
                setLoadingMessage(true)
                // Busca o UID do usuário baseado no userNick
                const userName = conversationId.slice(1);
                const usersRef = collection(db, 'community');
                const userQuery = query(usersRef, where('normalizedUserNick', '==', userName));
                const userQuerySnapshot = await getDocs(userQuery);

                if (!userQuerySnapshot.empty) {
                    const otherUserUid = userQuerySnapshot.docs[0].id;
                    const otherUserDoc = userQuerySnapshot.docs[0].data();

                    // Verifica se já existe uma conversa
                    const conversationsRef = collection(db, "conversations");
                    const convQuery = query(conversationsRef, where("participants", "array-contains", otherUserUid));
                    const convQuerySnapshot = await getDocs(convQuery);

                    let existingConversation = convQuerySnapshot.docs.find(doc => doc.data().participants.includes(user.uid));
                    if (existingConversation) {
                        // Atualiza apenas os dados necessários, preservando as mensagens existentes
                        setSelectedUser(prev => ({
                            ...prev,
                            id: existingConversation.id,
                            otherUser: { uid: otherUserUid, ...otherUserDoc }
                        }));
                    } else {
                        // Prepara para uma nova conversa, preservando mensagens existentes
                        setSelectedUser(prev => ({
                            ...prev,
                            id: null,
                            messages: prev?.messages || [],
                            participants: [user.uid, otherUserUid],
                            otherUser: { uid: otherUserUid, ...otherUserDoc }
                        }));
                    }
                }
                setLoadingMessage(false)
            }
        };

        fetchAndSetSelectedConversation();

        return () => {
            allConversationsUnsubscribe();
        };
    }, [user.uid, conversationId]);

    const handleUserClick = async (clickedUser, typeMessage) => {
        if (typeMessage === 'newMessage') {
            // Caso seja uma nova mensagem, atualize os dados relevantes
            setSelectedUser(prevState => {
                return {
                    ...prevState, // Mantém os dados existentes
                    id: null, // Define o ID da conversa como null para uma nova conversa
                    otherUser: clickedUser, // Atualiza os dados do outro usuário
                    messages: [] // Reseta as mensagens para a nova conversa
                };
            });
        } else {
            // Para uma conversa existente, basta atualizar o usuário selecionado
            setSelectedUser(prevState => {
                return {
                    ...prevState, // Mantém os dados existentes
                    ...clickedUser // Atualiza com os dados da conversa clicada
                };
            });
        }

        setSearchTerm('');
        setResults([]);
    };

    const handleMenuClick = (messageId) => {
        setActiveMenuMessageId(activeMenuMessageId === messageId ? null : messageId);
    };

    useEffect(() => {
        const closeMenu = (e) => {
            if (!e.target.closest('.menuMessageCommunity')) {
                setActiveMenuMessageId(null);
            }
        };

        document.addEventListener('mousedown', closeMenu);
        return () => document.removeEventListener('mousedown', closeMenu);
    }, []);

    const copyMessage = (message) => {
        navigator.clipboard.writeText(message).then(() => {
            // Notifique o usuário de que a mensagem foi copiada, se necessário
            addNotification('Mensagem copiada com sucesso!', 'dark3');
        }).catch(err => {
            console.error('Erro ao copiar mensagem:', err);
            addNotification('Erro ao copiar mensagem', 'error3');
        });
    };

    const deleteMessageAndUpdateState = async (conversationId, messageId) => {
        await deleteMessage(conversationId, messageId);

        // Filtra a mensagem excluída do estado local
        const updatedMessages = selectedUser?.messages?.filter(message => message.id !== messageId) || [];

        // Atualize o estado com as mensagens restantes
        setSelectedUser(prev => ({ ...prev, messages: updatedMessages }));
    };

    const deleteMessage = async (conversationId, messageId) => {
        if (!conversationId || !messageId) {
            console.error("Faltam parâmetros necessários para exclusão.");
            return;
        }

        try {
            const messageRef = doc(db, "conversations", conversationId, "messages", messageId);
            await deleteDoc(messageRef);
            console.log("Mensagem excluída com sucesso.");
        } catch (error) {
            console.error("Erro ao excluir mensagem:", error);
        }
    };


    const [lastVisibleMessageDoc, setLastVisibleMessageDoc] = useState(null);
    const [hasMoreMessages, setHasMoreMessages] = useState(true);
    const [showLoaderMessage, setShowLoaderMessage] = useState(false)
    const [loadingMessage, setLoadingMessage] = useState(false)

    const fetchMoreMessages = async () => {
        if (!lastVisibleMessageDoc || !hasMoreMessages) return;

        setShowLoaderMessage(true);
        const currentScrollPosition = chatContainerRef.current.scrollHeight - chatContainerRef.current.scrollTop;

        const nextMessagesQuery = query(
            collection(db, "conversations", selectedUser.id, "messages"),
            orderBy("timestamp", "desc"),
            startAfter(lastVisibleMessageDoc),
            limit(30)
        );

        const querySnapshot = await getDocs(nextMessagesQuery);
        if (!querySnapshot.empty) {
            const newMessages = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id  // Adicionando o ID
            })).reverse();

            setSelectedUser(prev => ({
                ...prev,
                // Garantindo que prev.messages seja sempre um array
                messages: [...newMessages, ...(prev.messages || [])]
            }));

            setLastVisibleMessageDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
        } else {
            setHasMoreMessages(false);
        }

        setTimeout(() => {
            setShowLoaderMessage(false);
            // Ajustar o scroll para manter a posição de visualização
            if (chatContainerRef.current) {
                chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight - currentScrollPosition;
            }
        }, 0);
    };

    useEffect(() => {
        if (!user.uid || !selectedUser?.id) return;

        setLoadingMessage(true)
        const messagesRef = collection(db, "conversations", selectedUser.id, "messages");
        const messagesQuery = query(messagesRef, orderBy("timestamp", "desc"), limit(30));

        const selectedConversationUnsubscribe = onSnapshot(messagesQuery, (querySnapshot) => {
            const messages = querySnapshot.empty
                ? []
                : querySnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id  // Adicionando o ID
                })).reverse();

            setSelectedUser(prev => ({
                ...prev,
                messages: messages // messages sempre será um array
            }));

            if (messages.length > 0) {
                setLastVisibleMessageDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
            } else {
                setHasMoreMessages(false);
            }

            if (calling && messages.length > 1) {
                setCall(false);
                handleSendVideoCallRequest(messages);
            }

            setTimeout(() => {
                const element = chatContainerRef.current;
                if (element) {
                    element.scrollTop = element.scrollHeight;
                }
            }, 0);
            setLoadingMessage(false)
        });

        return () => selectedConversationUnsubscribe();
    }, [user.uid, selectedUser?.id, calling]);

    useEffect(() => {
        const onScroll = () => {
            if (chatContainerRef?.current?.scrollTop === 0) {
                fetchMoreMessages();
            }
        };

        const element = chatContainerRef.current;
        if (element) {
            element.addEventListener('scroll', onScroll);
        }

        return () => {
            if (element) {
                element.removeEventListener('scroll', onScroll);
            }
        };
    }, [lastVisibleMessageDoc, hasMoreMessages]);

    const groupMessagesBySender = (messages) => {
        return messages.reduce((groups, message) => {
            const lastGroup = groups[groups.length - 1];
            if (lastGroup && lastGroup[0].fromUserId === message.fromUserId) {
                lastGroup.push(message);
            } else {
                groups.push([message]);
            }
            return groups;
        }, []);
    };

    const messageGroups = groupMessagesBySender(selectedUser?.messages || []);

    function getEmojiSizeClass(message) {
        const regex = emojiRegex();
        const matches = message?.match(regex);

        if (matches && matches.length > 0) {
            // Verifica se a mensagem contém apenas emojis
            const textWithoutEmoji = message.replace(regex, '').trim();
            if (textWithoutEmoji.length === 0) {
                return 'emoji-only';
            } else {
                return 'emoji-with-text';
            }
        }

        return '';
    }

    const VideoCallMessage = ({ message, user, joinVideoCall }) => {
        const callExpired = new Date().getTime() > message.validUntil;
        const callSuccessful = message.userIn;

        return (
            <div className='messageVideoCall'>
                {callSuccessful ? (
                    <div>
                        <div className='videoCall'>
                            <div className="iconJoinMeet">
                                <FontAwesomeIcon icon={faVideo} />
                            </div>
                            <h3></h3> {/* Sem conteúdo no h3 */}
                        </div>
                        <div className='contentVideCall'>
                            <p>Chamada efetuada com sucesso</p>
                        </div>
                    </div>
                ) : callExpired ? (
                    <div>
                        <div className='videoCall'>
                            <div className="iconJoinMeet failed">
                                <FontAwesomeIcon icon={faVideo} />
                            </div>
                            {/* <h3>Expired</h3> */}
                        </div>
                        <div className='contentVideCall'>
                            <p>A vídeo chamada expirou</p>
                        </div>
                    </div>
                ) : message.userCancel ? (
                    <div>
                        <div className='videoCall'>
                            <div className="iconJoinMeet failed">
                                <FontAwesomeIcon icon={faVideo} />
                            </div>
                            <h3></h3> {/* Sem conteúdo no h3 */}
                        </div>
                        <div className='contentVideCall'>
                            <p>Chamada cancelada devido à ausência do usuário</p>
                        </div>
                    </div>
                ) : (
                    <div>
                        <Link className='videoCall' onClick={(e) => {
                            if (user.uid !== message.fromUserId) {
                                joinVideoCall(message.id, message.callId);
                            } else {
                                e.preventDefault();
                            }
                        }}>
                            <div className="iconJoinMeet">
                                <FontAwesomeIcon icon={faVideo} />
                            </div>
                            <h3>{message.callId}</h3>
                        </Link>
                        <div className='contentVideCall'>
                            <p>{message.fromUserId === user.uid ? <span>Aguardando usuário<span className="animate-dots"></span></span> : message.message}</p>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="allCommunity">
            <div className='containerCommunity'>

                <Alert
                    title={`Chamada de vídeo`}
                    paragraph={`Deseja iniciar uma chamada de vídeo com ${selectedUser?.otherUser?.userNick}`}
                    buttons={[
                        { text: "Cancelar", action: () => setShowAlert(false) },
                        { text: "Continuar", action: () => sendVideoCallRequest() }
                    ]}
                    showAlert={showAlert}
                />

                {gettingInCall && (
                    <div className='setInCall'>
                        <h3>Extabelencendo conexão</h3>
                    </div>
                )}

                <div id="notifications3"></div>

                <PostOnCommunity showPostOnCommunity={showPostOnCommunity} setShowPostOnCommunity={setShowPostOnCommunity} setSelectedPost={setSelectedPost} setSelectedEditPost={setSelectedEditPost} />
                <HeaderCommunity showPostOnCommunity={showPostOnCommunity} setShowPostOnCommunity={setShowPostOnCommunity} />

                <div className="containerChat borderTop">
                    <div className='usersLeftMenuChat'>
                        <header className='headerMenuChat'>
                            <UserAvatar userName={newNameCommunity} photoURL={photoCommunity} size={47} />
                            <div className='contentInfoUser'>
                                <h3>{newNameCommunity}</h3>
                                <p>@{newNameCommunity.toLowerCase().replace(/\s+/g, '')}</p>
                                <div className='containeUserInfoPremiun'>
                                    <span>Pro Plan</span>
                                    <img src={crown} />
                                </div>
                            </div>
                        </header>
                        <div className='containerInputMessage'>
                            <input type='text' placeholder='Search messages' value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
                            <FontAwesomeIcon icon={faSearch} />
                        </div>

                        <div className='containerUsersMessage'>
                            {searchTerm ?
                                <div className='usersSearchMessage'>
                                    {results.length >= 1 ?
                                        results.map((user, index) => (
                                            <Link className="userSearchMessage" to={`/community/chat/@${user.normalizedUserNick}`} key={index} onClick={() => handleUserClick(user, 'newMessage')}>
                                                <UserAvatar userName={user.userNick} photoURL={user.photoURL} size={47} />
                                                <span>{user.userNick}</span>
                                            </Link>
                                        )) :
                                        <p>Nothing found</p>
                                    }
                                </div> :
                                messages.map((message, i) => (
                                    <Link className='userMessage' key={i} to={`/community/chat/@${message.otherUser.normalizedUserNick}`} onClick={() => handleUserClick(message, 'existingMessage')}>
                                        <div className='contentUserMessage'>
                                            <UserAvatar userName={message.otherUser?.userNick} photoURL={message.otherUser?.photoURL} size={47} />
                                            <div className='contentMessage'>
                                                <div className='contentInfoMessageContent'>
                                                    <h3>{message.otherUser?.userNick}</h3>
                                                    {message.lastMessage ? (
                                                        <span>{new Date(message.lastMessage.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
                                                    ) : (
                                                        <span>0</span>
                                                    )}
                                                </div>
                                                <div className='contentInfoMessageContent'>
                                                    {message.lastMessage ? (
                                                        <p>{message?.lastMessage?.message}</p>
                                                    ) : (
                                                        <p>Nenhuma mensagem</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))
                            }
                        </div>
                    </div>
                    <div className='conainerMessageUserContent'>
                        {loadingMessage
                            ?
                            <div className='loadingMessageContent'>
                                <Loading size={20} color='#AAAAAA' />
                            </div>
                            :
                            <>
                                {selectedUser ?
                                    <>
                                        <header className='headerContainerMessage'>
                                            <div className='containerContentUserInfo'>
                                                <UserAvatar userName={selectedUser?.userNick || selectedUser?.otherUser?.userNick} photoURL={selectedUser?.photoURL || selectedUser?.otherUser?.photoURL} size={45} />
                                                <div className='contentInfoUserMessage'>
                                                    <h2>{selectedUser?.userNick || selectedUser?.otherUser?.userNick}</h2>
                                                </div>
                                            </div>
                                            <div className='optionContainerMessageInfo'>
                                                <div className='optionMessageInfo' onClick={() => handleSendVideoCallRequest(selectedUser?.messages)} title={`Ligar para ${selectedUser?.otherUser?.userNick}`}>
                                                    <FontAwesomeIcon icon={faVideo} />
                                                </div>
                                                <div className='optionMessageInfo'>
                                                    <FontAwesomeIcon icon={faSearch} />
                                                </div>
                                                <div className='optionMessageInfo'>
                                                    <FontAwesomeIcon icon={faEllipsisVertical} />
                                                </div>
                                            </div>
                                        </header>
                                        <main className='mainChatCommunity' id='mainChatCommunity' ref={chatContainerRef}>
                                            <div className='userInfoMessage'>
                                                <UserAvatar userName={selectedUser?.userNick || selectedUser?.otherUser?.userNick} photoURL={selectedUser?.photoURL || selectedUser?.otherUser?.photoURL} size={90} />
                                                <div className='userInfoContentMessage'>
                                                    <span>{selectedUser?.userNick || selectedUser?.otherUser?.userNick} • EnglishFlu</span>
                                                    <Link to={`/community/@${selectedUser?.otherUser?.userNick?.toLowerCase().replace(/\s+/g, '')}`}>View Profile</Link>
                                                </div>
                                            </div>
                                            <div className='containerChatMessage'>
                                                {showLoaderMessage && (
                                                    <div className='loadigMessages'>
                                                        <img src={loader} />
                                                    </div>
                                                )}
                                                {messageGroups.map((group, groupId) => (
                                                    <div key={groupId} className="message-group">
                                                        {group.map((message, messageId) => {
                                                            const isMyMessage = message.fromUserId === user?.uid;
                                                            const isLastMessage = messageId === group.length - 1;

                                                            return (
                                                                <div key={messageId} className={`message ${isMyMessage ? 'me' : 'user'} ${!isMyMessage && isLastMessage ? 'last' : ''}`}>
                                                                    {!isMyMessage && (
                                                                        <div className="userAvatar">
                                                                            <UserAvatar userName={selectedUser?.otherUser?.userNick} photoURL={selectedUser?.otherUser?.photoURL} size={30} />
                                                                        </div>
                                                                    )}
                                                                    <div className='optionMessageUsert'>
                                                                        {isMyMessage && (
                                                                            <div className='menuMessageCommunity'>
                                                                                <FontAwesomeIcon icon={faEllipsisH} onClick={() => handleMenuClick(messageId)} />
                                                                                <div className={activeMenuMessageId === messageId ? 'menuMessageUser active' : 'menuMessageUser'}>
                                                                                    <li onClick={() => copyMessage(message.message)}>Copiar</li>
                                                                                    <li onClick={() => deleteMessageAndUpdateState(selectedUser.id, message.id)}>Excluir</li>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        <div className='messageContent'>
                                                                            {message.type === "videoCall" && (<VideoCallMessage key={messageId} message={message} user={user} joinVideoCall={joinVideoCall} />)}
                                                                            {message.type !== "videoCall" && (
                                                                                <div className='messageInfo'>
                                                                                    <p className={getEmojiSizeClass(message.message)} dangerouslySetInnerHTML={{ __html: twemoji.parse(message.message) }}></p>
                                                                                </div>
                                                                            )}

                                                                            {!isMyMessage && isLastMessage && (
                                                                                <span>
                                                                                    {selectedUser?.otherUser?.userNick} • {new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                ))}
                                            </div>
                                        </main>
                                        <div className='containerFooterSendMessage'>
                                            <div className='optionContainerMessageInfo'>
                                                {/* <div className='optionMessageInfo'>
                                                    <FontAwesomeIcon icon={faPaperclip} style={{ fontSize: 20 }} />
                                                </div> */}
                                            </div>
                                            <div className='contentInputSendMessage'>
                                                <InputEmoji
                                                    type='text'
                                                    id='sendMessageCommunity'
                                                    className='sendMessageCommunity'
                                                    placeholder='Type a message...'
                                                    value={inputText}
                                                    onChange={setInputText}
                                                    onEnter={(text) => sendMessage(text, user.uid, selectedUser?.otherUser?.uid || selectedUser.uid)}
                                                />
                                            </div>
                                            <div className='optionContainerMessageInfo'>
                                                <div className='optionMessageInfo' onClick={() => sendMessage(inputText, user.uid, selectedUser?.otherUser?.uid || selectedUser.uid)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="19px" height="19px" viewBox="0 0 64 64" id="send"><defs><clipPath id="a"><rect width="64" height="64"></rect></clipPath></defs><g clipPath="url(#a)"><path d=" M 8.216 36.338 L 26.885 32.604 C 28.552 32.271 28.552 31.729 26.885 31.396 L 8.216 27.662 C 7.104 27.44 6.021 26.356 5.799 25.245 L 2.065 6.576 C 1.731 4.908 2.714 4.133 4.259 4.846 L 61.228 31.139 C 62.257 31.614 62.257 32.386 61.228 32.861 L 4.259 59.154 C 2.714 59.867 1.731 59.092 2.065 57.424 L 5.799 38.755 C 6.021 37.644 7.104 36.56 8.216 36.338 Z "></path></g></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </> :
                                    <div className='containerAboutChat'>
                                        <Lottie
                                            animationData={defaultOptions.animationData}
                                            loop={defaultOptions.loop}
                                            autoplay={defaultOptions.autoplay}
                                            style={{ height: 500, width: 500 }}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}