import React from 'react';
import { Box } from "@mui/material";

const ImageBubble = ({ url }) => {
    return (
        <Box
            sx={{
                borderRadius: '20px',
                display: 'flex',
                alignItems: 'center',
                maxWidth: '75%',
                wordWrap: 'break-word',
                position: 'relative', // Para posicionar a "gotinha"
            }}
        >
            <img src={url} alt="content" draggable={false} style={{ borderRadius: '15px', maxWidth: '100%' }} />
        </Box>
    );
};

export default ImageBubble;
