// import React, { createContext, useContext, useState, useCallback } from 'react';
// import html2canvas from 'html2canvas';

// const CaptureContext = createContext();

// export const CaptureProvider = ({ children }) => {
//   const [captures, setCaptures] = useState([]);

//   const captureComponent = useCallback(async (ref, name) => {
//     if (ref.current) {
//       try {
//         const canvas = await html2canvas(ref.current);
//         const image = canvas.toDataURL('image/png');
//         setCaptures(prev => [...prev, { name, image }]);
//       } catch (error) {
//         console.error(`Erro ao capturar ${name}:`, error);
//       }
//     }
//   }, []);

//   const clearCaptures = useCallback(() => {
//     setCaptures([]);
//   }, []);

//   return (
//     <CaptureContext.Provider value={{ captures, captureComponent, clearCaptures }}>
//       {children}
//     </CaptureContext.Provider>
//   );
// };

// export const useCapture = () => useContext(CaptureContext);

import React, { createContext, useContext, useState, useCallback } from 'react';
import html2canvas from 'html2canvas';

const CaptureContext = createContext();

const convertCanvasToBase64 = (canvas) => {
  return new Promise((resolve, reject) => {
    try {
      const base64 = canvas.toDataURL('image/png');
      resolve(base64);
    } catch (error) {
      reject(error);
    }
  });
};

export const CaptureProvider = ({ children }) => {
  const [captures, setCaptures] = useState([]);

  const captureComponent = useCallback(async (ref, name) => {
    if (ref.current) {
      try {
        console.log(`Capturando componente: ${name}`); // Log de depuração
        if (name !== 'ShareScreen' && name !== 'interactLesson') {
          console.log(`Ignorando componente: ${name}`); // Log de depuração
          return null; // Ignora silenciosamente os componentes com nomes não correspondentes
        }

        const canvas = await html2canvas(ref.current);
        const base64 = await convertCanvasToBase64(canvas);
        const capture = {
          name,
          type: 'image/png',
          url: canvas.toDataURL('image/png'),
          base64
        };
        setCaptures(prev => [...prev, capture]);
        return capture; // Retorna a captura como uma promessa resolvida
      } catch (error) {
        console.error(`Erro ao capturar ${name}:`, error);
        return Promise.reject(error);
      }
    } else {
      console.error('Referência do componente é nula');
      return Promise.reject(new Error('Referência do componente é nula'));
    }
  }, []);

  const clearCaptures = useCallback(() => {
    setCaptures([]);
  }, []);

  return (
    <CaptureContext.Provider value={{ captures, captureComponent, clearCaptures }}>
      {children}
    </CaptureContext.Provider>
  );
};

export const useCapture = () => useContext(CaptureContext);
