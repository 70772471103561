import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh, faClose, faCheck } from '@fortawesome/free-solid-svg-icons';
import './style.css'
import { sendTextToSpeechRequest } from "../../../../componentsTalkIA/IAtalk2ME/Talk2MeVoice";


const handleWordClick = (word, selectedWord, setSelectedWord, matchedPairs) => {
    if (!matchedPairs.includes(word)) {
        setSelectedWord(word);
    }
}

const handleAudioClick = (word, selectedAudio, setSelectedAudio, matchedPairs, speak) => {
    if (!matchedPairs.includes(word)) {
        setSelectedAudio(word);
        speak(word);
    }
}

// const speak = async (text) => {
//     const url = await sendTextToSpeechRequest(text, "Daniella");
//     setAudioUrl(url);
// }

const shuffle = (array) => {
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array;
}

const renderPergunta = (item, index, checked, handleCheck, handleCheckOption, revealedAnswer, clickedOptions, textWidth, incorrectAnswers, verified, selectedAudio, matchedPairs, selectedWord, speakText, setSelectedAudio, setSelectedWord, shuffledWordList, answerSpanRef, disabled) => {
    switch (item.stylesClass) {
        case 'class1':
            return (
                <div key={index} className="contentMessageSpeak class1">
                    <div className="questionMessageContent">
                        <h2>{item.texto}</h2>
                        <div>
                            {item.opcoes.map((opcao, opcaoIndex) => (
                                <div className="questionMessageOptions" key={opcaoIndex}>
                                    <label className="checkOption">
                                        <input checked={checked === opcaoIndex} type="checkbox" onChange={() => handleCheck(opcao, opcaoIndex, item)} disabled={incorrectAnswers.includes(opcaoIndex) || disabled} />
                                        {/* <div className="checkmark"></div> */}
                                        <div className={checked === opcaoIndex && item.resposta === opcao ? "checkmark correct" : "checkmark incorrect"}></div>
                                    </label>
                                    <span>{opcao}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )
        case 'class2':
            return (
                <div key={index} className="contentMessageSpeak class1">
                    <div className="questionMessageContent">
                        <h2>{item.texto}</h2>
                        <div>
                            <div className="containerMessageLeftOptions">
                                <div className="containerMessageOption">
                                    <FontAwesomeIcon icon={faVolumeHigh} className="volumeHigh" onClick={() => speakText(item.resposta)} />
                                    <p className="p-resposta">
                                        <span
                                            ref={answerSpanRef}
                                            className="span-resposta"
                                            style={{ visibility: revealedAnswer ? 'visible' : 'hidden', minWidth: `${textWidth}px` }}
                                        >
                                            {revealedAnswer || " "}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="flexOptions">
                                {item.opcoes.map((opcao, opcaoIndex) => (
                                    <button
                                        key={opcaoIndex}
                                        className='buttonWord'
                                        onClick={() => handleCheckOption(opcao, opcaoIndex, item)}
                                        disabled={clickedOptions.includes(opcao) || disabled}
                                    >
                                        <span className="word">{opcao}</span>
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )
        case 'class3':
            return (
                <div key={index} className="contentMessageSpeak class1">
                    <div className="questionMessageContent">
                        <h2>{item.texto}</h2>
                        <div>
                            {item.opcoes.map((opcao, opcaoIndex) => (
                                <div className="questionMessageOptions">
                                    <button
                                        key={opcaoIndex}
                                        className={`buttonOptionListening ${checked === opcaoIndex && !incorrectAnswers.includes(opcaoIndex) ? 'optionSelected' : ''}`}
                                        onClick={() => handleCheck(opcao, opcaoIndex, item)}
                                        style={{ width: '100%' }}
                                        disabled={incorrectAnswers.includes(opcaoIndex) || disabled}
                                    >
                                        <div className='buttonOptionListeningNumber'>{opcaoIndex + 1}</div>
                                        <p>{opcao}</p>
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )
        case 'class4':
            return (
                <div key={index} className="contentMessageSpeak class1">
                    <div className="questionMessageContent">
                        <h2>{item.texto}</h2>
                        <div>
                            <div className="flexOptions">
                                {item.opcoes.map((opcao, opcaoIndex) => (
                                    <button
                                        key={opcaoIndex}
                                        disabled={incorrectAnswers.includes(opcaoIndex) || disabled}
                                        className={`buttonWord ${checked === opcaoIndex && !incorrectAnswers.includes(opcaoIndex) ? 'optionSelected' : ''}`}
                                        onClick={() => handleCheck(opcao, opcaoIndex, item)}
                                    >
                                        <span className="word">{opcao}</span>
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )
        case 'class5':
            return (
                <div key={index} className="contentMessageSpeak class1">
                    <div className="questionMessageContent">
                        <h2>{item.texto}</h2>
                        <div className='flexButtonOptions'>
                            <div className='buttomColumsOption'>
                                {shuffledWordList.map((word, index) => (
                                    <button key={index}
                                        onClick={() => handleAudioClick(word, selectedAudio, setSelectedAudio, matchedPairs, speakText)}
                                        className={`buttonOptionListening spaceTopAndBottom width100 ${(word === selectedAudio && !matchedPairs.includes(word)) ? 'optionSelected' : ''}`}
                                        disabled={matchedPairs.includes(word) || verified}>
                                        <div className='audioContentOption'>
                                            <FontAwesomeIcon icon={faVolumeHigh} />
                                            <div className='audioSkeleton'>
                                                {matchedPairs.includes(word) || verified
                                                    ?
                                                    <img src={require('../../../../assets/images/soundDisable.png')} alt='skeleton' className='skeleton' />
                                                    :
                                                    <img src={require('../../../../assets/images/sound.png')} alt='skeleton' className='skeleton' />
                                                }
                                            </div>
                                        </div>
                                    </button>
                                ))}
                            </div>
                            <div className='buttomColumsOption'>
                                {item.wordList.map((word, index) => (
                                    <button key={index}
                                        onClick={() => handleWordClick(word, selectedWord, setSelectedWord, matchedPairs)}
                                        className={`buttonOptionListening spaceTopAndBottom width100 ${(word === selectedWord && !matchedPairs.includes(word)) ? 'optionSelected' : ''}`}
                                        disabled={matchedPairs.includes(word) || verified}>
                                        <p>{word}</p>
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )
        default:
            return null;
    }
}

export default function Conversation({ exercicio, setResposta, setExplicacao }) {

    useEffect(() => {
        speakText(exercicio.dados.title)
    }, [])

    const [selectedWord, setSelectedWord] = useState(null);
    const [selectedAudio, setSelectedAudio] = useState(null);
    const [matchedPairs, setMatchedPairs] = useState([]);
    const [verified, setVerified] = useState(false);

    const answerSpanRef = useRef(null);

    useEffect(() => {
        if (selectedWord && selectedAudio) {
            if (selectedWord === selectedAudio) {
                setMatchedPairs(prevState => [...prevState, selectedWord]);
            }
            setSelectedWord(null);
            setSelectedAudio(null);
        }
    }, [selectedWord, selectedAudio]);

    useEffect(() => {
        if (exercicio.dados.dialogo) {
            const dialogoItemWithWordList = exercicio.dados.dialogo.find(item => item.wordList);
            if (dialogoItemWithWordList && matchedPairs.length === dialogoItemWithWordList.wordList.length) {
                setVerified(true);
                setBotaoHabilitado(true); // Adicione essa linha aqui.
            }
        }
    }, [matchedPairs, exercicio.dados.dialogo]);


    const [shuffledWordList, setShuffledWordList] = useState([]);

    useEffect(() => {
        const dialogoItemWithWordList = exercicio.dados.dialogo.find(item => item.wordList);
        if (dialogoItemWithWordList) {
            setShuffledWordList(shuffle([...dialogoItemWithWordList.wordList]));
        }
    }, [exercicio.dados.dialogo]);

    const [step, setStep] = useState(-1);
    const [checked, setChecked] = useState(null);
    const [botaoHabilitado, setBotaoHabilitado] = useState(true);
    const scrollContainerRef = useRef(null);
    const [respondido, setRespondido] = useState({});
    const [revealedAnswer, setRevealedAnswer] = useState("");
    const [clickedOptions, setClickedOptions] = useState([]);
    const [respostaConversation, setRespostaConversation] = useState('')
    const [explicacaoConversation, setExplicacaoConversation] = useState("")

    const [incorrectAnswers, setIncorrectAnswers] = useState([])

    const [disabled, setDisabled] = useState(false)

    const [audioUrl, setAudioUrl] = useState('');
    const audioRef = useRef(null);
    const speakText = async (text) => {
        const url = await sendTextToSpeechRequest(text, "Danielle");
        setAudioUrl(url);
    }

    useEffect(() => {
        // Reproduzir o áudio automaticamente quando a URL do áudio é atualizada
        if (audioUrl && audioRef.current) {
            audioRef.current.play();
        }
    }, [audioUrl]);

    const handleContinue = () => {
        if (exercicio.dados.dialogo[step]?.tipo === "pergunta" && botaoHabilitado) {
            setRespondido(prevState => ({ ...prevState, [step]: true }));
            setBotaoHabilitado(false);
            setChecked(null)
            setRevealedAnswer("")
            setClickedOptions([])
            setIncorrectAnswers([])

            setDisabled(false)

            setRespostaConversation('')
            setExplicacaoConversation('')

        }

        if (step < exercicio.dados.dialogo.length - 1) {
            setStep(step + 1);
            if ((exercicio.dados.dialogo[step + 1]?.tipo === "narrador" || exercicio.dados.dialogo[step + 1]?.tipo === "personagem")) {
                speakText(exercicio.dados.dialogo[step + 1].texto);
            }
        } else {
            setResposta('correct')
            setExplicacao('Congratulations you have completed the entire conversation!')
        }
    }

    const handleCheckOption = (opcao, index, pergunta) => {
        // verificar se a opção clicada é a próxima parte da resposta
        const nextPartOfAnswer = pergunta.resposta.split(' ')[clickedOptions.length];
        if (opcao === nextPartOfAnswer) {
            // revelar a próxima parte da resposta
            const newRevealedAnswer = revealedAnswer + " " + nextPartOfAnswer;
            setRevealedAnswer(newRevealedAnswer);

            // marcar a opção como clicada
            setClickedOptions([...clickedOptions, opcao]);

            // se a resposta completa for revelada, habilitar o botão
            if (newRevealedAnswer.trim() === pergunta.resposta) {
                setBotaoHabilitado(true);
                setDisabled(true)
            }

            // calcular a nova largura do texto
            if (answerSpanRef.current) {
                setTextWidth(answerSpanRef.current.getBoundingClientRect().width);
            }
        }
    }

    const [textWidth, setTextWidth] = useState(0);

    useEffect(() => {
        const dialogoItem = exercicio.dados.dialogo.find(item => item.tipo === "pergunta" && item.stylesClass === "class2");

        if (dialogoItem) {
            const respostaPergunta = dialogoItem.resposta || " ";
            setTextWidth(calculateTextWidth(respostaPergunta));
        }
    }, [exercicio.dados.dialogo]);

    const calculateTextWidth = (text) => {
        const calculationElement = document.createElement('span');
        calculationElement.style.visibility = 'hidden';
        calculationElement.style.whiteSpace = 'nowrap';
        calculationElement.innerText = text;
        document.body.appendChild(calculationElement);
        console.log(calculationElement)
        const width = calculationElement.getBoundingClientRect().width;
        document.body.removeChild(calculationElement);
        return width;
    }

    const handleCheck = (opcao, index, pergunta) => {
        setChecked(index);
        if (pergunta.resposta !== opcao) {
            setIncorrectAnswers([...incorrectAnswers, index]);

            setRespostaConversation('incorrect')
            setExplicacaoConversation('This answer is incorrect')
        }
        else {
            setRespostaConversation('correct')
            setExplicacaoConversation('This answer is correct')
            setDisabled(true)
        }
        setBotaoHabilitado(pergunta.resposta === opcao);
    };


    useEffect(() => {
        if (exercicio.dados.dialogo[step]?.tipo === "pergunta" && !respondido[step]) {
            setBotaoHabilitado(false);
        } else {
            setBotaoHabilitado(true);
        }

        setTimeout(() => {
            scrollContainerRef.current?.scrollTo({ top: scrollContainerRef.current.scrollHeight, behavior: "smooth" });
        }, 100);
    }, [step, exercicio.dados.dialogo, respondido]);

    function tryAgain() {
        setRespostaConversation('')
        setExplicacaoConversation('')
    }

    return (
        <>

            {audioUrl &&
                <audio
                    src={audioUrl}
                    ref={audioRef}
                    controls
                    style={{ display: 'none' }}
                />
            }

            <div className='containerExerciseLesson'>
                <div className="contentTitle">
                    <div className="constainerScroll">
                        <div className="scrollContainer" ref={scrollContainerRef}>
                            <div className="titleContent">
                                <div className="flexTitleContent">
                                    <FontAwesomeIcon icon={faVolumeHigh} className="volumeHigh" onClick={() => speakText(exercicio.dados.title)} />
                                    <h2>{exercicio.dados.title}</h2>
                                </div>
                            </div>

                            <div className="dialogContent">
                                {exercicio.dados.dialogo.slice(0, step + 1).map((item, index) => {
                                    if (item.tipo === "narrador") {
                                        return (
                                            <div className="contentMessageSpeak" key={index}>
                                                <div className="flexStorytellerContent">
                                                    <FontAwesomeIcon icon={faVolumeHigh} className="volumeHigh" onClick={() => speakText(item.texto)} />
                                                    <p>{item.texto}</p>
                                                </div>
                                            </div>
                                        )
                                    } else if (item.tipo === "personagem") {
                                        return (
                                            <div className="contentMessageSpeak contentMessageContainer" key={index}>
                                                <div className="conteinrMessageScenario">
                                                    <div className="containerMessageUser">
                                                        <span className={`personagem-${item.numero}`}>{item.nome.charAt(0)}</span>
                                                    </div>
                                                    <div className="containerMessageContent">
                                                        <FontAwesomeIcon icon={faVolumeHigh} className="volumeHigh" onClick={() => speakText(item.texto)} />
                                                        <p>{item.texto}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else if (item.tipo === "pergunta" && !respondido[index]) {
                                        return renderPergunta(item, index, checked, handleCheck, handleCheckOption, revealedAnswer, clickedOptions, textWidth, incorrectAnswers, verified, selectedAudio, matchedPairs, selectedWord, speakText, setSelectedAudio, setSelectedWord, shuffledWordList, answerSpanRef, disabled)
                                    }
                                })}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='containerButtonsExercise'>
                <div className='containerButtonsExerciseLessonContainer'>
                    <div className='containerButtons'>
                        <button onClick={handleContinue} disabled={!botaoHabilitado}>Continue</button>
                    </div>
                </div>
            </div>


            {/* <div className={`showAnswer ${respostaConversation}`}>
                <div className='flexAnswer'>
                    <div className='iconAnswer'>
                        {respostaConversation === "correct"
                            ?
                            <FontAwesomeIcon icon={faCheck} />
                            :
                            <FontAwesomeIcon icon={faClose} />
                        }
                    </div>
                    <div className='textAnswer'>
                        <h3>{respostaConversation}!</h3>
                        <p>{explicacaoConversation}</p>
                    </div>
                </div>
                <div>
                    <div className='nextButton'>
                        {respostaConversation === "correct"
                            ?
                            <button onClick={handleContinue}>Continue</button>
                            :
                            <button onClick={tryAgain}>Try again</button>
                        }
                    </div>
                </div>
            </div> */}
        </>
    )
}
