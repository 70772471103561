import React, { createContext, useState, useEffect } from 'react';
import { auth, db } from '../../auth/config/firebase';
import { doc, onSnapshot, collection, query, getDocs, orderBy, limit } from 'firebase/firestore';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [photo, setPhoto] = useState('');
    const [newName, setNewName] = useState('');

    const [photoCommunity, setPhotoCommunity] = useState('');
    const [newNameCommunity, setNewNameCommunity] = useState('');
    const [following, setFollowing] = useState(null);
    const [skills, setSkills] = useState(null);
    const [givenLikes, setGivenLikes] = useState([]);
    const [blockedUsers, setBlockedUsers] = useState([]);
    const [favorites, setFavorites] = useState(null);
    const [bio, setBio] = useState(null)
    const [postsCount, setPostsCount] = useState(null)
    const [followingCount, setFollowingCount] = useState(0)
    const [followersCount, setFollowersCount] = useState(0)
    const [streakDay, setStreakDay] = useState(0)

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
        });

        return unsubscribe;
    }, []);

    useEffect(() => {
        if (user) {
            const userRef = doc(db, 'users', user.uid);
            const unsubscribe = onSnapshot(userRef, (doc) => {
                const userData = doc.data();
                // setPhoto(getResizedImageURL(userData?.photo) || '');
                setPhoto(userData?.photo || '');
                setNewName(userData?.nome || '');
            });

            return unsubscribe;
        }
    }, [user]);

    useEffect(() => {
        if (user) {
            const userRef = doc(db, 'community', user.uid);
            const unsubscribe = onSnapshot(userRef, (doc) => {
                const userData = doc.data();
                setPhotoCommunity(userData?.photoURL || '');
                setNewNameCommunity(userData?.userNick || '');
                setSkills(userData?.selectedSkills);
                setFavorites(userData?.favorites);
                setBio(userData?.aboutMe);
                setPostsCount(userData?.posts)                
            });

            return unsubscribe;
        }
    }, [user]);

    useEffect(() => {
        const fetchCounts = async () => {
            if (user) {
                try {
                    const followersRef = collection(db, 'community', user.uid, 'followers');
                    const followersSnapshot = await getDocs(followersRef);
                    setFollowersCount(followersSnapshot.size);

                    const followingRef = collection(db, 'community', user.uid, 'following');
                    const followingSnapshot = await getDocs(followingRef);
                    setFollowingCount(followingSnapshot.size);
                } catch (error) {
                    console.error("Error fetching counts: ", error);
                    // Handle errors, e.g. show an error message
                }
            }
        };

        fetchCounts();
    }, [user]);

    useEffect(() => {
        const fetchFollowing = async () => {
            if (user) {
                try {
                    // Referência à coleção de seguindo
                    const followingRef = collection(db, 'community', user.uid, 'following');
                    
                    // Criar uma query para buscar os últimos 20 seguindo, ajuste o campo 'dateFollowed' conforme necessário
                    const q = query(followingRef, orderBy('dateFollowed', 'desc'), limit(20));
    
                    // Buscar os documentos na coleção seguindo
                    const followingSnapshot = await getDocs(q);
    
                    // Extrai e atualiza a lista de UIDs das contas que o usuário está seguindo
                    const followingList = followingSnapshot.docs.map(doc => doc.id);
                    setFollowing(followingList);
                } catch (error) {
                    console.error("Error fetching following list: ", error);
                    // Tratar erros, por exemplo, mostrar uma mensagem de erro
                }
            }
        };
    
        fetchFollowing();
    }, [user]); // Dependência para reexecutar se os dados do usuário mudarem
    

    const userName = user?.displayName;
    const email = user?.email;
    const photoURL = photo ? photo : user?.photoURL;
    const displayName = newName ? newName : (userName ? userName : email?.split('@')[0]);

    const value = {
        user,
        photoURL,
        displayName,
        email,

        photoCommunity,
        newNameCommunity,
        following,
        skills,
        // blockedUsers,
        favorites,
        bio,
        postsCount,
        followingCount,
        followersCount,

        streakDay
    };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

