import React from 'react';
import AudioExercise from './audioExercise';
import MultipleChoiceExercise from './multipleChoiceExercise';
import GapFillExercise from './GapFillExercise';
import WriteExercise from './writeExercise';
import SentenceBuilder from './SentenceBuilder';
import CompleteConversation from './completeConversation';
import SelectRealEnglishWord from './selectRealEnglishWord';
import FillTheGapsExercise from './FillTheGapsExercise';
import AudioWordMatchExercise from './AudioWordMatchExercise';
import SpeakExercise from './speakExercise';
import Conversation from './conversation';
import Explanation from './explanation';

function Exercise({
    currentExercise,
    setCheckFunction,
    setProgress,
    setControls,
    controls, // Recebe o estado de controls
    nextExercise,
    handleSendMessage
}) {
    const commonProps = {
        setCheckFunction,
        setProgress,
        setControls,
        controls, // Passa o estado de controls
        nextExercise,
        handleSendMessage
    };

    switch (currentExercise?.type) {
        case 'Explanation':
            return <Explanation exercicio={currentExercise} {...commonProps} />;
        case 'MultipleChoiceExercise':
            return (
                <MultipleChoiceExercise
                    exercicio={currentExercise}
                    {...commonProps}
                />
            );
        case 'AudioExercise':
            return (
                <AudioExercise
                    exercicio={currentExercise}
                    {...commonProps}
                />
            );
        case 'GapFillExercise':
            return (
                <GapFillExercise
                    exercicio={currentExercise}
                    {...commonProps}
                />
            );
        case 'WriteExercise':
            return (
                <WriteExercise
                    exercicio={currentExercise}
                    {...commonProps}
                />
            );
        case 'SentenceBuilder':
            return (
                <SentenceBuilder
                    exercicio={currentExercise}
                    {...commonProps}
                />
            );
        case 'CompleteConversation':
            return (
                <CompleteConversation
                    exercicio={currentExercise}
                    {...commonProps}
                />
            );
        case 'SelectRealEnglishWord':
            return (
                <SelectRealEnglishWord
                    exercicio={currentExercise}
                    {...commonProps}
                />
            );
        case 'FillTheGapsExercise':
            return (
                <FillTheGapsExercise
                    exercicio={currentExercise}
                    {...commonProps}
                />
            );
        case 'AudioWordMatchExercise':
            return (
                <AudioWordMatchExercise
                    exercicio={currentExercise}
                    {...commonProps}
                />
            );
        case 'SpeakExercise':
            return (
                <SpeakExercise
                    exercicio={currentExercise}
                    {...commonProps}
                />
            );
        case 'conversa-com-dialogos-e-perguntas-e-respostas-com-base-no-dialogo':
            return (
                <Conversation
                    exercicio={currentExercise}
                    {...commonProps}
                />
            );
        default:
            return <div>Exercício desconhecido</div>;
    }
}

export default Exercise;
