import React from 'react';
import { Box, Paper, Collapse, Button } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import HearingIcon from '@mui/icons-material/Hearing';
import MicIcon from '@mui/icons-material/Mic';
import BookIcon from '@mui/icons-material/Book';
import TranslateIcon from '@mui/icons-material/Translate';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import Writing from '../../componentsTalkIA/writing';
import Listening from '../../componentsTalkIA/Listening';
import Speaking from '../../componentsTalkIA/Speaking';
import Reading from '../../componentsTalkIA/Reading';
import Vocabulary from '../../componentsTalkIA/Vocabulary';
import PhrasalVerbs from '../../componentsTalkIA/PhrasalVerbs';

import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.buttonText,
    backgroundColor: theme.buttonBackground,
    borderRadius: '10px',
    padding: '5px 10px',
    minWidth: 'auto',
    '&:hover': {
        backgroundColor: theme.buttonHoverBackground,
    },
}));

const TopBarContent = ({ bookOn, addBox, theme }) => {
    return (
        <Collapse in={bookOn} timeout={300} unmountOnExit>
            <Paper
                sx={{
                    width: '100%',
                    backgroundColor: theme.topBarBackground,
                    marginBottom: '10px',
                    borderRadius: '10px',
                    transition: 'all 0.3s ease',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '6px',
                    gap: '8px',
                    boxShadow: 'none'
                }}
            >
                <StyledButton onClick={() => addBox(Writing, 'writing')} startIcon={<CreateIcon />} theme={theme}>
                    Writing
                </StyledButton>
                <StyledButton onClick={() => addBox(Listening, 'listening')} startIcon={<HearingIcon />} theme={theme}>
                    Listening
                </StyledButton>
                <StyledButton onClick={() => addBox(Speaking, 'speaking')} startIcon={<MicIcon />} theme={theme}>
                    Speaking
                </StyledButton>
                <StyledButton onClick={() => addBox(Reading, 'reading')} startIcon={<BookIcon />} theme={theme}>
                    Reading
                </StyledButton>
                <StyledButton onClick={() => addBox(Vocabulary, 'vocabulary')} startIcon={<TranslateIcon />} theme={theme}>
                    Vocabulary
                </StyledButton>
                <StyledButton onClick={() => addBox(PhrasalVerbs, 'phrasalVerbs')} startIcon={<TextFieldsIcon />} theme={theme}>
                    Phrasal Verbs
                </StyledButton>
            </Paper>
        </Collapse>
    );
};

export default TopBarContent;