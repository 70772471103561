import './style.css'
import React, { useEffect, useState } from 'react'

export default function ChangeIdiom() {

    const idiomas = ["English", "Português", "Français", "Deutsch", "Español", "Italiano", "中文", "日本語"];
    const [selectedIdioma, setSelectedIdioma] = useState("English");

    const handleChange = (idioma) => {
        setSelectedIdioma(idioma);
    };

    return (
        <div className="allSetting">

            <div id="notifications3"></div>

            <div className="containerSetting">
                <div className='centerSetting'>
                    <div className='changeIdiom'>
                        <h2>Language Preferences</h2>
                        <div className='contentChangeIdiom'>
                            <h3>App idioms</h3>
                            <span>See buttons, titles, and other texts in Talk2Me in the following languages.</span>
                        </div>

                        <div className='containerIdioms'>
                            <div className='searchIdiom'>
                                <input type="text" placeholder='Search idiom' />
                            </div>
                            <div className='contentIdioms'>
                                {idiomas.map((idioma, index) => (
                                    <div key={index} className='idiom' onClick={() => handleChange(idioma)}>
                                        <span>{idioma}</span>
                                        <label className="checkbox-container">
                                            <input
                                                type="radio"
                                                name="idioma"
                                                checked={selectedIdioma === idioma}
                                                onChange={() => handleChange(idioma)}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}