import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const ModalComponent = ({ open, handleClose }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backdropFilter: 'blur(5px)',
                backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }}
        >
            <Box
                sx={{
                    width: 400,
                    bgcolor: 'white',
                    borderRadius: '10px',
                    boxShadow: 24,
                    p: 4,
                    textAlign: 'center',
                    outline: 'none'
                }}
            >
                <Typography id="modal-title" variant="h6" component="h2" sx={{ color: '#000', mb: 2 }}>
                    Modo Aula
                </Typography>
                <Typography id="modal-description" sx={{ mb: 4 }}>
                    O modo aula é limitado a 15 minutos por dia e começará quando você enviar a primeira mensagem para iniciar o aprendizado.
                    Você não poderá sair desta tela até finalizar o modo aula para não perder e sempre concluir o aprendizado.
                </Typography>
                <Button
                    onClick={handleClose}
                    sx={{
                        width: '100%',
                        color: '#FFFFFF',
                        borderRadius: '12px',
                        boxShadow: '0 4px 0 #1A73E8',
                        bgcolor: '#3F8CE8',
                        p: '10px 30px',
                        fontWeight: 'bold',
                        transition: 'all 0.1s ease-in-out',
                        '&:hover': {
                            bgcolor: '#3F8CE8',
                        },
                        '&:active': {
                            boxShadow: '0 0px 0 #1A73E8',
                            transform: 'translateY(4px)'
                        }
                    }}
                >
                    Entendi
                </Button>
            </Box>
        </Modal>
    );
};

export default ModalComponent;
