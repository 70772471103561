import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, Container, InputBase } from '@mui/material';
import { useAllTheme } from '../../../../components/themes/ThemeContext';

function FillTheGapsExercise({ exercicio, setCheckFunction, setProgress, setControls, controls }) {
    const [answers, setAnswers] = useState(
        exercicio?.data?.textoEInputs?.filter(item => item.response)
            .flatMap(item => item.response.split(''))
            .map(() => "")
    );
    const answersRef = useRef([]);
    const inputRefs = useRef([]);

    useEffect(() => {
        setCheckFunction(() => check);
    }, [setCheckFunction]);

    useEffect(() => {
        if (!controls.isChecked) {
            const resetAnswers = exercicio?.data?.textoEInputs?.filter(item => item.response)
                .flatMap(item => item.response.split(''))
                .map(() => "");
            setAnswers(resetAnswers);
            answersRef.current = resetAnswers;
        }
    }, [controls.isChecked, exercicio]);

    useEffect(() => {
        const correctAnswers = exercicio.data.textoEInputs
            .filter(item => item.response)
            .flatMap(item => item.response.split(''));
        inputRefs.current = inputRefs.current.slice(0, correctAnswers.length);
    }, [exercicio]);

    const handleInputChange = (event, index) => {
        const value = event.target.value;
        setAnswers(oldAnswers => {
            const newAnswers = [...oldAnswers];
            newAnswers[index] = value;
            answersRef.current = newAnswers;
            return newAnswers;
        });
        if (value) {
            goToNextInput(index);
        }
        setControls((prev) => ({ ...prev, isDisabled: false }));
    };

    const handleKeyDown = (event, index) => {
        if (event.key === "Backspace" && answers[index] === "" && index > 0) {
            goBackToPreviousInput(index);
        }
    };

    const check = () => {
        const correctAnswers = exercicio.data.textoEInputs
            .filter(item => item.response)
            .flatMap(item => item.response.split(''));
        const currentAnswers = answersRef.current;

        const correctAnswersFull = exercicio.data.textoEInputs
                .filter(item => item.response)
                .map(item => item.precedingText + item.response);

        if (JSON.stringify(currentAnswers) === JSON.stringify(correctAnswers)) {
            setProgress({
                type: 'correct',
                explanation: 'Parabéns! Você acertou a resposta.',
                userResponse: currentAnswers.join(', ')
            });
        } else {
            setProgress({
                type: 'incorrect',
                explanation: `A resposta correta é "${correctAnswersFull.join(', ')}".`,
                userResponse: currentAnswers.join(', ')
            });
        }
        setControls((prev) => ({ ...prev, isChecked: true, isDisabled: true }));
    };

    const goToNextInput = (index) => {
        if (index + 1 < inputRefs.current.length) {
            inputRefs.current[index + 1].focus();
        }
    };

    const goBackToPreviousInput = (index) => {
        if (index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    let counter = 0;

    const { theme, isDark } = useAllTheme();

    return (
        <Container maxWidth="md">
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h4" sx={{ mb: 2, color: theme.color }}>
                    Fill in the blanks
                </Typography>
                <Box sx={{ maxWidth: '700px', width: '100%', mt: 2 }}>
                    <Typography variant="body1" sx={{ fontSize: '18px', color: theme.color, lineHeight: '130%' }}>
                        {exercicio?.data?.textoEInputs?.map((item, index) => {
                            if (item.texto) {
                                return item.texto;
                            } else {
                                const inputs = [];
                                for (let i = 0; i < item.response.length; i++) {
                                    const currentIndex = counter++;
                                    inputs.push(
                                        <InputBase
                                            inputRef={(el) => inputRefs.current[currentIndex] = el}
                                            key={currentIndex}
                                            value={answers[currentIndex] || ""}
                                            disabled={controls.isDisabled}
                                            onChange={(e) => handleInputChange(e, currentIndex)}
                                            onKeyDown={(e) => handleKeyDown(e, currentIndex)}
                                            inputProps={{
                                                maxLength: 1,
                                                size: 1,
                                                sx: {
                                                    border: 'none',
                                                    borderBottom: `2px solid ${theme.borderInput}`,
                                                    margin: '4px 0.5px',
                                                    padding: '0 3px',
                                                    height: '25px',
                                                    width: '25px',
                                                    lineHeight: 0,
                                                    textAlign: 'center',
                                                    color: theme.colorInput,
                                                    backgroundColor: theme.backgroundInput,
                                                    borderRadius: '5px',
                                                    textTransform: 'lowercase',
                                                    outline: 'none',
                                                    fontWeight: 400,
                                                    fontSize: '16px',
                                                    '&:disabled': {
                                                        borderBottom: isDark ? '' : '2px solid #00000092',
                                                        color: isDark ? '' : '#2b2b2b8a',
                                                        backgroundColor: isDark ? '' : 'hsla(0, 0%, 87.8%, 0.5)',
                                                    },
                                                }
                                            }}
                                        />
                                    );
                                }
                                return (
                                    <React.Fragment key={index}>
                                        {item.precedingText.split('').map((char, i) =>
                                            <span key={i} className={item.precedingTextClass}>{char}</span>
                                        )}
                                        {inputs}
                                    </React.Fragment>
                                );
                            }
                        })}
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
}

export default FillTheGapsExercise;
