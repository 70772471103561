import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, Switch, Typography, IconButton, List, ListItem, ListItemIcon, ListItemText, Box, Card, CardActionArea, CardContent, CardMedia } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MicOffIcon from '@mui/icons-material/MicOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import SettingsIcon from '@mui/icons-material/Settings';
import BuildIcon from '@mui/icons-material/Build';

import bola from '../../assets/images/bola.png'
import AvatarPicture from '../../assets/images/avatar.png'
import BallTalkAI from '../../componentsTalkIA/talkBallAI';
import Avatar from '../avatar';

const ConfigLexi = ({ handleDelete, addBox, open, setOpen }) => {
    const [microphone, setMicrophone] = useState('');
    const [speaker, setSpeaker] = useState('');
    const [micDisabled, setMicDisabled] = useState(true);
    const [pressToTalk, setPressToTalk] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState('LexiAI');

    const handleClose = () => setOpen(false);

    const handleMicChange = (event) => setMicrophone(event.target.value);
    const handleSpeakerChange = (event) => setSpeaker(event.target.value);
    const handleMicToggle = () => setMicDisabled(!micDisabled);
    const handlePressToTalkToggle = () => setPressToTalk(!pressToTalk);
    const handleAvatarChange = (avatarType) => {
        handleDelete(avatarType == 'voice' ? 'avatar' : 'voice')
        handleClose()
        if (avatarType === 'voice') {
            addBox(
                () => (
                    <Box sx={{ padding: '20px' }}>
                        <BallTalkAI
                            color="#FFFFFF"
                            size={280}
                        />
                    </Box>
                ),
                'voice'
            );
        }
        else {
            addBox(
                () => (
                    <Box sx={{ padding: '20px' }}>
                        <Avatar />
                    </Box>
                ),
                'avatar'
            );
        }
    };

    const renderContent = () => {
        switch (selectedMenu) {
            case 'Geral':
                return (
                    <div>
                        <Typography variant="h6">Configurações Gerais</Typography>
                    </div>
                );
            case 'LexiAI':
                return (
                    <div>
                        <Typography variant="h6" gutterBottom>
                            LexiAI
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Box
                                sx={{ borderRadius: '10px', width: '200px', border: '1px solid #E5E5E5' }}
                                onClick={() => handleAvatarChange('voice')}
                            >
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                                        image={bola}
                                        alt="Bola Interativa"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="div">
                                            Bola Interativa
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Box>
                            <Box
                                sx={{ borderRadius: '10px', width: '200px', border: '1px solid #E5E5E5' }}
                                onClick={() => handleAvatarChange('avatar')}
                            >
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                                        image={AvatarPicture}
                                        alt="Avatar Realista"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="div">
                                            Avatar Realista
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Box>
                        </Box>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <Dialog 
            open={open} 
            onClose={handleClose} 
            maxWidth="md" 
            fullWidth 
            PaperProps={{
                sx: {
                    borderRadius: '15px', // Adicione o borderRadius aqui
                },
            }}
        >
            <DialogTitle>
                Configurações
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers sx={{ display: 'flex', padding: 0, border: 'none' }}>
                <Box
                    sx={{
                        width: 240,
                        borderRight: '1px solid #E5E5E5',
                    }}
                >
                    <List>
                        <ListItem button selected={selectedMenu === 'LexiAI'} onClick={() => setSelectedMenu('LexiAI')}>
                            <ListItemIcon>
                                <BuildIcon />
                            </ListItemIcon>
                            <ListItemText primary="LexiAI" />
                        </ListItem>
                        <ListItem button selected={selectedMenu === 'Geral'} onClick={() => setSelectedMenu('Geral')}>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Geral" />
                        </ListItem>
                    </List>
                </Box>
                <Box sx={{ flexGrow: 1, p: 3, borderTop: '1px solid #E5E5E5', minHeight: '500px', maxHeight: '600px', overflowY: 'auto' }}>
                    {renderContent()}
                </Box>
            </DialogContent>
        </Dialog>
    );
    
};

export default ConfigLexi;
