import React, { useEffect, useRef, useState } from 'react';
import './style.css';

function Writing() {

    const inputRef = useRef();

    const sampleText = "Authors often misinterpret the lettuce as a folklore rabbi, when in actuality it feels more like an uncursed bacon. Pursued distances show us how mother-in-laws can be charleses. Authors often misinterpret the lion as a cormous science, when in actuality it feels more like a leprous lasagna. Recent controversy aside, their band was, in this moment, a racemed suit. The clutch of a joke becomes a togaed chair. The first pickled chess is.";

    const [inputText, setInputText] = useState("");
    const [correctText, setCorrectText] = useState("");
    const [incorrectText, setIncorrectText] = useState("");    

    const [timeLeft, setTimeLeft] = useState(60);
    const [isTyping, setIsTyping] = useState(false);

    const [isStarted, setIsStarted] = useState(true);
    const [timeRemaining, setTimeRemaining] = useState(60);
    const [wpm, setWpm] = useState(0);
    const [intervalId, setIntervalId] = useState(null);

    const [teste, setTeste] = useState(true)

    const startTest = () => {
        if (teste == true) {
            setIsStarted(true);
            const id = setInterval(() => {
                setTimeRemaining((prevTime) => prevTime - 1);
            }, 1000);
            setIntervalId(id);
        }
    };

    useEffect(() => {
        if (inputText.length >= 1) {
            startTest()
            setTeste(false)
        }
        else {

        }
    }, [inputText]);

    useEffect(() => {
        if (timeRemaining === 0) {
            clearInterval(intervalId);
            setIsStarted(false);
            setWpm(Math.floor(correctText.length / 5));
        }
    }, [timeRemaining]);

    useEffect(() => {
        if (!isStarted) {
            setTimeRemaining(60);
        }
    }, [isStarted]);

    useEffect(() => {
        if (isTyping && timeLeft < 0) {
            const timer = setInterval(() => {
                setTimeLeft((timeLeft) => timeLeft - 1);
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        }
    }, [isTyping, timeLeft]);

    const resetGame = () => {
        setInputText("");
        setCorrectText("");
        setIncorrectText("");
        setTimeLeft(60);
        setIsTyping(false);
        setIsStarted(true);
        setTimeRemaining(60);
        setWpm(0);
        setIntervalId(null);
        setTeste(true);
    };
    
    useEffect(() => {
        const inputArray = inputText.split("");
        const sampleArray = sampleText.split("");
        let correctBuffer = [];
        let incorrectBuffer = [];

        for (let i = 0; i < inputArray.length; i++) {
            if (inputArray[i] === sampleArray[i]) {
                correctBuffer.push(inputArray[i]);
            } else {
                incorrectBuffer.push(inputArray[i]);
            }
        }

        setCorrectText(correctBuffer.join(""));
        setIncorrectText(incorrectBuffer.join(""));
    }, [inputText]);

    const handleInputChange = (e) => {
        setInputText(e.target.value);
    };

    const handleWrapperClick = () => {
        inputRef.current.focus();
    };

    return (
        <div className="wrapperTyper" onClick={handleWrapperClick}>
            <input
                ref={inputRef}
                className="input-field"
                type="text"
                value={inputText}
                onChange={handleInputChange}
                autoFocus={true}
                disabled={!isStarted}
            />
            <div className="content-box">
                <div className="typing-text">
                    <p>
                        {sampleText.split('').map((char, index) => {
                            let className;
                            if (index < inputText.length) {
                                className = inputText[index] === char ? 'correct' : 'incorrect';
                            } else if (index === inputText.length) {
                                className = 'active'; // adiciona a classe 'active' para o caractere onde o cursor está atualmente
                            }
                            return (
                                <span key={index} className={className}>
                                    {char}
                                </span>
                            );
                        })}
                    </p>
                </div>
                <div className="contentTyper">
                    <ul className="result-details">
                        <li>
                            <p>Tempo:</p>
                            <span>
                                <b>{Math.floor(timeRemaining / 60)}</b>:
                                <b>{timeRemaining % 60 < 10 ? "0" + (timeRemaining % 60) : timeRemaining % 60}</b>
                            </span>
                        </li>
                        <li>
                            <p>Palavras:</p>
                            <span>
                                <b>{correctText.split(" ").length - 1}</b>
                            </span>
                        </li>
                        <li>
                            <p>Erros:</p>
                            <span>
                                <b>{incorrectText.split("").length}</b>
                            </span>
                        </li>
                        <li>
                            <p>WPM:</p>
                            <span>
                                <b>{isStarted ? Math.floor(correctText.length / 5) : wpm}</b>
                            </span>
                        </li>
                    </ul>
                    <button type="button" onClick={resetGame}>
                        Reiniciar
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Writing;