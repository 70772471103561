import React, { useState, useMemo, useCallback, useEffect, useContext, useRef } from 'react';
import { Box, IconButton } from '@mui/material';
import { createEditor, Transforms, Editor, Element as SlateElement, Path, Point } from 'slate';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import TextFormatOutlined from '@mui/icons-material/TextFormatOutlined';
import ChecklistOutlined from '@mui/icons-material/ChecklistOutlined';
import TableChartOutlined from '@mui/icons-material/TableChartOutlined';
import EditMenu from './EditMenu';
import CloseIcon from '@mui/icons-material/Close';
import './style.css';
import SideMenu from './SideMenu';
import { useNotes } from '../context/NotesContext';

import { db } from '../../auth/config/firebase';
import { collection, addDoc, updateDoc, doc, serverTimestamp, deleteDoc } from 'firebase/firestore';
import { UserContext } from '../../components/UserProvider';
import { useAlert } from '../../components/AlertComponent/AlertContext';

import { CircularProgress } from '@mui/material';
import { useAllTheme } from '../../components/themes/ThemeContext';

const initialValue = [
    {
        type: 'title',
        children: [{ text: '' }],
    },
];

export default function NotesComponent() {
    const editor = useMemo(() => withReact(createEditor()), []);
    const { value, setValue, noteId, setNoteId } = useNotes();

    const { user } = useContext(UserContext)
    const { showAlert } = useAlert();

    useEffect(() => {
        editor.children = value;
        editor.onChange();
    }, [value, setValue, editor]);

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedInlineFormats, setSelectedInlineFormats] = useState([]);
    const [selectedBlockFormat, setSelectedBlockFormat] = useState('paragraph');
    const [notes, setNotes] = useState([]);

    const text = value.map(n => n.children.map(c => c.text).join(' ')).join(' ');

    const debounceTimeout = useRef(null);

    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        if (noteId) {
            const note = notes.find(note => note.id === noteId);
            if (note) {
                Transforms.deselect(editor); // Limpa a seleção atual
                setValue(note.value);
                // Atualiza a seleção para o início do documento
            }
        }
    }, [noteId]);

    const handleEditorChange = (newValue) => {
        setValue(newValue);
        updateSelectionFormats();

        // Limpa o timeout anterior
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        // Define um novo timeout
        debounceTimeout.current = setTimeout(() => {
            saveNoteToFirestore(newValue);
        }, 2000);
    };

    const handleTextFormatClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleTextFormatClose = () => {
        setAnchorEl(null);
    };

    const handleFormatSelect = (format, type) => {
        if (type === 'inline') {
            toggleInlineStyle(format);
        } else {
            toggleBlockType(format);
        }
        handleTextFormatClose();
    };

    const toggleInlineStyle = (style) => {
        const isActive = isMarkActive(style);
        if (isActive) {
            Editor.removeMark(editor, style);
        } else {
            Editor.addMark(editor, style, true);
        }
        updateSelectionFormats();
    };

    const toggleBlockType = (type) => {
        const isActive = isBlockActive(type);
        Transforms.setNodes(
            editor,
            { type: isActive ? 'paragraph' : type },
            { match: n => SlateElement.isElement(n) && Editor.isBlock(editor, n) }
        );

        updateSelectionFormats();
    };

    const isMarkActive = (mark) => {
        const marks = Editor.marks(editor);
        return marks ? marks[mark] === true : false;
    };

    const isBlockActive = (block) => {
        const { selection } = editor;
        if (!selection) return false;

        const [match] = Editor.nodes(editor, {
            match: n => SlateElement.isElement(n) && n.type === block,
        });

        return !!match;
    };

    const updateSelectionFormats = () => {
        const marks = Editor.marks(editor);
        setSelectedInlineFormats(marks ? Object.keys(marks) : []);
        const [match] = Editor.nodes(editor, {
            match: n => SlateElement.isElement(n) && Editor.isBlock(editor, n),
        });
        setSelectedBlockFormat(match ? match[0].type : 'paragraph');
    };

    const renderLeaf = useCallback(props => {
        return <Leaf {...props} />;
    }, []);

    const renderElement = useCallback(props => {
        return <Element {...props} />;
    }, []);

    const insertChecklist = () => {
        const { selection } = editor;
        if (!selection) return;

        const [match] = Editor.nodes(editor, {
            match: n => n.type === 'checklist-item',
        });

        if (match) {
            // Se o cursor estiver em um item de checklist, trocamos para um parágrafo
            Transforms.setNodes(editor, { type: 'paragraph' }, { match: n => n.type === 'checklist-item' });
        } else {
            // Se o cursor não estiver em um item de checklist, adicionamos um novo item
            const checklistItem = {
                type: 'checklist-item',
                checked: false,
                children: [{ text: '' }],
            };
            Transforms.insertNodes(editor, checklistItem);
        }
    };


    const insertTable = () => {
        const { selection } = editor;
        if (!selection) return;

        const [match] = Editor.nodes(editor, {
            match: n => n.type === 'table' || n.type === 'table-row' || n.type === 'table-cell',
        });

        if (!match) {
            // Se o cursor não estiver em uma tabela, adicionamos uma nova tabela
            const table = {
                type: 'table',
                children: [
                    {
                        type: 'table-row',
                        children: [
                            { type: 'table-cell', children: [{ text: '' }] },
                            { type: 'table-cell', children: [{ text: '' }] },
                        ],
                    },
                    {
                        type: 'table-row',
                        children: [
                            { type: 'table-cell', children: [{ text: '' }] },
                            { type: 'table-cell', children: [{ text: '' }] },
                        ],
                    },
                ],
            };
            Transforms.insertNodes(editor, table);
        }
    };

    const handleKeyDown = (event) => {
        const { selection } = editor;
        if (!selection) return;

        if (event.key === 'Enter') {
            const [match] = Editor.nodes(editor, {
                match: n => n.type === 'table' || n.type === 'table-row' || n.type === 'table-cell',
            });

            if (match) {
                event.preventDefault();
                // Adicionar um parágrafo abaixo da tabela
                const path = ReactEditor.findPath(editor, match[0]);
                const nextPath = Path.next(path);
                Transforms.insertNodes(editor, { type: 'paragraph', children: [{ text: '' }] }, { at: nextPath });
                Transforms.select(editor, nextPath);
                return;
            }
        }

        if (event.key === 'Backspace') {
            const [match] = Editor.nodes(editor, {
                match: n => n.type === 'table-cell',
            });

            if (match) {
                const [node, path] = match;
                const start = Editor.start(editor, path);
                if (Editor.isEmpty(editor, node) && Point.equals(selection.anchor, start)) {
                    event.preventDefault();
                    return;
                }
            }
        }
    };

    const addColumn = (path) => {
        const table = Editor.node(editor, path);
        const newTable = { ...table[0] };
        newTable.children.forEach(row => {
            row.children.push({ type: 'table-cell', children: [{ text: '' }] });
        });
        Transforms.setNodes(editor, newTable, { at: path });
    };

    const removeColumn = (path) => {
        const table = Editor.node(editor, path);
        const newTable = { ...table[0] };
        newTable.children.forEach(row => {
            row.children.pop();
        });
        Transforms.setNodes(editor, newTable, { at: path });
    };

    const toggleCheckbox = (element) => {
        const path = ReactEditor.findPath(editor, element);
        const newProperties = {
            checked: !element.checked,
        };
        Transforms.setNodes(editor, newProperties, { at: path });
    };

    const removeTable = (path) => {
        Transforms.removeNodes(editor, { at: path });
    };

    const Element = ({ attributes, children, element }) => {
        switch (element.type) {
            case 'title':
                return <h1 {...attributes}>{children}</h1>;
            case 'heading':
                return <h2 {...attributes}>{children}</h2>;
            case 'subheading':
                return <h3 {...attributes}>{children}</h3>;
            case 'body':
                return <p {...attributes}>{children}</p>;
            case 'monospaced':
                return <pre {...attributes}><code>{children}</code></pre>;
            case 'bulleted':
                return <ul {...attributes}><li>{children}</li></ul>;
            case 'dashed':
                return (
                    <ul {...attributes} style={{ listStyleType: 'none', paddingLeft: 0 }}>
                        {React.Children.map(children, (child, index) => (
                            <li key={index} style={{ marginLeft: '33px', position: 'relative' }}>
                                <span style={{ position: 'absolute', left: '-20px' }}>-</span>
                                {child}
                            </li>
                        ))}
                    </ul>
                );
            case 'numbered':
                return <ol {...attributes}><li>{children}</li></ol>;
            case 'checklist-item':
                return (
                    <div {...attributes} className='checklist'>
                        <input
                            type="checkbox"
                            checked={element.checked}
                            onChange={() => toggleCheckbox(element)}
                        />
                        {children}
                    </div>
                );
            case 'table':
                return (
                    <table {...attributes} className='table'>
                        <tbody>
                            {children}
                        </tbody>
                        <div
                            onClick={() => {
                                const path = ReactEditor.findPath(editor, element);
                                removeTable(path);
                            }}
                            className="remove-table-button"
                        >
                            <CloseIcon style={{ fontSize: '15px' }} />
                        </div>
                    </table>
                );
            case 'table-row':
                return (
                    <tr {...attributes}>
                        {children}
                    </tr>
                );
            case 'table-cell':
                return (
                    <td {...attributes} style={{ border: `1px solid ${isDark ? '#FFFFFF' : '#1D1D1F'}`, padding: '5px' }}>
                        {children}
                    </td>
                );
            default:
                return <p {...attributes}>{children}</p>;
        }
    };


    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const saveNoteToFirestore = async (newValue) => {
        const getWords = (text, num) => text.split(/\s+/).slice(0, num).join(' ');

        const allText = newValue.map(n => n.children.map(c => c.text).join(' ')).join(' ');

        // Verifica se há pelo menos um caractere escrito
        if (allText.trim().length === 0) {
            return;
        }

        const title = getWords(allText, 14) || 'Untitled';
        const content = getWords(allText.split(/\s+/).slice(14).join(' '), 14);
        const userId = user.uid;  // Pegue o UID do usuário

        const noteData = {
            title,
            content,
            value: newValue,
            userId,
            updatedAt: Date.now() // Usando Date.now() localmente
        };

        if (noteId) {
            // Atualiza a nota existente
            const noteRef = doc(db, "notes", noteId);
            await updateDoc(noteRef, {
                ...noteData,
                updatedAt: serverTimestamp() // Usando serverTimestamp() no Firestore
            });
            setNotes(prevNotes => [
                { ...prevNotes.find(note => note.id === noteId), ...noteData },
                ...prevNotes.filter(note => note.id !== noteId)
            ]);
        } else {
            // Cria uma nova nota
            const docRef = await addDoc(collection(db, "notes"), {
                ...noteData,
                createdAt: serverTimestamp()
            });
            setNoteId(docRef.id);
            setNotes(prevNotes => [
                { id: docRef.id, ...noteData },
                ...prevNotes
            ]);
        }
    };

    const newNote = () => {
        Transforms.deselect(editor); // Limpa a seleção atual
        setValue(initialValue)
        setNoteId(null)
    }

    const Delete = async (noteId) => {
        const noteRef = doc(db, "notes", noteId);

        try {
            setIsDeleting(true); // Inicia o estado de carregamento
            // Deleta a nota do Firestore
            await deleteDoc(noteRef);

            // Remove a nota do estado notes
            setNotes(prevNotes => prevNotes.filter(note => note.id !== noteId));

            // Limpa o editor se a nota deletada for a atual
            Transforms.deselect(editor); // Limpa a seleção atual
            setValue(initialValue)
            setNoteId(null);

            console.log("Nota deletada com sucesso");
        } catch (error) {
            console.error("Erro ao deletar nota: ", error);
        } finally {
            setIsDeleting(false); // Finaliza o estado de carregamento
        }
    };

    const deleteNote = () => {

        const allText = value.map(n => n.children.map(c => c.text).join(' ')).join(' ');

        // Verifica se há pelo menos um caractere escrito
        if (allText.trim().length === 0) {
            return;
        }

        showAlert(
            "Confirmação",
            "Tem certeza que deseja excluir essa nota, essa ação não pode ser desfeita",
            [
                { text: "Não", onPress: () => console.log("Cancelado") },
                { text: "Sim", onPress: () => Delete(noteId), redText: true },
            ]
        );
    };

    const { theme, isDark } = useAllTheme();
    

    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
            <SideMenu menuOpen={menuOpen} userId={user.uid} notes={notes} setNotes={setNotes} theme={theme} />
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: theme.background }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '10px',
                        backgroundColor: theme.background,
                        borderBottom: `1px solid ${theme.border}`
                    }}
                >
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <IconButton onClick={toggleMenu}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className="icon-xl-heavy"><path fill="currentColor" fillRule="evenodd" d="M8.857 3h6.286c1.084 0 1.958 0 2.666.058.729.06 1.369.185 1.961.487a5 5 0 0 1 2.185 2.185c.302.592.428 1.233.487 1.961.058.708.058 1.582.058 2.666v3.286c0 1.084 0 1.958-.058 2.666-.06.729-.185 1.369-.487 1.961a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C17.1 21 16.227 21 15.143 21H8.857c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.232-.487-1.961C1.5 15.6 1.5 14.727 1.5 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.728.185-1.369.487-1.96A5 5 0 0 1 4.23 3.544c.592-.302 1.233-.428 1.961-.487C6.9 3 7.773 3 8.857 3M6.354 5.051c-.605.05-.953.142-1.216.276a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216-.05.617-.051 1.41-.051 2.546v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h.6V5h-.6c-1.137 0-1.929 0-2.546.051M11.5 5v14h3.6c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.134-.263.226-.611.276-1.216.05-.617.051-1.41.051-2.546v-3.2c0-1.137 0-1.929-.051-2.546-.05-.605-.142-.953-.276-1.216a3 3 0 0 0-1.311-1.311c-.263-.134-.611-.226-1.216-.276C17.029 5.001 16.236 5 15.1 5zM5 8.5a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1M5 12a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1" clipRule="evenodd"></path></svg>
                        </IconButton>
                        <IconButton onClick={newNote}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24" className="icon-xl-heavy"><path d="M15.673 3.913a3.121 3.121 0 1 1 4.414 4.414l-5.937 5.937a5 5 0 0 1-2.828 1.415l-2.18.31a1 1 0 0 1-1.132-1.13l.311-2.18A5 5 0 0 1 9.736 9.85zm3 1.414a1.12 1.12 0 0 0-1.586 0l-5.937 5.937a3 3 0 0 0-.849 1.697l-.123.86.86-.122a3 3 0 0 0 1.698-.849l5.937-5.937a1.12 1.12 0 0 0 0-1.586M11 4A1 1 0 0 1 10 5c-.998 0-1.702.008-2.253.06-.54.052-.862.141-1.109.267a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216C5.001 8.471 5 9.264 5 10.4v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h3.2c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.126-.247.215-.569.266-1.108.053-.552.06-1.256.06-2.255a1 1 0 1 1 2 .002c0 .978-.006 1.78-.069 2.442-.064.673-.192 1.27-.475 1.827a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C15.6 21 14.727 21 13.643 21h-3.286c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.233-.487-1.961C3 15.6 3 14.727 3 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.729.185-1.369.487-1.961A5 5 0 0 1 5.73 3.545c.556-.284 1.154-.411 1.827-.475C8.22 3.007 9.021 3 10 3A1 1 0 0 1 11 4"></path></svg>
                        </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <IconButton onClick={handleTextFormatClick}>
                            <TextFormatOutlined style={{ color: '#007aff' }} />
                        </IconButton>
                        <EditMenu
                            anchorEl={anchorEl}
                            handleTextFormatClose={handleTextFormatClose}
                            handleFormatSelect={handleFormatSelect}
                            selectedInlineFormats={selectedInlineFormats}
                            selectedBlockFormat={selectedBlockFormat}
                            theme={theme}
                            isDark={isDark}
                        />
                        <IconButton onClick={insertChecklist}>
                            <ChecklistOutlined style={{ color: '#007aff' }} />
                        </IconButton>
                        <IconButton onClick={insertTable}>
                            <TableChartOutlined style={{ color: '#007aff' }} />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <IconButton onClick={deleteNote} disabled={isDeleting}>
                            {isDeleting ? (
                                <CircularProgress size={24} style={{ color: '#007aff' }} />
                            ) : (
                                <DeleteOutline style={{ color: !text ? '#AAAAAA' : '#007aff' }} />
                            )}
                        </IconButton>
                    </Box>
                </Box>

                <Box className="scrollNotes" sx={{ flex: 1, overflowY: 'auto', padding: '20px', backgroundColor: theme.background, marginRight: '2px' }}>
                    <Slate editor={editor} initialValue={value} value={value} onChange={handleEditorChange}>
                        <Editable
                            style={{ flex: 1 }}
                            className='textbox'
                            renderLeaf={renderLeaf}
                            renderElement={renderElement}
                            // placeholder="Start typing..."
                            onKeyDown={handleKeyDown}
                        />
                    </Slate>
                    <style>
                        {`
                        ::selection {
                            background: rgba(0, 122, 255, 0.3);
                        }

                        ::-moz-selection {
                            background: rgba(0, 122, 255, 0.3);
                        }
                    `}
                    </style>
                </Box>
            </Box>
        </Box>
    );
}

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }
    if (leaf.italic) {
        children = <em>{children}</em>;
    }
    if (leaf.underline) {
        children = <u>{children}</u>;
    }
    if (leaf.strikethrough) {
        children = <s>{children}</s>;
    }
    return <span {...attributes}>{children}</span>;
};
