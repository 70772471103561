import './style.css'
import React, { useEffect, useState } from 'react'
import Alert from '../../components/alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FaChevronRight } from "react-icons/fa";
import { db } from '../../auth/config/firebase';
import { auth } from '../../auth/config/firebase';
import { doc, collection, onSnapshot, updateDoc, getDoc, deleteField } from 'firebase/firestore';
import { addNotification } from '../../components/notification';

import { useContext } from 'react';
import { LoginContext } from '../../index';

import edgeLogo from '../../assets/images/Microsoft_Edge_logo.png'
import chromeLogo from '../../assets/images/chrome.png'
import safariLogo from '../../assets/images/safari.png'
import firefoxLogo from '../../assets/images/social.png'
import operaLogo from '../../assets/images/opera.png'
import androidLogo from '../../assets/images/android.png'
import appleLogo from '../../assets/images/Apple_logo_black.svg.webp'

export default function Sessions() {

    const { setIsLoginComplete } = useContext(LoginContext);

    const [sessions, setSessions] = useState([]);

    const [uid, setUid] = useState(null);

    useEffect(() => {
        const currentUid = auth.currentUser?.uid;

        if (!currentUid) {
            console.error("Nenhum usuário está logado.");
            return;
        }

        setUid(currentUid);

    }, [auth.currentUser]);

    useEffect(() => {
        if (!uid) return;

        const userRef = doc(collection(db, 'users'), uid);

        const unsubscribe = onSnapshot(userRef, (docSnap) => {
            if (docSnap.exists()) {
                const userData = docSnap.data();
                const userSessions = userData.sessionsDevice || [];
                setSessions(userSessions);
            }
        });

        // Limpar a inscrição ao desmontar o componente
        return unsubscribe;
    }, [uid]);

    const browserLogos = {
        "Mozilla Firefox": firefoxLogo,
        "Opera": operaLogo,
        "Microsoft Edge": edgeLogo,
        "Google Chrome": chromeLogo,
        "Apple Safari": safariLogo,
        "Internet Explorer": '', // Adicione um logo para o Internet Explorer
        "Unknown": '', // Adicione um logo padrão para quando o navegador é desconhecido
    };

    const osLogos = {
        "macOS": appleLogo,
        "iOS": appleLogo,
        "iPadOS": appleLogo,
        "Windows": '', // Adicione um logo para Windows
        "Linux": '', // Adicione um logo para Linux
        "Android": androidLogo,
        "Unknown": '', // Adicione um logo padrão para quando o sistema operacional é desconhecido
    };

    function humanizeTimeDifference(timestamp) {
        const now = Date.now();
        const sessionDate = new Date(timestamp);
        const diffInSeconds = Math.floor((now - sessionDate) / 1000);

        if (diffInSeconds < 60) {
            return `${diffInSeconds} seconds ago`;
        }
        if (diffInSeconds < 3600) {
            return `${Math.floor(diffInSeconds / 60)} minutes ago`;
        }
        if (diffInSeconds < 86400) {
            return `${Math.floor(diffInSeconds / 3600)} hours ago`;
        }
        if (diffInSeconds < 604800) {
            return `${Math.floor(diffInSeconds / 86400)} days ago`;
        }
        if (diffInSeconds < 2419200) {
            return `${Math.floor(diffInSeconds / 604800)} weeks ago`;
        }
        return `${Math.floor(diffInSeconds / 2419200)} months ago`;
    }

    const handleDisconnectAll = async () => {
        try {
            // Primeiro, recuperamos o documento atual
            const userDoc = doc(db, "users", uid);

            // Deletamos o campo sessionsDevice do documento
            await updateDoc(userDoc, {
                sessionsDevice: deleteField()
            });

            // Notificamos o usuário sobre o sucesso da operação
            addNotification("sessionsDevice field deleted successfully", 'success3');
            setSelectedSession(null);
        } catch (e) {
            // Notificamos o usuário sobre a falha da operação
            addNotification("Sorry, unable to delete sessionsDevice field", 'error3');
        }
    };

    const disconnectAll = () => {
        setShowMessage({
            title: "Disconnect all devices",
            paragraph: "This action will disconnect all devices from your account including this one.",
            buttons: [
                { text: "Cancel", action: () => setShowMessage(prevState => ({ ...prevState, show: false })) },
                { text: "Disconnect all", action: handleDisconnectAll }
            ],
            show: true
        });
    };

    const [showMessage, setShowMessage] = useState({
        title: "",
        paragraph: "",
        buttons: [],
        show: false
    });

    const [selectedSession, setSelectedSession] = useState(null);

    function formatSessionDate(date) {
        const now = new Date();
        const sessionDate = new Date(date);
        const diffInDays = Math.floor((now - sessionDate) / (1000 * 60 * 60 * 24));

        let formattedDate;
        if (diffInDays === 0) {
            formattedDate = 'today';
        } else if (diffInDays === 1) {
            formattedDate = 'yesterday';
        } else {
            formattedDate = sessionDate.toLocaleDateString('en-US', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
            });
        }

        const sessionTime = sessionDate.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });

        return `${formattedDate} at ${sessionTime}`;
    }

    function formatLocation(session) {
        const parts = [session.city, session.region, session.country].filter(Boolean);

        if (parts.length > 0) {
            return parts.join(', ');
        } else {
            return 'Location not available';
        }
    }

    const handleDisconnect = async (uniqueID) => {

        setIsLoginComplete(false)

        try {
            // Primeiro, recuperamos o documento atual
            const userDoc = doc(db, "users", uid);
            const userDocData = (await getDoc(userDoc)).data();

            // Em seguida, encontramos o índice do objeto da sessão que queremos remover
            const updatedSessions = userDocData.sessionsDevice.filter(session => session.uniqueID !== uniqueID);

            // Finalmente, atualizamos o documento com o novo array de sessões
            await updateDoc(userDoc, {
                sessionsDevice: updatedSessions
            });

            // Após deletar a sessão, resetamos o selectedSession para null
            addNotification("Session disconnected successfully", 'success3');
            setSelectedSession(null);

            setTimeout(() => {
                setIsLoginComplete(true)
            }, 2000)
        } catch (e) {
            // console.error("Error removing document: ", e);
            addNotification("Sorry, unable to disconnect from this session", 'error3');
            setIsLoginComplete(true)
        }
    };


    return (
        <div className="allSetting allSessions">

            <div id="notifications3"></div>

            <Alert
                title={showMessage.title}
                paragraph={showMessage.paragraph}
                buttons={showMessage.buttons}
                showAlert={showMessage.show}
            />

            {selectedSession && (
                <aside>
                    <div className='infoDeviceLoged'>
                        <div className='closeInfoDeviceLoged'>
                            <FontAwesomeIcon icon={faX} onClick={() => setSelectedSession(null)} />
                        </div>
                        <div className='headerinfoDeviceLoged'>
                            <img src={browserLogos[selectedSession.browserName] || osLogos[selectedSession.operatingSystem] || edgeLogo} alt={selectedSession.browserName || selectedSession.operatingSystem} />
                            <h3>{selectedSession.browserName}</h3>
                        </div>
                        <div className='infoDeviceLogedContent'>
                            <div className='flexInfoDeviceLoged'>
                                <h3>Atividade</h3>
                                <span>Last active session {formatSessionDate(selectedSession.createdAt)}</span>
                            </div>
                            <div className='flexInfoDeviceLoged'>
                                <h3>Localização</h3>
                                <span>{formatLocation(selectedSession)}</span>
                            </div>
                        </div>
                        <button onClick={() => handleDisconnect(selectedSession.uniqueID)}>Disconnect</button>
                    </div>
                </aside>
            )}

            <div className="containerSetting">
                <div className='aboutSession'>
                    <div className='flexAboutSection'>
                        {/* <img src={crossPlatform} alt="cross-platform" /> */}
                        <div className='contentAboutSection'>
                            <h2>Manage Your Account's Logged Sessions</h2>
                            {/* <p>Ensure account security by overseeing active sessions. Review device and location access, and remotely log out if needed.</p> */}
                        </div>
                    </div>
                    <button onClick={disconnectAll}>Disconnect all</button>
                </div>

                <div className='containerDevices'>
                    {sessions.map((session, index) => {
                        const browserLogo = browserLogos[session.browserName] || '';
                        const osLogo = osLogos[session.operatingSystem] || '';

                        return (
                            <div
                                className='conectedDevices'
                                key={index}
                                onClick={() => setSelectedSession(session)}
                            >
                                <div className='logDevice'>
                                    <img src={browserLogo || osLogo || edgeLogo} alt={session.browserName || session.operatingSystem} />
                                    <div className='infoDevice'>
                                        <h3>{session.browserName} ({session.operatingSystem})</h3>
                                        <span>Last active: {humanizeTimeDifference(session.createdAt)}</span>
                                    </div>
                                </div>
                                <FaChevronRight />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}