import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh } from '@fortawesome/free-solid-svg-icons';
import {
    Box,
    Typography,    
} from '@mui/material';
import { sendTextToSpeechRequest } from "../../../../componentsTalkIA/IAtalk2ME/Talk2MeVoice";
import { OptionButton } from "../../StyledComponents";
import { useAllTheme } from "../../../../components/themes/ThemeContext";

export default function CompleteConversation({ exercicio, setCheckFunction, setProgress, setControls, controls }) {
    const respostaSelecionadaRef = useRef(null);

    useEffect(() => {
        setCheckFunction(() => check);
        // console.log('setCheckFunction configurada');
    }, [setCheckFunction]);

    useEffect(() => {
        if (!controls.isChecked) {
            respostaSelecionadaRef.current = null;
            // console.log("Estado resetado");
        }
    }, [controls.isChecked]);

    const selecionaResposta = (opcao) => {
        respostaSelecionadaRef.current = opcao;
        setControls((prev) => ({ ...prev, isDisabled: false }));
    }

    const check = () => {
        if (respostaSelecionadaRef.current === exercicio.data.correct_response) {
            setProgress({
                type: 'correct',
                explanation: 'Congratulations! You got the answer right.',
                userResponse: respostaSelecionadaRef.current
            });
        } else {
            setProgress({
                type: 'incorrect',
                explanation: `The correct answer is "${exercicio.data.correct_response}".`,
                userResponse: respostaSelecionadaRef.current
            });
        }
        setControls((prev) => ({ ...prev, isChecked: true }));
    }

    const [audioUrl, setAudioUrl] = useState('');
    const audioRef = useRef(null);

    const speak = async () => {
        const url = await sendTextToSpeechRequest(exercicio.data.initial_message, "Danielle");
        setAudioUrl(url);
    }

    useEffect(() => {
        if (audioUrl && audioRef.current) {
            audioRef.current.play();
        }
    }, [audioUrl]);

    const { theme, isDark } = useAllTheme();

    return (
        <Box sx={{ maxWidth: '500px', width: '100%', }}>
            {audioUrl && (
                <audio
                    src={audioUrl}
                    ref={audioRef}
                    controls
                    style={{ display: 'none' }}
                />
            )}
            <Typography variant="h4" textAlign="center" sx={{ mb: 2, fontFamily: "SF-Pro-Display, sans-serif", color: theme.color }}>
                Complete the conversation
            </Typography>
            <Box sx={{ mb: 3, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                    border: `2px solid ${theme.border}`,
                    borderRadius: '16px',
                    padding: '12px 15px',
                    gap: 2,
                    fontSize: '18px',
                    fontWeight: 500,
                    color: theme.color,
                    mb: 2,
                    width: 'fit-content',
                    justifyContent: 'flex-start',
                    alignSelf: 'flex-start'
                }}>
                    <FontAwesomeIcon
                        icon={faVolumeHigh}
                        onClick={speak}
                        style={{ color: 'rgb(28, 176, 246)', fontSize: '23px', cursor: 'pointer' }}
                    />
                    <Typography variant="body1" sx={{ fontFamily: "SF-Pro-Text-Regular, sans-serif" }}>
                        {exercicio.data.initial_message}
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    backgroundColor: theme.backgroundMenu,
                    border: `2px solid ${theme.borderMenu}`,
                    borderRadius: '16px',
                    padding: '12px 15px',
                    gap: 2,
                    width: 'fit-content',
                    alignSelf: 'flex-end'
                }}>
                    <Box sx={{
                        width: '160px',
                        height: '20px',
                        borderBottom: '2px solid rgb(175, 175, 175)'
                    }} />
                </Box>
            </Box>
            <Box>
                {exercicio.data.response_options.map((opcao, index) => (
                    <OptionButton
                        key={index}
                        theme={theme}
                        onClick={() => selecionaResposta(opcao)}
                        disabled={controls.isChecked}
                        className={respostaSelecionadaRef.current === opcao ? 'optionSelected' : ''}
                    >
                        <Box className="buttonOptionListeningNumber">
                            {index + 1}
                        </Box>
                        <Typography variant="body1" textAlign="center" sx={{ width: 'calc(100% - 38px)' }}>{opcao}</Typography>
                    </OptionButton>
                ))}
            </Box>
        </Box>
    );
}
