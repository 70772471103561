import './styles.css'
import React, { useState, useRef, useEffect } from 'react';
import Lottie from 'lottie-react';
import animation from '../../assets/animation/animation.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { faClose, faChevronDown, faChevronUp, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { addNotification } from '../../components/notification'
import axios from 'axios';
import UserAvatar from '../../components/UserAvatar';
import { setDoc, onSnapshot, doc, collection, query, getDocs, where } from "firebase/firestore";
import { db } from '../../auth/config/firebase';
import { auth } from '../../auth/config/firebase';
import { FaCamera } from "react-icons/fa";
import Alert from '../../components/alert';

import { UserContext } from '../../components/UserProvider';
import { useContext } from 'react';

const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animation
}

export default function Interview() {

    const { user, photoURL, displayName, email } = useContext(UserContext)

    const [selectedCountry, setSelectedCountry] = useState('');

    const getUserIP = async () => {
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            return response.data.ip;
        } catch (error) {
            // console.error("Erro ao obter o IP:", error);
            return null;
        }
    };

    // Função para obter detalhes de localização
    const getLocationDetails = async (ip, retries = 3) => {
        try {
            const response = await axios.get(`https://get.geojs.io/v1/ip/geo/${ip}.json`);
            return response.data;
        } catch (error) {
            // console.error(`Erro ao obter detalhes de localização: ${error}`);
            if (retries > 0) {
                // console.log(`Tentando novamente em 5 segundos. Restam ${retries} tentativas.`);
                await new Promise(resolve => setTimeout(resolve, 5000));
                return getLocationDetails(ip, retries - 1);
            }
            return null;
        }
    };

    useEffect(() => {
        async function fetchIP() {
            const userIP = await getUserIP();
            if (userIP) {
                const location = await getLocationDetails(userIP);
                if (location) {
                    setSelectedCountry(location.country)
                }
            }
        }

        fetchIP();
    }, []);


    const [countries, setCountries] = useState([]);

    useEffect(() => {
        fetch('https://restcountries.com/v2/all')
            .then(response => response.json())
            .then(data => {
                setCountries(data);
            });
    }, []);


    const [showInfo, setShowInfo] = useState(true);

    const [activeIndex, setActiveIndex] = useState(0);
    const [isButtonEnabled, setButtonEnabled] = useState(true);

    let swiperRef = useRef(null);

    const goToNextPage = () => {
        if (isButtonEnabled && swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    }

    const goToPreviousPage = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    }

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex);
    }

    const totalPages = 4;

    useEffect(() => {
        setActiveIndex(0);  // Define o índice inicial quando o componente é montado
    }, []);

    const progress = (activeIndex / (totalPages - 1)) * 100;

    const allSkills = [
        "🎓 IELTS",
        "💼 business",
        "🎤 interview",
        "🔧 engineer",
        "💰 finance",
        "⚕️ medical",
        "✍ writing",
        "⚖️ law",
        "👥 human resources",
        "📈 marketing",
        "🎶 music",
        "🎨 art",
        "⚽ football",
        "✈️ aviation",
        "🔬 science",
        "🌠 anime",
        "🎨 design",
        "🌍 travel",
        "📜 history",
        "🎬 movie",
        "🏅 sports",
        "💻 information technology",
        "🏫 education",
        "🩺 health care",
        "📖 Literature",
        "🔭 Astronomy",
        "🏢 Real Estate",
        "🧪 Chemistry",
        "🔍 Forensic Science",
        "🍽️ Culinary Arts",
        "📷 Photography",
        "🧘 Yoga",
        "👗 Fashion",
        "🌱 Environment",
        "🏛️ Politics",
        "🧑‍🚒 Firefighting",
        "🚜 Agriculture",
        "🕵️ Detective",
        "🪙 Cryptocurrency",
        "🎭 Theater",
        "🧠 Psychology",
        "🐕 Pet Care",
        "📊 Statistics",
        "🏊 Swimming",
        "🙏 Gospel",
        "🎪 Circus Arts",
        "🪁 Kite Flying",
        "🛹 Skateboarding",
        "💃 Dance",
        "👾 Video Games",
        "🌠 Astronomy",
        "🛠️ DIY",
        "🔭 Physics",
        "🗺️ Geography",
        "🏞️ National Parks",
        "🧩 Puzzles",
        "📸 Photography",
        "🍽️ Gastronomy",
        "🍶 Ceramics",
        "🕍 Religious Studies",
        "🎎 Puppetry",
        "🌐 Environmentalism",
        "🧁 Baking",
        "📚 Literature",
        "🔍 Detective Stories",
        "🤖 Robotics",
        "👽 UFOlogy",
        "🚀 Space Exploration",
        "🍀 Gardening",
        "🍷 Winemaking",
        "🌱 Veganism",
        "🎯 Archery",
        "📻 Podcasting",
        "🏰 Medieval History",
        "💫 Astrology",
        "🦖 Paleontology",
        "🐍 Herpetology",
        "🎢 Roller Coasters",
        "💡 Philosophy",
        "🐦 Bird Watching",
        "🎖️ Military History",
        "🎲 Board Games",
        "🎣 Fishing",
        "🌋 Geology",
        "🧵 Sewing",
        "🔖 Bibliophile",
        "🦺 Safety & First Aid",
        "🦠 Microbiology",
        "🎌 Vexillology (study of flags)",
        "💎 Gemology",
        "🐟 Ichthyology",
        "🛶 Kayaking",
        "🎮 Game Development",
        "🏹 Hunting",
        "🧊 Ice Sculpting",
        "👩‍🌾 Homesteading",
        "👢 Cowboy Culture",
        "🍂 Autumn Enthusiast",
        "🌸 Flower Arranging",
        "🐝 Beekeeping"
    ]

    const [selectedSkills, setSelectedSkills] = useState([]);

    const toggleSkill = (skill) => {
        if (selectedSkills.includes(skill)) {
            setSelectedSkills(prevSkills => prevSkills.filter(s => s !== skill));
        } else {
            if (selectedSkills.length < 5) {
                setSelectedSkills(prevSkills => [...prevSkills, skill]);
            } else {
                addNotification('Você pode selecionar no máximo 5 habilidades!', 'error3');
            }
        }
    }

    const [isScrolledDown, setIsScrolledDown] = useState(false);

    const handleScroll = () => {
        const optionsSkilsDiv = document.querySelector('.optionsSkils');
        if (!isScrolledDown) {
            optionsSkilsDiv.scrollTop += 800; // ou qualquer valor que você deseje para o scroll
            setIsScrolledDown(true);
        } else {
            optionsSkilsDiv.scrollTop -= 800; // ou qualquer valor que você deseje para o scroll
            setIsScrolledDown(false);
        }
    }

    const [aboutMe, setAboutMe] = useState('');
    const [userNick, setUserNick] = useState('');

    const [showConfirmPopup, setShowConfirmPopup] = useState(false)

    async function end() {
        // Normaliza o nome de usuário para a consulta
        const normalizedUserNick = userNick.replace(/\s+/g, '').toLowerCase();

        const usersRef = collection(db, 'community');
        const q = query(usersRef, where('normalizedUserNick', '==', normalizedUserNick));
        const userSnapshot = await getDocs(q);

        if (userSnapshot.empty) {
            // Nome de usuário disponível
            setShowConfirmPopup(!showConfirmPopup);
        } else {
            // Nome de usuário já está sendo usado
            addNotification('Este nome de usuário já está sendo usado por outra conta. Por favor, escolha outro.', 'error3');
        }
    }

    const handleFormSubmit = (e) => {
        e.preventDefault(); // Isso evitará o envio do formulário
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Isso evitará que a tecla "Enter" acione o envio do formulário
        }
    };

    const sendUserCommunity = async () => {
        const user = auth.currentUser;

        if (!user) {
            console.error("Nenhum usuário autenticado");
            return;
        }

        // const normalizedUserNick = userNick.replace(/\s+/g, '').toLowerCase();
        const userDocRef = doc(db, "community", user.uid);

        const userData = {
            selectedCountry: selectedCountry,
            userNick: userNick,
            normalizedUserNick: userNick.replace(/\s+/g, '').toLowerCase(),
            aboutMe: aboutMe,
            selectedSkills: selectedSkills,
            photoURL: photoURL,
            currentYear: new Date().getFullYear(),
        };

        try {
            await setDoc(userDocRef, userData);
            addNotification('Congratulations, you are now a new member of our community', 'success3');
            setShowConfirmPopup(false)
            window.location.reload();
        } catch (error) {
            setShowConfirmPopup(false)
            addNotification('Something went wrong, please try again!', 'error3');
        }
    }

    return (
        <div className="allInterview">

            <Alert
                title="Do you want to continue?"
                paragraph="Do you want to continue and join our Talk2MeIA community"
                buttons={[
                    { text: "Cancel", action: end },
                    { text: "Continue", action: sendUserCommunity }
                ]}
                showAlert={showConfirmPopup}
            />

            <div id="notifications3"></div>
            <div className={showInfo ? 'containeIntroInterview' : 'containeIntroInterview show'}>
                <div className='flexLottieAnimation'>
                    <Lottie
                        animationData={defaultOptions.animationData}
                        loop={defaultOptions.loop}
                        autoplay={defaultOptions.autoplay}
                        style={{ height: 290, width: 290 }}
                    />
                </div>
                <div className='flexInfoAnimation'>
                    <div className='infoAnimation'>
                        <h2>Join Our Community</h2>
                        <span>To become a part of our community, please answer a few questions so fellow members can get to know you better.</span>
                        <button onClick={() => setShowInfo(false)}>Start questions</button>
                    </div>
                </div>
            </div>

            <div className={showInfo ? 'questionsContainer' : 'questionsContainer show'}>
                <div className="progressCommunity">
                    {activeIndex
                        ?
                        <FontAwesomeIcon icon={faArrowLeft} onClick={goToPreviousPage} />
                        :
                        <Link to='/'>
                            <FontAwesomeIcon id='closePage' icon={faClose} />
                        </Link>
                    }
                    <div className="progressBarContainer">
                        <div className="progressBar" style={{ width: `${progress}%` }}></div>
                    </div>
                </div>
                <div className='containerQuestionsCommunity'>
                    <Swiper ref={swiperRef} onSlideChange={handleSlideChange} className='swiperQuestions' simulateTouch={false}>
                        <SwiperSlide className='swiper-slide allContainerSlide'>
                            <div className='containerContentQuestion'>
                                <h2>Choose your types of interests</h2>
                                <div className='containerInfoQuestion'>
                                    <div className='optionsSkils'>
                                        {allSkills.map(skill => (
                                            <li
                                                key={skill}
                                                onClick={() => toggleSkill(skill)}
                                                className={selectedSkills.includes(skill) ? 'active' : ''}
                                            >
                                                {skill}
                                            </li>
                                        ))}
                                    </div>
                                    <span id='scroll' onClick={handleScroll}>
                                        {isScrolledDown ? "Scroll up" : "Scroll down"}
                                        <FontAwesomeIcon icon={isScrolledDown ? faChevronUp : faChevronDown} />
                                    </span>
                                </div>
                            </div>
                            <div className='containerButtonQustions'>
                                <button disabled={selectedSkills.length === 0} onClick={goToNextPage}>Next</button>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className='swiper-slide allContainerSlide'>
                            <div className='containerContentQuestion'>
                                <h2>Where do you live?</h2>
                                <div className='containerInfoQuestion'>
                                    <select value={selectedCountry} onChange={e => setSelectedCountry(e.target.value)} className='selectCountry'>
                                        <option value="" disabled>Select your country</option>
                                        {countries.map(country => (
                                            <option key={country.name} value={country.name}>
                                                {country.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='containerButtonQustions'>
                                <button disabled={!selectedCountry} onClick={goToNextPage}>Next</button>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className='swiper-slide allContainerSlide'>
                            <div className='containerContentQuestion'>
                                <h2>Tell something about yourself</h2>
                                <div className='containerInfoQuestion'>
                                    <form className='aboutMe'>
                                        <div className='textArea'>
                                            <textarea value={aboutMe} onChange={e => setAboutMe(e.target.value)} name='aboutMe' id='aboutMe' cols='30' rows='10' placeholder='Hello, my name is John and I like to study English with series.' minLength={50} maxLength={500} required />
                                            <span>{aboutMe.length}/500 characters</span>
                                        </div>
                                        <label>This text will be available to all users who have visited your profile in the community, you can modify this in the settings</label>
                                    </form>
                                </div>
                            </div>
                            <div className='containerButtonQustions'>
                                <button disabled={aboutMe.length <= 49} onClick={goToNextPage}>Next</button>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className='swiper-slide allContainerSlide'>
                            <div className='containerContentQuestion'>
                                <h2>Choose your username</h2>
                                <div className='containerInfoQuestion'>
                                    <form className='aboutMe' onSubmit={handleFormSubmit} onKeyPress={handleKeyPress}>
                                        <div className='textArea'>
                                            <input type="text" value={userNick} onChange={e => setUserNick(e.target.value)} name='userName' id='userName' placeholder='Nickname' required minLength={3} maxLength={15} />
                                            <div className='photoUserQustion'>
                                                {user && <UserAvatar userName={displayName} email={email} photoURL={photoURL} size={53} />}
                                            </div>
                                        </div>
                                        <label>This username will be available to all users who visit your profile in the community, you can modify this in the configuration</label>
                                    </form>
                                </div>
                            </div>
                            <div className='containerButtonQustions'>
                                <button disabled={userNick.length <= 2} onClick={end}>Continue</button>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    )
}