import React from 'react';
import { Box, Typography } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';

const DragOverlay = ({ isDragging }) => {
  return isDragging ? (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.85)',
        backdropFilter: 'blur(28.5px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 10, // Certifique-se de que a sobreposição está acima de outros elementos
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Arraste sua imagem aqui
        </Typography>
        <UploadIcon sx={{ fontSize: 50 }} />
      </Box>
    </Box>
  ) : null;
};

export default DragOverlay;
