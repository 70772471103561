import React from 'react';
import styled from '@emotion/styled';
import LockIcon from '@mui/icons-material/Lock';
import starIcon from '../../../../assets/icons/lessonIcons/starIcon.svg';
import { useAllTheme } from '../../../../components/themes/ThemeContext';
import { Link } from 'react-router-dom';

const getColorSchemes = (isDark) => ({
  // completed: {
  //     main: '#FFC800',
  //     shadow: '#CCA000',
  //     icon: '#FFE700',
  //     text: '#CD7900'
  // },
  completed: {
    main: '#B2F9F9',
    shadow: '#88DADE',
    icon: '#9DEDED',
    text: '#57C0C5'
  },
  locked: {
    main: isDark ? '#37464F' : '#E5E5E5', // Cor depende do tema
    shadow: isDark ? '#2C383F' : '#B7B7B7', // Cor depende do tema
    icon: 'transparent',
    text: isDark ? '#52656D' : '#AFAFAF' // Cor depende do tema
  },
  active: {
    main: '#1CB0F6',
    shadow: '#168DC5',
    icon: '#43BEF8',
    text: '#FFFFFF'
  }
});

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: ${props => props.locked ? 'not-allowed' : 'pointer'};
`;

const ButtonCircle = styled.div`
  width: 70px;
  height: 60px;
  border-radius: 50%;
  background-color: ${props => props.colorSchemes[props.status].main};
  box-shadow: 0 8px 0 ${props => props.colorSchemes[props.status].shadow};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease-in-out;
  position: relative;
  z-index: 2;

  &:hover {
    box-shadow: 0 6px 0 ${props => props.colorSchemes[props.status].shadow};
    transform: translateY(2px);
  }

  &:active {
    box-shadow: 0 0px 0 ${props => props.colorSchemes[props.status].shadow};
    transform: translateY(8px);
  }
  &:disabled {
    z-index: 2;
  }
`;

const IconWrapper = styled.div`
  color: ${props => props.colorSchemes[props.status].icon};
  padding-top: 5px;
  transform: rotate(10deg);
  user-select: none;
`;

const CenterIcon = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: ${props => props.colorSchemes[props.status].text};
`;

const LessonButton = ({ completed, locked, number }) => {
  const { isDark } = useAllTheme();
  const colorSchemes = getColorSchemes(isDark);
  const status = completed ? 'completed' : locked ? 'locked' : 'active';

  const getIcon = () => {
    if (completed) return <svg xmlns="http://www.w3.org/2000/svg" width="42" height="34" viewBox="0 0 42 34" fill="none"><g clip-path="url(#clip0_7030_116512)"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.5239 18.112L14.4053 13.9934C13.1459 12.734 11.104 12.734 9.84455 13.9934C8.58514 15.2528 8.58514 17.2947 9.84455 18.5541L16.1331 24.8427C16.7889 25.4985 17.6569 25.8128 18.5161 25.7856C19.3802 25.817 20.2545 25.5028 20.9142 24.8432L32.2521 13.5053C33.5115 12.2459 33.5115 10.204 32.2521 8.94456C30.9927 7.68515 28.9508 7.68515 27.6914 8.94456L18.5239 18.112Z" fill="currentColor" /></g><defs><clipPath id="clip0_7030_116512"><rect width="24.2966" height="17.7878" fill="white" transform="translate(8.89999 8)" /></clipPath></defs></svg>;
    if (locked) return <LockIcon sx={{ fontSize: 40 }} />;
    return <img src={starIcon} style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} />;
  };

  return (
    <Link to='/lessons/exercise' style={{ textDecoration: 'none' }}>
      <ButtonWrapper locked={locked}>
        <ButtonCircle status={status} colorSchemes={colorSchemes}>
          <IconWrapper status={status} colorSchemes={colorSchemes}>
            <svg width="56" height="46" viewBox="0 0 56 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M34.2346 3.25135C35.3157 2.1269 34.7053 0.276787 33.1512 0.143156C32.0512 0.0485729 30.9331 0 29.8002 0C13.342 0 0 10.2517 0 22.8979C0 26.3985 1.02236 29.7157 2.85016 32.6827C3.47761 33.7012 4.88715 33.7751 5.71626 32.9128L34.2346 3.25135Z" fill="currentColor"></path><path d="M55.0954 12.5231C53.3548 9.61289 49.8186 6.8733 47.2219 5.21074C46.2417 4.58319 44.9772 4.77038 44.1616 5.60066C34.5035 15.4328 18.3374 31.8498 12.05 38.0427C10.9724 39.1041 10.996 40.8688 12.249 41.716C16.2271 44.4058 20.9121 45.5851 23.4852 45.9072C24.1853 45.9949 24.8657 45.7259 25.3691 45.2315C34.775 35.9934 50.2041 19.9015 54.7166 15.0879C55.3787 14.3818 55.5923 13.3539 55.0954 12.5231Z" fill="currentColor"></path></svg>
            <CenterIcon status={status} colorSchemes={colorSchemes}>
              {getIcon()}
            </CenterIcon>
          </IconWrapper>
        </ButtonCircle>
      </ButtonWrapper>
    </Link>
  );
};

export default LessonButton;
