import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBell, faChartSimple, faCreditCard, faDisplay, faHome, faInfoCircle, faLock, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons';

import { faChartColumn, faEdit, faMessage, faVideo } from '@fortawesome/free-solid-svg-icons';

import './style.css'

export default function Menu({ currentScreen }) {

    return (
        <div className='editOptions'>
            <header>
                <div className='containerManage'>
                    <Link to={`/`}>
                        <h2>Settings</h2>
                    </Link>
                </div>
            </header>
            <div className='manageAccount'>
                <nav>
                    <div className='containerManageOptions'>
                        <div className='containerManage'>
                            <label>Manage your account</label>
                        </div>
                        <div className='contentManageOptions'>
                            <Link className={currentScreen === "/settings" ? "active" : ""} to="/settings">
                                <div className='containerManage'>
                                    <FontAwesomeIcon icon={faHome} />
                                    <span>Start</span>
                                </div>
                            </Link>
                            <Link className={currentScreen === "/settings/subscription" ? "active" : ""} to="/settings/subscription">
                                <div className='containerManage'>
                                    <FontAwesomeIcon icon={faCreditCard} />
                                    <span>Subscription</span>
                                </div>
                            </Link>
                            <Link className={currentScreen === "/settings/privacy" ? "active" : ""} to="/settings/privacy">
                                <div className='containerManage'>
                                    <FontAwesomeIcon icon={faLock} />
                                    <span>Privacy</span>
                                </div>
                            </Link>
                            <Link className={currentScreen === "/settings/sessions" ? "active" : ""} to="/settings/sessions">
                                <div className='containerManage'>
                                    <FontAwesomeIcon icon={faDisplay} />
                                    <span>Sessions</span>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className='containerManageOptions'>
                        <div className='containerManage'>
                            <label>Preferences</label>
                        </div>
                        <div className='contentManageOptions'>
                            <Link className={currentScreen === "/settings/edit" ? "active" : ""} to="/settings/edit">
                                <div className='containerManage'>
                                    <FontAwesomeIcon icon={faAddressCard} />
                                    <span>Edit Profile</span>
                                </div>
                            </Link>
                            <Link className={currentScreen === "/settings/idiom" ? "active" : ""} to="/settings/idiom">
                                <div className='containerManage'>
                                    <svg aria-label="" className="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title></title><path d="M13.25 5.124h-2.875v-.372a.875.875 0 0 0-1.75 0v.372H5.759a.875.875 0 1 0 0 1.75h.643a8.794 8.794 0 0 0 1.712 2.723 4.84 4.84 0 0 1-1.481.536.875.875 0 0 0 .116 1.742.891.891 0 0 0 .113-.007 6.982 6.982 0 0 0 2.659-1.081 6.99 6.99 0 0 0 2.608 1.08.87.87 0 0 0 .984-.741.878.878 0 0 0-.736-.992 4.846 4.846 0 0 1-1.453-.537 8.57 8.57 0 0 0 1.681-2.723h.645a.875.875 0 0 0 0-1.75Zm-3.73 3.41a6.78 6.78 0 0 1-1.196-1.66h2.37a6.583 6.583 0 0 1-1.175 1.66ZM20 5a1 1 0 0 0 0 2 1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-6a1 1 0 0 0-1 1v1.586l-2.293-2.293a1 1 0 0 0-1.414 1.414l4 4A1 1 0 0 0 15 22v-3h5a3.003 3.003 0 0 0 3-3V8a3.003 3.003 0 0 0-3-3Zm-5 10a3.003 3.003 0 0 0 3-3V4a3.003 3.003 0 0 0-3-3H4a3.003 3.003 0 0 0-3 3v8a3.003 3.003 0 0 0 3 3v3a1 1 0 0 0 1.625.781L10.351 15Zm-5.625-1.781L6 15.919V14a1 1 0 0 0-1-1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-5a1.002 1.002 0 0 0-.625.219Z"></path></svg>
                                    <span>Idiom</span>
                                </div>
                            </Link>
                            <Link className={currentScreen === "/settings/notifications" ? "active" : ""} to="#">
                                <div className='containerManage'>
                                    <FontAwesomeIcon icon={faBell} />
                                    <span>Notifications</span>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className='containerManageOptions'>
                        <div className='containerManage'>
                            <label>How others can interact with you</label>
                        </div>
                        <div className='contentManageOptions'>
                            <Link className={location.pathname === "/community/call" ? "active" : ""} to="#">
                                <div className='containerManage'>
                                    <FontAwesomeIcon icon={faVideo} />
                                    <span>Call You</span>
                                </div>
                            </Link>
                            <Link className={location.pathname === "/community/messages" ? "active" : ""} to="#">
                                <div className='containerManage'>
                                    <FontAwesomeIcon icon={faMessage} />
                                    <span>Send Messages</span>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className='containerManageOptions'>
                        <div className='containerManage'>
                            <label>More information and support</label>
                        </div>
                        <div className='contentManageOptions'>
                            <Link className={location.pathname === "/community/help" ? "active" : ""} to="#">
                                <div className='containerManage'>
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                    <span>Help</span>
                                </div>
                            </Link>
                            <Link className={location.pathname === "/community/account" ? "active" : ""} to="#">
                                <div className='containerManage'>
                                    <FontAwesomeIcon icon={faUserAlt} />
                                    <span>Your account</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}