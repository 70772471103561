import React, { useState, useEffect } from 'react';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faVolumeUp } from '@fortawesome/free-solid-svg-icons';

function useDictionary() {
    const [word, setWord] = useState("");
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [audio, setAudio] = useState(null);
    const [volumeColor, setVolumeColor] = useState("#999");
    const [infoText, setInfoText] = useState("Type any existing word and press enter to get meaning, example, synonyms, etc.");
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (word) {
            setIsActive(true);
            const url = `https://api.dictionaryapi.dev/api/v2/entries/en/${word}`;
            fetch(url).then(response => response.json()).then(result => {
                if (result.title) {
                    setError(`Can't find the meaning of "${word}". Please, try to search for another word.`);
                } else {
                    setResult(result[0]);
                    setAudio(new Audio(result[0].phonetics[0].audio));
                    setInfoText("");
                }
            }).catch(() => {
                setError(`Can't find the meaning of "${word}". Please, try to search for another word.`);
            });
        } else {
            setIsActive(false);
        }
    }, [word]);

    useEffect(() => {
        if (audio) {
            setVolumeColor("#4D59FB");
            audio.play();
            setTimeout(() => {
                setVolumeColor("#999");
            }, 800);
        }
    }, [audio]);

    const onCloseClick = () => {
        setWord("");
        setInfoText("Type any existing word and press enter to get meaning, example, synonyms, etc.");
    };

    return { word, setWord, result, error, volumeColor, infoText, isActive, onCloseClick };
}

function Dictionary() {
    const { word, setWord, result, error, volumeColor, infoText, isActive, onCloseClick } = useDictionary();

    const synonyms = result?.meanings[0].definitions[0].synonyms;
    const displaySynonyms = synonyms && synonyms.length > 0 ? "block" : "none";

    const search = (word) => {
        setWord(word);
    };

    const onKeyUp = (e) => {
        let word = e.target.value.replace(/\s+/g, ' ');
        if (e.key === "Enter" && word) {
            search(word);
        }
    };

    const activeClass = isActive ? 'active' : '';

    return (
        <div className={`wrapper ${activeClass}`}>
            <header>English Dictionary</header>
            <div className="search">
                <input type="text" placeholder="Search a word" required spellCheck="false" onKeyUp={onKeyUp} />
                {/* <i className="fas fa-search"></i> */}
                <FontAwesomeIcon icon={faSearch} />
                <span className="material-icons" onClick={onCloseClick}>close</span>
            </div>
            <p className="info-text">{error || infoText}</p>
            <ul>
                <li className="word">
                    <div className="details">
                        <p>{result ? result.word : "__"}</p>
                        <span>{result ? `${result.meanings[0].partOfSpeech} /${result.phonetics[0].text}/` : "_ _"}</span>
                    </div>
                    {/* <i className="fas fa-volume-up" style={{ color: volumeColor }}></i> */}
                    <FontAwesomeIcon icon={faVolumeUp} style={{ color: volumeColor, cursor: "pointer"  }} />
                </li>
                <div className="content">
                    <li className="meaning">
                        <div className="details">
                            <p>Meaning</p>
                            <span>{result?.meanings[0].definitions[0].definition || "___"}</span>
                        </div>
                    </li>
                    <li className="example">
                        <div className="details">
                            <p>Example</p>
                            <span>{result?.meanings[0].definitions[0].example || "___"}</span>
                        </div>
                    </li>
                    <li className="synonyms" style={{ display: displaySynonyms }}>
                        <div className="details">
                            <p>Synonyms</p>
                            <div className="list">
                                {synonyms?.slice(0, 5).map((synonym, i) => (
                                    <span key={i} onClick={() => search(synonym)}>
                                        {synonym}{i < 4 ? ',' : ''}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </li>
                </div>
            </ul>
        </div>
    );
}

export default Dictionary;