import { Box, Typography } from '@mui/material';
import React from 'react';
import ItemList from './CardContainer';

import erros from '../../assets/icons/homeScreen/erros.svg';
import words from '../../assets/icons/homeScreen/words.svg';
import story from '../../assets/icons/homeScreen/story.svg';
import Popup from '../../components/Popup';

import superIcon from '../../assets/icons/super.svg';
import { useAllTheme } from '../../components/themes/ThemeContext';

export default function Collections() {
    const [open, setOpen] = React.useState(false);
    const [popupType, setPopupType] = React.useState('');
    const [popupTitle, setPopupTitle] = React.useState('');

    const { theme, isDark } = useAllTheme();

    const handleOpen = (type, title) => {
        setPopupType(type);
        setPopupTitle(title);
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const items = [
        {
            title: "Erros",
            description: "Faça uma lição personalizada e revise os seus erros.",
            icon: <img src={erros} />,
            type: 'errors',
            titlePopup: 'Revise os seus erros recentes'
        },
        {
            title: "Palavras",
            description: "Revise o seu vocabulário de inglês quando quiser.",
            icon: <img src={words} />,
            type: 'words',
            titlePopup: 'Pratique seu vocabulário em inglês'
        },
        {
            title: "Histórias",
            description: "Releia uma história pra revisar palavras dentro de um contexto.",
            icon: <img src={story} />,
            type: 'story',
            titlePopup: 'Aprenda com situações'
        }
    ];

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h5" component="div" sx={{ fontFamily: 'Din-Round-Bold, sans-serif', color: theme.color, marginBottom: '20px' }}>
                Suas Coleções
            </Typography>
            <ItemList items={items} handleOpen={handleOpen} theme={theme} />

            <Popup
                open={open}
                onClose={handleClose}
                type={popupType}
                icon={<img src={superIcon} />}
                title={popupTitle}
                buttonText="Começar"
                buttonAction={handleClose}
            />
        </Box>
    );
}
