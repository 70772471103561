import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { OptionButton } from '../../StyledComponents';
import { useAllTheme } from '../../../../components/themes/ThemeContext';

export default function SelectRealEnglishWord({ exercicio, setCheckFunction, setProgress, setControls, controls }) {
    const respostasSelecionadasRef = useRef([]);

    useEffect(() => {
        setCheckFunction(() => check);
        // console.log('setCheckFunction configurada');
    }, [setCheckFunction]);

    useEffect(() => {
        if (!controls.isChecked) {
            respostasSelecionadasRef.current = [];
            // console.log("Estado resetado");
        }
    }, [controls.isChecked]);

    const toggleResposta = (opcao) => {
        if (respostasSelecionadasRef.current.includes(opcao)) {
            respostasSelecionadasRef.current = respostasSelecionadasRef.current.filter(r => r !== opcao);
        } else {
            respostasSelecionadasRef.current.push(opcao);
        }
        setControls((prev) => ({ ...prev, isDisabled: false }));
    };

    const check = () => {
        if (JSON.stringify(respostasSelecionadasRef.current.sort()) === JSON.stringify(exercicio.data.correct_words.sort())) {
            setProgress({
                type: 'correct',
                explanation: 'Congratulations! You got the answer right.',
                userResponse: respostasSelecionadasRef.current.sort().join(", ")
            });
        } else {
            setProgress({
                type: 'incorrect',
                explanation: `The correct answers are "${exercicio.data.correct_words.join(", ")}".`
            });
        }
        setControls((prev) => ({ ...prev, isChecked: true }));
    };

    const { theme, isDark } = useAllTheme();

    return (
        <Box>
            <Typography variant="h4" textAlign="center" sx={{ mb: 2, fontFamily: "SF-Pro-Display, sans-serif", color: theme.color }}>
                Select only the real English word
            </Typography>
            {exercicio.data.word_choices.map((opcao, index) => (
                <OptionButton
                    theme={theme}
                    key={index}
                    onClick={() => toggleResposta(opcao)}
                    disabled={controls.isChecked}
                    className={respostasSelecionadasRef.current.includes(opcao) ? 'optionSelected' : ''}
                >
                    <Box className="buttonOptionListeningNumber">
                        {index + 1}
                    </Box>
                    <Typography variant="body1" textAlign="center" sx={{ width: 'calc(100% - 38px)' }}>{opcao}</Typography>
                </OptionButton>
            ))}
        </Box>
    );
}
