import { Box, Typography, Card, CardContent, Button } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import { useAllTheme } from "../../components/themes/ThemeContext";

const CardContainer = styled(Button)(({ theme, primaryColor, secondColor }) => ({
    borderRadius: '16px',
    color: '#FFFFFF',
    padding: 0,
    backgroundColor: primaryColor,
    position: 'relative',
    overflow: 'hidden',
    minWidth: '300px',
    // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    flex: '1',
    transition: 'all 0.1s ease-in-out',
    boxShadow: `0px 4px 0px ${secondColor}`,
    border: `2px solid ${primaryColor}`,
    '&:hover': {
        backgroundColor: primaryColor,
    },
    '&:active': {
        boxShadow: `0 0px 0 ${secondColor}`,
        transform: 'translateY(4px)'
    }
}));

const games = [
    {
        to: '/games/type',
        title: "O labirinto das frases",
        description: "Complete as sentenças e salve o professor.",
        image: <img src="/path/to/image1.png" alt="Game 1" style={{ width: '100%', height: 'auto' }} />, // Substitua pelo caminho real da imagem
        primaryColor: '#00CD9C',
        secondColor: '#00A47D'
    },
    {
        to: '/games/balloon',
        title: "Palavras fora dos trilhos",
        description: "Soletre a palavra antes que o trem saia do trilho.",
        image: <img src="/path/to/image2.png" alt="Game 2" style={{ width: '100%', height: 'auto' }} />, // Substitua pelo caminho real da imagem
        primaryColor: '#46C4FF',
        secondColor: '#0190CD'
    }
];

const GameCard = ({ to, title, description, image, primaryColor, secondColor }) => {

    return (
        <Link to={to} style={{ textDecoration: 'none' }}>
            <CardContainer primaryColor={primaryColor} secondColor={secondColor}>
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '16px',
                        marginRight: 0,
                        '&:last-child': {
                            paddingBottom: '16px',
                        },
                    }}
                >
                    <Box>
                        <Typography
                            variant="h6"
                            component="div"
                            gutterBottom
                            sx={{ fontFamily: 'Din-Round-Bold, sans-serif', textAlign: 'left', textTransform: 'initial', fontSize: '16px' }}
                        >
                            {title}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{ fontFamily: 'SF-Pro-Text-Regular, sans-serif', textAlign: 'left', textTransform: 'initial', fontSize: '14px' }}
                        >
                            {description}
                        </Typography>
                    </Box>
                    <Box sx={{ marginLeft: '16px' }}>
                        {image}
                    </Box>
                </CardContent>
            </CardContainer>
        </Link>
    );
};

const GamesComponent = () => {

    const { theme, isDark } = useAllTheme();

    return (
        <Box>
            <Typography variant="h5" component="div" sx={{ fontFamily: 'Din-Round-Bold, sans-serif', color: theme.color, marginBottom: '20px' }}>
                Jogos
            </Typography>
            <Box sx={{ display: 'flex', gap: '16px' }}>
                {games.map((game, index) => (
                    <GameCard
                        key={index}
                        to={game.to}
                        title={game.title}
                        description={game.description}
                        image={game.image}
                        primaryColor={game.primaryColor}
                        secondColor={game.secondColor}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default GamesComponent;
