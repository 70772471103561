import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import CenterContent from "../CenterContent";
import './style.css'

export default function Utils({ addBoxCenter, boxesCenter, setBoxesCenter }) {

    return (
        <>
            {boxesCenter.length > 0 && (
                <div className="utils">
                    <CenterContent boxes={boxesCenter} setBoxes={setBoxesCenter} />
                </div>
            )}
        </>
    )
}