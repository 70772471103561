import React from 'react';
import { Box, IconButton, MenuList, MenuItem, Popover, Typography } from '@mui/material';
import Bold from '@mui/icons-material/FormatBold';
import Italic from '@mui/icons-material/FormatItalic';
import Underline from '@mui/icons-material/FormatUnderlined';
import Strikethrough from '@mui/icons-material/FormatStrikethrough';
import CheckIcon from '@mui/icons-material/Check';

export default function EditMenu({ anchorEl, handleTextFormatClose, handleFormatSelect, selectedInlineFormats, selectedBlockFormat, theme }) {
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClickInlineFormat = (format) => {
        handleFormatSelect(format, 'inline');
    };

    const handleClickBlockFormat = (format) => {
        handleFormatSelect(format, 'block');
    };

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleTextFormatClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '10px',
                    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
                    padding: '10px',
                    minWidth: '250px',
                    backgroundColor: theme.background
                },
            }}
        >
            <MenuList>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px', paddingBottom: '8px', borderBottom: `1px solid ${theme.border}` }}>
                    <IconButton
                        onClick={() => handleClickInlineFormat('bold')}
                        style={{ borderRadius: '10px', backgroundColor: selectedInlineFormats.includes('bold') ? '#007aff' : 'transparent' }}
                    >
                        <Bold fontSize="large" style={{ color: theme.color }} />
                    </IconButton>
                    <IconButton
                        onClick={() => handleClickInlineFormat('italic')}
                        style={{ borderRadius: '10px', backgroundColor: selectedInlineFormats.includes('italic') ? '#007aff' : 'transparent' }}
                    >
                        <Italic fontSize="large" style={{ color: theme.color }} />
                    </IconButton>
                    <IconButton
                        onClick={() => handleClickInlineFormat('underline')}
                        style={{ borderRadius: '10px', backgroundColor: selectedInlineFormats.includes('underline') ? '#007aff' : 'transparent' }}
                    >
                        <Underline fontSize="large" style={{ color: theme.color }} />
                    </IconButton>
                    <IconButton
                        onClick={() => handleClickInlineFormat('strikethrough')}
                        style={{ borderRadius: '10px', backgroundColor: selectedInlineFormats.includes('strikethrough') ? '#007aff' : 'transparent' }}
                    >
                        <Strikethrough fontSize="large" style={{ color: theme.color }} />
                    </IconButton>
                </Box>
                <MenuItem style={{ borderRadius: '10px' }} onClick={() => handleClickBlockFormat('title')}>
                    {selectedBlockFormat === 'title' && <CheckIcon style={{ marginRight: '8px', color: '#007aff' }} />}
                    <Typography variant="body2" sx={{ fontWeight: '700', fontSize: '28px', fontFamily: 'SF-Pro-Text-Display, sans-serif', color: theme.color }}>Title</Typography>
                </MenuItem>
                <MenuItem style={{ borderRadius: '10px' }} onClick={() => handleClickBlockFormat('heading')}>
                    {selectedBlockFormat === 'heading' && <CheckIcon style={{ marginRight: '8px', color: '#007aff' }} />}
                    <Typography variant="body2" sx={{ fontWeight: '700', fontSize: '22px', fontFamily: 'SF-Pro-Text-Display, sans-serif', color: theme.color }}>Heading</Typography>
                </MenuItem>
                <MenuItem style={{ borderRadius: '10px' }} onClick={() => handleClickBlockFormat('subheading')}>
                    {selectedBlockFormat === 'subheading' && <CheckIcon style={{ marginRight: '8px', color: '#007aff' }} />}
                    <Typography variant="body2" sx={{ fontWeight: '700', fontSize: '17px', fontFamily: 'SF-Pro-Text-Display, sans-serif', color: theme.color }}>Subheading</Typography>
                </MenuItem>
                <MenuItem style={{ borderRadius: '10px' }} onClick={() => handleClickBlockFormat('body')}>
                    {selectedBlockFormat === 'body' || selectedBlockFormat === 'paragraph' && <CheckIcon style={{ marginRight: '8px', color: '#007aff' }} />}
                    <Typography variant="body2" sx={{ color: theme.color }}>Body</Typography>
                </MenuItem>
                <MenuItem style={{ borderRadius: '10px' }} onClick={() => handleClickBlockFormat('monospaced')}>
                    {selectedBlockFormat === 'monospaced' && <CheckIcon style={{ marginRight: '8px', color: '#007aff' }} />}
                    <Typography variant="body2" sx={{ fontFamily: 'monospace', color: theme.color }}>Monospaced</Typography>
                </MenuItem>
                <MenuItem style={{ borderRadius: '10px' }} onClick={() => handleClickBlockFormat('bulleted')}>
                    {selectedBlockFormat === 'bulleted' && <CheckIcon style={{ marginRight: '8px', color: '#007aff' }} />}
                    <Typography variant="body2" sx={{ color: theme.color }}>• Bulleted List</Typography>
                </MenuItem>
                <MenuItem style={{ borderRadius: '10px' }} onClick={() => handleClickBlockFormat('dashed')}>
                    {selectedBlockFormat === 'dashed' && <CheckIcon style={{ marginRight: '8px', color: '#007aff' }} />}
                    <Typography variant="body2" sx={{ color: theme.color }}>- Dashed List</Typography>
                </MenuItem>
                <MenuItem style={{ borderRadius: '10px' }} onClick={() => handleClickBlockFormat('numbered')}>
                    {selectedBlockFormat === 'numbered' && <CheckIcon style={{ marginRight: '8px', color: '#007aff' }} />}
                    <Typography variant="body2"sx={{ color: theme.color }}>1. Numbered List</Typography>
                </MenuItem>
            </MenuList>
        </Popover>
    );
}

// import 'draft-js/dist/Draft.css';