export const themes = {
  light: {
    background: '#FFFFFF',
    border: 'rgb(229, 229, 229)',
    color: "#1D1D1F",

    borderMenu: 'rgb(132, 216, 255)',
    backgroundMenu: 'rgb(221, 244, 255)',
    colorMenuSelected: '#1CB0F6',
    colorMenu: '#777777',

    secondMenu: '#F9F9F9',
    secondMenuButtonSelected: '#007aff',
    secondMenuButtonSelectedHover: '#005bb5',
    seconMenuButtonHover: '#F1F1F1',

    backgroundInput: '#F7F7F7',
    borderInput: '#AFAFAF',
    colorInput: '#2B2B2B'
  },
  dark: {
    background: 'rgb(19, 31, 36)',
    border: 'rgb(55, 70, 79)',
    color: "#FFFFFF",

    borderMenu: 'rgb(63, 133, 167)',
    backgroundMenu: 'rgb(32, 47, 54)',
    colorMenuSelected: 'rgb(73, 192, 248)',
    colorMenu: 'rgb(220, 230, 236)',

    secondMenu: '#111C20',
    secondMenuButtonSelected: '#007aff',
    secondMenuButtonSelectedHover: '#005bb5',
    seconMenuButtonHover: '#080d0f',

    backgroundInput: '#202F36',
    borderInput: 'rgb(55, 70, 79)',
    colorInput: 'rgb(241, 247, 251)'
  },
};
