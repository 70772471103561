export const sendLexiScenariosRequest = async (messages, system) => {
    try {
        // const response = await fetch('https://talk2meia.uc.r.appspot.com/chat', {
        const response = await fetch('https://talk2meia.uc.r.appspot.com/scenarios', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ messages, system })
        });
        return await response.json();
    } catch (error) {
        console.error("Failed to send request:", error);
        return { error: error.message };
    }
};
