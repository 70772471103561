import React, { useEffect } from 'react';
import { Container, Box, Button } from '@mui/material';

const ActionButtonsContainer = ({ enableSkip, onSkip, onCheck, isCheckEnabled, theme }) => {

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && isCheckEnabled && !['INPUT', 'TEXTAREA', 'SELECT', 'CONTENTEDITABLE'].includes(event.target.tagName.toUpperCase()) && !(event.target.getAttribute("contenteditable") === "true")) {
                onCheck();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isCheckEnabled, onCheck]);

    return (
        <Box sx={{ width: '100%', borderTop: `1px solid ${theme.border}` }}>
            <Container sx={{ display: 'flex', justifyContent: 'space-between', p: 2, maxWidth: '1100px', bgcolor: theme.background }}>
                {enableSkip && (
                    <Button
                        onClick={onSkip}
                        sx={{
                            color: theme.color,
                            border: `1px solid ${theme.border}`,
                            borderRadius: '12px',
                            boxShadow: `0 2px 0 ${theme.border}`,
                            p: '10px 30px',
                            fontWeight: 'bold'
                        }}
                    >
                        PULAR
                    </Button>
                )}
                <Button
                    onClick={onCheck}
                    disabled={!isCheckEnabled}
                    sx={{
                        color: '#FFFFFF',
                        borderRadius: '12px',
                        boxShadow: isCheckEnabled ? '0 4px 0 #1A73E8' : 'none',
                        bgcolor: isCheckEnabled ? '#3F8CE8' : '#E0E0E0',
                        p: '10px 30px',
                        fontWeight: 'bold',
                        transition: 'all 0.1s ease-in-out',
                        '&:hover': {
                            bgcolor: isCheckEnabled ? '#3F8CE8' : '#E0E0E0',
                        },
                        '&:active': {
                            boxShadow: isCheckEnabled ? '0 0px 0 #1A73E8' : 'none',
                            transform: isCheckEnabled ? 'translateY(4px)' : 'none'
                        }
                    }}
                >
                    VERIFICAR
                </Button>
            </Container>
        </Box>
    );
};

export default ActionButtonsContainer;
