import React, { createContext, useState, useContext } from 'react';

const NotesContext = createContext();

const initialValue = [
    {
        type: 'title',
        children: [{ text: '' }],
    },
];

export const NotesProvider = ({ children }) => {
    const [value, setValue] = useState(initialValue);
    const [noteId, setNoteId] = useState(null);

    return (
        <NotesContext.Provider value={{ value, setValue, noteId, setNoteId }}>
            {children}
        </NotesContext.Provider>
    );
};

export const useNotes = () => {
    return useContext(NotesContext);
};
