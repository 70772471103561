import axios from 'axios';

export const startSession = async (userId) => {
    try {
        const response = await axios.post('https://talk2meia.uc.r.appspot.com/startSession', { userId });

        if (response.status === 200) {
            return response.data;
        } else if (response.status === 403) {
            throw new Error(response.data.message);
        }
    } catch (error) {
        throw error;
    }
};
