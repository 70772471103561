import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { styled } from '@mui/system';
import { sendTextToSpeechRequest } from '../../componentsTalkIA/IAtalk2ME/Talk2MeVoice';
import { useAllTheme } from '../themes/ThemeContext';

const StyledButton = styled(Button)({
    color: '#FFFFFF',
    borderRadius: '12px',
    boxShadow: '0 4px 0 #1A73E8',
    backgroundColor: '#3F8CE8',
    p: '10px 30px',
    fontWeight: 'bold',
    transition: 'all 0.1s ease-in-out',
    minWidth: '400px',
    '&:hover': {
        backgroundColor: '#3F8CE8',
    },
    '&:active': {
        boxShadow: '0 0px 0 #1A73E8',
        transform: 'translateY(4px)'
    }
});

const StoryButton = styled(Button)(({ theme }) => ({
    textAlign: 'center',
    margin: '10px',
    flexDirection: 'column',
    transition: 'all 0.1s ease-in-out',
    boxShadow: `0px 2px 0px ${theme.border}`,
    border: `2px solid ${theme.border}`,
    marginBottom: '8px',
    borderRadius: '16px',
    color: theme.color,
    width: '100%',
    '&:active': {
        boxShadow: `0 0px 0 ${theme.border}`,
        transform: 'translateY(2px)'
    }
}));

const errors = [
    { text: 'Toque no que escutar:', phrase: 'Sorry, your ticket isn\'t for this floor.' },
    { text: 'Traduza esta frase:', phrase: 'How much money do you need?' },
    { text: 'Traduza esta frase:', phrase: 'his phone number' }
    // Adicione mais erros conforme necessário
];

const words = [
    { words: 'care', translations: 'importamos, importo, se importa' },
    { words: 'last name', translations: 'sobrenome' },
    { words: 'first name', translations: 'primeiro nome' }
    // Adicione mais erros conforme necessário
];

const stories = [
    { icon: '🍪☕', title: 'Bom dia' },
    { icon: '🕯️', title: 'Um encontro' },
    { icon: '🍞🔪', title: 'Uma coisa' },
    { icon: '👨‍👩‍👧', title: 'Família grande' },
    { icon: '🧥', title: 'A jaqueta vermelha' },
    { icon: '🛂', title: 'O passaporte' }
];

const Popup = ({ open, onClose, type, icon, title, buttonText }) => {

    const [audioUrl, setAudioUrl] = useState('');
    const audioRef = useRef(null);

    const speak = async (word) => {
        const url = await sendTextToSpeechRequest(word, "Danielle");
        setAudioUrl(url);
    };

    useEffect(() => {
        if (audioUrl && audioRef.current) {
            audioRef.current.play();
        }
    }, [audioUrl]);

    const { theme, isDark } = useAllTheme();

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" PaperProps={{ style: { minWidth: '600px', borderRadius: '16px', backgroundColor: theme.background  } }}>
            {audioUrl && (
                <audio
                    src={audioUrl}
                    ref={audioRef}
                    style={{ display: 'none' }}
                />
            )}
            <DialogContent dividers sx={{ borderTop: 'none', borderBottom: `2px solid ${theme.border}`, }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick={onClose}>
                        <CloseIcon sx={{ color: theme.color }} />
                    </IconButton>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ marginBottom: '16px' }}>
                        {icon}
                    </div>
                    <div style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '20px', fontFamily: 'Din-Round-Bold, sans-serif', color: theme.color }}>
                        {title}
                    </div>
                    {type !== 'story' && (
                        <StyledButton theme={theme} onClick={onClose}>
                            {buttonText}
                        </StyledButton>
                    )}
                </div>
            </DialogContent>
            {type === 'errors' && (
                <DialogContent style={{ justifyContent: 'center', overflowY: 'auto', maxHeight: '40vh' }}>
                    <Typography variant="h6" gutterBottom sx={{ color: theme.color }}>{errors.length} erros</Typography>
                    <Box sx={{ border: `2px solid ${theme.border}`, borderRadius: '10px' }}>
                        {errors.map((error, index) => (
                            <div key={index} style={{ padding: '15px 10px', borderBottom: `1px solid ${theme.border}` }}>
                                <Typography variant="body2" sx={{ color: theme.color }}>{error.text}</Typography>
                                <Typography variant="body1" style={{ fontWeight: 'bold', color: theme.color }}>{error.phrase}</Typography>
                            </div>
                        ))}
                    </Box>
                </DialogContent>
            )}

            {type === 'words' && (
                <DialogContent style={{ justifyContent: 'center', overflowY: 'auto', maxHeight: '40vh' }}>
                    <Typography variant="h6" gutterBottom sx={{ color: theme.color }}>{errors.length} palavras</Typography>
                    <Box sx={{ border: `2px solid ${theme.border}`, borderRadius: '10px' }}>
                        {words.map((error, index) => (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', padding: '15px 10px', borderBottom: `1px solid ${theme.border}` }}>
                                <IconButton onClick={() => speak(error.words)}>
                                    <VolumeUpIcon sx={{ color: '#3F8CE8', fontSize: '30px' }} />
                                </IconButton>
                                <div key={index}>
                                    <Typography variant="body1" style={{ fontWeight: 'bold', color: theme.color }}>{error.words}</Typography>
                                    <Typography variant="body2" sx={{ color: theme.color }}>{error.translations}</Typography>
                                </div>
                            </Box>
                        ))}
                    </Box>
                </DialogContent>
            )}


            {type === 'story' && (
                <DialogContent style={{ justifyContent: 'center', overflowY: 'auto', maxHeight: '40vh' }}>
                    <Typography variant="h6" gutterBottom textAlign="center"sx={{ color: theme.color }} >Sua biblioteca</Typography>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                        {stories.map((story, index) => (
                            <StoryButton theme={theme} key={index}>
                                <div style={{ fontSize: '3rem' }}>{story.icon}</div>
                                <Typography variant="body1" sx={{ fontFamily: 'Din-Round-Bold, sans-serif', color: theme.color }}>{story.title}</Typography>
                            </StoryButton>
                        ))}
                    </div>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default Popup;
