import React, { useContext } from 'react';
import { Box, Collapse, Typography, Zoom } from '@mui/material';
import { UserContext } from '../../components/UserProvider';

import logo from '../../assets/images/logo.png'
import UserAvatar from '../../components/UserAvatar';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import LinkifyIt from 'linkify-it';

import './style.css'

export default function Subtitle({ responseCaption, userResponseCaption, subtitlesOn }) {

    const { photoURL, displayName, email } = useContext(UserContext);

    const linkify = LinkifyIt();

    const splitParagraphs = (text) => {
        return text.split('\n').filter((paragraph) => paragraph.trim() !== '');
    };

    return (
        <Collapse in={subtitlesOn}
            className="scrollOverflowCenterBox"
            sx={{
                maxHeight: '250px',
                overflowY: 'auto',
            }}>
            <Box
                sx={{
                    width: '100%',
                    borderRadius: '10px',
                    transition: 'all 0.3s ease',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: '10px 30px',
                    gap: '8px'
                }}
            >
                {responseCaption && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <img src={logo} alt="Logo" style={{ width: '32px', height: '32px' }} />
                        <Box >
                            {splitParagraphs(responseCaption).map((paragraph, paragraphIndex) => {
                                const matches = linkify.match(paragraph);
                                if (matches) {
                                    matches.forEach((match) => {
                                        const url = match.url.startsWith("http") ? match.url : "http://" + match.url;
                                        paragraph = paragraph.replace(match.raw, `[${match.raw}](${url})`);
                                    });
                                }
                                return <ReactMarkdown key={paragraphIndex} className="markdown-subtitle" rehypePlugins={[rehypeRaw]}>{paragraph}</ReactMarkdown>
                            })}
                        </Box>
                    </Box>
                )}
                {userResponseCaption && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <UserAvatar userName={displayName} email={email} photoURL={photoURL} size={32} />
                        <Box>
                            {splitParagraphs(userResponseCaption).map((paragraph, paragraphIndex) => {
                                const matches = linkify.match(paragraph);
                                if (matches) {
                                    matches.forEach((match) => {
                                        const url = match.url.startsWith("http") ? match.url : "http://" + match.url;
                                        paragraph = paragraph.replace(match.raw, `[${match.raw}](${url})`);
                                    });
                                }
                                return <ReactMarkdown key={paragraphIndex} className="markdown-subtitle" rehypePlugins={[rehypeRaw]}>{paragraph}</ReactMarkdown>
                            })}
                        </Box>
                    </Box>
                )}
            </Box>
        </Collapse>
    )
}
