import React, { useRef, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Feed from './social';
import PostDetails from './postDetails';
import UsersCommunity from './users';
import './style.css'
import './style1.css'
import Chat from './chat';
import ProfileUsersCommunity from './profile';
import { useStatus } from './componentsCommunity/PostingContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../components/UserProvider';
import Activity from './activity';
import Loading from '../components/loading';

function MainContainer() {
    
    const location = useLocation();

    const { user, photoCommunity, newNameCommunity } = useContext(UserContext)

    const isProfilePath = () => {
        const path = location.pathname.split('/').filter(Boolean); // Remove quaisquer strings vazias de splits no início ou no final
        // Verifica se o caminho tem exatamente 2 partes depois de 'community' e não é uma das palavras-chave reservadas
        return path.length === 2 &&
            path[0] === 'community' &&
            !['feed', 'users', 'chat', 'post', 'activity'].includes(path[1]);
    }

    const { statusPosting } = useStatus();

    return (
        <div className="mainContainer">
            <div className={location.pathname == ('/community') ? 'visible' : 'hidden'}>
                <UsersCommunity />
            </div>
            <div className={location.pathname.startsWith('/community/feed') ? 'visible' : 'hidden'}>
                <Feed />
            </div>
            <div className={location.pathname == ('/community/activity') ? 'visible' : 'hidden'}>
                <Activity />
            </div>
            <div className={location.pathname.includes('/community/') && location.pathname.includes('/post/') ? 'visible' : 'hidden'}>
                <PostDetails />
            </div>
            <div className={location.pathname.includes('chat') ? 'visible' : 'hidden'}>
                <Chat />
            </div>
            <div className={isProfilePath() ? 'visible' : 'hidden'}>
                <ProfileUsersCommunity />
            </div>

            <aside className={`infoBanner ${statusPosting.value && 'active'}`} style={{ width: (statusPosting.value == 'Editing...' || statusPosting.value == 'Posting...' || statusPosting.value == 'Successful') ? 300 : 'auto' }}>
                <div className='contentBanner'>
                    {statusPosting.value && statusPosting.value == 'Successful' && (
                        <FontAwesomeIcon icon={faCheck} />
                    )}
                    {statusPosting.value && statusPosting.value == 'Fail' && (
                        <FontAwesomeIcon icon={faX} />
                    )}
                    {statusPosting.value && (statusPosting.value == 'Editing...' || statusPosting.value == 'Posting...') && (
                        <Loading size={19} color={'#AAAAAA'} />
                    )}
                    <p>{statusPosting.value}</p>
                </div>
                {statusPosting.url && (    
                    <Link to={`/community/@${newNameCommunity.toLowerCase().replace(/\s+/g, '')}}/post/${statusPosting.url}`}>View</Link>
                )}
            </aside>

        </div>
    );
}

export default MainContainer;
