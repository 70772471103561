import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, List, ListItem, ListItemText, Collapse, Typography } from '@mui/material';
import './style.css';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useNotes } from '../../context/NotesContext';
import { db } from '../../../auth/config/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

import { format, isToday, isYesterday, subDays, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Timestamp } from 'firebase/firestore';

const SideMenu = ({ menuOpen, userId, notes, setNotes, theme, isDark }) => {
    const { setValue, noteId, setNoteId } = useNotes();

    const [searchText, setSearchText] = useState('');
    const [debouncedSearchText, setDebouncedSearchText] = useState('');
    const debounceTimeout = useRef(null);
    const [filteredNotes, setFilteredNotes] = useState(notes);

    useEffect(() => {
        const fetchNotes = async () => {
            try {
                const q = query(collection(db, "notes"), where("userId", "==", userId));
                const querySnapshot = await getDocs(q);
                const userNotes = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        ...data,
                        updatedAt: data.updatedAt && data.updatedAt.toDate ? data.updatedAt.toDate() : null,
                        createdAt: data.createdAt && data.createdAt.toDate ? data.createdAt.toDate() : null
                    };
                }).sort((a, b) => b.updatedAt - a.updatedAt || b.createdAt - a.createdAt);

                setNotes(userNotes);
            } catch (error) {
                // console.error("Error fetching notes: ", error);
            }
        };

        fetchNotes();
    }, [userId]);


    useEffect(() => {
        const filterNotes = () => {
            if (debouncedSearchText.trim()) {
                const searchText = debouncedSearchText.trim().toLowerCase();
                const filteredNotes = notes.filter(note =>
                    note.title.toLowerCase().includes(searchText) ||
                    note.content.toLowerCase().includes(searchText) ||
                    note.value.some(block => block.children.some(child => child.text.toLowerCase().includes(searchText)))
                );
                setFilteredNotes(filteredNotes);
            } else {
                setFilteredNotes(notes);
            }
        };

        filterNotes();
    }, [debouncedSearchText, notes]);

    const formatDate = (date) => {
        if (!date) return ''; // Verifica se a data é nula ou indefinida

        let d;
        if (date instanceof Timestamp) {
            d = date.toDate();
        } else if (typeof date === 'string') {
            d = parseISO(date);
        } else if (date instanceof Date) {
            d = date;
        } else if (typeof date === 'number') {
            d = new Date(date); // Lida com Date.now()
        } else {
            return ''; // Retorna vazio se o formato da data não for reconhecido
        }

        if (isNaN(d.getTime())) return ''; // Verifica se a data não é válida

        if (isToday(d)) {
            return format(d, 'HH:mm', { locale: ptBR });
        } else if (isYesterday(d)) {
            return 'Yesterday';
        } else {
            return format(d, 'dd/MM/yyyy', { locale: ptBR });
        }
    };

    useEffect(() => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            setDebouncedSearchText(searchText);
        }, 500);

        return () => {
            clearTimeout(debounceTimeout.current);
        };
    }, [searchText]);

    const highlightTextWithContext = (text, query, contextLength = 20) => {
        if (!query) return text;
        const searchText = query.trim().toLowerCase();
        // console.log(`searchText: ${searchText}`);

        // Escapar caracteres especiais no regex
        const escapedQuery = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(`(${escapedQuery})`, 'gi');
        const matches = [...text.toLowerCase().matchAll(regex)];

        if (matches.length === 0) {
            // console.log('No matches found');
            return text;
        }

        // console.log(`Matches found: ${matches.length}`);

        const firstMatchIndex = matches[0].index;
        const start = Math.max(firstMatchIndex - contextLength, 0);
        const snippetStart = Math.max(firstMatchIndex - 2, 0);
        const snippet = text.substring(snippetStart).split(regex);

        // console.log(`Snippet: ${snippet}`);

        const before = start > 0 ? '...' : '';

        return (
            <span>
                {before}
                {snippet.map((part, index) =>
                    part.toLowerCase() === searchText ? (
                        <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span>
                    ) : (
                        part
                    )
                )}
            </span>
        );

    };

    return (
        <Collapse in={menuOpen} timeout={300} unmountOnExit orientation="horizontal">
            <Box sx={{ width: 300, height: '100%', backgroundColor: theme.secondMenu, boxShadow: 1, borderRight: `1px solid ${theme.border}`, paddingTop: '16px', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ paddingRight: '20px', paddingLeft: '20px' }}>
                    <TextField
                        placeholder="Search all notes"
                        fullWidth
                        variant="outlined"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: theme.color, }} />
                                </InputAdornment>
                            ),
                            sx: {
                                padding: '8px',
                                color: theme.color,
                            },
                        }}
                        sx={{
                            marginBottom: '8px',
                            borderRadius: '10px',
                            color: theme.color,
                            fontFamily: 'SF-Pro-Text-Regular, sans-serif',
                            '& .MuiOutlinedInput-root': {
                                padding: '0px',
                                paddingLeft: '14px',
                            },
                            '& .MuiInputBase-input': {
                                padding: '8px',
                            },
                            '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                            backgroundColor: theme.border,
                        }}
                    />
                </Box>
                {filteredNotes.length > 0 ? (
                    <Box className="scrollStyle" sx={{ overflowY: 'auto', flexGrow: 1, paddingLeft: '10px', paddingRight: '10px' }}>
                        <List>
                            {filteredNotes.map((note, index) => (
                                <ListItem
                                    button
                                    key={index}
                                    sx={{
                                        borderRadius: '10px',
                                        marginBottom: '8px',
                                        animation: 'scaleBlur 0.3s ease-out',
                                        backgroundColor: note.id === noteId ? theme.secondMenuButtonSelected : 'transparent',
                                        '&:hover': {
                                            backgroundColor: note.id === noteId ? theme.secondMenuButtonSelectedHover : theme.seconMenuButtonHover,
                                        },
                                        '& .MuiListItemText-primary': {
                                            color: note.id === noteId ? '#ffffff' : theme.color
                                        },
                                        '& .MuiListItemText-secondary': {
                                            color: note.id === noteId ? '#ffffff' : theme.color
                                        }
                                    }}
                                    onClick={() => [setNoteId(note.id), setValue(note.value)]}
                                >
                                    <ListItemText
                                        primary={<span style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontFamily: 'SF-Pro-Display, sans-serif' }}>{note.title || 'New Note'}</span>}
                                        secondary={
                                            <span style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontFamily: 'SF-Pro-Text, sans-serif' }}>
                                                <strong>{formatDate(note?.updatedAt || note?.createdAt)}</strong> {highlightTextWithContext(note.content || 'No additional text', debouncedSearchText)}
                                            </span>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', flex: 1, paddingLeft: '10px', paddingRight: '10px', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography sx={{ color: theme.color, fontFamily: "SF-Pro-Text-Regular, sans-serif", fontSize: 15, textAlign: 'center' }}>
                            Parece que você ainda não tem nenhuma nota salva.
                        </Typography>
                    </Box>
                )}
            </Box>
        </Collapse>
    );
};

export default SideMenu;
