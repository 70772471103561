export const sendClassModeRequest = async (messages) => {
    try {
        // Obtendo o token de onde ele está armazenado (por exemplo, localStorage)
        const token = localStorage.getItem('sessionToken');

        if (!token) {
            throw new Error("No session token found");
        }

        const response = await fetch('https://talk2meia.uc.r.appspot.com/classmode', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ messages, token })
        });

        return await response.json();
    } catch (error) {
        console.error("Failed to send request:", error);
        return { error: error.message };
    }
};
