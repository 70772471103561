import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

import test from '../../assets/scenarios.svg'
import test01 from '../../assets/download02.png'

const CardContainer = styled(Card)(({ theme }) => ({
    borderRadius: '16px',
    backgroundColor: '#CE82FF',
    color: '#FFFFFF',
    padding: 0,
    position: 'relative',
    overflow: 'hidden',
    minWidth: '100%'
}));

const ImageContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    '& img': {
        marginLeft: theme.spacing(1),
    }
}));

const ScenariosCard = () => {
    return (
        <CardContainer>
            <CardContent sx={{
                display: 'flex',
                padding: 0,
                justifyContent: 'space-between',
                '&:last-child': {
                    paddingBottom: 0,
                }
            }}>
                <Box sx={{ padding: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography variant="h5" component="div" gutterBottom sx={{ fontFamily: 'Din-Round-Bold, sans-serif', }}>
                        Ganhe recompensas com as missões!
                    </Typography>
                    <Typography variant="body2" sx={{ fontFamily: 'Din-Round-Bold, sans-serif' }}>
                        Você fez <strong>0 de 3</strong> missões hoje.
                    </Typography>
                </Box>
                <ImageContainer>
                    {/* <img src={test} alt="Image" /> */}
                    <img src={test01} alt="Image" />
                </ImageContainer>
            </CardContent>
        </CardContainer>
    );
}

export default ScenariosCard;
