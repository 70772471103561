import React, { useContext, useState, useEffect, useRef } from 'react';
import { db } from '../../../auth/config/firebase'; // Adapte isso para o seu código
import { collection, query, getDocs, startAfter, limit, doc, getDoc, where, orderBy } from 'firebase/firestore';
import InfiniteScroll from 'react-infinite-scroll-component';
import './style.css'
import PostItem from '../PostItem';
import { UserContext } from '../../../components/UserProvider';
import Loading from '../../../components/loading';

export default function ContentFeed({ handleOpenPostOnCommunity, handleOpenEditPostOnCommunity }) {

    const { user, following, skills } = useContext(UserContext)

    const [posts, setPosts] = useState([]);
    const [lastDoc, setLastDoc] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false); // Certifique-se de adicionar isso se ainda não estiver no estado

    const fetchPosts = async () => {
        if (loading) return;
        setLoading(true);

        // let postsRef = collection(db, 'posts');
        // let q = query(
        //     postsRef,
        //     where("parentPostId", "==", null),
        //     orderBy("nicePostScore", "desc"),
        //     lastDoc ? startAfter(lastDoc) : limit(5)
        // );

        const postsRef = collection(db, 'posts');
        const q = query(
            postsRef,
            where("parentPostId", "==", null),
            orderBy("createdAt", "desc"),
            orderBy("nicePostScore", "desc"),
            lastDoc ? startAfter(lastDoc) : limit(8),
            limit(8)
        );

        const postsSnapshot = await getDocs(q);
        if (postsSnapshot.docs.length > 0) {
            setLastDoc(postsSnapshot.docs[postsSnapshot.docs.length - 1]);
        } else {
            setHasMore(false);
            setLoading(false);
            return;
        }

        // Fetching posts along with like and vote status
        const newPosts = await Promise.all(postsSnapshot.docs.map(async docSnap => {
            const postData = docSnap.data();
            const userRef = doc(db, 'community', postData.userId);
            const userDoc = await getDoc(userRef);

            // Check if the user liked the post
            const userLikeRef = doc(db, 'community', user.uid, 'likedPosts', docSnap.id);
            const userLikeDoc = await getDoc(userLikeRef);

            // Count the number of likes
            const likesRef = collection(db, 'posts', docSnap.id, 'likes');
            const likesSnapshot = await getDocs(likesRef);
            const likeCount = likesSnapshot.size;

            // Check if the user voted in the poll
            let isVoted = false;
            let userVoteOption = null;
            if (postData.poll) { // Check if the post has a poll
                const pollVoteRef = doc(db, 'posts', docSnap.id, 'pollVotes', user.uid);
                const pollVoteDoc = await getDoc(pollVoteRef);
                if (pollVoteDoc.exists()) {
                    isVoted = true;
                    userVoteOption = pollVoteDoc.data().vote;  // Assuming "vote" holds the option voted
                }
            }

            return {
                ...postData,
                id: docSnap.id,
                user: userDoc.exists() ? userDoc.data() : null,
                likeCount: likeCount,  // Real like count from the likes subcollection
                isLiked: userLikeDoc.exists(),  // Check if user has liked the post
                isVoted: isVoted,  // Check if user has voted in the poll
                userVote: userVoteOption  // Specific option user voted for
            };
            // })).then(posts => posts.filter(post => post.userId !== user.uid)); // Filter out own user's posts after fetching.
        })).then(); // Filter out own user's posts after fetching.

        setPosts(prevPosts => {
            // Separate new posts between followed and not followed
            const newFollowedPosts = newPosts.filter(post => following.includes(post.userId));
            const newNotFollowedPosts = newPosts.filter(post => !following.includes(post.userId));

            // Add first new followed posts, then not followed posts.
            // This ensures that within the new posts, those from people you follow are prioritized.
            const updatedPosts = [...prevPosts, ...newFollowedPosts, ...newNotFollowedPosts];


            // Filter to remove duplicated posts, keeping the first occurrence of each unique post.
            const uniquePosts = updatedPosts.filter((post, index, self) =>
                index === self.findIndex((t) => t.id === post.id)
            );

            return uniquePosts;
        });

        setLoading(false);
    };

    useEffect(() => {
        fetchPosts();
    }, []);

    return (
        <div id="contentFeed" className="contentFeed">

            <InfiniteScroll
                dataLength={posts.length}
                next={() => fetchPosts(lastDoc)}
                hasMore={hasMore}
                scrollableTarget="contentFeedUser"
                loader={(
                    <div className='containerLoaderPosters'>
                        <Loading size={30} color={'#999999'} />
                    </div>
                )}
            >
                <PostItem posts={posts} setPosts={setPosts} handleOpenPostOnCommunity={handleOpenPostOnCommunity} showElements={true} handleOpenEditPostOnCommunity={handleOpenEditPostOnCommunity} />
            </InfiniteScroll>
        </div>
    )
}