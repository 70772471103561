import React, { createContext, useContext, useState, useCallback } from 'react';
import Alert from './Alert'; // Certifique-se de ajustar o caminho conforme necessário

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
    const [alert, setAlert] = useState({ title: '', paragraph: '', buttons: [], showAlert: false });

    const showAlert = (title, paragraph, buttons) => {
        setAlert({ title, paragraph, buttons, showAlert: true });
    };

    const hideAlert = () => {
        setAlert((prev) => ({ ...prev, showAlert: false }));
    };

    return (
        <AlertContext.Provider value={{ showAlert, hideAlert }}>
            <Alert {...alert} hideAlert={hideAlert} />
            {children}
        </AlertContext.Provider>
    );
};

export const useAlert = () => {
    return useContext(AlertContext);
};
