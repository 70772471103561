import React, { useState, useEffect } from 'react';
import { IconButton, Menu, Tooltip, Box, CircularProgress } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import BookIcon from '@mui/icons-material/Book';
import TranslateIcon from '@mui/icons-material/Translate';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { useBallTalkContext } from '../../../componentsTalkIA/BallTalkContext';
import { sendTextToSpeechRequest, sendTextToSpeechRequestMulti } from '../../../componentsTalkIA/IAtalk2ME/Talk2MeVoice';
import { useNotes } from '../../context/NotesContext';

const OptionsMenu = ({ anchorEl, handleClose, selectedText, boxes, selectedTextWithTypes }) => {
    const { setAudioUrl } = useBallTalkContext();
    const [loading, setLoading] = useState({ copy: false, share: false, speak: false, notes: false });
    const [addNote, setAddNote] = useState(false);
    const { value, setValue } = useNotes();

    const open = Boolean(anchorEl);
    const ColorIcon = '#007aff';

    useEffect(() => {
        setAddNote(boxes.some(box => box.type === 'notes'));
    }, [boxes]);

    const copyText = (text) => {
        setLoading((prev) => ({ ...prev, copy: true }));
        navigator.clipboard.writeText(text).then(() => {
            setLoading((prev) => ({ ...prev, copy: false }));
            console.log('Texto copiado para a área de transferência');
        }).catch((err) => {
            setLoading((prev) => ({ ...prev, copy: false }));
            console.error('Erro ao copiar texto: ', err);
        });
    };

    const shareText = (text) => {
        setLoading((prev) => ({ ...prev, share: true }));
        if (navigator.share) {
            navigator.share({
                title: 'Texto Compartilhado',
                text: text
            }).then(() => {
                setLoading((prev) => ({ ...prev, share: false }));
                console.log('Texto compartilhado com sucesso');
            }).catch((err) => {
                setLoading((prev) => ({ ...prev, share: false }));
                console.error('Erro ao compartilhar texto: ', err);
            });
        } else {
            setLoading((prev) => ({ ...prev, share: false }));
            console.warn('A API de compartilhamento não está disponível neste dispositivo');
        }
    };

    

    const speakText = async (text) => {
        setLoading((prev) => ({ ...prev, speak: true }));
        try {
            const url = await sendTextToSpeechRequest(text, 'Danielle');
            // const url = await sendTextToSpeechRequestMulti(text, "nova");
            setAudioUrl(url);
            setLoading((prev) => ({ ...prev, speak: false }));
        } catch (err) {
            setLoading((prev) => ({ ...prev, speak: false }));
            console.warn('Erro ao falar esse texto', err);
        }
    };

    const addTextToNotes = (selectedTextWithTypes) => {
        console.log(selectedTextWithTypes);

        const addSpaces = (elements) => {
            const spacedElements = [];

            elements.forEach(({ type, children }, index, array) => {
                if (children.length > 0) {
                    const element = { type, children };
                    spacedElements.push(element);

                    if (index < array.length - 1) {
                        const spacer = { type: 'paragraph', children: [{ text: '' }] };
                        spacedElements.push(spacer);
                    }
                }
            });

            return spacedElements;
        };

        if (addNote) {
            setLoading((prev) => ({ ...prev, notes: true }));
            try {
                setValue((prev) => {
                    const newElements = addSpaces(selectedTextWithTypes);

                    // Verifica se o valor atual contém apenas um objeto vazio
                    if (prev.length === 1 && prev[0].type === 'paragraph' && prev[0].children.length === 1 && prev[0].children[0].text === '') {
                        return newElements;
                    } else {
                        return [
                            ...prev,
                            ...newElements,
                        ];
                    }
                });
                setLoading((prev) => ({ ...prev, notes: false }));
                console.log('Texto adicionado ao bloco de notas');
            } catch (err) {
                setLoading((prev) => ({ ...prev, notes: false }));
                console.error('Erro ao adicionar texto ao bloco de notas: ', err);
            }
        }
    };

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            PaperProps={{
                sx: {
                    border: '1px solid #E5E5E5',
                    borderRadius: '30px',
                    p: '3px'
                }
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Tooltip title="Copiar" placement="right">
                    <IconButton size="small" onClick={() => copyText(selectedText)}>
                        {loading.copy ? <CircularProgress size={20} /> : <ContentCopyIcon fontSize="small" style={{ color: ColorIcon }} />}
                    </IconButton>
                </Tooltip>
                <Tooltip title="Compartilhar" placement="right">
                    <IconButton size="small" onClick={() => shareText(selectedText)}>
                        {loading.share ? <CircularProgress size={20} /> : <ShareIcon fontSize="small" style={{ color: ColorIcon }} />}
                    </IconButton>
                </Tooltip>
                <Tooltip title="Áudio" placement="right">
                    <IconButton size="small" onClick={() => speakText(selectedText)}>
                        {loading.speak ? <CircularProgress size={20} /> : <VolumeUpIcon fontSize="small" style={{ color: ColorIcon }} />}
                    </IconButton>
                </Tooltip>
                <Tooltip title="Dicionário" placement="right">
                    <IconButton size="small" onClick={() => { /* logic to search text in dictionary */ }}>
                        <BookIcon fontSize="small" style={{ color: ColorIcon }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Traduzir" placement="right">
                    <IconButton size="small" onClick={() => { /* logic to translate text */ }}>
                        <TranslateIcon fontSize="small" style={{ color: ColorIcon }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Bloco de notas" placement="right">
                    <IconButton size="small" onClick={() => addTextToNotes(selectedTextWithTypes)}>
                        {loading.notes ? <CircularProgress size={20} /> : <NoteAddIcon fontSize="small" style={{ color: addNote ? ColorIcon : '#C2C2C2' }} />}
                    </IconButton>
                </Tooltip>
            </Box>
        </Menu>
    );
};

export default OptionsMenu;
