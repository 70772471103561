import './styles.css'

export default function Alert({ title, paragraph, buttons = [], showAlert }) {
    return (
        <>
            {showAlert && (
                <div className='alertBanner'>
                    <div className='alert'>
                        <div className='contentText'>
                            <h3>{title}</h3>
                            <p>{paragraph}</p>
                        </div>
                        <div className='contentButtons'>
                            {buttons.map((button, index) => (
                                <button key={index} onClick={button.action}>
                                    {button.text}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
