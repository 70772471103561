import React from 'react';
import { Box, Typography } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloseIcon from '@mui/icons-material/Close';

const FilesList = ({ files, setFiles }) => {

    const handleDelete = (index) => {
        const updatedFiles = files.filter((_, i) => i !== index);
        setFiles(updatedFiles);
    };

    const renderIcon = (file) => {
        if (file.type.includes('pdf')) {
            return <PictureAsPdfIcon sx={{ color: '#FFFFFF' }} />;
        } else if (file.type.includes('word')) {
            return <DescriptionIcon sx={{ color: '#FFFFFF' }} />;
        } else {
            return <InsertDriveFileIcon sx={{ color: '#FFFFFF' }} />;
        }
    };

    const renderFileType = (file) => {
        if (file.type.includes('pdf')) {
            return 'PDF';
        } else if (file.type.includes('word')) {
            return 'Word';
        } else if (file.type.includes('image')) {
            return 'Image';
        } else {
            return 'File';
        }
    };

    return (
        <Box sx={{ flexGrow: 1, overflowX: 'auto', paddingBottom: '10px', display: 'flex', flexWrap: 'wrap', gap: '10px', overflow: 'visible', }}>
            {files.map((file, index) => (
                <Box
                    key={index}
                    sx={{
                        display: 'flex',
                        position: 'relative',
                        alignItems: 'center',
                        border: file.type.includes('image') ? 'none' : '1px solid #E5E5E5',
                        borderRadius: '10px',
                        padding: file.type.includes('image') ? '0' : '5px',
                        backgroundColor: file.type.includes('image') ? 'transparent' : 'white',
                        overflow: 'visible',
                        animation: 'scaleBlur 0.3s ease-out',
                        '&:hover .delete-button': {
                            opacity: 1,
                        }
                    }}
                >
                    {file.type.includes('image') ? (
                        <img src={file.localUrl || file.url} alt={file.name} style={{ width: '60px', height: '60px', borderRadius: '5px', objectFit: 'cover', border: '1px solid #E5E5E5' }} />
                    ) : (
                        <Box
                            sx={{
                                marginRight: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '50px',
                                height: '50px',
                                backgroundColor: '#007AFF',
                                borderRadius: '10px',
                            }}
                        >
                            {renderIcon(file)}
                        </Box>
                    )}
                    {!file.type.includes('image') && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Typography sx={{ color: '#1D1D1F', fontSize: '14px' }}>{file.name}</Typography>
                            <Typography sx={{ color: '#E5E5E5', fontSize: '12px' }}>{renderFileType(file)}</Typography>
                        </Box>
                    )}
                    <Box
                        className="delete-button"
                        sx={{
                            position: 'absolute',
                            top: '-8px', // Metade fora da caixa
                            right: '-8px', // Metade fora da caixa
                            width: '20px',
                            height: '20px',
                            backgroundColor: '#FFFFFF',
                            borderRadius: '50%',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            opacity: 0,
                            transition: 'all 0.3s ease',
                            zIndex: 11,
                            ':hover': {
                                backgroundColor: '#E5E5E5',
                            },
                        }}
                        onClick={() => handleDelete(index)}
                    >
                        <CloseIcon style={{ fontSize: '15px', color: '#000000' }} />
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default FilesList;
