import React, { useState, useEffect, useContext, useRef } from 'react';
import { db } from "../../../auth/config/firebase";
import { collection, query, where, getDocs, limit, startAfter, orderBy, doc, getDoc } from 'firebase/firestore';
import InfiniteScroll from 'react-infinite-scroll-component';
import PostItem from '../../componentsCommunity/PostItem';
import { UserContext } from '../../../components/UserProvider';
import Loading from '../../../components/loading';
import { useLocation } from 'react-router-dom';

export default function PostsProfile({ handleOpenPostOnCommunity, handleOpenEditPostOnCommunity, userData, setViewPostDetails = null }) {

    let location = useLocation();

    const { user } = useContext(UserContext)

    const [selectedOption, setSelectedOption] = useState('posts');
    const [userPosts, setUserPosts] = useState([]);
    const [userReposts, setUserReposts] = useState([]);
    const [lastPostDoc, setLastPostDoc] = useState(null);
    const [lastRepostDoc, setLastRepostDoc] = useState(null);
    const [hasMorePosts, setHasMorePosts] = useState(true);
    const [hasMoreReposts, setHasMoreReposts] = useState(true);
    const [firstLoadComplete, setFirstLoadComplete] = useState(false);

    const [userId, setUserId] = useState(null)

    const [loading, setLoading] = useState(false); // Estado para controlar o loading
    const lastPostElementRef = useRef(null); // Ref para o container que será


    const [loadingPost, setLoadingPost] = useState(false);
    const [loadingRepost, setLoadingRepost] = useState(false);

    useEffect(() => {
        if (userId !== userData?.uid) {
            setSelectedOption('posts');
            setUserPosts([]);
            setUserReposts([]);
            setLastPostDoc(null);
            setLastRepostDoc(null);
            setHasMorePosts(true);
            setHasMoreReposts(true);
            setFirstLoadComplete(false);
            setLoading(false);
            setLoadingPost(false);
            setLoadingRepost(false);
        }
        setUserId(userData?.uid)
    }, [userData])

    // Função para buscar os dados de um usuário baseado em seu UID
    const fetchUserData = async (userId) => {
        const userRef = doc(db, 'community', userId);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
            return { id: userSnap.id, ...userSnap.data() };
        } else {
            console.error("Dados do usuário não encontrados");
            return null;
        }
    };

    const fetchOriginalPostData = async (parentPostId) => {
        const postRef = doc(db, 'posts', parentPostId);
        const postSnap = await getDoc(postRef);

        if (postSnap.exists()) {
            const originalPostData = postSnap.data();

            // Buscar os dados do usuário que fez o post original
            const originalPostUserData = await fetchUserData(originalPostData.userId);

            // Verificar se o usuário atual curtiu o post original
            const likeRef = doc(db, 'posts', parentPostId, 'likes', user.uid);
            const likeSnap = await getDoc(likeRef);

            // Fetch actual like count for the original post
            const likesRef = collection(postRef, 'likes');
            const likesSnapshot = await getDocs(likesRef);
            const likeCount = likesSnapshot.size; // Actual like count

            let viewCount = 0
            if (location.pathname === "/community/edit") {
                const viewsRef = collection(postRef, 'views');
                const viewsSnapshot = await getDocs(viewsRef);
                viewCount = viewsSnapshot.size;
            }

            // Check if the user voted in the poll and what option they voted for in the original post
            let isVoted = false;
            let userVoteOption = null;
            if (originalPostData.poll) {
                const pollVoteRef = doc(db, 'posts', parentPostId, 'pollVotes', user.uid);
                const pollVoteDoc = await getDoc(pollVoteRef);
                if (pollVoteDoc.exists()) {
                    isVoted = true;
                    userVoteOption = pollVoteDoc.data().vote; // Assuming "vote" stores the option voted for
                }
            }

            return {
                id: postSnap.id,
                ...originalPostData,
                user: originalPostUserData, // Add user data to the original post
                likeCount: likeCount, // Include actual like count from subcollection
                viewCount: viewCount, // Inclui a contagem de visualizações no objeto do post original
                isLiked: likeSnap.exists(), // Determine if the original post was liked by the current user
                isVoted: isVoted, // Check if user has voted in the poll of the original post
                userVote: userVoteOption // Specific option user voted for in the original post
            };
        } else {
            // console.error("Original post not found");
            return null;
        }
    };

    const fetchPosts = async (uid, isReposts = false, afterDoc = null) => {

        try {
            let postsRef = collection(db, 'posts');
            let q;

            if (isReposts) {
                q = query(
                    postsRef,
                    where('userId', '==', uid),
                    where("parentPostId", "!=", null),
                    orderBy("parentPostId"),
                    orderBy("createdAt", "desc"),
                    limit(5)
                );
            } else {
                q = query(
                    postsRef,
                    where('userId', '==', uid),
                    where("parentPostId", "==", null),
                    orderBy("createdAt", "desc"),
                    limit(5)
                );
            }

            if (afterDoc) {
                q = query(q, startAfter(afterDoc));
            }

            const querySnapshot = await getDocs(q);

            const newDocsPromises = querySnapshot.docs.map(async (doco) => {
                let postData = { id: doco.id, ...doco.data(), user: userData }; // Adiciona userData diretamente ao post

                // Fetch actual like count for the post
                const likesRef = collection(db, 'posts', doco.id, 'likes');
                const likesSnapshot = await getDocs(likesRef);
                const likeCount = likesSnapshot.size; // Actual like count

                if (location.pathname === "/community/edit") {
                    const viewsRef = collection(db, 'posts', doco.id, 'views');
                    const viewsSnapshot = await getDocs(viewsRef);
                    const viewCount = viewsSnapshot.size; // Contagem de visualizações

                    postData.viewCount = viewCount;
                }

                // Verifique se o usuário atual curtiu o post
                const likeRef = doc(db, 'posts', doco.id, 'likes', user.uid);
                const likeSnap = await getDoc(likeRef);
                postData.isLiked = likeSnap.exists();
                postData.likeCount = likeCount; // Include actual like count from subcollection

                // Verificar se o usuário votou na enquete do post e qual foi a opção votada
                let isVoted = false;
                let userVoteOption = null;
                if (postData.poll) {
                    const pollVoteRef = doc(db, 'posts', doco.id, 'pollVotes', user.uid);
                    const pollVoteDoc = await getDoc(pollVoteRef);
                    if (pollVoteDoc.exists()) {
                        isVoted = true;
                        userVoteOption = pollVoteDoc.data().vote;
                    }
                }
                postData.isVoted = isVoted;
                postData.userVote = userVoteOption;

                if (isReposts && postData.parentPostId) {
                    const originalPostData = await fetchOriginalPostData(postData.parentPostId);
                    if (originalPostData) {
                        postData.originalPost = originalPostData;
                    }
                    else {
                        postData.originalPost = [];
                    }
                }

                return postData;
            });

            const newDocs = await Promise.all(newDocsPromises);

            if (isReposts) {
                setLoadingRepost(true)
                setUserReposts((prev) => [...prev, ...newDocs]);
                setLastRepostDoc(querySnapshot.docs[querySnapshot.docs.length - 1] || null);
                setHasMoreReposts(newDocs.length === 5);
            } else {
                setLoadingPost(true)
                setUserPosts((prev) => [...prev, ...newDocs]);
                setLastPostDoc(querySnapshot.docs[querySnapshot.docs.length - 1] || null);
                setHasMorePosts(newDocs.length === 5);
            }
        } catch (error) {
            console.error("Erro ao buscar os posts/reposts do usuário:", error);
        }
    };

    useEffect(() => {
        if (userData && userData.uid) {
            fetchPosts(userData.uid);
        }
    }, [userData]);

    const filterDuplicatePosts = (posts) => {
        const uniquePosts = new Map();
        posts.forEach(post => {
            if (!uniquePosts.has(post.id)) {
                uniquePosts.set(post.id, post);
            }
        });
        return Array.from(uniquePosts.values());
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const firstEntry = entries[0];
                if (firstEntry.isIntersecting && !loading && (selectedOption === 'posts' ? hasMorePosts : hasMoreReposts)) {
                    setLoading(true);
                    fetchPosts(
                        userData.uid,
                        selectedOption === 'replies',
                        selectedOption === 'posts' ? lastPostDoc : lastRepostDoc
                    ).then(() => {
                        setLoading(false);
                    });
                }
            },
            { threshold: 0.1 }
        );

        if (userPosts.length > 0 || userReposts.length > 0) {
            const lastElement = document.querySelector("#scrollContent > div:last-child");
            if (lastElement) {
                observer.observe(lastElement);
            }

            return () => {
                if (lastElement) {
                    observer.unobserve(lastElement);
                }
            };
        }
    }, [userPosts, userReposts, hasMorePosts, hasMoreReposts, loading, selectedOption, userData]);

    const initialLoad = async () => {
        if (!firstLoadComplete) {
            setLoading(true);
            try {
                setSelectedOption('replies')
                setFirstLoadComplete(true); // Marca que o primeiro carregamento foi concluído
                await fetchPosts(userData.uid, true);
            } catch (error) {
                console.error("Erro ao carregar os posts/reposts:", error);
            } finally {
                setSelectedOption('replies')
                setLoading(false);
            }
        }
        else {
            setSelectedOption('replies')
        }
    };

    const [activeElement, setActiveElement] = useState(null);

    const handleElementClick = (elementId) => {
        setActiveElement(elementId);
    };

    return (
        <div className='containerPostsUsers' >
            <div className="containerCenterWidthSocial otherMaxWidth">
                <div className='whichPost'>
                    <div
                        className={`contentPosts ${selectedOption === 'posts' ? 'selectedPost' : ''}`}
                        onClick={() => setSelectedOption('posts')}
                    >
                        <span>Posts</span>
                    </div>
                    <div
                        className={`contentPosts ${selectedOption === 'replies' ? 'selectedPost' : ''}`}
                        onClick={initialLoad}
                    >
                        <span>Replies</span>
                    </div>
                    <div className="bar" style={{ transform: selectedOption === 'posts' ? 'translateX(-50%)' : 'translateX(50%)' }}></div>
                </div>
                <div className="contentFeed" id="scrollContent">

                    {selectedOption === 'replies' && userReposts.length < 1 && !loadingRepost && (
                        <div className='loadingPosts'>
                            <Loading size={20} color='#777777' />
                        </div>
                    )}

                    {selectedOption === 'posts' && userPosts.length < 1 && !loadingPost && (
                        <div className='loadingPosts'>
                            <Loading size={20} color='#777777' />
                        </div>
                    )}

                    {selectedOption === 'replies' && userReposts.length < 1 && loadingRepost && (
                        <div className='loadingPosts'>
                            <span>No replies yet.</span>
                        </div>
                    )}
                    {selectedOption === 'posts' && userPosts.length < 1 && loadingPost && (
                        <div className='loadingPosts'>
                            <span>No posts yet.</span>
                        </div>
                    )}
                    {
                        selectedOption === 'posts' ? (
                            filterDuplicatePosts(userPosts).map((post, index) => (
                                <PostItem
                                    ref={index === userPosts.length - 1 ? lastPostElementRef : null}
                                    key={post.id}
                                    posts={[post]}
                                    setPosts={setUserPosts}
                                    handleOpenPostOnCommunity={handleOpenPostOnCommunity}
                                    showElements={true}
                                    handleOpenEditPostOnCommunity={handleOpenEditPostOnCommunity}

                                    isActive={activeElement === post.createdAt}
                                    onElementClick={handleElementClick}
                                    setViewPostDetails={setViewPostDetails}
                                />
                            ))
                        ) : (
                            userReposts.map((repost, index) => (
                                <React.Fragment key={repost.id}>
                                    {repost && repost.originalPost && (
                                        <PostItem
                                            key={repost.originalPost.id}
                                            posts={[repost.originalPost]}
                                            setPosts={() => { }}
                                            handleOpenPostOnCommunity={handleOpenPostOnCommunity}
                                            showElements={true}
                                            showLine={true}
                                            handleOpenEditPostOnCommunity={handleOpenEditPostOnCommunity}
                                        />
                                    )}
                                    <PostItem
                                        key={repost.id}
                                        posts={[repost]}
                                        setPosts={setUserReposts}
                                        handleOpenPostOnCommunity={handleOpenPostOnCommunity}
                                        showElements={true}
                                        handleOpenEditPostOnCommunity={handleOpenEditPostOnCommunity}

                                        isActive={activeElement === repost.createdAt}
                                        onElementClick={handleElementClick}
                                        setViewPostDetails={setViewPostDetails}
                                    />
                                </React.Fragment>
                            ))
                        )
                    }

                    {
                        loading &&
                        !((selectedOption === 'replies' && userReposts.length < 1 && !loadingRepost) ||
                            (selectedOption === 'posts' && userPosts.length < 1 && !loadingPost)) && (
                            <div className='loadingMorePosts'>
                                <Loading size={30} color={'#999999'} />
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}