import './style.css'
import React, { useState, useEffect } from 'react'
import { auth } from '../../auth/config/firebase';
import { db } from '../../auth/config/firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

export default function PrivacySettings({ onChangeScreen }) {

    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
        });

        return unsubscribe;
    }, []);

    const userName = user?.displayName;
    const email = user?.email;
    const emailVerified = user?.emailVerified;

    const [photo, setPhoto] = useState('')
    const [newName, setNewName] = useState('')

    useEffect(() => {
        if (user) {
            const userRef = doc(db, 'users', user.uid);
            const unsubscribe = onSnapshot(userRef, (doc) => {
                const userData = doc.data();
                setPhoto(userData?.photo || '');
                setNewName(userData?.nome || '');
            });

            return unsubscribe; // Isso é para "limpar" a assinatura ao desmontar
        }
    }, [user]);

    const photoURL = photo ? photo : user?.photoURL;
    const displayName = newName ? newName : (userName ? userName : email?.split('@')[0]);

    return (
        <div className="allSetting">
            <div className='containerSetting'>
                <div className='privacySettings'>

                    <div className='titlePrivacy'>
                        <h2>Manage Your Account's privacy</h2>
                    </div>

                    <div className='optionsPrivacySettings'>
                        <div className='optionPrivacySettings'>
                            <div className='contentPrivacySettings'>
                                <h3>Email address</h3>
                                <p>The email address associated with your account.</p>
                            </div>
                            <div className='actionPrivacySettings'>
                                <div className='contentPlusButton'>
                                    <div className='contentInfoPrivacySettings'>
                                        <h3>{email}</h3>
                                        <p className={emailVerified ? "verified" : "unverified"}>{emailVerified ? "Verified" : "Unverified"}</p>
                                    </div>
                                    <div className='buttonOptionPrivacySettings'>
                                        <Link to={`/settings/privacy/email`}>Edit <FontAwesomeIcon icon={faEdit} /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='optionPrivacySettings'>
                            <div className='contentPrivacySettings'>
                                <h3>Password</h3>
                                <p>Set a unique password to protect your account.</p>
                            </div>
                            <div className='actionPrivacySettings'>
                                <div className='contentPlusButton'>
                                    <div className='buttonOptionPrivacySettings'>
                                        <Link to={`/settings/privacy/password`}>Change Password</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='optionPrivacySettings'>
                            <div className='contentPrivacySettings'>
                                <h3>2-step verification</h3>
                                <p>Make your account extra secure. Along with your password, you'll need a code</p>
                            </div>
                            <div className='actionPrivacySettings'>
                                <div className='contentPlusButton'>
                                    <div className='buttonOptionPrivacySettings'>
                                        <label class="switch">
                                            <input type="checkbox" />
                                            <span class="slider"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='optionPrivacySettings'>
                            <div className='contentPrivacySettings'>
                                <h3>Request my account details</h3>
                                <p>A data report of your configuration and account data</p>
                            </div>
                            <div className='actionPrivacySettings'>
                                <div className='contentPlusButton'>
                                    <div className='buttonOptionPrivacySettings'>
                                        <Link id='noBorder'>Manage</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='optionPrivacySettings'>
                            <div className='contentPrivacySettings'>
                                <h3>Delete Account</h3>
                                <p>This will delete your account. Your account will be permanently deleted from Talk2MeIA</p>
                            </div>
                            <div className='actionPrivacySettings'>
                                <div className='contentPlusButton'>
                                    <div className='buttonOptionPrivacySettings'>
                                        <Link id='noBorder' style={{ color: '#EB6570', fontWeight: '500' }}>Delete</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}