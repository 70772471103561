import React, { useState, useEffect, useContext } from 'react';
import HeaderCommunity from "../componentsCommunity/headerCommunity"
import Calendar from "../../components/calendar"
import './styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faPeopleGroup, faFlag, faLock, faCalendar, faMessage, faBell, faHeart, faHandshakeAngle, faVideo, faCalendarDay, faFire, faEdit, faChevronDown } from '@fortawesome/free-solid-svg-icons';
// import { FaStar } from "react-icons/fa";
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate, useParams } from "react-router-dom";
import { db, auth } from "../../auth/config/firebase";
import { collection, query, where, getDocs, doc, onSnapshot, getDoc, updateDoc, setDoc, deleteDoc } from 'firebase/firestore';
import Loader from '../../components/loader';
import PostOnCommunity from '../componentsCommunity/postOnCommunity';
import PostsProfile from './postsProfile';
import { addNotification } from '../../components/notification';
import { UserContext } from '../../components/UserProvider';
import Alert from '../../components/alert';
import { checkIfBlocked, checkIfFollowing, checkIfLiked, checkIfFavorited } from '../../components/userInteractions';
import Loading from '../../components/loading';
import { useCall } from '../componentsCommunity/CallContext';
import UserAvatar from '../../components/UserAvatar';

export default function ProfileUsersCommunity() {

    const { setCall } = useCall();

    // const { user, following, newNameCommunity, givenLikes, blockedUsers, favorites } = useContext(UserContext)
    const { user, newNameCommunity } = useContext(UserContext)

    const [showPostOnCommunity, setShowPostOnCommunity] = useState(false)
    const [selectedPost, setSelectedPost] = useState(null);
    const [selectedEditPost, setSelectedEditPost] = useState(null);

    const handleOpenEditPostOnCommunity = (selectedPost) => {
        setSelectedEditPost(selectedPost);
        setShowPostOnCommunity(true);
    };

    const handleOpenPostOnCommunity = (selectedPost) => {
        setSelectedPost(selectedPost);
        setShowPostOnCommunity(true);  // abre o popup
    };

    const [userNotFound, setUserNotFound] = useState(false)
    const [userData, setUserData] = useState(null);
    const [isFollowing, setIsFollowing] = useState(false);
    const [hasLiked, setHasLiked] = useState(false);
    const [hasFavorited, setHasFavorited] = useState(false);
    const [isBlocked, setIsBlocked] = useState(false)

    const [loading, setLoading] = useState(true)

    const [showAlert, setShowAlert] = useState(false)

    const { id: rawId } = useParams();
    const id = rawId?.startsWith('@') ? rawId?.slice(1) : rawId;
    const isId = rawId?.startsWith('@')

    useEffect(() => {
        if (id && isId) {
            const normalizedId = id?.replace(/\s+/g, '').toLowerCase();

            const usersRef = collection(db, 'community');
            const q = query(usersRef, where('normalizedUserNick', '==', normalizedId));

            const unsubscribe = onSnapshot(q, (snapshot) => {
                if (!snapshot.empty) {
                    const user = snapshot.docs.map(doc => ({
                        uid: doc.id,
                        ...doc.data()
                    }))[0];
                    setUserData(user);
                    document.title = `${user.userNick} (@${normalizedId}) • on Talk2MeIA`;
                } else {
                    console.log('User not found');
                    setUserNotFound(true)
                }
            });

            return () => {
                unsubscribe();
                document.title = 'Talk2MeIA';
            };
        }
    }, [id]);


    useEffect(() => {
        // Atualiza o estado com base nas verificações
        const updateStates = async () => {
            setLoading(true)
            const isFollowing = await checkIfFollowing(userData?.uid, user.uid);
            const hasLiked = await checkIfLiked(userData?.uid, user.uid);
            const hasFavorited = await checkIfFavorited(userData?.uid, user.uid);

            setIsFollowing(isFollowing);
            setHasLiked(hasLiked);
            setHasFavorited(hasFavorited);
            setLoading(false)
        };

        if (userData?.uid) {
            updateStates();
        }
    }, [user, userData]); // Dependências

    const [followersCount, setFollowersCount] = useState(0);
    const [followingCount, setFollowingCount] = useState(0);

    useEffect(() => {
        const fetchCounts = async () => {
            if (userData?.uid) {
                try {
                    const followersRef = collection(db, 'community', userData.uid, 'followers');
                    const followersSnapshot = await getDocs(followersRef);
                    setFollowersCount(followersSnapshot.size);

                    const followingRef = collection(db, 'community', userData.uid, 'following');
                    const followingSnapshot = await getDocs(followingRef);
                    setFollowingCount(followingSnapshot.size);
                } catch (error) {
                    console.error("Error fetching counts: ", error);
                    // Handle errors, e.g. show an error message
                }
            }
        };

        fetchCounts();
    }, [userData?.uid]);

    const toggleFollow = async () => {
        setIsFollowing(prev => !prev); // Atualiza o estado de UI imediatamente para refletir a ação do usuário

        const currentUserId = user?.uid;
        const profileUserId = userData?.uid;

        // Referências aos documentos de seguindo e seguidores
        const followingRef = doc(db, 'community', currentUserId, 'following', profileUserId);
        const followersRef = doc(db, 'community', profileUserId, 'followers', currentUserId);

        try {
            // Checar se o usuário atual já está seguindo o perfil
            if (isFollowing) {
                // Deixar de seguir: remover o documento de 'seguindo' e 'seguidores'
                setFollowersCount(prev => prev - 1); // Ajustar a contagem
                await deleteDoc(followingRef);
                await deleteDoc(followersRef);
            } else {
                // Seguir: adicionar documento em 'seguindo' e 'seguidores'
                setFollowersCount(prev => prev + 1); // Ajustar a contagem
                const timestamp = new Date(); // Data atual como timestamp
                await setDoc(followingRef, {
                    followingUserId: profileUserId,
                    dateFollowed: timestamp
                });
                await setDoc(followersRef, {
                    followerUserId: currentUserId,
                    dateFollowed: timestamp
                });
            }
        } catch (error) {
            console.error("Error updating follow status: ", error);
            setIsFollowing(prev => !prev); // Reverter o estado se a operação falhar
            alert("There was an error trying to follow/unfollow this user. Please try again.");
            // Ajustar a contagem de seguindo para refletir o erro
            if (isFollowing) {
                setFollowersCount(prev => prev + 1);
            } else {
                setFollowersCount(prev => prev - 1);
            }
        }
    };

    const [receivedLikesCount, setReceivedLikesCount] = useState(0);

    useEffect(() => {
        const fetchReceivedLikesCount = async () => {
            if (userData?.uid) {
                try {
                    // Referência à coleção de likes recebidos
                    const receivedLikesRef = collection(db, 'community', userData.uid, 'receivedLikes');
                    const receivedLikesSnapshot = await getDocs(receivedLikesRef);

                    // Atualiza a contagem de likes recebidos
                    setReceivedLikesCount(receivedLikesSnapshot.size);
                } catch (error) {
                    console.error("Error fetching received likes count: ", error);
                    // Tratar erros, por exemplo, mostrar uma mensagem de erro
                }
            }
        };

        fetchReceivedLikesCount();
    }, [userData?.uid]); // Dependência para reexecutar se os dados do usuário mudarem


    const toggleLike = async () => {
        setHasLiked(prev => !prev); // Atualiza o estado de UI imediatamente para refletir a ação do usuário

        // Referências aos documentos de likes dados e recebidos
        const givenLikeRef = doc(db, 'community', user?.uid, 'givenLikes', userData?.uid); // Like que o usuário deu
        const receivedLikeRef = doc(db, 'community', userData?.uid, 'receivedLikes', user?.uid); // Like que o perfil recebeu

        try {
            if (hasLiked) {
                setReceivedLikesCount(prev => prev - 1);
                // Se já curtiu, remover documentos de like de ambas as coleções
                await deleteDoc(givenLikeRef);
                await deleteDoc(receivedLikeRef);
            } else {
                setReceivedLikesCount(prev => prev + 1);
                // Se ainda não curtiu, criar documentos de like em ambas as coleções
                await setDoc(givenLikeRef, { likedProfileUid: userData?.uid });
                await setDoc(receivedLikeRef, { likedByUserUid: user?.uid });
            }
        } catch (error) {
            console.error("Error updating like status: ", error);
            setHasLiked(prev => !prev); // Reverte o estado se a operação falhar
            alert("Houve um erro ao tentar dar like neste usuário. Por favor, tente novamente.");
            if (hasLiked) {
                setReceivedLikesCount(prev => prev + 1);
            } else {
                setReceivedLikesCount(prev => prev - 1);
            }
        }
    };


    const [userInfoFollowAndFollowers, setUserInfoFollowAndFollowers] = useState(true)
    const [displayedUsers, setDisplayedUsers] = useState([]);
    const [showFollowAndFollowers, setShowFollowAndFollowers] = useState(false)

    const blockUser = async (uidToBlock, userName) => {
        setIsBlocked(!isBlocked)
        // Referência ao documento do usuário bloqueado na coleção blockedUsers
        const blockedUserRef = doc(db, 'community', user.uid, 'blockedUsers', uidToBlock);

        try {
            const blockedSnapshot = await getDoc(blockedUserRef);

            if (blockedSnapshot.exists()) {
                // Se o documento existe, o usuário está atualmente bloqueado, então desbloquear
                await deleteDoc(blockedUserRef);
                addNotification(`Usuário ${userName} desbloqueado(a) com sucesso.`, "dark3");
            } else {
                // Se o documento não existe, o usuário não está bloqueado, então bloquear
                await setDoc(blockedUserRef, { blockedUid: uidToBlock });
                addNotification(`Usuário ${userName} bloqueado(a) com sucesso.`, "dark3");
            }
        } catch (error) {
            setIsBlocked(!isBlocked)
            console.error("Error updating block status: ", error);
            addNotification("Houve um erro ao tentar atualizar o status de bloqueio/desbloqueio deste usuário. Por favor, tente novamente.", "dark3");
        }
    };

    useEffect(() => {
        const updateBlockStatus = async () => {
            const isCurrentlyBlocked = await checkIfBlocked(userData?.uid, user.uid);
            setIsBlocked(isCurrentlyBlocked);
        };

        if (userData?.uid) {
            updateBlockStatus();
        }
    }, [user, userData]);

    const navigate = useNavigate();

    function callUser(user) {
        setCall(true)
        navigate(`/community/chat/@${user}`);
    }

    return (
        <>
            <PostOnCommunity showPostOnCommunity={showPostOnCommunity} setShowPostOnCommunity={setShowPostOnCommunity} post={selectedPost} setSelectedPost={setSelectedPost} selectedEditPost={selectedEditPost} setSelectedEditPost={setSelectedEditPost} />

            <div className={showFollowAndFollowers ? 'allCommunity active' : 'allCommunity'}>

                <div id="notifications3"></div>

                <Alert
                    title={`Função ainda em desenvolvimento`}
                    paragraph={`Essa função ainda se econtra em estado de desenvolvimento, fique ligado para novas atualizações.`}
                    buttons={[
                        { text: "Cancelar", action: () => setShowAlert(false) },
                        { text: "OK", action: () => setShowAlert(false) }
                    ]}
                    showAlert={showAlert}
                />


                {showFollowAndFollowers && (
                    <aside className='allPopupContainer'>
                        <div className='allBlurPopupContainer' onClick={() => setShowFollowAndFollowers(false)}></div>
                        <div className='usersFollowAndFollowers'>
                            <header>
                                <div
                                    className={userInfoFollowAndFollowers ? 'containerInfoUserDataFollowAndFollowers active' : 'containerInfoUserDataFollowAndFollowers'}
                                    onClick={() => setUserInfoFollowAndFollowers(true)}
                                >
                                    <h3>Followers</h3>
                                    <span>{followersCount}</span> {/* Atualizado para usar o estado followersCount */}
                                </div>
                                <div
                                    className={userInfoFollowAndFollowers ? 'containerInfoUserDataFollowAndFollowers' : 'containerInfoUserDataFollowAndFollowers active'}
                                    onClick={() => setUserInfoFollowAndFollowers(false)}
                                >
                                    <h3>Following</h3>
                                    <span>{followingCount}</span> {/* Atualizado para usar o estado followingCount */}
                                </div>
                            </header>
                            <div className='containerUsersFollowAndFollowers'>
                                {(followingCount === 0 && !userInfoFollowAndFollowers) ||
                                    (followersCount === 0 && userInfoFollowAndFollowers) ? (
                                    <div className='containerNoInfoUsersFollowAndFollowers'>
                                        <span>
                                            {userInfoFollowAndFollowers
                                                ? `${userData?.userNick} doesn't have any followers yet.`
                                                : `${userData?.userNick} isn't following anyone yet.`}
                                        </span>
                                    </div>
                                ) :
                                    <div className='containerUsersInfoFollowAndFollowers'>
                                        {displayedUsers.map((user, index) => (
                                            <Link key={index} className='containerUserInfoFollowAndFollowers' to={`/community/@${user?.normalizedUserNick}`} onClick={() => setShowFollowAndFollowers(false)}>
                                                <div>
                                                    <UserAvatar userName={user?.userNick} photoURL={user?.photoURL} size={45} />
                                                </div>
                                                <div className='contentInfoUserInfoFollowAndFollowers'>
                                                    <Link to={`/community/@${user?.normalizedUserNick}`} onClick={() => setShowFollowAndFollowers(false)}>{user.normalizedUserNick}</Link>
                                                    <span>{user.userNick}</span>
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                }
                            </div>
                        </div>
                    </aside>
                )}

                <div className='containerCommunity'>

                    <div className="positionFixed">
                        <HeaderCommunity showPostOnCommunity={showPostOnCommunity} setShowPostOnCommunity={setShowPostOnCommunity} />
                    </div>

                    <div className='containerBottomCommunity borderTop'>
                        <div className="containerWidthCommunity">
                            <div className="centerWidthCommunity">
                                {userData
                                    ?
                                    <div className="containerProfile">
                                        <div className="profileUserCommunity">
                                            <div>
                                                <div className="flexInfoUserProfileCommunity">
                                                    <div className="continerInfoUserProfileCommunity">
                                                        <div className="continerInfoUserProfile">
                                                            <h2>{userData?.userNick}</h2>
                                                            <span className="spanInfoUserProfileSign">@{userData?.userNick.toLowerCase().replace(/\s+/g, '')}</span>
                                                            <div className="infoUserProfile">
                                                                <div className="infoProfile">
                                                                    <FontAwesomeIcon icon={faClock} />
                                                                    <span>Joined in {userData?.currentYear}</span>
                                                                </div>
                                                                <div className="infoProfile">
                                                                    <FontAwesomeIcon icon={faPeopleGroup} />
                                                                    <span>
                                                                        <Link onClick={() => { setUserInfoFollowAndFollowers(false); setShowFollowAndFollowers(true); }}> Follow {followingCount} </Link> / <Link onClick={() => { setUserInfoFollowAndFollowers(true); setShowFollowAndFollowers(true); }}> {followersCount} followers</Link>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="flexInfoUserProfileCommunityBorn">
                                                                <img src={"https://www.gov.br/agricultura/pt-br/assuntos/relacoes-internacionais/agro-mais-investimentos/imagens/bandeira-dos-estados-unidos.png/@@images/image.png"} alt="" />
                                                            </div>
                                                            {String(newNameCommunity) !== String(userData?.userNick)
                                                                ?
                                                                <div className="containerButtonReport">
                                                                    <button> <FontAwesomeIcon icon={faFlag} onClick={() => setShowAlert(true)} /> Report</button>
                                                                    <button onClick={() => blockUser(userData?.uid, userData?.userNick)}>
                                                                        {loading
                                                                            ?
                                                                            <>
                                                                                <Loading size={20} color='#777777' />
                                                                                <span>loading</span>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <FontAwesomeIcon icon={faLock} />
                                                                                {isBlocked ? ' Unblock' : ' Block'}
                                                                            </>
                                                                        }
                                                                    </button>
                                                                </div>
                                                                :
                                                                <div className="containerButtonReport">
                                                                    <Link to='/settings/edit'> <FontAwesomeIcon icon={faEdit} /> Edit</Link>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <UserAvatar userName={userData?.userNick} photoURL={userData?.photoURL} size={120} />
                                                    </div>
                                                </div>
                                                <div className="infoUserCommunityBiography">
                                                    <div className="aboutUserCommunity">
                                                        <h2>About {userData?.userNick}: </h2>
                                                        <span>{userData?.aboutMe}</span>
                                                    </div>

                                                    <div className="callUserCommunityProfile">
                                                        {String(newNameCommunity) !== String(userData?.userNick) && (
                                                            <>
                                                                <Link className="buttonCallUserCommunity" to={`/community/chat/@${userData?.userNick.toLowerCase().replace(/\s+/g, '')}`} onClick={() => callUser(userData?.userNick.toLowerCase().replace(/\s+/g, ''))}>
                                                                    <FontAwesomeIcon icon={faCalendar} />
                                                                    <span>Call {userData?.userNick}</span>
                                                                </Link>

                                                                <div className="buttonsActionUserCommunity">
                                                                    <Link className="buttonActionUserCommunityProfile" to={`/community/chat/@${userData?.userNick.toLowerCase().replace(/\s+/g, '')}`}>
                                                                        <FontAwesomeIcon icon={faMessage} className="outline-icon" />
                                                                        <span>Message</span>
                                                                    </Link>
                                                                    <div className="buttonActionUserCommunityProfile" onClick={toggleFollow}>
                                                                        {loading
                                                                            ?
                                                                            <>
                                                                                <Loading size={20} color='#777777' />
                                                                                <span>loading</span>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {!isFollowing && (
                                                                                    <FontAwesomeIcon icon={faBell} className="outline-icon" />
                                                                                )}
                                                                                <span>{isFollowing ? 'Following' : 'Follow'}</span>
                                                                                {isFollowing && (
                                                                                    <FontAwesomeIcon icon={faChevronDown} />
                                                                                )}
                                                                            </>
                                                                        }
                                                                    </div>
                                                                    <div className="buttonActionUserCommunityProfile" onClick={() => setShowAlert(true)}>
                                                                        <FontAwesomeIcon icon={faHandshakeAngle} className="outline-icon" />
                                                                        <span>Help</span>
                                                                    </div>
                                                                    <div className="buttonActionUserCommunityProfile" onClick={toggleLike}>
                                                                        {loading
                                                                            ?
                                                                            <>
                                                                                <Loading size={20} color='#777777' />
                                                                                <span>loading</span>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <FontAwesomeIcon icon={faHeart} className={hasLiked ? '' : 'outline-icon'} />
                                                                                <span>Like</span>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                    <div className="buttonActionUserCommunityProfile">
                                                                        <FontAwesomeIcon icon={faStar} className={hasFavorited ? '' : 'outline-icon'} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}

                                                    </div>
                                                </div>

                                                <div className="statisticUserCommunity">
                                                    <h2>Statistic</h2>
                                                    <div className="statisticUser">
                                                        <div className="boxStatisticUser">
                                                            <FontAwesomeIcon icon={faVideo} />
                                                            {/* <FontAwesomeIcon icon={faVideo} style={{ color: '#007CFD' }} /> */}
                                                            <div className="contentBoxStatisticUser">
                                                                <h3>0</h3>
                                                                <span>Connections</span>
                                                            </div>
                                                        </div>
                                                        <div className="boxStatisticUser">
                                                            <FontAwesomeIcon icon={faCalendarDay} />
                                                            {/* <FontAwesomeIcon icon={faCalendarDay} style={{ color: '#00D26A' }} /> */}
                                                            <div className="contentBoxStatisticUser">
                                                                <h3>0</h3>
                                                                <span>Active days</span>
                                                            </div>
                                                        </div>
                                                        <div className="boxStatisticUser">
                                                            <FontAwesomeIcon icon={faFire} />
                                                            {/* <FontAwesomeIcon icon={faFire} style={{ color: '#FFC800' }} /> */}
                                                            <div className="contentBoxStatisticUser">
                                                                <h3>0</h3>
                                                                <span>Attack days</span>
                                                            </div>
                                                        </div>
                                                        <div className="boxStatisticUser">
                                                            <FontAwesomeIcon icon={faHeart} style={{ color: receivedLikesCount ? '#F00' : '#E5E5E5' }} />
                                                            <div className="contentBoxStatisticUser">
                                                                <h3 style={{ color: receivedLikesCount ? '#1D1D1F' : '#AFAFAF' }}>{receivedLikesCount}</h3>
                                                                <span>Likes</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='classRightInfoUser'>
                                            {/* <Calendar data={{ class: "glass" }} /> */}
                                            <Calendar data={{ class: "" }} />

                                            <div className='optionsSkils'>
                                                {userData?.selectedSkills.map((skill, index) => (
                                                    <li key={index}>{skill}</li>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='loaderProfileCommunity'>
                                        {userNotFound
                                            ?
                                            <div className='userNotFound'>
                                                <h1>User not found</h1>
                                                <Link to="/community/users">Go back</Link>
                                            </div>
                                            :
                                            <Loader />
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {!userNotFound && (
                        <PostsProfile handleOpenPostOnCommunity={handleOpenPostOnCommunity} handleOpenEditPostOnCommunity={handleOpenEditPostOnCommunity} userData={userData} />
                    )}
                </div>
            </div>
        </>
    )
}
