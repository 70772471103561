import React, { useContext, useState, useEffect, useRef } from 'react';
import { UserContext } from '../../../components/UserProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faArrowLeft, faChartBar, faX, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { faComment, faHeart, faImages } from "@fortawesome/free-regular-svg-icons";
import { FaComment } from "react-icons/fa";
import axios from 'axios';
import './style.css'
import { db, storage } from '../../../auth/config/firebase';
import { collection, doc, setDoc, updateDoc, increment } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import AudioPlayer from '../audioPlayer';
import PostItem from '../PostItem';
import { addNotification } from '../../../components/notification';
import { Mention, MentionsInput } from 'react-mentions';

import ImageCompressor from 'image-compressor.js';
import { useStatus } from '../PostingContext';
import { SERVER, ServerPost } from '../../../auth/config/serverUrls';
import UserAvatar from '../../../components/UserAvatar';

const PercentageCircle = ({
    percentage,
    size = 120,
    color = "blue",
    backgroundColor = "gray",
    maxPercentage = 500 // Novo prop para definir o valor máximo de percentagem
}) => {
    const strokeWidth = size * 0.1;
    const radius = (size - strokeWidth) / 2;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (Math.min(percentage, maxPercentage) / maxPercentage) * circumference;

    return (
        <svg width={size} height={size} className="circle-container">
            <circle
                cx={size / 2}
                cy={size / 2}
                r={radius}
                stroke={backgroundColor}
                strokeWidth={strokeWidth}
                fill="transparent"
            />
            <circle
                cx={size / 2}
                cy={size / 2}
                r={radius}
                stroke={color}
                strokeWidth={strokeWidth}
                fill="transparent"
                strokeDasharray={circumference}
                strokeDashoffset={offset}
                style={{
                    transform: 'rotate(-90deg)',
                    transformOrigin: '50% 50%'
                }}
            />
            <text
                x={size / 2}
                y={size / 2 + size * 0.10}
                textAnchor="middle"
                fontSize={size * 0.30}
                fill={color}
                style={{ fontWeight: '500' }}
            >
                {`${percentage}`}
            </text>
        </svg>
    );
};

export default function PostOnCommunity({ showPostOnCommunity, setShowPostOnCommunity, post, setSelectedPost, selectedEditPost, setSelectedEditPost }) {

    const { setStatus } = useStatus();

    const [audio, setAudio] = useState(null);

    const audioRef = useRef(null);

    const { user, photoCommunity, newNameCommunity } = useContext(UserContext)

    const [showPopupPackage, setShowPopupPackage] = useState(false)

    const [questionText, setQuestionText] = useState('');
    const [optionOneText, setOptionOneText] = useState("");
    const [optionTwoText, setOptionTwoText] = useState("");
    const [optionThreeText, setOptionThreeText] = useState("");
    const [optionFourText, setOptionFourText] = useState("");

    const allowOptionThree = optionOneText?.trim() !== "" && optionTwoText?.trim() !== "";
    const optionThreeClass = optionThreeText?.trim() === "" ? "inputOptionPackageNone" : "inputOptionPackage";

    const showOptionFour = optionOneText?.trim() !== "" && optionTwoText?.trim() !== "" && optionThreeText?.trim() !== "";
    const optionFourClass = optionFourText?.trim() === "" ? "inputOptionPackageNone" : "inputOptionPackage";


    const maxRows = 3;

    const handleTextareaChange = (setTextFunction) => (e) => {
        const textarea = e.target;
        textarea.style.height = 'auto';
        const scrollHeight = textarea.scrollHeight;
        const minScrollHeight = 24;

        const finalScrollHeight = Math.max(scrollHeight, minScrollHeight);
        textarea.style.overflowY = finalScrollHeight > maxRows * minScrollHeight ? 'scroll' : 'hidden';
        textarea.style.height = `${Math.min(finalScrollHeight, maxRows * minScrollHeight)}px`;

        setTextFunction(textarea.value);
    };

    const [photos, setPhotos] = useState([]);
    const [originalImages, setOriginalImages] = useState([]);

    const handleFileSelect = (event) => {
        const files = Array.from(event.target.files).slice(0, 5);
        const newFileURLs = files.map(file => URL.createObjectURL(file));
        setPhotos(prevPhotos => [...prevPhotos, ...newFileURLs].slice(0, 5));
    };

    const removePhoto = (indexToRemove) => {
        setPhotos(prevPhotos => prevPhotos.filter((_, index) => index !== indexToRemove));
    };

    const [metaDadosURL, setMetaDadosURL] = useState([])
    const [text, setText] = useState('');

    const fetchUrlMetadata = async (url) => {
        try {
            const { data } = await axios.post('http://localhost:5000/fetch-url', { url });
            setMetaDadosURL(data);
        } catch (error) {
            console.error('Error fetching URL metadata:', error);
        }
    };

    useEffect(() => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const urls = text?.match(urlRegex);

        if (urls && urls?.length > 0) {
            const lastUrl = urls[urls.length - 1];
            fetchUrlMetadata(lastUrl);
        }
    }, [text]);

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const mediaRecorder = new MediaRecorder(stream);
            const audioChunks = [];

            mediaRecorder.ondataavailable = event => {
                audioChunks.push(event.data);
            };

            mediaRecorder.onstop = () => {
                const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
                const audioUrl = URL.createObjectURL(audioBlob);
                setAudio(audioUrl);
            };

            mediaRecorder.start();

            setTimeout(() => {
                mediaRecorder.stop();
            }, 5000); // Grava por 5 segundos, ajuste conforme necessário
        } catch (error) {
            console.error('Erro ao gravar áudio:', error);
        }
    };

    const convertImageToJPEG = async (blob) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                canvas.toBlob(resolve, 'image/jpeg');
            };
            img.onerror = reject;
            img.src = URL.createObjectURL(blob);
        });
    };

    const handleSendPost = async () => {

        setShowPlaceholder(true)
        setText('');
        setPhotos([]);
        setQuestionText('');
        setOptionOneText('');
        setOptionTwoText('');
        setOptionThreeText('');
        setOptionFourText('');
        setShowPopupPackage(false);
        setMetaDadosURL([]);
        setAudio(null);
        setShowPostOnCommunity(false)
        setSelectedPost(null)
        setSelectedEditPost(null)
        if (inputRef.current) {
            inputRef.current.innerText = '';
        }

        if (selectedEditPost && selectedEditPost.id) {
            setStatus({ value: 'Editing...', url: '' })
        } else {
            setStatus({ value: 'Posting...', url: '' })
        }

        setLoader(true)

        const handleImageUploadAndUpdate = async () => {
            for (let url of selectedEditPost.photos) {
                const storageRefToDelete = getStorageRefFromUrl(storage, url);
                console.log("Deleting image with ref:", storageRefToDelete);
                await deleteObject(storageRefToDelete);
            }
        };

        const handleAudioDelete = async () => {
            if (selectedEditPost && selectedEditPost.audio) {
                const storageRefToDelete = getStorageRefFromUrl(storage, selectedEditPost.audio);
                console.log("Deleting audio with ref:", storageRefToDelete);
                await deleteObject(storageRefToDelete);
            }
        };

        let isTextSafe = true, areImagesSafe = true, score = 0;

        const proceedWithPost = async () => {
            if (selectedEditPost && selectedEditPost.photos) {
                await handleImageUploadAndUpdate();
            }
            if (selectedEditPost && selectedEditPost.audio) {
                await handleAudioDelete();
            }

            let uploadPromises = [];

            // Se o áudio estiver disponível
            if (audio) {
                score += 15;
                const audioBlob = await fetch(audio).then(r => r.blob());
                const audioFile = new File([audioBlob], `${uuidv4()}.wav`, { type: 'audio/wav' });
                const storageRef = ref(storage, `audios/${user.uid}/${audioFile.name}`);
                const uploadTask = uploadBytesResumable(storageRef, audioFile);

                const audioUploadPromise = new Promise((resolve, reject) => {
                    uploadTask.on('state_changed',
                        (snapshot) => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                        },
                        (error) => {
                            console.error('Error during upload:', error);
                            reject(error);
                        },
                        async () => {
                            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                            postData.audio = downloadURL;
                            resolve();
                        }
                    );
                });

                uploadPromises.push(audioUploadPromise);
            }
            else {
                postData.audio = null;
            }

            // Aguarde a conclusão de todos os uploads
            await Promise.all(uploadPromises);

            if (showPopupPackage && optionOneText && optionTwoText) {
                score += 10;

                score += (optionThreeText.trim() !== "") ? 5 : 0;
                score += (optionFourText.trim() !== "") ? 5 : 0;
                postData.poll = {
                    question: questionText,
                    // votedUsers: []
                };

                // Opção 1 e Opção 2 sempre estarão preenchidas neste ponto, então adicioná-los diretamente.
                postData.poll.optionOne = optionOneText;
                postData.poll.optionTwo = optionTwoText;

                postData.poll.votes = {
                    optionOne: 0,
                    optionTwo: 0
                };

                // Se a Opção 3 estiver preenchida, adicione-a.
                if (optionThreeText.trim() !== "") {
                    score += 5;
                    postData.poll.optionThree = optionThreeText;
                    postData.poll.votes.optionThree = 0;
                }

                // Se a Opção 4 estiver preenchida, adicione-a.
                if (optionFourText.trim() !== "") {
                    score += 5;
                    postData.poll.optionFour = optionFourText;
                    postData.poll.votes.optionFour = 0;
                }
            }

            try {
                const userRef = doc(db, 'community', user.uid);

                let postRef;
                let newPostId;
                if (selectedEditPost && selectedEditPost.id) {
                    postRef = doc(db, 'posts', selectedEditPost.id);
                    await updateDoc(postRef, postData);
                    newPostId = selectedEditPost.id;
                } else {
                    postRef = doc(collection(db, 'posts'));
                    await setDoc(postRef, postData);
                    await updateDoc(userRef, {
                        posts: increment(1)
                    });
                    newPostId = postRef.id;

                    if (post) {
                        const postRef = doc(db, 'posts', post.id);
                        await updateDoc(postRef, {
                            commentCount: increment(1)
                        });
                        await updateDoc(userRef, {
                            replies: increment(1)
                        });
                        const userCommentRef = doc(userRef, 'commentedPosts', post.id);
                        await setDoc(userCommentRef, { postId: post.id });
                    }
                }

                setStatus({ value: 'Successful', url: newPostId })
                setTimeout(() => {
                    setStatus({ value: '', url: '' })
                }, [4000])

                setLoader(false)

                if (inputRef.current) {
                    inputRef.current.innerText = '';
                }
                setText('');
                setPhotos([]);
                setQuestionText('');
                setOptionOneText('');
                setOptionTwoText('');
                setOptionThreeText('');
                setOptionFourText('');
                setShowPopupPackage(false);
                setMetaDadosURL([]);
                setAudio(null);
                setShowPostOnCommunity(false)
                setSelectedPost(null)
                setSelectedEditPost(null)
                setLoader(false)

            } catch (error) {
                setStatus({ value: 'Fail', url: '' })
                setTimeout(() => {
                    setStatus({ value: '', url: '' })
                }, [4000])

                setLoader(false)
                // console.error("Error adding document: ", error);
            }
        };

        if (!text && !photos?.length &&
            (!showPopupPackage || !questionText || !optionOneText || !optionTwoText)) return;

        const postData = {
            userId: user.uid,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            photos: photos,
            parentPostId: post ? post.id : null,
            commentCount: 0
        };



        if (selectedEditPost && selectedEditPost.id) {
            delete postData.createdAt;
            delete postData.likes;
            postData.updatedAt = new Date().toISOString();
            postData.parentPostId = selectedEditPost.parentPostId
        }

        if (text) {
            try {
                const textResponse = await fetch(`${ServerPost}/check-text`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ text: text })
                });

                if (!textResponse.ok) {
                    const errorMsg = await textResponse.text();
                    throw new Error(errorMsg || "Error checking text.");
                }

                const textData = await textResponse.json();
                if (textData.safe === false) {
                    addNotification('Your message contains potentially offensive content.', 'error3');

                    setStatus({ value: 'Fail', url: '' })
                    setTimeout(() => {
                        setStatus({ value: '', url: '' })
                    }, [4000])

                    setLoader(false);
                    isTextSafe = false;
                    return;
                } else {
                    postData.text = text;
                    const sentimentScore = textData.sentimentScore;
                    score += (sentimentScore >= 0.5) ? 25 : ((sentimentScore >= 0) ? 15 : 10);

                    const wordCount = text.split(/\s+/).length;
                    if (wordCount > 40) score += 10;

                    if (wordCount < 10) {
                        score -= 5;
                    }

                    const hashtags = text.match(/#\w+/g);
                    if (hashtags) {
                        score += hashtags.length * 8;
                    }

                    const mentions = text.match(/@\w+/g);
                    if (mentions) {
                        score += mentions.length * 5;
                    }

                    const talkIARegex = /talk-?ia/i;
                    if (talkIARegex.test(text)) {
                        score += 10;
                    }
                }
            } catch (error) {
                console.error("Error checking the text:", error.message);

                setStatus({ value: 'Fail', url: '' })
                setTimeout(() => {
                    setStatus({ value: '', url: '' })
                }, [4000])
                setLoader(false)
                addNotification('Error checking the message. Please try again.', 'error3');
                isTextSafe = false;
                return; // Parar o processo se ocorrer um erro
            }
        }

        const uploadPromises = [];

        const getStorageRefFromUrl = (storage, url) => {
            // Separar a URL em segmentos
            const parts = url.split("/");

            // Tentar obter a parte relevante da URL (depois de "o/")
            const encodedPath = parts.find(part => part.includes('%2F'));

            if (!encodedPath) {
                console.error("Failed to extract path from URL:", url);
                return;
            }

            // Decodificar o caminho para transformá-lo em um formato legível
            let path = decodeURIComponent(encodedPath);

            // Remova o query string se estiver presente
            path = path.split('?')[0];

            // Verifica se o sufixo '_1300x1300' já está presente antes de tentar adicioná-lo
            if (!path.includes('_1300x1300') && path.endsWith('.jpg')) {
                path = path.replace('.jpg', '_1300x1300.jpg');
            }

            console.log("Final path for ref:", path);  // Vamos adicionar um log para depuração

            return ref(storage, path);
        };

        if (selectedEditPost && selectedEditPost.id) {
            setOriginalImages(selectedEditPost.photos || []);
        }

        const verifyImageWithServer = async (imageUrl) => {
            try {
                // Obtemos o blob da imagem usando o URL
                const blobResponse = await fetch(imageUrl);
                const imageBlob = await blobResponse.blob();

                // Converter a imagem para JPEG
                const convertedBlob = await convertImageToJPEG(imageBlob);

                // Criar o FormData e anexar o blob JPEG como um arquivo
                const formData = new FormData();
                formData.append('image', new File([convertedBlob], 'image.jpg', { type: 'image/jpeg' }));

                // Fazer a requisição POST para o servidor
                const serverResponse = await fetch(`${ServerPost}/upload`, {
                    method: 'POST',
                    body: formData
                });

                if (!serverResponse.ok) {
                    const errorMsg = await serverResponse.text();
                    throw new Error(errorMsg || "Error checking image.");
                }

                const data = await serverResponse.json();
                return data.allImagesApproved;
            } catch (error) {
                console.error("Error in verifyImageWithServer: ", error);
                throw error;
            }
        };

        const verifyAllImages = async (imageUrls) => {
            for (const url of imageUrls) {
                console.log("url: ", url)
                console.log("imageUrlsimageUrls: ", imageUrls)
                const isImageSafe = await verifyImageWithServer(url);
                if (!isImageSafe) {
                    throw new Error("Some images are not safe.");
                }
            }
            return true;
        };

        const waitAndRetry = async (func, maxRetries = 5) => {
            for (let i = 0; i < maxRetries; i++) {
                try {
                    return await func();
                } catch (error) {
                    console.log(`Attempt ${i + 1} failed. Retrying...`);
                    await new Promise(res => setTimeout(res, 1000 * (i + 1)));
                }
            }
            throw new Error('All retry attempts failed.');
        };

        const uploadImagesToFirebase = async () => {
            const storage = getStorage();
            const uploadedImageURLs = [];
            for (const photoURL of photos) {
                const blob = await fetch(photoURL).then(response => response.blob());
                const originalFileName = `${uuidv4()}.jpg`;
                const photoFile = new File([blob], originalFileName, { type: 'image/jpeg' });
                const storageRef = ref(storage, `photos/${user.uid}/${photoFile.name}`);
                const uploadTask = uploadBytesResumable(storageRef, photoFile);

                const downloadURL = await new Promise((resolve, reject) => {
                    uploadTask.on('state_changed',
                        (snapshot) => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                        },
                        (error) => {
                            console.error('Error during upload:', error);
                            reject(error);
                        },
                        async () => {
                            const url = await waitAndRetry(async () => {
                                const modifiedRef = ref(storage, `photos/${user.uid}/${originalFileName.split('.jpg')[0]}_1300x1300.jpg`);
                                return await getDownloadURL(modifiedRef);
                            });
                            resolve(url);
                        }
                    );
                });
                uploadedImageURLs.push(downloadURL);
            }
            console.log("All images uploaded:", uploadedImageURLs);
            return uploadedImageURLs;
        };

        if (photos?.length) {
            try {
                areImagesSafe = await verifyAllImages(photos);
                if (!areImagesSafe) {
                    throw new Error("Some images are not safe.");
                }
                const uploadedImageURLs = await uploadImagesToFirebase(photos);
                postData.photos = uploadedImageURLs;
                score += Math.min(photos.length * 15, 60);
            } catch (error) {
                console.error("Error uploading files:", error);
                addNotification('Error uploading the images. Please try again.', 'error3');

                setStatus({ value: 'Fail', url: '' })
                setTimeout(() => {
                    setStatus({ value: '', url: '' })
                }, [4000])

                setLoader(false);
                return; // Parar o processo se as imagens não forem seguras
            }
        }

        score = Math.min(score, 100); // Garantir que a pontuação não exceda 100
        if (post) {
            postData.niceCommentScore = score;
        }
        else {
            postData.nicePostScore = score;
        }

        try {
            await Promise.all(uploadPromises);
            if (isTextSafe && areImagesSafe) {
                await proceedWithPost();
            } else {
                addNotification('Not proceeding due to unsafe content.', 'error3');

                setStatus({ value: 'Fail', url: '' })
                setTimeout(() => {
                    setStatus({ value: '', url: '' })
                }, [4000])

                setLoader(false);
            }
        } catch (error) {
            console.error("Error uploading files:", error);

            setStatus({ value: 'Fail', url: '' })
            setTimeout(() => {
                setStatus({ value: '', url: '' })
            }, [4000])

            setLoader(false);
        }
    };

    const [loader, setLoader] = useState(false);

    function Clear() {
        setText('');
        setPhotos([]);
        setShowPopupPackage(false)
        setOptionOneText('');
        setOptionTwoText('');
        setOptionThreeText('');
        setOptionFourText('');
        setAudio(null);
    }

    const [isDataChanged, setIsDataChanged] = useState(false);

    const hasDataChanged = () => {
        if (selectedEditPost?.text !== text) return true;
        if (selectedEditPost?.audio !== audio) return true;
        if (JSON.stringify(photos) !== JSON.stringify(selectedEditPost?.photos)) return true;
        return false;
    };

    useEffect(() => {
        setIsDataChanged(hasDataChanged());
    }, [text, audio, photos]);


    const inputRef = useRef(null);

    const scrollRef = useRef(null);

    const [showPlaceholder, setShowPlaceholder] = useState(true);

    useEffect(() => {
        if (inputRef.current && inputRef.current.textContent) {
            setShowPlaceholder(inputRef.current.textContent.trim().length === 0);
        }
    }, [text]);

    // Efeito para focar no inputRef na montagem do componente
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.innerHTML = '<p class="editableText"><br></p>';
            inputRef.current.focus(); // Foca no elemento editável
        }
    }, []);

    useEffect(() => {
        if (scrollRef.current) {
            const element = scrollRef.current;
            element.scrollTop = element.scrollHeight;
        }
        if (inputRef.current) {
            inputRef.current && inputRef.current.focus();
        }
    }, [post]);

    useEffect(() => {
        if (selectedEditPost && selectedEditPost?.userId === user.uid) {
            setText(selectedEditPost?.text);
            setPhotos(selectedEditPost?.photos);
            // setShowPopupPackage(selectedEditPost?.poll)
            // setOptionOneText(selectedEditPost?.poll?.optionOne || '');
            // setOptionTwoText(selectedEditPost?.poll?.optionTwo || '');
            // setOptionThreeText(selectedEditPost?.poll?.optionThree || '');
            // setOptionFourText(selectedEditPost?.poll?.optionFour || '');
            setAudio(selectedEditPost?.audio);

            if (inputRef.current) {
                inputRef.current.innerText = selectedEditPost?.text || '';
            }
        }
    }, [selectedEditPost])

    // const handleInput = (e) => {
    //     const content = e.target.innerText;
    //     if (content.length <= 500) {
    //         setText(content);
    //     }

    //     if (!inputRef.current) return;

    //     const regexPatterns = {
    //         mention: /@\w+/g,
    //         hashtag: /#\w+/g,
    //         url: /https?:\/\/[^\s]+/g, // Este é um regex simples para capturar URLs, ajuste conforme necessário
    //     };

    //     let innerHTML = inputRef.current.innerText;

    //     // Substitui menções, hashtags e URLs por spans com classes específicas
    //     innerHTML = innerHTML.replace(regexPatterns.mention, (match) => `<span class='mention' data-lexical-text='true'>${match}</span>`);
    //     innerHTML = innerHTML.replace(regexPatterns.hashtag, (match) => `<span class='hashtag' data-lexical-text='true'>${match}</span>`);
    //     innerHTML = innerHTML.replace(regexPatterns.url, (match) => `<span class='url' data-lexical-text='true'>${match}</span>`);

    //     // Atualiza o HTML do contenteditable
    //     inputRef.current.innerHTML = `<p class='editableText'>${innerHTML}</p>`;

    //     // Restaura o foco e a posição do cursor ao final do conteúdo
    //     const range = document.createRange();
    //     const sel = window.getSelection();
    //     const lastNode = inputRef.current.childNodes[0].lastChild;
    //     range.setStartAfter(lastNode);
    //     range.collapse(true);
    //     sel.removeAllRanges();
    //     sel.addRange(range);
    // };

    const handleInput = (e) => {
        const content = e.target.innerText;
        if (content.length <= 500) {
            setText(content);
        }

        if (!inputRef.current) return;

        const regexPatterns = {
            mention: /@\w+/g,
            hashtag: /#\w+/g,
            url: /https?:\/\/[^\s]+/g, // Este é um regex simples para capturar URLs, ajuste conforme necessário
        };

        let innerHTML = inputRef.current.innerText;

        // Substitui menções, hashtags e URLs por spans com classes específicas
        innerHTML = innerHTML.replace(regexPatterns.mention, (match) => `<span class='mention' data-lexical-text='true'>${match}</span>`);
        innerHTML = innerHTML.replace(regexPatterns.hashtag, (match) => `<span class='hashtag' data-lexical-text='true'>${match}</span>`);
        innerHTML = innerHTML.replace(regexPatterns.url, (match) => `<span class='url' data-lexical-text='true'>${match}</span>`);

        // Atualiza o HTML do contenteditable
        inputRef.current.innerHTML = innerHTML ? `<p class='editableText'>${innerHTML}</p>` : `<p class='editableText'></p>`;

        // Restaura o foco e a posição do cursor
        const range = document.createRange();
        const sel = window.getSelection();
        // Garantir que haja sempre um último nó
        const lastNode = inputRef.current.querySelector('.editableText').lastChild || inputRef.current.querySelector('.editableText');
        if (lastNode) {
            range.setStartAfter(lastNode);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
        }
    };


    useEffect(() => {
        // Define o conteúdo inicial para o div contenteditable
        if (inputRef.current) {
            inputRef.current.innerHTML = '<p class="editableText"></p>';
        }
    }, []);

    const handlePlaceholderClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    return (
        <>
            {showPostOnCommunity && (
                <aside className="postOnCommunityTalk2MeIA">
                    <div className="blurPostInfo" onClick={() => [setShowPostOnCommunity(false), setSelectedPost(null), setSelectedEditPost(null), Clear()]}></div>
                    <div id="notifications3"></div>
                    <div className="postOnCommunity">
                        <header>
                            <div className="userInfoCommunity">
                                <FontAwesomeIcon icon={faArrowLeft} onClick={() => [setShowPostOnCommunity(false), setSelectedPost(null), setSelectedEditPost(null), Clear()]} />
                                <UserAvatar userName={newNameCommunity} photoURL={photoCommunity} size={40} />
                            </div>
                            <div className="contentOnPostCommunity">
                                {loader
                                    ?
                                    <div className='loaderPostPoster'>
                                        <svg aria-label="Loading..." class="xemfg65 xa4qsjk xs51kk x2a5n4e spin" role="img" viewBox="0 0 100 100">
                                            <rect class="fade" style={{ animationDelay: '0s' }} fill="#A5A5A5" height="10" rx="5" ry="5" transform="rotate(-90 50 50)" width="28" x="67" y="45"></rect>
                                            <rect class="fade" style={{ animationDelay: '0.1s' }} fill="#A5A5A5" height="10" rx="5" ry="5" transform="rotate(-45 50 50)" width="28" x="67" y="45"></rect>
                                            <rect class="fade" style={{ animationDelay: '0.2s' }} fill="#A5A5A5" height="10" rx="5" ry="5" transform="rotate(0 50 50)" width="28" x="67" y="45"></rect>
                                            <rect class="fade" style={{ animationDelay: '0.3s' }} fill="#A5A5A5" height="10" rx="5" ry="5" transform="rotate(45 50 50)" width="28" x="67" y="45"></rect>
                                            <rect class="fade" style={{ animationDelay: '0.4s' }} fill="#A5A5A5" height="10" rx="5" ry="5" transform="rotate(90 50 50)" width="28" x="67" y="45"></rect>
                                            <rect class="fade" style={{ animationDelay: '0.5s' }} fill="#A5A5A5" height="10" rx="5" ry="5" transform="rotate(135 50 50)" width="28" x="67" y="45"></rect>
                                            <rect class="fade" style={{ animationDelay: '0.6s' }} fill="#A5A5A5" height="10" rx="5" ry="5" transform="rotate(180 50 50)" width="28" x="67" y="45"></rect>
                                            <rect class="fade" style={{ animationDelay: '0.7s' }} fill="#A5A5A5" height="10" rx="5" ry="5" transform="rotate(225 50 50)" width="28" x="67" y="45"></rect>
                                        </svg>
                                    </div>
                                    :
                                    <button
                                        disabled={selectedEditPost && selectedEditPost.id ?
                                            !isDataChanged :
                                            !(text || (photos && photos.length))}
                                        onClick={handleSendPost}
                                    >
                                        <FontAwesomeIcon icon={faPaperPlane} />
                                        <span>{selectedEditPost && selectedEditPost.id ? 'Update' : 'send'}</span>
                                    </button>
                                }
                            </div>
                        </header>
                        <div className='scrollInfoPostReply' ref={scrollRef}>

                            {post && (
                                <div className='postArea'>
                                    <PostItem posts={[post]} setPosts={''} hideElements={false} showLine={true} expandImage={false} />
                                </div>
                            )}

                            <div className="contentInfoPostCommunity">
                                <div className="inputAreaPostCommunity">
                                    {/* <div
                                        ref={inputRef}
                                        contentEditable={true}
                                        onInput={handleDivInput}
                                        className={text?.length > 0 ? 'textArea' : 'textArea show-placeholder'}
                                        data-placeholder={
                                            selectedEditPost && selectedEditPost.id
                                                ? 'Edit your post'
                                                : (post ? `Reply to ${post.user.userNick}` : 'Start a new post')
                                        }
                                        style={{
                                            minWidth: '100%',
                                            minHeight: '20px',
                                            maxHeight: '100px',
                                            overflowY: 'auto',
                                        }}
                                        autoFocus
                                    ></div> */}
                                    {/* <div class="xzsf02u xw2csxc x1odjw0f x1n2onr6 x1hnll1o xpqswwc notranslate" contenteditable="true" role="textbox" spellcheck="true" tabindex="0" data-lexical-editor="true" style={{ userSelect: 'text', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                                        <p class="xdj266r x11i5rnm xat24cr x1mh8g0r" dir="ltr">
                                            <span class="x1pwva54 xt0e3qv x1ncy3xk" data-lexical-text="true">@cela</span>
                                            <span data-lexical-text="true"> kkk</span>
                                        </p>
                                    </div> */}


                                    {/* <div
                                        ref={inputRef}
                                        className="xzsf02u xw2csxc x1odjw0f x1n2onr6 x1hnll1o xpqswwc notranslate"
                                        contentEditable="true"
                                        role="textbox"
                                        spellCheck="true"
                                        tabIndex="0"
                                        data-lexical-editor="true"
                                        onInput={handleInput}
                                        style={{ userSelect: 'text', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
                                    ></div> */}

                                    <div className="containerEditableText">
                                        <div className="contentEditableText">
                                            <div
                                                className="divEditableText"
                                                ref={inputRef}
                                                contentEditable="true"
                                                role="textbox"
                                                spellCheck="true"
                                                tabIndex="0"
                                                data-lexical-editor="true"
                                                onInput={handleInput}
                                                style={{ userSelect: 'text', whiteSpace: 'pre-wrap', wordBreak: 'break-word', outline: 'none' }}
                                            >
                                                <p className="editableText">
                                                    {/* <br /> */}
                                                </p>
                                            </div>
                                            {showPlaceholder && (
                                                <div className="contentPlaceholder" onClick={handlePlaceholderClick}>
                                                    <span
                                                        className="placeholder"
                                                        dir="auto"
                                                        style={{
                                                            lineHeight: 'var(--base-line-clamp-line-height)',
                                                            '--base-line-clamp-line-height': 'calc(1.4 * 1em)',
                                                        }}
                                                    >
                                                        {selectedEditPost && selectedEditPost.id
                                                            ? 'Edit your post'
                                                            : (post ? `Reply to ${post.user.userNick}` : 'Start a new post')}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {audio && (
                                    <div className='continerAudio'>
                                        <div className="closeAudio" onClick={() => setAudio(null)}>
                                            <FontAwesomeIcon icon={faX} />
                                        </div>
                                        <AudioPlayer audioSrc={audio} audioRef={audioRef} />
                                    </div>
                                )}
                                {photos?.length >= 1 && (
                                    <div className="imagesAreaPostCommunity">
                                        <Swiper
                                            slidesPerView='auto'
                                            className="swipperPhotosUserPost"
                                            spaceBetween={10}
                                        >
                                            {photos.map((photoURL, index) => (
                                                <SwiperSlide key={index} className='photoUserPost'>
                                                    <picture className={photos?.length >= 2 ? 'image active' : 'maxWidthImagePost'}>
                                                        <div className="closeImage" onClick={() => removePhoto(index)}>
                                                            <FontAwesomeIcon icon={faX} />
                                                        </div>
                                                        <img src={photoURL} alt="Selected" />
                                                    </picture>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>
                                )}
                                {metaDadosURL && metaDadosURL.title && photos?.length === 0 && (
                                    <div className="containerDataLink">
                                        <div className="closeImage" onClick={() => setMetaDadosURL([])}>
                                            <FontAwesomeIcon icon={faX} />
                                        </div>
                                        <div className="containerImageDataLink">
                                            <img src={metaDadosURL.image} alt="Thumbnail" />
                                        </div>
                                        <div className="contentDataImageLink">
                                            <span>{metaDadosURL.domain}</span>
                                            <p>{metaDadosURL.title}</p>
                                        </div>
                                    </div>
                                )}
                                {showPopupPackage && (
                                    <div className="containerPackage">
                                        <div className="containerInfoAboutPackage">
                                            <textarea
                                                rows={1}
                                                placeholder="Ask a question"
                                                maxLength={40}
                                                value={questionText}
                                                onChange={handleTextareaChange(setQuestionText)}
                                            />
                                        </div>
                                        <div className="contentOptionsPackage">
                                            {/* Primeira Opção - Sempre visível */}
                                            <div className="inputOptionPackage">
                                                <textarea
                                                    rows={1}
                                                    placeholder="Option 1"
                                                    maxLength={40}
                                                    value={optionOneText}
                                                    onChange={handleTextareaChange(setOptionOneText)}
                                                />
                                            </div>

                                            {/* Segunda Opção - Sempre visível */}
                                            <div className="inputOptionPackage">
                                                <textarea
                                                    rows={1}
                                                    placeholder="Option 2"
                                                    maxLength={40}
                                                    value={optionTwoText}
                                                    onChange={handleTextareaChange(setOptionTwoText)}
                                                />
                                            </div>

                                            {/* Terceira Opção */}
                                            <div className={optionThreeClass}>
                                                <textarea
                                                    rows={1}
                                                    placeholder="Option 3"
                                                    maxLength={40}
                                                    value={optionThreeText}
                                                    onChange={handleTextareaChange(setOptionThreeText)}
                                                    readOnly={!allowOptionThree}
                                                />
                                            </div>


                                            {showOptionFour && (
                                                <div className={optionFourClass}>
                                                    <textarea
                                                        rows={1}
                                                        placeholder="Option 4"
                                                        maxLength={40}
                                                        value={optionFourText}
                                                        onChange={handleTextareaChange(setOptionFourText)}
                                                    />
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="contentOptionPostOnCommunity">
                            <div className="flexOptionsCommunity">
                                {(!metaDadosURL || !metaDadosURL.title) && !showPopupPackage && (
                                    <div className="buttonOptionPostOnCommunity">
                                        <label>
                                            <FontAwesomeIcon icon={faImages} />
                                            <input
                                                type="file"
                                                multiple
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                onChange={handleFileSelect}
                                                disabled={loader}
                                            />
                                        </label>
                                    </div>
                                )}
                                {!selectedEditPost && (
                                    <>
                                        {(!metaDadosURL || !metaDadosURL.title) && photos?.length === 0 && (
                                            <div className="buttonOptionPostOnCommunity" onClick={() => !loader && setShowPopupPackage(!showPopupPackage)}>
                                                <FontAwesomeIcon icon={faChartBar} />
                                            </div>
                                        )}
                                    </>
                                )}
                                <div className="buttonOptionPostOnCommunity" onClick={() => !loader && startRecording()}>
                                    <FontAwesomeIcon icon={faMicrophone} />
                                </div>
                            </div>
                            <div className="flexOptionsCommunity">
                                <PercentageCircle percentage={text?.length} size={35} color={text?.length > 500 ? "#FF0000" : "#4B4B4B"} backgroundColor={text?.length > 500 ? "#FF0000" : "#E8E8E3"} />
                            </div>
                        </div>
                    </div>
                </aside>
            )}
        </>
    )
}