import { useParams } from 'react-router-dom';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faComments, faSearch, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useRef, useEffect, useState } from 'react';
import UserAvatar from '../../components/UserAvatar';
import { UserContext } from '../../components/UserProvider';
import { useContext } from 'react';
import { sendTextToSpeechRequest } from '../../componentsTalkIA/IAtalk2ME/Talk2MeVoice';
import AfterMission from '../../componentsTalkIA/afterMission';
import MainChat from './MainChat';
import { useAllTheme } from '../../components/themes/ThemeContext';
import emojiRegex from 'emoji-regex';
import { sendLexiScenariosRequest } from '../../componentsTalkIA/IAtalk2ME/sendLexiScenariosRequest';

import logo from '../image/logo.png'

import Loader from '../../components/loader';

export default function ChatScenarios({ scenarios }) {

    if (scenarios.length < 1) {
        return (
            <div style={{ display: 'flex', height: '100vh', width: '100vw', flex: 1, justifyContent: 'center', alignItems: 'center', background: '#4D59FB' }}>
                <Loader />
            </div>
        )
    }

    const { id } = useParams();

    const [isExpanded, setIsExpanded] = useState(false);

    const shortText = "Engaging with TalkIA's scenarios ensures complete confidentiality and discretion. We want to inform you that all conversational data are treated as ephemeral — they are not stored or retained in our systems. As you transition between scenarios or...";
    const fullText = "Engaging with TalkIA's scenarios ensures complete confidentiality and discretion. We want to inform you that all conversational data are treated as ephemeral — they are not stored or retained in our systems. As you transition between scenarios or upon the conclusion of each individual conversation, all corresponding data will be immediately and securely deleted. This safeguard is part of our commitment to prioritize and protect your privacy at all times.";

    const { user, photoURL, displayName, email } = useContext(UserContext)
    const { theme } = useAllTheme();

    const situation = scenarios.find(scenario => scenario.id === id);

    const messageRef = useRef();
    const [message, setMessage] = useState('');

    const [messages, setMessages] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [search, setSeach] = useState(false)

    const [missionFinalized, setMissionFinalized] = useState(false)
    const [missionArguments, setMissionArguments] = useState(null)

    useEffect(() => {
        const fetchAudioUrl = async () => {
            const aiMessage = {
                role: "assistant",
                content: [
                    {
                        type: "text",
                        text: situation.initialMessage
                    },
                ]
            };

            setMessages([aiMessage]);
            // const url = await sendTextToSpeechRequest(situation.initialMessage, "Danielle");
            // setAudioUrl(url);
        };

        fetchAudioUrl();
    }, [situation.initialMessage, missionFinalized]);

    const handleSendMessage = async (message, [], event, speak) => {
        if (message.trim() !== '') {
            event.preventDefault();
            setMessage("");

            try {
                const newMessage = {
                    role: "user",
                    content: [
                        {
                            type: "text",
                            text: message.trim()
                        },
                    ]
                };

                let updatedMessages = [...messages, newMessage];

                setMessages(updatedMessages);

                const response = await sendLexiScenariosRequest(updatedMessages, situation.system);

                if (response.error) {
                    return;
                }

                const responseMessages = response.messages;

                if (responseMessages && responseMessages.length > 0) {

                    const lastMessage = responseMessages[responseMessages.length - 1];

                    if (lastMessage.tool_calls && lastMessage.tool_calls.length > 0) {
                        const functionCallPart = lastMessage.tool_calls[0];
                        setMissionFinalized(true)
                        setMissionArguments(functionCallPart.function.arguments)
                    } else if (lastMessage.content && Array.isArray(lastMessage.content)) {
                        setMessages(prevMessages => [...prevMessages, ...responseMessages]);
                        const talkAiResponse = lastMessage.content
                            .map(contentItem => contentItem.text || '')
                            .join('\n');
                        if (speak) {
                            const emojiRegEx = emojiRegex();
                            const contentWithoutEmojis = talkAiResponse.replace(emojiRegEx, "");
                            const urlRegEx = /(https?:\/\/[^\s]+)/g;
                            const contentWithoutEmojisAndUrls = contentWithoutEmojis.replace(urlRegEx, "");

                        }
                    }
                }
            } catch (error) {

            }
        }
    };

    useEffect(() => {
        if (messageRef.current) {
            messageRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    let filteredAndSortedSituations = scenarios
        .filter(situation => {
            if (!searchValue) {
                return true;
            }
            return situation?.title.toLowerCase().includes(searchValue.toLowerCase()) ||
                situation?.goal.toLowerCase().includes(searchValue.toLowerCase());
        })
        .sort((a, b) => a.title.localeCompare(b.title));

    let situationElements = filteredAndSortedSituations
        .map((situation, index) => (
            <Link key={index} className={`optionChat ${situation.id === id ? 'activeOptionChat' : ''}`} to={`/comunication/scenario/${situation.id}`} onClick={() => setMessages([])}>
                <li>{situation.title}</li>
                <p>{situation.goal}</p>
            </Link>
        ));


    const [audioUrl, setAudioUrl] = useState('');
    const audioRef = useRef(null);

    const speakMessage = async (text) => {
        const url = await sendTextToSpeechRequest(text, "Danielle");
        setAudioUrl(url);
    };

    useEffect(() => {
        // Reproduzir o áudio automaticamente quando a URL do áudio é atualizada
        if (audioUrl && audioRef.current) {
            audioRef.current.play();
        }
    }, [audioUrl]);

    return (
        <div className='containerMina'>

            <div id="notifications3"></div>

            {missionFinalized && (
                <aside className="popupShow">
                    <AfterMission setMissionFinalized={setMissionFinalized} data={missionArguments} />
                </aside>
            )}

            <div className='allContainerChat'>

                {audioUrl &&
                    <audio
                        src={audioUrl}
                        ref={audioRef}
                        controls
                        style={{ display: 'none' }}
                    />
                }

                <div>
                    <div className='contentLogoTalkIA'>
                        <Link to={`/`}>
                            <img src={logo} alt={'logo Talk2MeIA'} />
                        </Link>
                    </div>
                    <div className='optionScreen'>
                        <Link className='optionScreenContent' to='/scenarios'>
                            <FontAwesomeIcon icon={faHome} />
                        </Link>
                        <Link className='optionScreenContent selected'>
                            <FontAwesomeIcon icon={faComments} />
                        </Link>
                    </div>
                </div>
                <div className='chatContainer'>
                    <div className={`headerChat ${search ? 'activeSearch' : ''}`}>
                        <h3>Conversations ({scenarios.length})</h3>
                        <FontAwesomeIcon icon={faSearch} onClick={() => setSeach(!search)} />
                        <div className='optionSearchAbsolute'>
                            <input type='text' placeholder='Search for any scenario' value={searchValue} onChange={e => setSearchValue(e.target.value)} />
                        </div>
                    </div>
                    <div className='contentChats'>
                        {searchValue == "" && faSearch == true
                            ?
                            <div>
                                {scenarios.map((situation, index) => (
                                    <Link key={index} className={`optionChat ${situation.id === id ? 'activeOptionChat' : ''}`} to={`/comunication/scenario/${situation.id}`} onClick={() => setMessages([])}>
                                        <li>{situation.title}</li>
                                        <p>{situation.goal}</p>
                                    </Link>
                                ))}
                            </div>
                            :
                            <div>
                                {searchValue !== ""
                                    ?
                                    <div>
                                        {situationElements.length > 0 ? situationElements : <p id='nothingFound'>Nothing found</p>}
                                    </div>
                                    :
                                    <div>
                                        {scenarios.map((situation, index) => (
                                            <Link key={index} className={`optionChat ${situation.id === id ? 'activeOptionChat' : ''}`} to={`/scenarios/${situation.id}`} onClick={() => setMessages([])}>
                                                <li>{situation.title}</li>
                                                <p>{situation.goal}</p>
                                            </Link>
                                        ))}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className='contentChat'>
                    <div className='headerRealChat'>
                        <div className='contentLogoTalkIALine'>
                            <img src={logo} alt='logo EnglishFlu' />
                        </div>
                        <div className="center">
                            <h3 className='titleMessageSection'>{situation.title}</h3>
                        </div>
                        <FontAwesomeIcon icon={faEllipsis} />
                    </div>



                    <MainChat messageRef={messageRef} messages={messages} setMessage={setMessage} message={message} theme={theme} handleSendMessage={handleSendMessage} />


                </div>
                <div className='aboutThisChat'>
                    <div className='contentInfoAboutThisChat'>
                        <div className='imageForThisChat'>
                            <img src={situation.image_url} alt={'image'} />
                            <div className='barBottomShadow'></div>
                        </div>
                        <div className='usersInThisChat'>
                            <div className='userProfile profileUser'>
                                {user && <UserAvatar userName={displayName} email={email} photoURL={photoURL} size={50} />}
                            </div>
                            <div className='contentLogoTalkIALine userTalkIAProfile'>
                                <img src={logo} alt={'logo Talk2MeIA'} />
                            </div>
                        </div>
                        <div className='contentAboutThisChat'>
                            <h3>{situation.title}</h3>
                        </div>
                    </div>
                    <div className='contentInfoAboutThisScroll'>
                        <div className='contentInfoAboutThis'>
                            <h3>Goal </h3>
                            <p>{situation.goal}</p>
                        </div>
                        <div className='contentInfoAboutThis'>
                            <h3>Tags </h3>
                            <div className='containerTags'>
                                {situation && situation.keywords && situation.keywords.map(keyword => (
                                    <li key={keyword}>{keyword}</li>
                                ))}
                            </div>
                        </div>
                        <div className='contentInfoAboutThis'>
                            <h3>Information: </h3>
                            <p>{isExpanded ? fullText : shortText} <button onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? 'Show less' : 'Learn more'}</button></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
