import HeaderCommunity from '../componentsCommunity/headerCommunity';
import './style.css';
import { db } from "../../auth/config/firebase";
import { collection, query, getDocs, limit } from 'firebase/firestore';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { UserContext } from '../../components/UserProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPlusCircle, faEllipsisH } from '@fortawesome/free-solid-svg-icons';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper/core';

import UserAvatar from "../../components/UserAvatar";
import { Link } from "react-router-dom";
import PostOnCommunity from "../componentsCommunity/postOnCommunity";

import ContentFeed from "../componentsCommunity/contentFeed";


export default function Feed({ mainContainerRef }) {

    const { user, photoCommunity, newNameCommunity } = useContext(UserContext)

    // const [users, setUsers] = useState([]);

    // useEffect(() => {
    //     const fetchUsers = async () => {
    //         const usersCollectionRef = collection(db, 'community');
    //         const q = query(usersCollectionRef, limit(20));
    //         const usersSnapshot = await getDocs(q);
    //         const usersList = usersSnapshot.docs.map(doc => {
    //             return { uid: doc.id, ...doc.data() };
    //         });
    //         setUsers(usersList);
    //     };

    //     fetchUsers();
    // }, []);

    const [showPostOnCommunity, setShowPostOnCommunity] = useState(false)

    const [selectedPost, setSelectedPost] = useState(null);

    const handleOpenPostOnCommunity = (selectedPost) => {
        setSelectedPost(selectedPost);
        setShowPostOnCommunity(true);
    };  


    function getResizedImageURL(url) {
        if (!url) return '';
    
        const baseUrl = url.split("?")[0];
        return `${baseUrl}_1300x1300?${url.split("?")[1]}`;
    }

    const scrollContainerRef = useRef(null);

    const [selectedEditPost, setSelectedEditPost] = useState(null);

    const handleOpenEditPostOnCommunity = (selectedPost) => {
        setSelectedEditPost(selectedPost);
        setShowPostOnCommunity(true);
    }; 

    return (
        <div className="allCommunity contentFeedUser" id='contentFeedUser' ref={scrollContainerRef}>

            <PostOnCommunity showPostOnCommunity={showPostOnCommunity} setShowPostOnCommunity={setShowPostOnCommunity} post={selectedPost} setSelectedPost={setSelectedPost} selectedEditPost={selectedEditPost} setSelectedEditPost={setSelectedEditPost} />

            <div className="basePosition"></div>
            <div className="positionFixed">
                <HeaderCommunity showPostOnCommunity={showPostOnCommunity} setShowPostOnCommunity={setShowPostOnCommunity} mainContainerRef={scrollContainerRef} />
            </div>
            <div className='containerSocial'>
                <div className="containerCenterWidthSocial">
                    <div className="contentInputShare">
                        <div className="inputContainerInfo" onClick={() => setShowPostOnCommunity(!showPostOnCommunity)}>
                            <div className="userInfoPost">
                                <UserAvatar userName={newNameCommunity} photoURL={photoCommunity} size={25} />
                            </div>
                            <input type="text" placeholder="Share something..." />
                            <div className="buttonPlusInfoPost">
                                <FontAwesomeIcon icon={faPlusCircle} />
                            </div>
                        </div>
                    </div>
                    <div className="contentFollowPeople">
                        {/* <div className="flexFollowPeople">
                            <h3>People you can follow</h3>
                            <FontAwesomeIcon icon={faArrowRight} />
                        </div>
                        <div className="contentPeopleFollow">
                            <Swiper
                                slidesPerView='auto'
                                className="swipperUserToFollow"
                                spaceBetween={20}
                                modules={[Pagination, Navigation]}
                            >
                                {users.map((user, index) => (
                                    <SwiperSlide className='containerUserToFollow' key={index}>
                                        <div>
                                            <UserAvatar userName={user.userNick} photoURL={getResizedImageURL(user.photoURL)} size={70} />
                                        </div>
                                        <div className="contentInfoUserToFollow">
                                            <h3>{user.userNick}</h3>
                                            <span>{user.selectedCountry}</span>
                                        </div>
                                        <div className="containerButtonProfileToFollow">
                                            <Link to={`/community/@${user.normalizedUserNick}`}>Profile <FontAwesomeIcon icon={faArrowRight} /></Link>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div> */}

                        <ContentFeed scrollableTarget={scrollContainerRef.current} handleOpenPostOnCommunity={handleOpenPostOnCommunity} handleOpenEditPostOnCommunity={handleOpenEditPostOnCommunity} />
                        
                    </div>
                </div>
            </div>
        </div>
    )
}