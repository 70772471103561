import { IconButton } from '@mui/material';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import VolumeUpTwoTone from '@mui/icons-material/VolumeUpTwoTone';
import { sendTextToSpeechRequest } from '../../componentsTalkIA/IAtalk2ME/Talk2MeVoice';
import { useAlert } from '../../components/AlertComponent/AlertContext';

// const words = ['BALLOON', 'REACT', 'JAVASCRIPT', 'GAME', 'FUN'];
const words = [
  "KNOWLEDGE",
  "INTELLIGENCE",
  "COMPLEXITY",
  "DISCOVERY",
  "ADVANTAGE",
  "DEVELOPMENT",
  "TECHNOLOGY",
  "INFORMATION",
  "LANGUAGE",
  "CREATIVITY",
  "INNOVATION",
  "COMMUNICATION",
  "EDUCATION",
  "EXPERIENCE",
  "IMAGINATION",
  "UNDERSTANDING",
  "OPPORTUNITY",
  "EXPLORATION",
  "TRANSFORMATION",
  "ACHIEVEMENT"
];



const Balloon = ({ id, x, y, letter, color, onPop }) => {
  const [popped, setPopped] = useState(false);

  const handlePop = () => {
    setPopped(true);
    setTimeout(() => onPop(id, letter), 300);
  };

  return (
    <div
      style={{
        position: 'absolute',
        left: `${x}%`,
        bottom: `${y}%`,
        width: '60px',
        height: '70px',
        cursor: 'pointer',
        transition: 'transform 0.1s',
      }}
      onClick={handlePop}
    >
      {!popped ? (
        <svg viewBox="0 0 60 70" width="60" height="70">
          <defs>
            <radialGradient id={`grad-${id}`} cx="40%" cy="40%" r="50%" fx="40%" fy="40%">
              <stop offset="0%" style={{ stopColor: 'white', stopOpacity: 0.3 }} />
              <stop offset="100%" style={{ stopColor: color, stopOpacity: 1 }} />
            </radialGradient>
          </defs>
          <path d="M30 5 C 10 5, 5 30, 5 50 C 5 65, 15 65, 30 65 C 45 65, 55 65, 55 50 C 55 30, 50 5, 30 5" fill={`url(#grad-${id})`} />
          <path d="M30 65 L28 70 M30 65 L32 70" stroke={color} strokeWidth="1.5" />
          <text x="30" y="40" fontSize="24" fontWeight="bold" fill="white" textAnchor="middle" dominantBaseline="middle">{letter}</text>
        </svg>
      ) : (
        <div className="explosion" style={{
          width: '60px',
          height: '60px',
          borderRadius: '50%',
          background: color,
          animation: 'explode 0.3s forwards',
        }} />
      )}
    </div>
  );
};

const WordDisplay = ({ word, guessedLetters }) => (
  <div style={{ position: 'absolute', top: '50px', left: '50%', transform: 'translateX(-50%)', display: 'flex', gap: '10px' }}>
    {word.split('').map((letter, index) => (
      <div key={index} style={{ width: '30px', height: '40px', border: '2px solid black', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '24px', fontWeight: 'bold' }}>
        {guessedLetters.includes(letter) ? letter : ''}
      </div>
    ))}
  </div>
);

const BalloonGame = () => {

  const { showAlert } = useAlert();

  const [balloons, setBalloons] = useState([]);
  const [score, setScore] = useState(0);
  const [currentWord, setCurrentWord] = useState('');
  const [guessedLetters, setGuessedLetters] = useState([]);
  const [level, setLevel] = useState(1);
  const [gameStarted, setGameStarted] = useState(false);
  const [audioUrl, setAudioUrl] = useState('');
  const audioRef = useRef(null);

  const getDifficultyFactor = useCallback(() => {
    // Suaviza a curva de dificuldade até o nível 20
    return (level - 1) / 19; // Ajustado para uma curva mais gradual
  }, [level]);

  useEffect(() => {
    setCurrentWord(words[Math.floor(Math.random() * words.length)]);
    setGameStarted(true);
  }, []);

  useEffect(() => {
    if (!gameStarted) return;

    const createBalloon = () => {
      const remainingLetters = currentWord.split('').filter(l => !guessedLetters.includes(l));
      const correctLetterProbability = Math.max(0.1, 0.5 - (getDifficultyFactor() * 0.3));

      let letter;
      if (Math.random() < correctLetterProbability && remainingLetters.length > 0) {
        letter = remainingLetters[Math.floor(Math.random() * remainingLetters.length)];
      } else {
        letter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
      }

      letter = letter.toUpperCase(); // Assegura que a letra é maiúscula

      const newBalloon = {
        id: Date.now(),
        x: Math.random() * 90 + 5,
        y: -10,
        letter: letter,
        color: `hsl(${Math.random() * 360}, 80%, 60%)`,
      };
      setBalloons(prevBalloons => [...prevBalloons, newBalloon]);
    };

    // Ajusta a frequência de criação dos balões com base na dificuldade
    const interval = setInterval(createBalloon, Math.max(500, 1200 - (getDifficultyFactor() * 500))); // Frequência inicial alta e aumento gradual menor
    return () => clearInterval(interval);
  }, [currentWord, guessedLetters, level, gameStarted, getDifficultyFactor]);

  useEffect(() => {
    if (!gameStarted) return;

    const moveBalloons = () => {
      setBalloons(prevBalloons =>
        prevBalloons
          .map(balloon => ({
            ...balloon,
            y: balloon.y + (0.1 + getDifficultyFactor() * 0.05)  // Velocidade de movimento ajustada
          }))
          .filter(balloon => balloon.y < 110)
      );
    };

    const animationFrame = requestAnimationFrame(function animate() {
      moveBalloons();
      requestAnimationFrame(animate);
    });

    return () => cancelAnimationFrame(animationFrame);
  }, [level, gameStarted, getDifficultyFactor]);

  const popBalloon = useCallback((id, letter) => {
    setBalloons(prevBalloons => prevBalloons.filter(balloon => balloon.id !== id));
    if (currentWord.includes(letter) && !guessedLetters.includes(letter)) {
      setGuessedLetters(prev => [...prev, letter]);
      setScore(prevScore => prevScore + 10);
    } else {
      setScore(prevScore => Math.max(0, prevScore - 5));
    }
  }, [currentWord, guessedLetters]);

  useEffect(() => {
    const isWordComplete = currentWord.split('').every(letter => guessedLetters.includes(letter));
    if (gameStarted && isWordComplete && currentWord) {
      setTimeout(() => {
        showAlert(
          "Parabéns!",
          `Você completou a palavra "${currentWord}"!`,
          [
            { text: "Ok", onPress: () => console.log('') },
          ]
        );
        setCurrentWord(words[Math.floor(Math.random() * words.length)]);
        setGuessedLetters([]);
        setScore(prevScore => prevScore + 50);
        setLevel(prevLevel => prevLevel + 1);
      }, 500);
    }
  }, [guessedLetters, currentWord, gameStarted]);

  const speak = async () => {
    const url = await sendTextToSpeechRequest(currentWord, "Danielle");
    setAudioUrl(url);
  };

  useEffect(() => {
    speak();
  }, [currentWord]);

  useEffect(() => {
    if (audioUrl && audioRef.current) {
      audioRef.current.play();
    }
  }, [audioUrl]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100vh', backgroundColor: '#e0f7ff', overflow: 'hidden', userSelect: 'none' }}>
      {audioUrl && (
        <audio
          src={audioUrl}
          ref={audioRef}
          style={{ display: 'none' }}
        />
      )}
      <style>
        {`
          @keyframes explode {
            0% {
              transform: scale(1);
              opacity: 1;
            }
            100% {
              transform: scale(2);
              opacity: 0;
            }
          }
        `}
      </style>
      <span style={{ position: 'absolute', top: '20px', left: '20px', fontSize: '24px', fontFamily: 'Din-Round-Bold, sans-serif', fontWeight: 'bold' }}>
        Pontuação: {score}
      </span>
      <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#FFFFFF', fontFamily: 'Din-Round-Bold, sans-serif', textShadow: '2px 2px 4px rbga(0, 0, 0, 0.3)', fontSize: '64px', fontWeight: 'bold' }}>
        Nível: {level}
      </span>
      <IconButton onClick={speak} sx={{ position: 'absolute', bottom: '20px', left: '20px' }}>
        <VolumeUpTwoTone color='#007aff' />
      </IconButton>
      <WordDisplay word={currentWord} guessedLetters={guessedLetters} />
      {balloons.map(balloon => (
        <Balloon key={balloon.id} {...balloon} onPop={popBalloon} />
      ))}
    </div>
  );
};

export default BalloonGame;