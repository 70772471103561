import { db } from '../../auth/config/firebase';
import { doc, getDoc } from 'firebase/firestore';

export const checkIfBlocked = async (uidToCheck, currentUserUid) => {
    if (currentUserUid && uidToCheck) {
        const blockedUserRef = doc(db, 'community', currentUserUid, 'blockedUsers', uidToCheck);
        const docSnap = await getDoc(blockedUserRef);
        return docSnap.exists();
    }
    return false;
};

export const checkIfFollowing = async (uidToCheck, currentUserUid) => {
    if (currentUserUid && uidToCheck) {
        const followingRef = doc(db, 'community', currentUserUid, 'following', uidToCheck);
        const docSnap = await getDoc(followingRef);
        return docSnap.exists();
    }
    return false;
};

export const checkIfLiked = async (uidToCheck, currentUserUid) => {
    if (currentUserUid && uidToCheck) {
        const likeRef = doc(db, 'community', currentUserUid, 'givenLikes', uidToCheck);
        const docSnap = await getDoc(likeRef);
        return docSnap.exists();
    }
    return false;
};

export const checkIfFavorited = async (uidToCheck, currentUserUid) => {
    if (currentUserUid && uidToCheck) {
        const favoriteRef = doc(db, 'community', currentUserUid, 'favorites', uidToCheck);
        const docSnap = await getDoc(favoriteRef);
        return docSnap.exists();
    }
    return false;
};
