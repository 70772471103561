import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

export default function Alert({ title, paragraph, buttons = [], showAlert, hideAlert }) {
    const handleButtonClick = (onPress) => {
        return () => {
            if (onPress) {
                onPress();
            }
            hideAlert();
        };
    };

    const formatButtonText = (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    };

    return (
        <Dialog
            open={showAlert}
            onClose={hideAlert}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                sx: {
                    backdropFilter: 'saturate(180%) blur(20px)',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: '10px',
                    boxShadow: '0 3px 1px -2px rgba(0, 0, 0, 0.167), 0 2px 2px 0 rgba(0, 0, 0, 0.167), 0 1px 5px 0 rgba(0, 0, 0, 0.167)',
                    animation: 'scale-in-center 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
                    maxWidth: '350px' // Ensure it doesn't exceed 350px
                }
            }}
            BackdropProps={{
                sx: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)'
                }
            }}
        >
            <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', paddingBottom: '3px' }}>
                <Typography variant="h6" component="h3" sx={{ fontFamily: '"SF-Pro-Display-Bold", sans-serif', color: '#1d1d1f' }}>
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent dividers sx={{ textAlign: 'center', border: 'none', paddingTop: 0 }}>
                <Typography variant="body1" sx={{ fontFamily: '"SF-Pro-Text", sans-serif', color: '#1d1d1f', fontWeight: 500 }}>
                    {paragraph}
                </Typography>
            </DialogContent>
            <DialogActions
                sx={{
                    display: 'flex',
                    padding: 0,
                    justifyContent: 'center'
                }}
            >
                {buttons.map((button, index) => (
                    <Button
                        key={index}
                        onClick={handleButtonClick(button.onPress)}
                        sx={{
                            width: buttons.length === 1 ? '100%' : '50%',
                            borderTop: '1px solid #C1C1C1',
                            borderRight: index % 2 === 0 ? '1px solid #C1C1C1' : 'none',
                            borderLeft: '0px !important',
                            color: button.redText ? 'red' : '#5ba8f0',
                            fontSize: '16px',
                            fontWeight: 500,
                            borderRadius: 0,
                            margin: 0,
                            marginLeft: '0px !important',
                            whiteSpace: 'nowrap', // Prevent line breaks inside buttons
                            textTransform: 'none', // Preserve text case
                            '&:hover': {
                                backgroundColor: '#F5F5F5',
                                transition: '0.2s'
                            }
                        }}
                    >
                        {formatButtonText(button.text)}
                    </Button>
                ))}
            </DialogActions>
        </Dialog>
    );
}
