import React, { createContext, useState, useContext } from 'react';

// Criando o contexto
const PostContext = createContext();

export const usePost = () => useContext(PostContext);

// Provedor do contexto
export const PostProvider = ({ children }) => {
    const [clickedPost, setClickedPost] = useState(null);

    const setPost = (post) => {
        setClickedPost(post);
    };

    return (
        <PostContext.Provider value={{ clickedPost, setPost }}>
            {children}
        </PostContext.Provider>
    );
};
