import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import './style.css';

export default function AudioPlayer({ audioSrc }) {
    const waveformRef = useRef();
    const [playing, setPlaying] = useState(false);
    const [wavesurfer, setWavesurfer] = useState(null);

    useEffect(() => {
        const ws = WaveSurfer.create({
            container: waveformRef.current,
            waveColor: '#D9D9D9',
            progressColor: '#1D1D1F',
            height: 30,
            barWidth: 1.5,
            cursorWidth: 0,
        });

        ws.load(audioSrc);

        ws.on('ready', () => {
            setWavesurfer(ws);
        });

        // Adicionando um listener para o evento 'finish'
        ws.on('finish', () => {
            setPlaying(false);  // Alterando o estado para 'false' quando a reprodução for concluída
        });

        return () => {
            ws && ws.destroy();
        };
    }, [audioSrc]);

    const togglePlayPause = () => {
        if (wavesurfer) {
            if (playing) {
                wavesurfer.pause();
            } else {
                wavesurfer.play();
            }
            setPlaying(!playing);
        }
    };

    return (
        <div className="audio-player ignore-click">
            <button onClick={togglePlayPause}>
                <FontAwesomeIcon icon={playing ? faPause : faPlay} />
            </button>
            <div ref={waveformRef} className="wavesSoud"></div>
        </div>
    );
}
