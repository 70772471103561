import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import { Box, Skeleton } from '@mui/material';

const ContainerSituations = styled('div')({
    display: 'grid',
    gap: '30px',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridAutoRows: 'min-content',
    justifyContent: 'center',
    maxWidth: '1000px',
    width: '100%',
    padding: '0',
    overflow: 'visible',
    position: 'relative',
    paddingTop: '10px',
});

const ContainerBoxSituation = styled(Link)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    backgroundColor: theme.background,
    borderRadius: '20px',
    border: '2px solid transparent',
    gap: '20px',
    fontFamily: '"SF-Pro-Display", sans-serif',
    textDecoration: 'none',
    position: 'relative',
    boxShadow: `0px 2px 0px ${theme.border}`,
    border: `2px solid ${theme.border}`,
    '&:active': {
        boxShadow: `0 0px 0 ${theme.border}`,
        transform: 'translateY(2px)'
    }
}));

const BoxImageSituation = styled('div')({
    width: '100%',
    height: '185px',
    position: 'relative',
    '& img': {
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'block',
        objectFit: 'cover',
        borderRadius: '20px',
    },
});

const ContentBoxSituation = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    '& h3': {
        color: theme.color,
        fontFamily: 'Din-Round-Bold, sans-serif',
        fontSize: '18px',
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
    },
    '& p': {
        fontFamily: '"SF-Pro-Text", sans-serif',
        color: theme.color,
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
    },
}));

export default function ItemSituation({ situations, theme }) {
    return (
        <ContainerSituations>
            {situations.length === 0 ? (
                Array.from(new Array(30)).map((_, index) => (
                    <Box key={index} sx={{ width: '100%', padding: '20px', backgroundColor: theme.background, borderRadius: '20px', border: `2px solid ${theme.border}` }}>
                        <Skeleton variant="rectangular" width="100%" height={185} sx={{ borderRadius: '20px' }} />
                        <Skeleton variant="text" sx={{ fontSize: '18px', margin: '10px 0' }} />
                        <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                    </Box>
                ))
            ) : (
                situations.map((situation, index) => (
                    <ContainerBoxSituation theme={theme} key={index} to={`/scenarios/${situation.id}`}>
                        <BoxImageSituation>
                            <img src={situation.image_url} alt={situation.alt} draggable={false} />
                        </BoxImageSituation>
                        <ContentBoxSituation theme={theme}>
                            <h3>{situation.title}</h3>
                            <p>{situation.goal}</p>
                        </ContentBoxSituation>
                    </ContainerBoxSituation>
                ))
            )}
        </ContainerSituations>
    );
}
