import { useState, useEffect, useRef } from 'react';
import { UserContext } from '../components/UserProvider';
import { useContext } from 'react';
import SimpleMenu from '../components/SimpleMenu';
import { Box, Container, Button } from '@mui/material';
import './style.css'
import ReviewCard from './ReviewCard';
import PayComponent from './PayComponent';
import AdComponent from './AdComponent';
import AddFriend from './addFriend';
import HeadComponent from './headComponent';
import Collections from './CollectionsComponent';
import GamesComponent from './gamesComponent';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import AnimatedButton from './AnimatedButton'
import { useNavigate } from 'react-router-dom';
import { useAllTheme } from '../components/themes/ThemeContext';

export default function Home() {

    const { user, displayName, streakDay } = useContext(UserContext)
    const navigate = useNavigate();

    const { theme, isDark } = useAllTheme();

    return (
        <div className={isDark ? 'allHome dark' : 'allHome'}>
            <SimpleMenu />
            <Box className="home" sx={{ padding: '24px' }}>
                <Container sx={{ display: 'flex', gap: '48px', width: '100%' }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '41px' }}>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <ReviewCard />
                            <AnimatedButton onClick={() => navigate('/lexi/INY-Sdqa-ZeO-T/classMode')} sx={{ mt: '6px', display: 'flex', alignItems: 'center', gap: '6px' }}>Modo Aula (15 min) <AccessTimeIcon /></AnimatedButton>
                        </Box>
                        <Collections />
                        <GamesComponent />
                    </Box>
                    <Box sx={{ width: '360px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px', position: 'sticky', top: 0, }}>
                            <HeadComponent />
                            <PayComponent />
                            <AddFriend />
                            <AdComponent />
                        </Box>
                    </Box>
                </Container>
            </Box>
        </div>
    )
}