import React, { useState, useEffect } from 'react';
import { Container, Box, Button, Typography, IconButton, Snackbar } from '@mui/material';
import { Close as CloseIcon, Check as CheckIcon, Close as IncorrectIcon } from '@mui/icons-material';
import ProgressBar from '../components/ProgressBar';
import ActionButtonsContainer from '../components/ActionButtonsContainer';
import Exercise from '../components/exercises';
import FeedbackSnackbar from '../components/FeedbackSnackbar';
import { LessonProvider, useLesson } from '../LessonContext';
import { useAllTheme } from '../../components/themes/ThemeContext';
import { useLocation } from 'react-router-dom';
// import SuccessLesson from '../screens/learn/lesson/successLesson';

const lessonData = [
    {
        type: 'SentenceBuilder',
        data: {
            word_pool: ['Greetings', 'to', 'the', 'people', 'there', 'Hello', 'world', 'sunshine', 'sky', 'blue'],
            correct_sentence: ['Hello', 'there']
        }
    },
    {
        type: 'AudioExercise',
        data: {
            choices: ["cat", "dog", "bird", "mouse"],
            correct_answer: "cat"
        }
    },
    {
        type: 'Explanation',
        data: {
            explanation_text: 'We can use some of these words together to talk about other periods of time in the past and in the future. Look at the examples below. Can you spot the exception to the rule?',
            examples: ["yesterday morning, yesterday afternoon, yesterday evening, last night", "tomorrow morning, tomorrow afternoon, tomorrow evening, tomorrow night"]
        }
    },
    {
        type: 'GapFillExercise',
        data: {
            sentence: 'The ________ is bigger than the mouse.',
            correct_word: 'elephant'
        }
    },
    {
        type: 'FillTheGapsExercise',
        data: {
            textoEInputs: [
                { texto: "So we come to the " },
                { response: "stion", precedingText: "que", precedingTextClass: "customClass" },
                { texto: " of " },
                { response: "ether", precedingText: "wh", precedingTextClass: "customClass" },
                { texto: " it is better to be " },
                { response: "ved", precedingText: "lo", precedingTextClass: "customClass" },
                { texto: " than to be feared. The " },
                { response: "wer", precedingText: "ans", precedingTextClass: "customClass" },
                { texto: " is that it would be " },
                { response: "sirable", precedingText: "de", precedingTextClass: "customClass" },
                { texto: " to be both loved and " },
                { response: "ed", precedingText: "fear", precedingTextClass: "customClass" },
                { texto: ", but as such a " },
                { response: "ation", precedingText: "combin", precedingTextClass: "customClass" },
                { texto: " is " },
                { response: "cult", precedingText: "diffi", precedingTextClass: "customClass" },
                { texto: ", it is much safer to be " },
                { response: "ed", precedingText: "fe", precedingTextClass: "customClass" },
                { texto: " if a choice has to be " },
                { response: "de", precedingText: "ma", precedingTextClass: "customClass" },
                { texto: "." }
            ]
        }
    },
    {
        type: 'SpeakExercise',
        data: {
            phrase_to_say: "Say yes to new adventures."
        }
    },
    {
        type: 'AudioWordMatchExercise',
        data: {
            word_list: ["Apple", "Orange", "Banana", "Grape", "Pineapple"]
        }
    },
    {
        type: 'SelectRealEnglishWord',
        data: {
            word_choices: ["Hello", "unireal", "testi", "unreal", "test", "tanks"],
            correct_words: ["Hello", "unreal", "test"]
        }
    },
    {
        type: 'CompleteConversation',
        data: {
            initial_message: "I'm from Canada, and you?",
            response_options: ["Thanks for the coffee", "I'm from Brazil"],
            correct_response: "I'm from Brazil"
        }
    },
    {
        type: 'WriteExercise',
        data: {
            original_sentence: "The quick brown fox jumps over the lazy dog",
            shuffled_sentence: "fox the dog jumps quick lazy over brown the"
        }
    },
    {
        type: 'MultipleChoiceExercise',
        data: {
            question: 'Qual é a capital da França?',
            options: ['Paris', 'Berlim', 'Londres'],
            correct_answer: 'Paris'
        }
    }
];

const LessonComponent = ({ data, enableSkip, verifyButton, messages, setMessages, handleSendMessage }) => {
    const {
        progress,
        controls,
        scores,
        setScores,
        nextExercise,
        handleCheck,
        handleSkip,
        setCheckFunction,
        setProgress,
        setControls
    } = useLesson();

    useEffect(() => {
        const preventReload = (event) => {
            event.preventDefault();
            event.returnValue = 'Are you sure you want to leave?';
        };

        window.addEventListener('beforeunload', preventReload);

        return () => {
            window.removeEventListener('beforeunload', preventReload);
        };
    }, []);

    const exercises = [...data];
    const currentExercise = exercises[controls.currentExerciseIndex];
    const progressValue = ((controls.currentExerciseIndex) / exercises.length) * 100;

    const updateLessonStatus = (lessonId, newStatus, userResponse) => {
        setMessages((prevMessages) => {
            const updatedMessages = prevMessages.map((msg) => {
                if (msg.tool_calls && msg.tool_calls.length > 0) {
                    let allAttempted = true;
                    let lessonUpdated = false;

                    const updatedToolCalls = msg.tool_calls.map((toolCall) => {
                        if (toolCall.id === lessonId) {
                            if (toolCall.lessonStatus !== 'not_attempted') {
                                return toolCall; // Se a lição já foi tentada, não faz nada
                            } else {
                                lessonUpdated = true;
                                return {
                                    ...toolCall,
                                    lessonStatus: newStatus,
                                    userResponse: userResponse
                                };
                            }
                        }

                        if (toolCall.lessonStatus === 'not_attempted') {
                            allAttempted = false;
                        }

                        return toolCall;
                    });

                    if (!lessonUpdated) {
                        return msg; // Se a lição já foi tentada, sai cedo
                    }

                    if (allAttempted) {
                        const fullMessageData = {
                            ...msg,
                            tool_calls: updatedToolCalls
                        };

                        handleSendMessage(
                            '',  // message
                            [],  // files
                            { preventDefault: () => { } },  // event
                            true,  // speak
                            fullMessageData  // full message data
                        );
                    }

                    return {
                        ...msg,
                        tool_calls: updatedToolCalls
                    };
                }

                return msg;
            });

            return updatedMessages;
        });
    };


    const handleNextExercise = () => {
        const status = progress.type === 'incorrect' ? 'incorrect' : 'correct';
        if (status === 'incorrect') {
            if (exercises.length > 1) {
                exercises.push(currentExercise);
            }
            setScores((prev) => ({ ...prev, incorrect: prev.incorrect + 1 }));
        } else {
            setScores((prev) => ({ ...prev, correct: prev.correct + 1 }));
        }

        if (typeof verifyButton === 'function') {
            updateLessonStatus(currentExercise.id, status, progress.userResponse);
            verifyButton()
        }
        else {
            nextExercise();
            setProgress({ type: '', explanation: '', userResponse: '' });
        }
    };

    const { theme, isDark } = useAllTheme();

    const location = useLocation();
    const currentPath = (location.pathname == '/lessons/exercise');

    return (
        <Box style={{ width: currentPath ? '100vw' : '', height: currentPath ? '100vh' : '' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: theme.background }}>
                <Container sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', p: 2, bgcolor: theme.background }}>
                    {data.length > 1 && (
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2, mb: 2, maxWidth: '1100px', width: '100%' }}>
                            <IconButton sx={{ fontSize: '20px', color: theme.color }}>
                                <CloseIcon />
                            </IconButton>
                            <ProgressBar progress={progressValue} />
                        </Box>
                    )}
                    <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        {currentExercise ? (
                            <Exercise
                                currentExercise={currentExercise}
                                setCheckFunction={setCheckFunction}
                                setProgress={setProgress}
                                setControls={setControls}
                                controls={controls} // Passa o estado de controls
                                nextExercise={nextExercise}
                            />
                        ) : (
                            <Typography variant="h6" sx={{ color: theme.color }}>Loading...</Typography>
                        )}
                    </Box>
                </Container>
                <ActionButtonsContainer enableSkip={enableSkip} onSkip={handleSkip} onCheck={handleCheck} isCheckEnabled={!controls.isDisabled} theme={theme} />
                {progress.type && (
                    <FeedbackSnackbar
                        response={progress.type}
                        explanation={progress.explanation}
                        nextExercise={handleNextExercise}
                        setResponse={(response) => setProgress((prev) => ({ ...prev, type: response }))}
                    />
                )}
            </Box>
        </Box>
    );
};

const Lesson = ({ data = lessonData, enableSkip = true, verifyButton = null, messages, setMessages, handleSendMessage }) => (
    <LessonProvider>
        <LessonComponent data={data} enableSkip={enableSkip} verifyButton={verifyButton} messages={messages} setMessages={setMessages} handleSendMessage={handleSendMessage} />
    </LessonProvider>
);


export default Lesson;