import './style.css'
import { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faClose } from '@fortawesome/free-solid-svg-icons';
import { db } from '../../auth/config/firebase';
import { auth } from '../../auth/config/firebase';
import { doc, setDoc } from 'firebase/firestore';

import socialMedia from '../../assets/icons/social-media.png'
import googleLogo from '../../assets/icons/google.png'
import parents from '../../assets/icons/people.png'
import others from '../../assets/icons/more.png'
import youtubeLogo from '../../assets/icons/youtube.png'

import A1 from '../../assets/images/iconsLevels/educacao.png'
import A2 from '../../assets/images/iconsLevels/ponto-intermediario.png'
import B1 from '../../assets/images/iconsLevels/intermediario.png'
import B2 from '../../assets/images/iconsLevels/intermediario (1).png'
import C1 from '../../assets/images/iconsLevels/avanco.png'
import C2 from '../../assets/images/iconsLevels/crescimento.png'

import listening from '../../assets/images/iconAbility/eavesdropping.png'
import writing from '../../assets/images/iconAbility/writing.png'
import reading from '../../assets/images/iconAbility/read.png'
import speaking from '../../assets/images/iconAbility/speaking.png'

import yes from '../../assets/images/iconsYesOrNo/yes.png'
import no from '../../assets/images/iconsYesOrNo/check.png'
import finish from '../../assets/images/iconsYesOrNo/dashboard.png'
import eng from '../../assets/images/iconsYesOrNo/teaching.png'

import { useNavigate } from 'react-router-dom';

function AboutTheUser() {
    const swiperRef = useRef(null);
    const totalPages = 8; // Número total de páginas

    const [activeIndex, setActiveIndex] = useState(0);
    const progress = (activeIndex / (totalPages - 1)) * 100;

    const slidesData = [
        {
            title: 'Como você soube do Talk2MeIA?',
            type: 'image',
            options: [
                { image: youtubeLogo, label: 'Youtube' },
                { image: googleLogo, label: 'Busca do google' },
                { image: socialMedia, label: 'Facebook ou instagram' },
                { image: parents, label: 'Amigos ou família' },
                { image: others, label: 'Outros' }
            ]
        },
        {
            title: 'Qual nivel você acha que esta de inglês?',
            type: 'image',
            options: [
                { image: A1, label: 'A1: Inglês Básico' },
                { image: A2, label: 'A2: Inglês Pré-Intermediário' },
                { image: B1, label: 'B1: Inglês Intermediário' },
                { image: B2, label: 'B2: Inglês Alto Intermediário' },
                { image: C1, label: 'C1: Inglês Avançado' },
                { image: C2, label: 'C2: Inglês Fluente' }
            ]
        },
        {
            title: 'Qual habilidade você tem mais dificuldade e gostaria de focar no inglês?',
            type: 'image',
            options: [
                { image: speaking, label: 'Speaking' },
                { image: listening, label: 'Listening' },
                { image: writing, label: 'Writing' },
                { image: reading, label: 'Reading' }
            ]
        },
        {
            title: 'Quanto tempo você gostaria de dedicar para aprender inglês por dia?',
            type: 'text',
            options: [
                { label: 'Casual', subtext: '15 min / dia' },
                { label: 'Regular', subtext: '30 min / dia' },
                { label: 'Intenso', subtext: '45 min / dia' },
                { label: 'Puxado', subtext: '1 h / dia' }
            ]
        },
        {
            title: 'Você se sente confortável em falar inglês com alguém?',
            type: 'image',
            options: [
                { image: yes, label: 'Yes, I do' },
                { image: no, label: 'No, I don\'t' }
            ]
        },
        {
            title: 'Há quanto tempo você estuda inglês?',
            type: 'text',
            options: [
                { label: 'Pouco', subtext: '0 to 3 months' },
                { label: 'Regular', subtext: '3 to 6 months' },
                { label: 'Intenso', subtext: '6 to 12 months' },
                { label: 'Puxado', subtext: '12 to 24 months' }
            ]
        },
        {
            title: 'Você tem o hábito de assistir filmes ou séries em inglês?',
            type: 'image',
            options: [
                { image: yes, label: 'Yes, I do' },
                { image: no, label: 'No, I don\'t' }
            ]
        },
        {
            title: 'Gostaria de fazer um teste de inglês para sabermos o seu nível de inglês?',
            type: 'image',
            options: [
                { image: eng, label: 'Yes, I do' },
                { image: finish, label: 'No, I don\'t' }
            ]
        }
    ];

    const [selectedOptions, setSelectedOptions] = useState(Array(slidesData.length).fill(null));

    const handleBoxClick = (slideIndex, optionLabel) => {
        const newSelectedOptions = { ...selectedOptions };
        newSelectedOptions[slideIndex] = optionLabel;
        setSelectedOptions(newSelectedOptions);
    };

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex);
    };

    useEffect(() => {
        setActiveIndex(0);
    }, []);

    const goToNextPage = () => {
        console.log(selectedOptions);
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const goToPreviousPage = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const sendDataToFirestore = async () => {
        // Certifique-se de que um usuário está autenticado
        if (auth.currentUser) {
            const uid = auth.currentUser.uid;
            const userRef = doc(db, 'users', uid);

            await setDoc(userRef, {
                selectedOptions
            }, { merge: true })
                .then(() => {
                    console.log("Document successfully written!");
                })
                .catch((error) => {
                    console.error("Error writing document: ", error);
                });
        } else {
            console.log("No user is signed in.");
        }
    }

    return (
        <div className='allAboutUser'>
            <div className='containerAboutTheUser'>
                <div className='containerAboutTheUserContent'>
                    <div className='flexContainerAboutTheUser01'>
                        {activeIndex
                            ? <FontAwesomeIcon icon={faArrowLeft} onClick={goToPreviousPage} />
                            : <FontAwesomeIcon icon={faClose} />
                        }
                        <div className="progressBarContainer">
                            <div className="progressBar" style={{ width: `${progress}%` }}></div>
                        </div>
                    </div>

                    <Swiper ref={swiperRef} onSlideChange={handleSlideChange} className='swiperPages' simulateTouch={false}>
                        {slidesData.map((slide, slideIndex) => (
                            <SwiperSlide key={slideIndex} className='swiper-slide' id='page1'>
                                <h2>{slide.title}</h2>
                                <div className={`${slide.type === 'image' ? 'containerSlide' : 'containerSlideText'}`}>
                                    {slide.options.map((option, optionIndex) => (
                                        <div
                                            key={optionIndex}
                                            className={`${slide.type === 'image' ? 'boxKnow' : 'option'} ${selectedOptions[slideIndex] === option.label ? 'boxActive' : ''}`}
                                            onClick={() => handleBoxClick(slideIndex, option.label)}>
                                            {slide.type === 'image' && <img src={option.image} alt='Image option' />}
                                            <h3>{option.label}</h3>
                                            {slide.type === 'text' && <p>{option.subtext}</p>}
                                        </div>
                                    ))}
                                </div>
                                <button
                                    disabled={!selectedOptions[slideIndex]}
                                    onClick={slideIndex === slidesData.length - 1 ? sendDataToFirestore : goToNextPage}
                                >
                                    Continuar
                                </button>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
}

export default AboutTheUser;