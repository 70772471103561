import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh } from '@fortawesome/free-solid-svg-icons';
import { Box, Typography, Container } from '@mui/material';
import soundImage from '../../../../assets/images/sound.png';
import soundDisableImage from '../../../../assets/images/soundDisable.png';
import { sendTextToSpeechRequest } from '../../../../componentsTalkIA/IAtalk2ME/Talk2MeVoice';
import { OptionButton } from '../../StyledComponents';
import { useAllTheme } from '../../../../components/themes/ThemeContext';

function AudioWordMatchExercise({ exercicio, setCheckFunction, setProgress, setControls, controls }) {
    const [selectedWord, setSelectedWord] = useState(null);
    const [selectedAudio, setSelectedAudio] = useState(null);
    const matchedPairsRef = useRef([]);
    const [shuffledWordList, setShuffledWordList] = useState([]);

    useEffect(() => {
        setCheckFunction(() => check);
        // console.log('setCheckFunction configurada');
    }, [setCheckFunction]);

    useEffect(() => {
        if (!controls.isChecked) {
            setSelectedWord(null);
            setSelectedAudio(null);
            matchedPairsRef.current = [];
            if (exercicio.data && exercicio.data.word_list) {
                setShuffledWordList(shuffle([...exercicio.data.word_list]));
            }
            // console.log("Estado resetado");
        }
    }, [controls.isChecked, exercicio.data]);

    const [audioUrl, setAudioUrl] = useState('');
    const audioRef = useRef(null);

    const speak = async (text) => {
        const url = await sendTextToSpeechRequest(text, "Danielle");
        setAudioUrl(url);
    };

    useEffect(() => {
        if (audioUrl && audioRef.current) {
            audioRef.current.play();
        }
    }, [audioUrl]);

    useEffect(() => {
        if (selectedWord && selectedAudio) {
            if (selectedWord === selectedAudio) {
                matchedPairsRef.current.push(selectedWord);
                console.log("Novo par correspondido:", matchedPairsRef.current);
            }
            setSelectedWord(null);
            setSelectedAudio(null);
        }
    }, [selectedWord, selectedAudio]);

    useEffect(() => {
        if (exercicio.data && exercicio.data.word_list && matchedPairsRef.current.length === exercicio.data.word_list.length) {
            setControls((prev) => ({ ...prev, isDisabled: false, isChecked: false }));
            console.log("Todos os pares correspondem. Botão habilitado.");
        }
    }, [matchedPairsRef.current.length, exercicio.data, setControls]);

    const shuffle = (array) => {
        let currentIndex = array.length, temporaryValue, randomIndex;
        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    };

    const handleWordClick = (word) => {
        if (!matchedPairsRef.current.includes(word)) {
            setSelectedWord(word);
        }
    };

    const handleAudioClick = (word) => {
        if (!matchedPairsRef.current.includes(word)) {
            setSelectedAudio(word);
            speak(word);
        }
    };

    const check = () => {
        if (exercicio.data && exercicio.data.word_list && matchedPairsRef.current.length === exercicio.data.word_list.length) {
            setProgress({
                type: 'correct',
                explanation: 'Congratulations! You got the answer right.',
                userResponse: ''
            });
        } else {
            setProgress({
                type: 'incorrect',
                explanation: `incorrect pairs.`,
                userResponse: ''
            });
        }
        setControls((prev) => ({ ...prev, isChecked: true }));
    };

    if (!exercicio.data || !exercicio.data.word_list) {
        return <Typography variant="h6">No word list available for this exercise.</Typography>;
    }

    const { theme, isDark } = useAllTheme();

    return (
        <Box>
            {audioUrl &&
                <audio
                    src={audioUrl}
                    ref={audioRef}
                    controls
                    style={{ display: 'none' }}
                />
            }
            <Typography variant="h4" textAlign="center" sx={{ mb: 2, fontFamily: "SF-Pro-Display, sans-serif", color: theme.color }}>
                Connect the pairs
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {shuffledWordList.map((word, index) => (
                        <OptionButton
                            theme={theme}
                            key={index}
                            onClick={() => handleAudioClick(word)}
                            disabled={matchedPairsRef.current.includes(word) || controls.isChecked}
                            className={(word === selectedAudio && !matchedPairsRef.current.includes(word)) ? 'optionSelected' : ''}
                            sx={{ minWidth: '300px' }}
                        >
                            <Box className="buttonOptionListeningNumber">
                                {index + 1}
                            </Box>
                            <Box sx={{ width: 'calc(100% - 38px)', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                                <FontAwesomeIcon color={matchedPairsRef.current.includes(word) || controls.isChecked ? '#E5E9EE' : '#1899D6'} icon={faVolumeHigh} />
                                <img src={matchedPairsRef.current.includes(word) || controls.isChecked ? soundDisableImage : soundImage} style={{ width: 40 }} alt="Sound Icon" />
                            </Box>
                        </OptionButton>
                    ))}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {exercicio.data.word_list.map((word, index) => (
                        <OptionButton
                            theme={theme}
                            key={index}
                            onClick={() => handleWordClick(word)}
                            disabled={matchedPairsRef.current.includes(word) || controls.isChecked}
                            className={(word === selectedWord && !matchedPairsRef.current.includes(word)) ? 'optionSelected' : ''}
                            sx={{ minWidth: '300px' }}
                        >
                            <Box className="buttonOptionListeningNumber">
                                {index + 1}
                            </Box>
                            <Typography variant="body1" textAlign="center" sx={{ width: 'calc(100% - 38px)' }}>{word}</Typography>
                        </OptionButton>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}

export default AudioWordMatchExercise;
