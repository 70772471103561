import React, { useState, createContext, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { doc, onSnapshot, getDoc } from 'firebase/firestore';
import { signOut } from 'firebase/auth'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './auth/config/firebase';
import { db } from './auth/config/firebase';

import Loader from './components/loader';

import { BallTalkProvider } from './componentsTalkIA/BallTalkContext';
import { NotesProvider } from './talk2IA/context/NotesContext';
import { AlertProvider } from './components/AlertComponent/AlertContext';
import { UserProvider } from './components/UserProvider';
import { CaptureProvider } from './talk2IA/Capture/CaptureContext';
import { ThemeProvider } from './talk2IA/themes/ThemeContext';

import AboutUser from './login/aboutUser';
import Login from './login';
import Home from './home';
import Lexi from './talk2IA';
import Sounds from './sounds';
import Notes from './notes';
import LessonScreen from './lessons';
import ClassMode from './ClassMode';
import Type from './games/type';
import BalloonGame from './games/BalloonGame';
import StartCommunity from './community/startCommunity';
import Interview from './community/interview';
import SessionCall from './community/sessionCall';
import MainContainer from './community';
import Config from './config';
import { PostProvider } from './community/componentsCommunity/PostContext';
import { PostInteractionProvider } from './community/componentsCommunity/PostInteractionContext';
import { StatusPostingProvider } from './community/componentsCommunity/PostingContext';
import { CallProvider } from './community/componentsCommunity/CallContext';
import Plans from './config/plans';
import { AllThemeProvider } from './components/themes/ThemeContext';
import Lesson from './lessons/LessonComponent';
import MainScenarios from './scenarios/MainScenarios';

export const stripePromise = loadStripe('pk_test_51MGrHjGYuiyBCe0C6y3GSeKTfjXQzmvqGjNo48T2se5K0mLQszBUOwQ1125q5crnqnbKHL03STGjhFZYki9VbcUu00c4dPIkeL');

const AuthenticatedUserContext = createContext({});

const AuthenticatedUserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  return (
    <AuthenticatedUserContext.Provider value={{ user, setUser }}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
};

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const [isUserDataPresent, setIsUserDataPresent] = useState(null);
  const { user } = useContext(AuthenticatedUserContext);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user) {
        console.error("No authenticated user");
        navigate('/startCommunity');
        return;
      }

      const userDocRef = doc(db, "community", user.uid);
      const docSnap = await getDoc(userDocRef);

      if (docSnap.exists()) {
        setIsUserDataPresent(true);
      } else {
        setIsUserDataPresent(false);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    if (isUserDataPresent === false) {
      navigate('/startCommunity');
    }
  }, [isUserDataPresent]);

  if (isUserDataPresent === null) return null;
  if (isUserDataPresent) return children;
  return null;
}

const RedirectIfCommunityDataExists = ({ children }) => {
  const navigate = useNavigate();
  const [isUserDataPresent, setIsUserDataPresent] = useState(null);
  const { user } = useContext(AuthenticatedUserContext);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user) {
        console.error("No authenticated user");
        navigate('/startCommunity');
        return;
      }

      const userDocRef = doc(db, "community", user.uid);
      const docSnap = await getDoc(userDocRef);

      if (docSnap.exists()) {
        setIsUserDataPresent(true);
      } else {
        setIsUserDataPresent(false);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    if (isUserDataPresent === true) {
      navigate('/community');
    }
  }, [isUserDataPresent]);

  if (isUserDataPresent === null) return null;
  if (isUserDataPresent) return null;
  return children;
}

function ChatStack() {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const { isLoginComplete, setIsLoginComplete } = useContext(LoginContext);
  const { user } = useContext(AuthenticatedUserContext);
  const [userData, setUserData] = useState(null);

  const monitorUniqueIDInFirestore = async (userData) => {
    const localUniqueID = localStorage.getItem('uniqueID');

    if (!localUniqueID) {
      await signOut(auth);
      navigate('/');
      return;
    }

    if (userData && userData.sessionsDevice && userData.sessionsDevice.length > 0 && isLoginComplete) {
      const sessionExists = userData.sessionsDevice.some(
        (session) => session.uniqueID === localUniqueID
      );

      if (!sessionExists) {
        // console.log("Deslogado devido à ausência de uniqueID no banco de dados");
        setIsLoginComplete(false)
        await signOut(auth);
        navigate('/');
      }
    }
  };

  useEffect(() => {
    let unsubscribe;

    const fetchData = async () => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);

        unsubscribe = onSnapshot(userRef, async (docSnap) => {
          if (docSnap.exists()) {
            const userData = docSnap.data();
            setUserData(userData);

            if (userData && userData.sessionsDevice) {
              await monitorUniqueIDInFirestore(userData).catch(console.error);
            }

            if (userData.sessionsDevice && userData.sessionsDevice.length === 0 && isLoginComplete === true) {
              setIsLoginComplete(false);
              await signOut(auth);
              navigate('/');
            }

            if (!userData.sessionsDevice && isLoginComplete === true) {
              setIsLoginComplete(false)
              await signOut(auth);
              navigate('/');
            }

          } else {
            setUserData({});
          }

          setLoading(false);
        });
      }
    };

    fetchData();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user, isLoginComplete]);


  if (loading || !user) {
    return (
      <div style={{ display: 'flex', height: '100vh', width: '100vw', flex: 1, justifyContent: 'center', alignItems: 'center', background: '#4D59FB' }}>
        <Loader />
      </div>
    );
  }

  return userData && userData.selectedOptions ? (
    <Routes>
      <Route path='/' element={<Home />} />

      <Route path='/sounds' element={<Sounds />} />
      <Route path='/notes' element={<Notes />} />
      <Route path='/lessons' element={<LessonScreen />} />

      <Route path='/lessons/exercise' element={<Lesson />} />

      <Route path='/games/type' element={<Type />} />
      <Route path='/games/balloon' element={<BalloonGame />} />

      <Route path='/lexi/:sessionId' element={<Lexi />} />
      <Route path='/lexi/:sessionId/classMode' element={<ClassMode />} />

      <Route path='/startCommunity' element={<RedirectIfCommunityDataExists><StartCommunity /></RedirectIfCommunityDataExists>} />
      <Route path='/interview' element={<RedirectIfCommunityDataExists><Interview /></RedirectIfCommunityDataExists>} />

      <Route path='/community/call' element={<PrivateRoute><SessionCall /></PrivateRoute>} />
      <Route path='/community/call/:callId' element={<PrivateRoute><SessionCall /></PrivateRoute>} />

      <Route path='/community' element={<PrivateRoute><MainContainer /></PrivateRoute>} />
      <Route path='/community/feed' element={<PrivateRoute><MainContainer /></PrivateRoute>} />
      <Route path='/community/activity' element={<PrivateRoute><MainContainer /></PrivateRoute>} />
      <Route path='/community/chat' element={<PrivateRoute><MainContainer /></PrivateRoute>} />
      <Route path='/community/chat/:conversationId' element={<PrivateRoute><MainContainer /></PrivateRoute>} />
      <Route path='/community/:id' element={<PrivateRoute><MainContainer /></PrivateRoute>} />
      <Route path='/community/:username/post/:postId' element={<PrivateRoute><MainContainer /></PrivateRoute>} />

      <Route path='/scenarios' element={<MainScenarios />} />
      <Route path='/scenarios/:id' element={<MainScenarios />} />

      <Route path='/settings' element={<Config />} />
      <Route path='/settings/subscription' element={<Config />} />
      <Route path='/settings/subscription/plans' element={<Plans />} />
      <Route path='/settings/privacy' element={<Config />} />
      <Route path='/settings/privacy/email' element={<Config />} />
      <Route path='/settings/privacy/password' element={<Config />} />
      <Route path='/settings/sessions' element={<Config />} />
      <Route path='/settings/edit' element={<Config />} />
      <Route path='/settings/idiom' element={<Config />} />
      <Route path='/settings/notifications' element={<Config />} />
      <Route path='/settings/help' element={<Config />} />
      <Route path='/settings/usage' element={<Config />} />

    </Routes>
  ) : (
    <AboutUser />
  );
}


export const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
  const [isLoginComplete, setIsLoginComplete] = useState(false);
  const { user } = useContext(AuthenticatedUserContext);

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        setIsLoginComplete(true);
      }, 8000);
    }
  }, [user])


  return (
    <LoginContext.Provider value={{ isLoginComplete, setIsLoginComplete }}>
      {children}
    </LoginContext.Provider>
  );
};

function AuthStack() {
  return (
    <Routes>
      <Route path='/' element={<Login />} />
    </Routes>
  );
}

function RootNavigator() {
  const { user, setUser } = useContext(AuthenticatedUserContext);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {

    const unsubscribeAuth = onAuthStateChanged(
      auth,
      async authenticatedUser => {
        authenticatedUser ? setUser(authenticatedUser) : setUser(null);
        setIsLoading(false);
      }
    );

    return unsubscribeAuth;
  }, [user]);
  if (isLoading) {
    return (
      <div style={{ display: 'flex', height: '100vh', width: '100vw', flex: 1, justifyContent: 'center', alignItems: 'center', background: '#4D59FB' }}>
        <Loader />
      </div>
    );
  }

  return (
    <BrowserRouter>
      {user ? <ChatStack /> : <AuthStack />}
    </BrowserRouter>
  );
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Elements stripe={stripePromise}>
    <AllThemeProvider>
      <ThemeProvider>
        <AuthenticatedUserProvider>
          <LoginProvider>
            <UserProvider>
              <PostProvider>
                <CallProvider>
                  <StatusPostingProvider>
                    <PostInteractionProvider>
                      <BallTalkProvider>
                        <NotesProvider>
                          <AlertProvider>
                            <CaptureProvider>
                              <RootNavigator />
                            </CaptureProvider>
                          </AlertProvider>
                        </NotesProvider>
                      </BallTalkProvider>
                    </PostInteractionProvider>
                  </StatusPostingProvider>
                </CallProvider>
              </PostProvider>
            </UserProvider>
          </LoginProvider>
        </AuthenticatedUserProvider>
      </ThemeProvider>
    </AllThemeProvider>
  </Elements>
);