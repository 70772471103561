import React, { useState } from "react"
import { auth } from "../../../auth/config/firebase";
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { Link } from "react-router-dom";
import { addNotification } from "../../../components/notification";

export default function ChangePassword({ setBarLoad }) {

    const [password, setPassword] = useState({ value: '', error: '' })
    const [newPassword, setNewPassword] = useState({ value: '', error: '' })
    const [retypePassword, setRetypePassword] = useState({ value: '', error: '' })

    const reauthenticate = (currentPassword) => {
        var user = auth.currentUser;
        var cred = EmailAuthProvider.credential(user.email, currentPassword);
        return reauthenticateWithCredential(user, cred);
    }

    const changePassword = (currentPassword, newPassword) => {

        if (!password.value || !newPassword.value || !retypePassword.value) {
            addNotification('Todos os campos devem estar preenchidos', 'error3');
            return;
        }

        // Verifique se os dois campos de senha são iguais
        if (newPassword.value !== retypePassword.value) {
            addNotification('As senhas não coincidem', 'error3');
            return;
        }

        reauthenticate(currentPassword.value).then(() => {
            setBarLoad(50)
            var user = auth.currentUser;
            updatePassword(user, newPassword.value).then(() => {
                setBarLoad(100)
                addNotification('Senha atualizada com sucesso!', 'success3');
                setBarLoad(0)
            }).catch((error) => {
                // console.error(error)
                setBarLoad(0)
                if (error.code === 'auth/weak-password') {
                    addNotification('A senha é muito fraca. Por favor, escolha uma senha mais forte', 'error3');
                } else if (error.code === 'auth/too-many-requests') {
                    addNotification('Muitas tentativas falhas de login. Tente novamente mais tarde', 'error3');
                } else {
                    // Alert.alert('Erro', 'Erro ao atualizar senha: ' + error.message);
                    addNotification('Erro ao atualizar senha', 'error3');
                }
            });
        }).catch((error) => {
            setBarLoad(0)
            // console.error(error)
            if (error.code === 'auth/wrong-password') {
                addNotification('Senha atual incorreta', 'error3');
            } else if (error.code === 'auth/too-many-requests') {
                addNotification('Muitas tentativas falhas de login. Tente novamente mais tarde', 'error3');
            } else {
                addNotification('Erro ao reautenticar', 'error3');
                // Alert.alert('Erro', 'Erro ao reautenticar: ' + error.message);
            }
        });
    }

    return (
        <div className="allSetting">

            <div id="notifications3"></div>
            <div className="containerSetting">
                <div className="changeEmail">
                    <h2>Change password</h2>

                    <div className="changeEmailOptions">
                        <div className="inputsChangeEmail">
                            <div className="inputChangeEmail">
                                <label>Current password</label>
                                <input
                                    type="password"
                                    value={password.value}
                                    onChange={(event) => setPassword({ value: event.target.value, error: '' })}
                                    required
                                />
                                <Link>Forgot password?</Link>
                            </div>
                            <div className="inputChangeEmail">
                                <label>New password</label>
                                <input
                                    type="password"
                                    value={newPassword.value}
                                    onChange={(event) => setNewPassword({ value: event.target.value, error: '' })}
                                    required
                                />
                            </div>
                            <div className="inputChangeEmail">
                                <label>Retype new password</label>
                                <input
                                    type="password"
                                    value={retypePassword.value}
                                    onChange={(event) => setRetypePassword({ value: event.target.value, error: '' })}
                                    required
                                />
                            </div>

                            <button onClick={() => changePassword(password, newPassword)}>Change</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}