import React, { useState, useEffect } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { Mic, Subtitles, EmojiEmotions, Book, Edit, MoreVert, Chat, ChatOutlined, Info, InfoOutlined, CallEnd } from "@mui/icons-material";
import MoreOptionsMenu from './MoreOptionsMenu'; // certifique-se de ajustar o caminho da importação
import { Tooltip, Badge } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InfoIcon from '@mui/icons-material/Info';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import { useVideoShare } from "../Capture/VideoShareProvider";
import NotesComponent from "../Notes";

import { styled } from '@mui/material/styles';

// const CommonButton = styled(IconButton)(({ theme, active }) => ({
//     color: active ? '#202124' : '#FFFFFF',
//     backgroundColor: active ? '#FFFFFF' : '#434649',
//     margin: 8,
//     padding: 9.6,
//     '&:hover': {
//         backgroundColor: active ? '#FFFFFF' : '#434649',
//     },
// }));

// const RedButton = styled(IconButton)(({ theme }) => ({
//     color: '#FFFFFF',
//     backgroundColor: 'red',
//     margin: 1,
//     padding: '0.5rem 1rem',
//     borderRadius: '20px',
//     '&:hover': {
//         backgroundColor: 'darkred',
//     },
// }));


const CommonButton = styled(IconButton)(({ theme, active }) => ({
    color: active ? theme.actionBarButtonActiveColor : theme.actionBarButtonInactiveColor,
    backgroundColor: active ? theme.actionBarButtonActiveBackground : theme.actionBarButtonInactiveBackground,
    margin: 8,
    padding: 9.6,
    transition: 'all 0.1s ease-in-out',
    boxShadow: active
        ? `0 0px 0 ${theme.actionBarButtonShadowColor}` // Sombra mais escura para efeito pressionado
        : `0px 4px 0px ${theme.actionBarButtonShadowColor}`, // Sombra 3D com cinza bem escuro
    border: `2px solid ${active ? theme.actionBarButtonActiveBackground : theme.actionBarButtonInactiveBackground}`, // Bordas com cor baseada no estado ativo
    transform: active ? 'translateY(4px)' : 'none', // Movimentação para efeito de pressão
    '&:hover': {
        backgroundColor: active ? theme.actionBarButtonActiveBackground : theme.actionBarButtonInactiveBackground,
    },
    '&:active': {
        boxShadow: `0 0px 0 ${theme.actionBarButtonShadowColor}`, // Remover sombra ao clicar
        transform: 'translateY(4px)' // Movimento para efeito de pressão
    }
}));

const RedButton = styled(IconButton)(({ theme, active }) => ({
    color: theme.redButtonColor,
    backgroundColor: theme.redButtonBackground,
    margin: 1,
    padding: '0.5rem 1rem',
    borderRadius: '20px',
    transition: 'all 0.1s ease-in-out',
    boxShadow: active ? `0 0px 0 ${theme.redButtonShadowColor}` : `0px 4px 0px ${theme.redButtonShadowColor}`, // Efeito 3D ou pressionado
    border: `2px solid ${theme.redButtonBorder}`, // Borda combinando com a cor do botão
    transform: active ? 'translateY(4px)' : 'none', // Movimentação para efeito de pressão
    '&:hover': {
        backgroundColor: theme.redButtonBackground,
    },
    '&:active': {
        boxShadow: `0 0px 0 ${theme.redButtonShadowColor}`, // Remover sombra ao clicar
        transform: 'translateY(4px)' // Movimento para efeito de pressão
    }
}));

const ActionBar = ({ handleToggle, micOn, subtitlesOn, emojiOn, bookOn, editOn, moreOn, setMicOn, setSubtitlesOn, setEmojiOn, setBookOn, setEditOn, setMoreOn, handleToggleDisconnect, chatOn, handleToggleChat, selectedProfessor, selectedVoice, setSelectedVoice, handleDelete, addBox, setVisionOn, visionOn, isLessonActive, boxes, theme }) => {

    const { isSharing, isLoading, handleShareScreen, videoRef } = useVideoShare();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const ScreenShare = async (event) => {
        event.currentTarget.blur();
        if (isSharing) {
            handleShareScreen()
            handleDelete('ShareScreen')
        }
        else {
            handleShareScreen();
            addBox(
                () => (
                    <Box sx={{ width: '100%', maxWidth: 600, borderRadius: '10px', overflow: 'hidden', justifyContent: 'center', alignItems: 'center' }}>
                        <video
                            ref={videoRef}
                            autoPlay
                            playsInline
                            style={{ width: '100%', backgroundColor: '#f0f0f0' }}
                        />
                    </Box>
                ),
                'ShareScreen'
            );
        }
    }

    const OpenNote = (event) => {
        event.currentTarget.blur();
        if (editOn) {
            handleDelete('notes')
        }
        else {
            addBox(
                () => (
                    <Box sx={{ width: '800px', height: '500px', borderRadius: '10px', overflow: 'hidden' }}>
                        <NotesComponent />
                    </Box>
                ),
                'notes'
            );
        }
    }

    useEffect(() => {
        if (boxes.some(box => box.type === 'ShareScreen') && isSharing) {
            handleShareScreen()
        }
    }, [boxes]);

    useEffect(() => {
        if (boxes.some(box => box.type === 'ShareScreen') && !isSharing && !isLoading) {
            handleDelete('ShareScreen')
        }
    }, [boxes, isSharing, isLoading]);

    const handleMenuOpen = (event) => {
        event.currentTarget.blur();
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (event) => {
        event.currentTarget.blur();
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        if (!isSharing) {
            handleToggle(setVisionOn, visionOn)(event);
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 2,
                borderRadius: 2
            }}
        >
            <Typography sx={{ color: theme.timerTextColor, marginLeft: 2, fontFamily: theme.timerFontFamily, fontSize: theme.timerFontSize }}>
                10:00
            </Typography>



            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 2,
                }}
            >
                <CommonButton theme={theme} active={micOn} onClick={handleToggle(setMicOn, micOn)}>
                    <Mic />
                </CommonButton>
                <CommonButton theme={theme} active={isSharing} onClick={ScreenShare}>
                    <ScreenShareIcon />
                </CommonButton>
                <CommonButton theme={theme} active={subtitlesOn} onClick={handleToggle(setSubtitlesOn, subtitlesOn)}>
                    <Subtitles />
                </CommonButton>
                <CommonButton theme={theme} active={emojiOn} onClick={handleToggle(setEmojiOn, emojiOn)}>
                    <EmojiEmotions />
                </CommonButton>
                <CommonButton theme={theme} active={bookOn} onClick={handleToggle(setBookOn, bookOn)}>
                    <Book />
                </CommonButton>
                <CommonButton theme={theme} active={editOn} onClick={OpenNote}>
                    <Edit />
                </CommonButton>
                <CommonButton theme={theme} active={moreOn} onClick={handleMenuOpen}>
                    <MoreVert />
                </CommonButton>
                <MoreOptionsMenu
                    anchorEl={anchorEl}
                    open={open}
                    handleMenuClose={handleMenuClose}
                    selectedProfessor={selectedProfessor}
                    selectedVoice={selectedVoice}
                    setSelectedVoice={setSelectedVoice}
                    handleDelete={handleDelete}
                    addBox={addBox}
                />
                <RedButton theme={theme} onClick={handleToggleDisconnect}>
                    <CallEnd />
                </RedButton>
                {isLessonActive && (
                    <Tooltip
                        title={isSharing ? "O usuário deve parar o compartilhamento de tela para o Lexi conseguir analisar as lições" : "Ativar a capacidade de ver em tempo real os exercícios na tela"}
                    >
                        <Badge
                            badgeContent={
                                <InfoIcon
                                    sx={{
                                        color: '#FFFFFF',
                                        backgroundColor: isSharing ? 'orange' : '#1976d2',
                                        fontSize: 20,
                                        borderRadius: '50%',
                                        padding: 0.4,
                                    }}
                                />
                            }
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            overlap="circular"
                            sx={{
                                '& .MuiBadge-badge': {
                                    cursor: 'pointer',
                                    top: '12px',
                                },
                            }}
                        >
                            <CommonButton
                                theme={theme}
                                active={visionOn}
                                disabled={isSharing}
                                onClick={handleClick}
                                sx={{
                                    marginLeft: 5,
                                    transition: 'all 0.3s ease',
                                    animation: 'scaleBlur 0.3s ease-out',
                                    '&:disabled': {
                                        color: '#B0B0B0',
                                        backgroundColor: '#E0E0E0',
                                    },
                                }}
                            >
                                <VisibilityIcon />
                            </CommonButton>
                        </Badge>
                    </Tooltip>
                )}
            </Box>

            <Box sx={{ display: 'flex', marginRight: 2 }}>
                <IconButton onClick={handleToggleChat} sx={{ color: theme.chatButtonColor, fontSize: theme.chatButtonFontSize }}>
                    {chatOn ? <Chat fontSize="small" /> : <ChatOutlined fontSize="small" />}
                </IconButton>
            </Box>
        </Box>


    );
};

export default ActionBar;
