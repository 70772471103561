// export const openaiApiKey = "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";
// export const openaiApiKey = "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";



// export const openaiApiKey = "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";

// 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 antiga com e-mail da Natalia
// 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 que tem o GPT 4


// Chave API ID apple:
// export const openaiApiKey = "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";

// export const openaiApiKey = "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";


export const openaiApiKey = "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";