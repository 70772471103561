import React, { createContext, useState, useContext } from 'react';

// Criando o contexto
const PostingContext = createContext();

export const useStatus = () => useContext(PostingContext);

// Provedor do contexto
export const StatusPostingProvider = ({ children }) => {
    const [statusPosting, setStatusPosting] = useState({ value: '', url: '' });

    const setStatus = (status) => {
        setStatusPosting(status);
    };

    return (
        <PostingContext.Provider value={{ statusPosting, setStatus }}>
            {children}
        </PostingContext.Provider>
    );
};
