import React from 'react';
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import { styled } from '@mui/system';

const CardContainer = styled(Button)(({ theme }) => ({
    borderRadius: '16px',
    background: theme.background,
    color: theme.color,
    padding: 0,
    position: 'relative',
    overflow: 'hidden',
    minWidth: '300px',
    transition: 'all 0.1s ease-in-out',
    boxShadow: `0px 2px 0px ${theme.border}`,
    border: `2px solid ${theme.border}`,
    marginBottom: '8px',
    '&:active': {
        boxShadow: `0 0px 0 ${theme.border}`,
        transform: 'translateY(2px)'
    }
}));

const ItemCard = ({ title, description, icon, handleOpen, type, titlePopup, theme }) => {
    return (
        <CardContainer theme={theme} onClick={() => handleOpen(type, titlePopup)}>
            <CardContent
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    boxShadow: 'none',
                    padding: 0,
                    alignItems: 'flex-end'
                }}
            >
                <Box sx={{ padding: '16px' }}>
                    <Typography
                        variant="h6"
                        component="div"
                        gutterBottom
                        sx={{ fontFamily: 'Din-Round-Bold, sans-serif', textAlign: 'left', textTransform: 'initial', color: theme.color }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ fontFamily: 'SF-Pro-Text-Regular, sans-serif', textAlign: 'left', textTransform: 'initial', color: theme.color }}
                    >
                        {description}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    {icon}
                </Box>
            </CardContent>
        </CardContainer>
    );
};

const ItemList = ({ items, handleOpen, theme }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            {items.map((item, index) => (
                <ItemCard
                    key={index}
                    title={item.title}
                    description={item.description}
                    icon={item.icon}
                    handleOpen={handleOpen}
                    type={item.type}
                    titlePopup={item.titlePopup}
                    theme={theme}
                />
            ))}
        </Box>
    );
};

export default ItemList;
