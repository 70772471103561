import React, { useState, useEffect, useRef } from "react";
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faCopy, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { sendTextToSpeechRequestMulti } from "../IAtalk2ME/Talk2MeVoice";
import CircularProgress from '@mui/material/CircularProgress';

function Translator() {

    const [fromText, setFromText] = useState("");
    const [toText, setToText] = useState("");
    const [fromLang, setFromLang] = useState("en-US");
    const [toLang, setToLang] = useState("pt-BR");
    const [countries, setCountries] = useState({});


    useEffect(() => {
        const initialCountries = {
            "am-ET": "Amharic",
            "ar-SA": "Arabic",
            "be-BY": "Bielarus",
            "bem-ZM": "Bemba",
            "bi-VU": "Bislama",
            "bjs-BB": "Bajan",
            "bn-IN": "Bengali",
            "bo-CN": "Tibetan",
            "br-FR": "Breton",
            "bs-BA": "Bosnian",
            "ca-ES": "Catalan",
            "cop-EG": "Coptic",
            "cs-CZ": "Czech",
            "cy-GB": "Welsh",
            "da-DK": "Danish",
            "dz-BT": "Dzongkha",
            "de-DE": "German",
            "dv-MV": "Maldivian",
            "el-GR": "Greek",
            "en-US": "English",
            "es-ES": "Spanish",
            "et-EE": "Estonian",
            "eu-ES": "Basque",
            "fa-IR": "Persian",
            "fi-FI": "Finnish",
            "fn-FNG": "Fanagalo",
            "fo-FO": "Faroese",
            "fr-FR": "French",
            "gl-ES": "Galician",
            "gu-IN": "Gujarati",
            "ha-NE": "Hausa",
            "he-IL": "Hebrew",
            "hi-IN": "Hindi",
            "hr-HR": "Croatian",
            "hu-HU": "Hungarian",
            "id-ID": "Indonesian",
            "is-IS": "Icelandic",
            "it-IT": "Italian",
            "ja-JP": "Japanese",
            "kk-KZ": "Kazakh",
            "km-KM": "Khmer",
            "kn-IN": "Kannada",
            "ko-KR": "Korean",
            "ku-TR": "Kurdish",
            "ky-KG": "Kyrgyz",
            "la-VA": "Latin",
            "lo-LA": "Lao",
            "lv-LV": "Latvian",
            "men-SL": "Mende",
            "mg-MG": "Malagasy",
            "mi-NZ": "Maori",
            "ms-MY": "Malay",
            "mt-MT": "Maltese",
            "my-MM": "Burmese",
            "ne-NP": "Nepali",
            "niu-NU": "Niuean",
            "nl-NL": "Dutch",
            "no-NO": "Norwegian",
            "ny-MW": "Nyanja",
            "ur-PK": "Pakistani",
            "pau-PW": "Palauan",
            "pa-IN": "Panjabi",
            "ps-PK": "Pashto",
            "pis-SB": "Pijin",
            "pl-PL": "Polish",
            "pt-PT": "Portuguese",
            "pt-BR": "Portuguese BR",
            "rn-BI": "Kirundi",
            "ro-RO": "Romanian",
            "ru-RU": "Russian",
            "sg-CF": "Sango",
            "si-LK": "Sinhala",
            "sk-SK": "Slovak",
            "sm-WS": "Samoan",
            "sn-ZW": "Shona",
            "so-SO": "Somali",
            "sq-AL": "Albanian",
            "sr-RS": "Serbian",
            "sv-SE": "Swedish",
            "sw-SZ": "Swahili",
            "ta-LK": "Tamil",
            "te-IN": "Telugu",
            "tet-TL": "Tetum",
            "tg-TJ": "Tajik",
            "th-TH": "Thai",
            "ti-TI": "Tigrinya",
            "tk-TM": "Turkmen",
            "tl-PH": "Tagalog",
            "tn-BW": "Tswana",
            "to-TO": "Tongan",
            "tr-TR": "Turkish",
            "uk-UA": "Ukrainian",
            "uz-UZ": "Uzbek",
            "vi-VN": "Vietnamese",
            "wo-SN": "Wolof",
            "xh-ZA": "Xhosa",
            "yi-YD": "Yiddish",
            "zu-ZA": "Zulu"
        };

        setCountries(initialCountries);
    }, []);

    const handleTranslate = () => {
        let text = fromText.trim();
        let translateFrom = fromLang;
        let translateTo = toLang;

        if (!text) return;

        setToText("Translating...");
        let apiUrl = `https://api.mymemory.translated.net/get?q=${text}&langpair=${translateFrom}|${translateTo}`;

        fetch(apiUrl)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error("Error in translation API");
                }
            })
            .then((data) => {
                setToText(data.responseData.translatedText);
                data.matches.forEach((data) => {
                    if (data.id === 0) {
                        setToText(data.translation);
                    }
                });
            })
            .catch((error) => {
                console.error("Error during translation: ", error);
            });
    };

    const handleSwapLanguages = () => {
        const tempText = fromText;
        const tempLang = fromLang;

        setFromText(toText);
        setToText(tempText);

        setFromLang(toLang);
        setToLang(tempLang);
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert('Text copied to clipboard');
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    };


    const [audioUrl, setAudioUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const audioRef = useRef(null);

    const handleSpeech = async (text, lang) => {
        setLoading(true);
        const url = await sendTextToSpeechRequestMulti(text, "nova");
        setAudioUrl(url);
        setLoading(false);
    };

    useEffect(() => {
        if (audioUrl && audioRef.current) {
            audioRef.current.play();
        }
    }, [audioUrl]);

    return (
        <div className="containerTranslate">
            {audioUrl && (
                <audio
                    src={audioUrl}
                    ref={audioRef}
                    style={{ display: 'none' }}
                />
            )}
            <div className="wrapperTranslate">
                <div className="text-input">
                    <textarea
                        spellCheck="false"
                        className="from-text"
                        placeholder="Enter text"
                        value={fromText}
                        onChange={(e) => setFromText(e.target.value)}
                    ></textarea>
                    <textarea
                        spellCheck="false"
                        readOnly
                        disabled
                        className="to-text"
                        placeholder="Translation"
                        value={toText}
                    ></textarea>
                </div>
                <ul className="controls">
                    <li className="row from li">
                        <div className="icons">                            
                            <FontAwesomeIcon id="from" icon={faVolumeUp} onClick={() => handleSpeech(fromText, fromLang)} />
                            <FontAwesomeIcon id="from" icon={faCopy} onClick={() => handleCopy(fromText)} />
                        </div>
                        <select
                            value={fromLang}
                            onChange={(e) => setFromLang(e.target.value)}
                        >
                            {Object.entries(countries).map(([code, name]) => (
                                <option key={code} value={code}>
                                    {name}
                                </option>
                            ))}
                        </select>
                    </li>
                    <li className="exchange li" onClick={handleSwapLanguages}>
                        <FontAwesomeIcon icon={faExchangeAlt} />
                    </li>
                    <li className="row to li">
                        <select
                            value={toLang}
                            onChange={(e) => setToLang(e.target.value)}
                        >
                            {Object.entries(countries).map(([code, name]) => (
                                <option key={code} value={code}>
                                    {name}
                                </option>
                            ))}
                        </select>
                        <div className="icons">
                            <FontAwesomeIcon id="to" icon={faVolumeUp} onClick={() => handleSpeech(toText, toLang)} />
                            <FontAwesomeIcon id="to" icon={faCopy} onClick={() => handleCopy(toText)} />
                        </div>
                    </li>
                </ul>
            </div>
            <button onClick={handleTranslate}>Translate Text</button>
        </div>
    )
}

export default Translator;