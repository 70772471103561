import React, { useEffect, useState, useContext } from 'react';
import { Box, Typography, Button, Card, CardContent, Grid, Container, CircularProgress } from '@mui/material';
import { UserContext } from '../../components/UserProvider';
import './style.css';
import axios from 'axios';
import { addNotification } from '../../components/notification';
import { useAllTheme } from '../../components/themes/ThemeContext';

export default function Subscription() {

    const { theme, isDark } = useAllTheme();

    const { user } = useContext(UserContext);
    const [subscription, setSubscription] = useState(null);
    const [paymentMethodDetails, setPaymentMethodDetails] = useState(null);

    const [loading, setLoading] = useState(false)

    const [loadingCancel, setLoadingCancel] = useState(false)
    const [updateState, setUpdateState] = useState(false)

    useEffect(() => {
        // Função para buscar a assinatura do usuário
        const fetchSubscription = async () => {
            try {
                const response = await axios.post('http://localhost:3009/api/get-current-subscription', {
                    userUid: user.uid,
                });
                const data = response.data;
                console.log('data: ', data);
                setSubscription(data.subscription);
                setPaymentMethodDetails(data.paymentMethodDetails);
            } catch (error) {
                console.error('Erro ao buscar assinatura:', error);
            }
        };

        if (user) {
            fetchSubscription();
        }
    }, [user, updateState]);

    async function redirectToBillingPortal(userUid) {
        const currentUrl = window.location.href;
        setLoading(true)
        try {
            const response = await fetch(`http://localhost:3009/api/user-billing-portal/${userUid}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ return_url: currentUrl }),
            });

            const data = await response.json();

            if (response.ok) {
                setLoading(false)
                window.location.href = data.url;
            } else {
                setLoading(false)
                console.error(data.error);
            }
        } catch (error) {
            setLoading(false)
            console.error("Erro ao redirecionar para o Billing Portal:", error);
        }
    }

    const handleCancelSubscription = async (subscriptionId, status) => {

        if (!user.emailVerified) {
            addNotification("Por favor, verifique seu e-mail para ativar todas as funcionalidades do TwoAlert.", 'warning');
            return;
        }
        setLoadingCancel(true)

        try {
            const payload = {
                subscriptionId: subscriptionId, // selectedSubscription.id
                status: status
            };

            if (!subscriptionId || !status) return;

            const response = await axios.post(`http://localhost:3009/cancel-subscription`, payload, {
            });

            if (response.data.success) {
                setUpdateState(!updateState)
                setLoadingCancel(false)
            } else {
                addNotification(response.data.message || "Não foi possível cancelar a assinatura.", 'error');
                setLoadingCancel(false)
            }
        } catch (error) {
            setLoadingCancel(false)
            addNotification("Ocorreu um erro ao cancelar a assinatura.", 'error');
            console.error("Erro ao cancelar a assinatura:", error);
        } finally {

        }
    };

    const traduzirInterval = (interval) => {
        switch (interval) {
            case 'day': return 'dia';
            case 'week': return 'semana';
            case 'month': return 'mês';
            case 'year': return 'ano';
            default: return interval;
        }
    };

    if (!subscription && !paymentMethodDetails) {
        return (
            <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress size={40} />
            </Box>
        );
    }

    return (
        <div className="allSetting">
            <div id="notifications3"></div>
            <div className='containerSetting'>
                <Container>
                    <Box mt={4} display="flex" justifyContent="center" alignItems="center">
                        <Card style={{ width: '100%', maxWidth: 600, padding: 20, backgroundColor: isDark ? theme.border : '#FFFFFFF' }}>
                            <CardContent>
                                <Typography variant="h4" sx={{ color: theme.color }} component="div" gutterBottom>
                                    Gerenciar seu plano
                                </Typography>
                                <Box mt={2}>
                                    <Typography variant="h6" component="div" style={{ backgroundColor: '#f8d7da', padding: '10px', borderRadius: '5px' }}>
                                        {subscription.items.data[0].plan.nickname || 'Plano Premium'}
                                    </Typography>
                                    <Typography variant="body1" style={{ color: theme.color }} mt={2}>
                                        1 conta Premium
                                    </Typography>
                                    {subscription.status === 'active' && !subscription.cancel_at_period_end && (
                                        <Typography style={{ color: theme.color }} variant="body1" mt={1}>
                                            Cancele quando quiser
                                        </Typography>
                                    )}
                                </Box>
                                <Grid container spacing={2} mt={2}>
                                    <Grid item xs={12} md={6}>
                                        {subscription.status === 'active' && !subscription.cancel_at_period_end && (
                                            <>
                                                <Typography style={{ color: theme.color }} variant="h6" component="div">
                                                    Pagamento
                                                </Typography>
                                                <Typography style={{ color: theme.color }} variant="body1" mt={1}>
                                                    Sua próxima cobrança será no dia {new Date(subscription.current_period_end * 1000).toLocaleDateString('pt-BR')}, no valor de {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(subscription.items.data[0].plan.amount / 100)}.
                                                </Typography>
                                            </>
                                        )}
                                        {paymentMethodDetails && (
                                            <>
                                                <Typography style={{ color: theme.color }} variant="body1" mt={1}>
                                                    Cartão {paymentMethodDetails.brand} que termina com {paymentMethodDetails.last4}
                                                </Typography>
                                                <Typography style={{ color: theme.color }} variant="body1" mt={1}>
                                                    Vencimento: {String(paymentMethodDetails.exp_month).padStart(2, '0')}/{paymentMethodDetails.exp_year}
                                                </Typography>
                                                <Button variant={!isDark ? "outlined" : 'contained'} color="primary" style={{ marginTop: '10px' }} onClick={() => redirectToBillingPortal(user.uid)}>
                                                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Atualizar'}
                                                </Button>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                                <Box mt={4} display="flex" justifyContent="space-between">
                                    <Button variant={!isDark ? "outlined" : 'contained'} color="primary" onClick={() => redirectToBillingPortal(user.uid)}>
                                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Mudar de plano'}
                                    </Button>
                                    {(subscription.status === 'active' || subscription.status === 'payment_failed' || subscription.status === 'unpaid' || subscription.status === 'trialing' || subscription.status === 'past_due' || subscription.status === 'expired' || subscription.status === 'incomplete_expired' || subscription.status === 'incomplete') && !subscription.cancel_at_period_end && (
                                        <Button variant={!isDark ? "outlined" : 'contained'} color="secondary" onClick={() => handleCancelSubscription(subscription.id, subscription.status)}>
                                            {loadingCancel ? <CircularProgress size={24} color="inherit" /> : 'Cancelar plano'}
                                        </Button>
                                    )}
                                </Box>
                                {(subscription.status === 'active' || subscription.status === 'payment_failed' || subscription.status === 'unpaid' || subscription.status === 'trialing' || subscription.status === 'past_due' || subscription.status === 'expired' || subscription.status === 'incomplete_expired' || subscription.status === 'incomplete') && !subscription.cancel_at_period_end && (
                                    <Box mt={4}>
                                        <Typography variant="body1" component="div" style={{ marginTop: 25, color: theme.color }}>
                                            {subscription.status === 'incomplete' ? (
                                                "Seu pagamento inicial está pendente. Conclua o pagamento para ativar sua assinatura. Se o pagamento não for completado em 23 horas, a assinatura será cancelada automaticamente."
                                            ) : new Date() <= new Date(subscription?.current_period_end * 1000) ? (
                                                `Se você cancelar agora, ainda poderá acessar sua assinatura até ${new Date(subscription?.current_period_end * 1000).toLocaleDateString('pt-BR')}`
                                            ) : (
                                                "Se você cancelar, perderá imediatamente o acesso à sua assinatura."
                                            )}
                                        </Typography>
                                    </Box>
                                )}
                                {subscription.status === 'payment_failed' && (
                                    <Box mt={4} p={2} bgcolor="error.main" color="error.contrastText" borderRadius={2}>
                                        <Typography variant="h6">Seu método de pagamento foi recusado.</Typography>
                                        <Typography>Para evitar interrupções em sua assinatura, atualize seu método de pagamento.</Typography>
                                        <Button variant="contained" color="secondary" onClick={() => redirectToBillingPortal(user.uid)}>Pagar assinatura</Button>
                                    </Box>
                                )}
                                {subscription.status === 'expired' && (
                                    <Box mt={4} p={2} bgcolor="error.main" color="error.contrastText" borderRadius={2}>
                                        <Typography variant="h6">Sua assinatura expirou.</Typography>
                                        <Typography>Sua assinatura do TwoAlert expirou em {new Date(subscription?.current_period_end * 1000).toLocaleDateString()}.</Typography>
                                        <Button variant="contained" color="secondary" onClick={() => redirectToBillingPortal(user.uid)}>
                                            Renovar: {(subscription?.items?.data[0]?.plan?.amount / 100)?.toFixed(2)?.replace('.', ',')}/{traduzirInterval(subscription?.items?.data[0]?.plan?.interval)}
                                        </Button>
                                    </Box>
                                )}
                                {subscription.status === 'canceled' && (
                                    <Box mt={4} p={2} bgcolor="error.main" color="error.contrastText" borderRadius={2}>
                                        <Typography variant="h6">Sua assinatura foi cancelada.</Typography>
                                        <Typography>Você cancelou sua assinatura. Caso queira retomar os benefícios do TwoAlert Plus, você pode reativar sua assinatura a qualquer momento.</Typography>
                                        <Button variant="contained" color="secondary" onClick={() => redirectToBillingPortal(user.uid)}>Reativar Assinatura</Button>
                                    </Box>
                                )}
                                {subscription.status === 'incomplete' && (
                                    <Box mt={4} p={2} bgcolor="error.main" color="error.contrastText" borderRadius={2}>
                                        <Typography variant="h6">Pagamento pendente.</Typography>
                                        <Typography>Seu pagamento inicial está pendente. Por favor, complete o pagamento para ativar sua assinatura.</Typography>
                                        <Button variant="contained" color="secondary" onClick={() => redirectToBillingPortal(user.uid)}>Completar Pagamento</Button>
                                    </Box>
                                )}
                                {subscription.status === 'incomplete_expired' && (
                                    <Box mt={4} p={2} bgcolor="error.main" color="error.contrastText" borderRadius={2}>
                                        <Typography variant="h6">Assinatura não ativada.</Typography>
                                        <Typography>Sua assinatura não foi ativada no período de tempo alocado e agora está expirada.</Typography>
                                        <Button variant="contained" color="secondary" onClick={() => redirectToBillingPortal(user.uid)}>Nova Assinatura</Button>
                                    </Box>
                                )}
                                {subscription.status === 'past_due' && (
                                    <Box mt={4} p={2} bgcolor="error.main" color="error.contrastText" borderRadius={2}>
                                        <Typography variant="h6">Pagamento atrasado.</Typography>
                                        <Typography>Seu pagamento está atrasado. Por favor, atualize seu método de pagamento para continuar usufruindo da assinatura do TwoAlert Plus.</Typography>
                                        <Button variant="contained" color="secondary" onClick={() => redirectToBillingPortal(user.uid)}>Atualizar Pagamento</Button>
                                    </Box>
                                )}
                                {subscription.status === 'trialing' && (
                                    <Box mt={4} p={2} bgcolor="info.main" color="info.contrastText" borderRadius={2}>
                                        <Typography variant="h6">Você está em período de teste!</Typography>
                                        <Typography>Aproveite todos os benefícios do TwoAlert Plus durante seu período de teste, que termina em {new Date(subscription?.trial_end * 1000).toLocaleDateString()}.</Typography>
                                    </Box>
                                )}
                                {subscription.status === 'unpaid' && (
                                    <Box mt={4} p={2} bgcolor="error.main" color="error.contrastText" borderRadius={2}>
                                        <Typography variant="h6" className="errorMessage">Assinatura não paga.</Typography>
                                        <Typography>Seu pagamento não foi efetuado, e sua assinatura foi suspensa temporariamente.</Typography>
                                        <Button variant="contained" color="secondary" onClick={() => redirectToBillingPortal(user.uid)}>Efetuar Pagamento</Button>
                                    </Box>
                                )}
                                {subscription.cancel_at_period_end && (
                                    <Box mt={4} p={2} bgcolor="warning.main" color="warning.contrastText" borderRadius={2}>
                                        <Typography variant="h6">Sua assinatura está para expirar.</Typography>
                                        <Typography>Sua assinatura do TwoAlert Plus expira em {new Date(subscription?.current_period_end * 1000).toLocaleDateString('pt-BR')}.</Typography>
                                        {/* <Button variant="contained" color="primary" onClick={() => renewSubscription(user.uid)}>
                                            Renovar: {(subscription?.items?.data[0]?.plan?.amount / 100)?.toFixed(2)?.replace('.', ',')}/{traduzirInterval(subscription?.items?.data[0]?.plan?.interval)}
                                        </Button> */}
                                    </Box>
                                )}
                            </CardContent>
                        </Card>
                    </Box>
                </Container>
            </div>
        </div>
    );
}
