// import React from 'react';
// import { Box } from '@mui/material';
// import LessonButton from './LessonButton';

// const LessonTrack = () => {
//   const lessons = [
//     { completed: true, locked: false },
//     { completed: true, locked: false },
//     { completed: false, locked: false },
//     { completed: false, locked: true },
//     { completed: false, locked: true },
//   ];

//   return (
//     <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
//       {lessons.map((lesson, index) => (
//         <LessonButton
//           key={index}
//           completed={lesson.completed}
//           locked={lesson.locked}
//           number={index + 1}
//         />
//       ))}
//     </Box>
//   );
// };

// export default LessonTrack;


// import React from 'react';
// import styled from '@emotion/styled';
// import { Box } from '@mui/material';
// import LessonButton from './LessonButton';

// const TrackContainer = styled(Box)`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

// const DashedLineSegment = styled.div`
//   width: 4px;
//   height: 20px;
//   margin: 2px 0;
//   border-radius: 20px;
//   background-color: ${props => props.color || '#E5E5E5'};
// `;

// const DashedLine = ({ colors }) => (
//   <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 1 }}>
//     {colors.map((color, index) => (
//       <DashedLineSegment key={index} color={color} />
//     ))}
//   </Box>
// );

// const LessonTrack = () => {
//   const lessons = [
//     { completed: true, locked: false },
//     { completed: true, locked: false },
//     { completed: false, locked: false },
//     { completed: false, locked: true },
//     { completed: false, locked: true },
//   ];

//   // Exemplo de cores para as linhas tracejadas
//   const defaultLineColors = ['#E5E5E5', '#E5E5E5', '#E5E5E5', '#E5E5E5'];

//   return (
//     <TrackContainer>
//       {lessons.map((lesson, index) => (
//         <React.Fragment key={index}>
//           <LessonButton
//             completed={lesson.completed}
//             locked={lesson.locked}
//             number={index + 1}
//           />
//           {index < lessons.length - 1 && (
//             <DashedLine colors={defaultLineColors} />
//           )}
//         </React.Fragment>
//       ))}
//     </TrackContainer>
//   );
// };

// export default LessonTrack;


// import React from 'react';
// import styled from '@emotion/styled';
// import { Box } from '@mui/material';
// import LessonButton from './LessonButton';

// const TrackContainer = styled(Box)`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 300px;
//   margin: 0 auto;
// `;

// const LessonRow = styled(Box)`
//   display: flex;
//   width: 100%;
//   justify-content: ${props => props.isLeft ? 'flex-start' : 'flex-end'};
//   position: relative;
//   height: 120px;
// `;

// const CurvedLine = styled.div`
//   position: absolute;
//   top: 60px;
//   left: ${props => props.isLeft ? '35px' : '35px'};
//   width: calc(100% - 70px);
//   height: 60px;
//   border: 2px solid #E5E5E5;
//   border-top: none;
//   border-${props => props.isLeft ? 'left' : 'right'}: none;
//   border-radius: ${props => props.isLeft ? '0 0 100px 0' : '0 0 0 100px'};
// `;

// const DashedLineSegment = styled.div`
//   position: absolute;
//   width: 2px;
//   height: 15px;
//   background-color: ${props => props.color || '#E5E5E5'};
//   top: ${props => props.top}px;
//   left: ${props => props.left}%;
// `;

// const LessonTrack = () => {
//   const lessons = [
//     { completed: true, locked: false },
//     { completed: true, locked: false },
//     { completed: false, locked: false },
//     { completed: false, locked: true },
//     { completed: false, locked: true },
//   ];

//   const defaultLineColors = ['#E5E5E5', '#E5E5E5', '#E5E5E5', '#E5E5E5'];

//   return (
//     <TrackContainer>
//       {lessons.map((lesson, index) => {
//         const isLeft = index % 2 === 0;
//         const isLast = index === lessons.length - 1;

//         return (
//           <LessonRow key={index} isLeft={isLeft}>
//             <LessonButton
//               completed={lesson.completed}
//               locked={lesson.locked}
//               number={index + 1}
//             />
//             {!isLast && (
//               <>
//                 <CurvedLine isLeft={isLeft} />
//                 {defaultLineColors.map((color, lineIndex) => (
//                   <DashedLineSegment
//                     key={lineIndex}
//                     color={color}
//                     top={75 + lineIndex * 15}
//                     left={isLeft ? 25 + (lineIndex * 16.6) : 75 - (lineIndex * 16.6)}
//                   />
//                 ))}
//               </>
//             )}
//           </LessonRow>
//         );
//       })}
//     </TrackContainer>
//   );
// };

// export default LessonTrack;


// import React from 'react';
// import styled from '@emotion/styled';
// import { Box } from '@mui/material';
// import LessonButton from './LessonButton';

// const TrackContainer = styled(Box)`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 300px;
//   margin: 0 auto;
// `;

// const LessonRow = styled(Box)`
//   display: flex;
//   width: 100%;
//   justify-content: ${props => props.isLeft ? 'flex-end' : 'flex-start'};
//   position: relative;
//   height: 180px; // Aumentado para dar mais espaço
// `;

// const CurvedLine = styled.div`
//   position: absolute;
//   top: 60px;
//   left: ${props => props.isLeft ? '35px' : '35px'};
//   width: calc(100% - 70px);
//   height: 120px; // Aumentado para uma curva mais suave
//   border: 2px solid #E5E5E5;
//   border-top: none;
//   border-left: none;
//   border-right: none;
//   border-${props => props.isLeft ? 'right' : 'left'}: none;
//   border-radius: ${props => props.isLeft ? '0 0 100px 0' : '0 0 0 100px'};
// `;

// const DashedLineSegment = styled.div`
//   display: none;
//   position: absolute;
//   width: 2px;
//   height: 20px;
//   background-color: ${props => props.color || '#E5E5E5'};
//   top: ${props => props.top}px;
//   left: ${props => props.left}%;
// `;

// const LessonTrack = () => {
//     const lessons = [
//         { completed: true, locked: false },
//         { completed: true, locked: false },
//         { completed: false, locked: false },
//         { completed: false, locked: true },
//         { completed: false, locked: true },
//     ];

//     const defaultLineColors = ['#E5E5E5', '#E5E5E5', '#E5E5E5', '#E5E5E5'];

//     return (
//         <TrackContainer>
//             {lessons.map((lesson, index) => {
//                 const isLeft = index % 2 === 0;
//                 const isLast = index === lessons.length - 1;

//                 return (
//                     <LessonRow key={index} isLeft={isLeft}>
//                         <LessonButton
//                             completed={lesson.completed}
//                             locked={lesson.locked}
//                             number={index + 1}
//                         />
//                         {!isLast && (
//                             <>
//                                 <CurvedLine isLeft={isLeft} />
//                                 {defaultLineColors.map((color, lineIndex) => (
//                                     <DashedLineSegment
//                                         key={lineIndex}
//                                         color={color}
//                                         top={90 + lineIndex * 25}
//                                         left={isLeft ? 75 - (lineIndex * 16.6) : 25 + (lineIndex * 16.6)}
//                                     />
//                                 ))}
//                             </>
//                         )}
//                     </LessonRow>
//                 );
//             })}
//         </TrackContainer>
//     );
// };

// export default LessonTrack;


// import React from 'react';
// import styled from '@emotion/styled';
// import { Box } from '@mui/material';
// import LessonButton from './LessonButton';

// const TrackContainer = styled(Box)`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 350px;
//   margin: 0 auto;
// `;

// const LessonRow = styled(Box)`
//   display: flex;
//   width: 100%;
//   justify-content: ${props => props.isLeft ? 'flex-end' : 'flex-start'};
//   position: relative;
//   height: 180px; // Aumentado para dar mais espaço
//   height: 169px; // Aumentado para dar mais espaço
// `;

// const CurvedLine = styled.div`
//   position: absolute;
//   top: 60px;
//   left: ${props => props.isLeft ? '35px' : 'auto'};
//   right: ${props => props.isLeft ? 'auto' : '35px'};
//   width: calc(100% - 70px);
//   height: 120px; // Aumentado para uma curva mais suave
//   border: 3px dashed #E5E5E5;
//   border-top: none;
//   border-left: ${props => props.isLeft ? 'none' : '3px dashed #E5E5E5'};
//   border-right: ${props => props.isLeft ? '3px dashed #E5E5E5' : 'none'};
//   border-radius: ${props => props.isLeft ? '0 0 100px 0' : '0 0 0 100px'};
//   z-index: 1;
// `;

// const LessonTrack = () => {
//     const lessons = [
//         { completed: true, locked: false },
//         { completed: true, locked: false },
//         { completed: false, locked: false },
//         { completed: false, locked: true },
//         { completed: false, locked: true },
//     ];

//     return (
//         <TrackContainer>
//             {lessons.map((lesson, index) => {
//                 const isLeft = index % 2 === 0;
//                 const isLast = index === lessons.length - 1;

//                 return (
//                     <LessonRow key={index} isLeft={isLeft}>
//                         <LessonButton
//                             completed={lesson.completed}
//                             locked={lesson.locked}
//                             number={index + 1}
//                         />
//                         {!isLast && (
//                             <CurvedLine isLeft={isLeft} />
//                         )}
//                     </LessonRow>
//                 );
//             })}
//         </TrackContainer>
//     );
// };

// export default LessonTrack;


import React from 'react';
import styled from '@emotion/styled';
import { Box, Button, Typography, IconButton, Paper } from '@mui/material';
import LessonButton from './LessonButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useAllTheme } from '../../../components/themes/ThemeContext';

const TrackContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  margin: 0 auto;
`;

const LessonRow = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: ${props => props.isLeft ? 'flex-end' : 'flex-start'};
  position: relative;
  height: 169px; // Aumentado para dar mais espaço
`;

const CurvedLine = styled.div`
  position: absolute;
  top: 60px;
  left: ${props => props.isLeft ? '35px' : 'auto'};
  right: ${props => props.isLeft ? 'auto' : '35px'};
  width: calc(100% - 70px);
  height: 120px; // Aumentado para uma curva mais suave
  border: 4px dashed ${props => props.completed ? '#B2F9F9' : (props.isDark ? '#2C383F' : '#E5E5E5')};
  border-top: none;
  border-left: ${props => props.isLeft ? 'none' : `4px dashed ${props.completed ? '#B2F9F9' : (props.isDark ? '#2C383F' : '#E5E5E5')}`};
  border-right: ${props => props.isLeft ? `4px dashed ${props.completed ? '#B2F9F9' : (props.isDark ? '#2C383F' : '#E5E5E5')}` : 'none'};
  border-radius: ${props => props.isLeft ? '0 0 100px 0' : '0 0 0 100px'};
  z-index: 1;
`;

const LessonTrack = () => {
    const lessons = [
        { completed: true, locked: false },
        { completed: true, locked: false },
        { completed: true, locked: false },
        { completed: true, locked: false },
        { completed: false, locked: false },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
        { completed: false, locked: true },
    ];


    const { isDark } = useAllTheme();

    return (
        <>
            <Paper
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#1CB0F6',
                    padding: '16px',
                    borderRadius: '12px',
                    color: '#fff',
                    position: 'sticky',
                    top: 0,
                    marginBottom: '20px',
                    zIndex: 10 // Adicionando z-index para garantir que o componente fique acima de outros elementos ao rolar a página
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box>
                        <Typography variant="caption" sx={{ fontFamily: 'Din-Round-Bold, sans-serif', color: '#BBE7FC' }}>NIVEL A1, PARTE 1</Typography>
                        <Typography variant="h6" sx={{ fontFamily: 'Din-Round-Bold, sans-serif', }}>Comunique-se em inglês</Typography>
                    </Box>
                </Box>
                <Button
                    sx={{
                        backgroundColor: '#1CB0F6',
                        color: '#fff',
                        fontFamily: 'Din-Round-Bold, sans-serif',
                        border: '1px solid #1794CE',
                        borderRadius: '12px',
                        boxShadow: '0 2px 0 #1794CE',
                        p: '10px 30px',
                        '&:hover': {
                            backgroundColor: '#1CB0F6'
                        }
                    }}
                    startIcon={<ListAltIcon />}
                >
                    GUIA
                </Button>
            </Paper>
            <TrackContainer>


                {lessons.map((lesson, index) => {
                    const isLeft = index % 2 === 0;
                    const isLast = index === lessons.length - 1;

                    return (
                        <LessonRow key={index} isLeft={isLeft}>
                            <LessonButton
                                completed={lesson.completed}
                                locked={lesson.locked}
                                number={index + 1}
                            />
                            {!isLast && (
                                <CurvedLine isLeft={isLeft} isDark={isDark} completed={lesson.completed} />
                            )}
                        </LessonRow>
                    );
                })}
            </TrackContainer>
        </>
    );
};

export default LessonTrack;