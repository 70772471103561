import React, { useState, useEffect, useRef } from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';

const Timer = ({ initialTime, isDark, theme }) => {
    const [timeLeft, setTimeLeft] = useState(initialTime);
    const timerRef = useRef(null);

    useEffect(() => {
        timerRef.current = setInterval(() => {
            setTimeLeft(prevTime => prevTime - 1000);
        }, 1000);

        return () => clearInterval(timerRef.current);
    }, []);

    useEffect(() => {
        if (timeLeft <= 0) {
            clearInterval(timerRef.current);
        }
    }, [timeLeft]);

    const minutes = Math.floor(timeLeft / 60000);
    const seconds = ((timeLeft % 60000) / 1000).toFixed(0);

    const getColor = () => {
        if (timeLeft <= 3 * 60 * 1000) {
            return 'red';
        }
        return isDark ? '#4255FF' : '#46C4FF';
    };

    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress
                variant="determinate"
                value={(timeLeft / initialTime) * 100}
                size={50}
                thickness={5}
                sx={{ color: getColor(), borderRadius: '20px' }}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="h6" component="div" color={getColor()} sx={{ fontSize: '15px', fontFamily: 'SF-Pro-Text-Regular, sans-serif' }}>
                    {minutes === 0 && seconds <= 10 ? (
                        <>{seconds < 10 ? `0${seconds}` : seconds}</>
                    ) : (
                        <>{minutes}</>
                    )}
                </Typography>
            </Box>
        </Box>
    );
};

export default Timer;
