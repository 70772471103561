import React, { useState, useEffect } from "react";
import "./style.css";

// function Listening({ onClose }) {
function Listening() {

    function onClose() {
        
    }

    const [screen, setScreen] = useState("rules");
    const [phrases, setPhrases] = useState([]);
    const [spokenPhrase, setSpokenPhrase] = useState('');
    const [selectedPhrase, setSelectedPhrase] = useState(null);
    const [gameOver, setGameOver] = useState(false);
    const [score, setScore] = useState(0); // para manter o controle do score
    const [counter, setCounter] = useState(15); // contador para o timer
    const [gameStart, setGameStart] = useState(false);
    const [attempts, setAttempts] = useState(0);
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState(1);

    const nextScreen = () => {
        if (screen === "rules") {
            setScreen("game");
        } else if (screen === "game") {
            setScreen("results");
        }
    };

    const phrasesArrays = [
        ["Blue moon.", "Blew moon.", "Bloom moon.", "Blue mew."],
        ["Catch the ball.", "Ketchup all.", "Catch up, Paul.", "Catch a pall."],
        ["Free the whales.", "Three Wales.", "Free to wail.", "Three the wails."],
        ["Good night.", "Good knight.", "Could knight.", "Could night."],
        ["Happy trails.", "Happy tales.", "Happily trails.", "Happily tales."],
        ["Ice cream.", "I scream.", "Eyes cream.", "I's cream."],
        ["Just in time.", "Just and time.", "Joust in time.", "Just in thyme."],
        ["Keep it up.", "Cap it up.", "Cape it up.", "Keep it cup."],
        ["Love conquers all.", "Loves conquer Saul.", "Loves conquer's all.", "Love conquer's Saul."],
        ["Meet you there.", "Meat you there.", "Meet you they're.", "Meat you they're."],
        ["Never say never.", "Never say, Nether.", "Nether say never.", "Nether say, Nether."],
        ["Open the door.", "Oven the door.", "Open the dour.", "Oven the dour."],
        ["Please be mine.", "Fleas be mine.", "Please be mime.", "Fleas be mime."],
        ["Quiet please.", "Quite please.", "Quiet, pleas.", "Quite, pleas."],
        ["Read a book.", "Red a book.", "Reed a book.", "Red a buck."],
        ["See the sea.", "See the C.", "Sea the sea.", "Sea the C."],
        ["Time flies.", "Time, flies.", "Thyme flies.", "Thyme, flies."],
        ["Under the sun.", "Under the son.", "Under the sung.", "Under the son."],
        ["Very nice.", "Very mice.", "Vary nice.", "Vary mice."],
        ["Wear a coat.", "Where a coat.", "Wear a quote.", "Where a quote."],
        ["X marks the spot.", "Ex marks the spot.", "X marks the spa.", "Ex marks the spa."],
        ["You are right.", "Ewe are right.", "You are write.", "Ewe are write."],
        ["Zoo is open.", "Zoo is opine.", "Zoo is hoping.", "Zoo is hopping."],
        ["Apple of my eye.", "A pull of my eye.", "Apple off my eye.", "A pull off my eye."],
        ["Buy the book.", "Bye the book.", "Buy the buck.", "Bye the buck."],
        ["Call me back.", "Call me buck.", "Caul me back.", "Caul me buck."],
        ["Dance till dawn.", "Dense till dawn.", "Dance till Don.", "Dense till Don."],
        ["Eggs for breakfast.", "Eggs four breakfast.", "Ex for breakfast.", "Ex four breakfast."],
        ["Fall is here.", "Fall is hear.", "Foul is here.", "Foul is hear."],
        ["Go for gold.", "Go four gold.", "Go for gored.", "Go four gored."],
        ["Help is on the way.", "Help is on the weigh.", "Help is on the whey.", "Help is on the wail."],
        ["I will win.", "Eye will win.", "I will when.", "Eye will when."],
        ["Jump for joy.", "Jump four joy.", "Jump for jai.", "Jump four jai."],
        ["Keep the change.", "Keep the chains.", "Keap the change.", "Keap the chains."],
        ["Love is blind.", "Love is blend.", "Luv is blind.", "Luv is blend."],
        ["Make a cake.", "Make a cape.", "Mace a cake.", "Mace a cape."],
        ["Never give up.", "Never give cup.", "Nether give up.", "Nether give cup."],
        ["Open your heart.", "Open your hart.", "Oven your heart.", "Oven your hart."],
        ["Peace and love.", "Peace end love.", "Piece and love.", "Piece end love."],
        ["Quiet the noise.", "Quite the noise.", "Quiet the noys.", "Quite the noys."],
        ["Race to the finish.", "Race two the finish.", "Rays to the finish.", "Rays two the finish."],
        ["Save the date.", "Save the dait.", "Sage the date.", "Sage the dait."],
        ["Time flies.", "Thyme flies.", "Time flays.", "Thyme flays."],
        ["Under the sea.", "Under the C.", "Udder the sea.", "Udder the C."],
        ["Victory is sweet.", "Victory is suite.", "Vic-tree is sweet.", "Vic-tree is suite."],
        ["Watch the game.", "Watch the gain.", "Witch the game.", "Witch the gain."],
        ["X-ray vision.", "Ex-ray vision.", "X-ray vission.", "Ex-ray vission."],
        ["You are beautiful.", "Ewe are beautiful.", "You are bootiful.", "Ewe are bootiful."],
        ["Zebra crossing.", "Zebra crassing.", "Zebra crosing.", "Zebra crasing."],
        ["Apples and oranges.", "Apples end oranges.", "Apples and orangess.", "Apples end orangess."],
        ["Break the rules.", "Break the roules.", "Brake the rules.", "Brake the roules."],
        ["Cats and dogs.", "Cats end dogs.", "Cats and dawgs.", "Cats end dawgs."],
        ["Do the dishes.", "Do the dishess.", "Dew the dishes.", "Dew the dishess."],
        ["Elephants remember.", "Elephants remembar.", "Elefants remember.", "Elefants remembar."],
        ["Fast and furious.", "Fast end furious.", "Fast and furiouss.", "Fast end furiouss."],
        ["Go the distance.", "Go the distants.", "Go the distanse.", "Go the distantss."],
        ["Happiness is key.", "Happiness is ki.", "Happyness is key.", "Happyness is ki."],
        ["In the end.", "In the and.", "In the endd.", "In the andd."],
        ["Jump the gun.", "Jump the gann.", "Jump the gonn.", "Jump the gon."],
        ["Keep the faith.", "Keep the faeth.", "Keap the faith.", "Keap the faeth."],
        ["Life is short.", "Life is shorrt.", "Lyfe is short.", "Lyfe is shorrt."],
        ["Make it happen.", "Make it hapan.", "Mack it happen.", "Mack it hapan."],
        ["Never give up.", "Never gave up.", "Nevver give up.", "Nevver gave up."],
        ["Open your heart.", "Open your hart.", "Openn your heart.", "Openn your hart."],
        ["Play the game.", "Play the gaim.", "Pley the game.", "Pley the gaim."],
        ["Question everything.", "Question everyting.", "Questtion everything.", "Questtion everyting."],
        ["Read the room.", "Read the roum.", "Reed the room.", "Reed the roum."],
        ["Stay the course.", "Stay the corse.", "Stey the course.", "Stey the corse."],
        ["Take your time.", "Take your tim.", "Tayk your time.", "Tayk your tim."],
        ["Understand the problem.", "Understand the problam.", "Understtand the problem.", "Understtand the problam."],
        ["Value your worth.", "Value your wurth.", "Valu your worth.", "Valu your wurth."],
        ["Wear your mask.", "Wear your masq.", "Were your mask.", "Were your masq."],
        ["X marks the spot.", "Ex marks the spot.", "X marks the spott.", "Ex marks the spott."],
        ["You are loved.", "Ewe are loved.", "You are lovved.", "Ewe are lovved."],
        ["Zest for life.", "Zest for lyfe.", "Zestt for life.", "Zestt for lyfe."],
        ["Appreciate the moment.", "Appreciate the momment.", "Appreciat the moment.", "Appreciat the momment."],
        ["Believe in yourself.", "Believe in yourselv.", "Belive in yourself.", "Belive in yourselv."],
        ["Carpe Diem.", "Karpe Diem.", "Carpe Diemm.", "Karpe Diemm."],
        ["Do your best.", "Dew your best.", "Do your bestt.", "Dew your bestt."],
        ["Enjoy the journey.", "Enjoy the journy.", "Enjoi the journey.", "Enjoi the journy."]
    ];

    useEffect(() => {
        if (screen === "game") {
            setCounter(15);
            setGameStart(true);
            const randomGroupIndex = Math.floor(Math.random() * phrasesArrays.length);
            const chosenPhrases = phrasesArrays[randomGroupIndex];
            setPhrases(chosenPhrases);

            const randomPhraseIndex = Math.floor(Math.random() * chosenPhrases.length);
            const randomPhrase = chosenPhrases[randomPhraseIndex];
            setSpokenPhrase(randomPhrase);

            speak(randomPhrase);
        }
    }, [screen]);


    const handlePhraseClick = (phrase) => {
        setSelectedPhrase(phrase);
        setAttempts(attempts + 1); // incrementa o número de tentativas
        if (phrase === spokenPhrase) {
            setScore(score + 1);
            setGameOver(true);
        } else {
            setGameOver(true);
        }
        setGameStart(false);
    };


    const handleStopClick = () => {
        setCounter(15);
        setScreen("results");
    };

    useEffect(() => {
        if (counter > 0 && gameStart && !gameOver) {
            const timer = setTimeout(() => {
                setCounter(counter - 1);
            }, 1000);
            return () => clearTimeout(timer);
        } else if (!gameStart) {
            setCounter(15);
        } else {
            setGameOver(true);
        }
    }, [counter, gameStart, gameOver]);


    const [speaking, setSpeaking] = useState(false)

    const speak = (phrase) => {
        const utterance = new SpeechSynthesisUtterance(phrase);
        utterance.lang = 'en-US';
        window.speechSynthesis.speak(utterance);

        utterance.onstart = () => {
            setSpeaking(true);
        };

        utterance.onend = () => {
            setSpeaking(false);
        };

    }

    const handleNextClick = () => {
        setGameOver(false);
        setSelectedPhrase(null);
        setGameStart(true);
        setCounter(15); // redefinindo o contador para 15

        const randomGroupIndex = Math.floor(Math.random() * phrasesArrays.length);
        const chosenPhrases = phrasesArrays[randomGroupIndex];
        setPhrases(chosenPhrases);

        const randomPhraseIndex = Math.floor(Math.random() * chosenPhrases.length);
        const randomPhrase = chosenPhrases[randomPhraseIndex];
        setSpokenPhrase(randomPhrase);

        speak(randomPhrase);

        setCurrentQuestionNumber(currentQuestionNumber + 1); // incrementa o número da pergunta
    }

    const replayQuiz = () => {
        setScreen("rules");
        setPhrases([]);
        setSpokenPhrase('');
        setSelectedPhrase(null);
        setGameOver(false);
        setScore(0);
        setCounter(15);
        setGameStart(false);
        setAttempts(0);
    };

    return (
        <div className="containerListening">
            {screen === "rules" && (
                <div className="info_box">
                    <div className="info-title">
                        <span>Regras do jogo</span>
                    </div>
                    <div className="info-list">
                        <div className="info">1. You will have only <span>15 seconds</span> per each question.</div>
                        <div className="info">2. Once you select your answer, it can't be undone.</div>
                        <div className="info">3. You can't select any option once time goes off.</div>
                        <div className="info">4. You can't exit from the Quiz while you're playing.</div>
                        <div className="info">5. You'll get points on the basis of your correct answers.</div>
                    </div>
                    <div className="buttons">
                        <button className="quit" onClick={() => onClose()}>Exit Quiz</button>
                        <button className="restart" onClick={nextScreen}>Next</button>
                    </div>
                </div>
            )}

            {screen === "game" && (
                <div className="quiz_box">
                    <header>
                        <div className="title">Choose the sentence you heard:</div>
                        <div className="timer">
                            <div className="time_left_txt">Time:</div>
                            <div className="timer_sec">{counter}</div>
                        </div>
                    </header>
                    <section>
                        <div className="que_text">
                            <span>Select what you heard</span>
                            <button className="speak-button" onClick={() => speak(spokenPhrase)}>
                                {!speaking
                                    ?
                                    <span className="material-symbols-outlined">
                                        volume_up
                                    </span>
                                    :
                                    <span className="material-symbols-outlined" style={{ color: '#84c5fe' }}>
                                        volume_up
                                    </span>
                                }
                            </button>
                        </div>
                        <div className="option_list">
                            {phrases.map((phrase, index) => (
                                <div
                                    key={index}
                                    onClick={() => handlePhraseClick(phrase)}
                                    className={
                                        selectedPhrase === phrase
                                            ? phrase === spokenPhrase
                                                ? "option correct" + (gameOver ? " disabled" : "")
                                                : "option incorrect" + (gameOver ? " disabled" : "")
                                            : gameOver && phrase === spokenPhrase
                                                ? "option correct" + (gameOver ? " disabled" : "")
                                                : "option" + (gameOver ? " disabled" : "")
                                    }
                                    disabled={gameOver ? "disabled" : ""}
                                >
                                    <span>
                                        {phrase}
                                    </span>
                                    {selectedPhrase === phrase
                                        ? phrase === spokenPhrase
                                            ? <div className="icon tick"><i className="fas fa-check"></i></div>
                                            : <div className="icon cross"><i className="fas fa-times"></i></div>
                                        : gameOver && phrase === spokenPhrase
                                            ? <div className="icon tick"><i className="fas fa-check"></i></div>
                                            : <div></div>
                                    }
                                </div>
                            ))}
                        </div>
                    </section>
                    <footer>
                        <div className="total_que">
                            <span>Question number: {currentQuestionNumber}</span>
                        </div>
                        {(selectedPhrase || gameOver) && (
                            <div className="action-buttons">
                                <button className="next_btn" onClick={handleNextClick}>Next</button>
                                <button className="quit" onClick={handleStopClick}>Stop</button>
                            </div>
                        )}
                    </footer>
                </div>
            )}

            {screen === "results" && (
                <div className="result_box">
                    <div className="icon">
                        <i className="fas fa-crown"></i>
                    </div>
                    <div className="complete_text">Você completou o quiz!</div>
                    <div className="score_text">
                        <p>Você acertou {score} frases.</p>
                        <p>Você errou {attempts - score} frases.</p>
                    </div>
                    <div className="buttons">
                        <button className="restart" onClick={replayQuiz}>Replay Quiz</button>
                        <button className="quit" onClick={() => onClose()}>Quit Quiz</button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Listening;

