import React, { createContext, useState, useContext } from 'react';

// Criação do contexto
const LessonContext = createContext();

export const useLesson = () => useContext(LessonContext);

export const LessonProvider = ({ children }) => {
    const [progress, setProgress] = useState({
        type: '',
        explanation: '',
        userResponse: ''
    });

    const [scores, setScores] = useState({
        correct: 0,
        incorrect: 0
    });

    const [controls, setControls] = useState({
        isDisabled: false,
        isChecked: false,
        currentExerciseIndex: 0
    });

    const [checkFunction, setCheckFunction] = useState(null);

    const nextExercise = () => {
        setControls((prev) => ({
            ...prev,
            currentExerciseIndex: prev.currentExerciseIndex + 1,
            isChecked: false,
            isDisabled: false
        }));

        setProgress({
            type: '',
            explanation: ''
        });
    };

    const handleCheck = () => {
        if (checkFunction) {
            checkFunction(); // Chama a função de verificação do exercício atual
        }
    };

    const handleSkip = () => {
        nextExercise();
    };

    return (
        <LessonContext.Provider
            value={{
                progress,
                setProgress,
                scores,
                setScores,
                controls,
                setControls,
                setCheckFunction,
                nextExercise,
                handleCheck,
                handleSkip
            }}
        >
            {children}
        </LessonContext.Provider>
    );
};
