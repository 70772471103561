import {
    Button,
    Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TextareaAutosize } from '@mui/material';

export const StyledButton = styled(Button)(({ theme, actived }) => ({
    border: actived ? `1px solid ${theme.borderMenu}` : `1px solid ${theme.border}`,
    borderRadius: '12px',
    boxShadow: actived ? `0 2px 0 ${theme.borderMenu}` : `0 2px 0 ${theme.border}`,
    padding: '10px 30px',
    textTransform: 'none',
    margin: '0 5px',
    backgroundColor: actived ? theme.backgroundMenu : 'transparent',
    color: actived ? theme.colorMenuSelected : theme.color,
    '&:active': {
        boxShadow: actived ? `0 0px 0 ${theme.borderMenu}` : `0 0px 0 ${theme.border}`,
        transform: 'translateY(2px)'
    }
}));


export const AudioButton = styled(Button)({
    width: '140px',
    height: '140px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '30px',
    border: 0,
    outline: 0,
    backgroundColor: '#19B0F5',
    cursor: 'pointer',
    color: '#FFFFFF',
    boxShadow: '0 4px 0 #2295CB',
    padding: '10px 30px',
    fontSize: 50,
    transition: 'all 0.1s ease-in-out',
    '&:hover': {
        backgroundColor: '#19B0F5',
    },
    '&:active': {
        boxShadow: '0 0px 0 #2295CB',
        transform: 'translateY(4px)'
    }
});

export const OptionButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    height: '58px',
    border: `2px solid ${theme.border}`,
    borderRadius: '12px',
    boxShadow: `0 2px 0 ${theme.border}`,
    backgroundColor: 'transparent',
    color: theme.colorMenu,
    outline: 0,
    fontSize: '18px',
    fontWeight: 400,
    alignItems: 'center',
    padding: '6px',
    marginBottom: '10px',
    cursor: 'pointer',
    textTransform: 'none',
    justifyContent: 'flex-start',
    '&:disabled': {
        border: `2px solid ${theme.border}`,
        boxShadow: `0 0px 0 ${theme.border}`,
        backgroundColor: 'transparent',
        color: '#E5E9EE',
        fontWeight: 400,
    },
    '&:active': {
        boxShadow: `0 0px 0 ${theme.borderMenu}`,
        transform: 'translateY(2px)',
        transition: 'all 0.1s ease-in-out',
    },
    '&.optionSelected': {
        border: `2px solid ${theme.borderMenu}`,
        boxShadow: `0 2px 0 ${theme.borderMenu}`,
        color: theme.colorMenuSelected,
        backgroundColor: theme.backgroundMenu,
    },
    '& .buttonOptionListeningNumber': {
        width: '30px',
        height: '30px',
        border: `2px solid ${theme.border}`,
        borderRadius: '6px',
        backgroundColor: 'transparent',
        color: theme.colorMenu,
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '8px',
    },
    '&.optionSelected .buttonOptionListeningNumber': {
        border: `2px solid ${theme.borderMenu}`,
        color: theme.colorMenuSelected,
        backgroundColor: theme.backgroundMenu,
    },
    '&:disabled .buttonOptionListeningNumber': {
        border: `2px solid ${theme.border}`,
        backgroundColor: 'transparent',
        color: '#E5E9EE',
    },
    '&.optionSelected:disabled .buttonOptionListeningNumber': {
        border: `2px solid ${theme.borderMenu}`,
        color: theme.colorMenuSelected,
        backgroundColor: theme.backgroundMenu,
    }
}));


export const LineBox = styled(Box)(({ theme }) => ({
    borderBottom: `2px solid ${theme.border}`,
    minHeight: '60px',
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    marginBottom: '15px',
    width: '600px',
}));

export const StyledTextarea = styled(TextareaAutosize)(({ theme, isDark }) => ({
    width: '100%',
    resize: 'none',
    borderRadius: '10px',
    padding: '10px 12px',
    margin: '20px 0',
    outline: 'none',
    backgroundColor: theme.backgroundInput,
    color: theme.colorInput,
    border: `1px solid ${theme.borderInput}`,
    fontSize: '18px',
    fontWeight: 500,
    height: '100%',
    height: '100%',
    '&:disabled': {
        backgroundColor: isDark ? '' : '#E0E0E0',
        color: isDark ? '' : '#A0A0A0',
    },
}));