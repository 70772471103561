import React, { useState } from "react";
import { Box, Paper, Collapse } from "@mui/material";
import CenterContent from "../CenterContent";
import TopBarContent from "../TopBarContent";
import ChatAI from "../ChatAI";
import Subtitle from "../Subtitle";
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import './style.css'
import { useTheme } from "../themes/ThemeContext";

const MainContent = ({ setInteractLesson, handleDelete, addBoxCenter, handleToggleChat, responseCaption, userResponseCaption, bookOn, subtitlesOn, chatOn, addBox, boxes, setBoxes, messages, setMessages, message, setMessage, handleSendMessage, files, setFiles }) => {

    const { theme, isDark } = useTheme();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: '100%', padding: '16px', paddingBottom: '0px', maxHeight: 'calc(100vh - 91px)' }}>
            <TopBarContent bookOn={bookOn} addBox={addBox} theme={theme} />
            <Box sx={{ display: 'flex', flex: 1, transition: 'all 0.3s ease', overflow: 'hidden', gap: '10px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, borderRadius: '10px', transition: 'all 0.3s ease', overflow: 'hidden' }}>
                    <CenterContent handleDelete={handleDelete} addBoxCenter={addBoxCenter} boxes={boxes} setBoxes={setBoxes} addBox={addBox} theme={theme} isDark={isDark} />
                </Box>
                <Collapse in={chatOn} timeout={300} unmountOnExit orientation="horizontal">
                    <ResizableBox
                        width={350}
                        minConstraints={[200, Infinity]}
                        maxConstraints={[600, Infinity]}
                        resizeHandles={['w']}
                    >
                        <Box sx={{ height: '100%', borderRadius: '10px', overflow: 'hidden', border: !isDark ? '2px solid #E5E5E5' : '' }}>
                            <Paper sx={{ height: '100%', borderRadius: '10px', overflow: 'hidden' }}>
                                <ChatAI setInteractLesson={setInteractLesson} handleToggleChat={handleToggleChat} messages={messages} setMessages={setMessages} message={message} setMessage={setMessage} handleSendMessage={handleSendMessage} boxes={boxes} files={files} setFiles={setFiles} />
                            </Paper>
                        </Box>
                    </ResizableBox>
                </Collapse>
            </Box>
            <Subtitle subtitlesOn={subtitlesOn} responseCaption={responseCaption} userResponseCaption={userResponseCaption} />
        </Box>
    );
};

export default MainContent;