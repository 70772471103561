import React, { useRef, useState, useEffect } from 'react';
import { Box, Container, Typography, Tabs, Tab, Button, Grid } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { UserContext } from '../components/UserProvider';
import { useContext } from 'react';
import SimpleMenu from '../components/SimpleMenu';

import PayComponent from '../home/PayComponent';
import AdComponent from '../home/AdComponent';
import AddFriend from '../home/addFriend';
import HeadComponent from '../home/headComponent';
import { sendTextToSpeechRequest } from '../componentsTalkIA/IAtalk2ME/Talk2MeVoice';
import { useAllTheme } from '../components/themes/ThemeContext';

const SoundButton = ({ sound, example, speak, theme }) => (

    <Button
        onClick={() => [speak(`${sound.replace(/\//g, '')}, ${example}`)]}
        variant="outlined"
        sx={{
            height: 96,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '16px',
            transition: 'all 0.1s ease-in-out',
            boxShadow: `0px 2px 0px ${theme.border}`,
            border: `2px solid ${theme.border}`,
            marginBottom: '8px',
            outline: 'none',
            '&:hover': {
                border: `2px solid ${theme.border}`,
            },
            '&:active': {
                boxShadow: `0 0px 0 ${theme.border}`,
                transform: 'translateY(2px)'
            }
        }}
    >
        <Typography variant="h6" sx={{ color: theme.color, fontFamily: 'Din-Round-Bold, sans-serif', }}>{sound}</Typography>
        <Typography variant="caption" sx={{ color: theme.border, fontFamily: 'SF-Pro-Display, sans-serif', }}>{example}</Typography>
    </Button>
);

const SoundGrid = ({ sounds, speak, theme }) => (
    <Grid container spacing={2} justifyContent="center">
        {sounds.map((sound, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} sx={{ maxWidth: '200px' }}>
                <SoundButton sound={sound.sound} example={sound.example} speak={speak} theme={theme} />
            </Grid>
        ))}
    </Grid>
);

const EnglishSoundsPractice = ({ speak, theme }) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const vowelSounds = [
        { sound: "/æ/", example: "cat" },
        { sound: "/eɪ/", example: "make" },
        { sound: "/ɛ/", example: "bed" },
        { sound: "/iː/", example: "see" },
        { sound: "/ɪ/", example: "sit" },
        { sound: "/aɪ/", example: "my" },
        { sound: "/ɒ/", example: "not" },
        { sound: "/oʊ/", example: "go" },
        { sound: "/ʊ/", example: "put" },
        { sound: "/uː/", example: "blue" },
        { sound: "/ʌ/", example: "cup" },
        { sound: "/ɑː/", example: "car" },
        { sound: "/ɔː/", example: "saw" },
        { sound: "/aʊ/", example: "now" },
        { sound: "/ɪə/", example: "near" },
        { sound: "/eə/", example: "hair" },
        { sound: "/ʊə/", example: "tour" },
    ];

    const consonantSounds = [
        { sound: "/b/", example: "bat" },
        { sound: "/d/", example: "dog" },
        { sound: "/f/", example: "fish" },
        { sound: "/g/", example: "go" },
        { sound: "/h/", example: "hat" },
        { sound: "/dʒ/", example: "joke" },
        { sound: "/k/", example: "cat" },
        { sound: "/l/", example: "lamp" },
        { sound: "/m/", example: "man" },
        { sound: "/n/", example: "net" },
        { sound: "/ŋ/", example: "sing" },
        { sound: "/p/", example: "pen" },
        { sound: "/r/", example: "red" },
        { sound: "/s/", example: "see" },
        { sound: "/ʃ/", example: "she" },
        { sound: "/t/", example: "top" },
        { sound: "/tʃ/", example: "chop" },
        { sound: "/θ/", example: "think" },
        { sound: "/ð/", example: "this" },
        { sound: "/v/", example: "van" },
        { sound: "/w/", example: "we" },
        { sound: "/j/", example: "yes" },
        { sound: "/z/", example: "zoo" },
        { sound: "/ʒ/", example: "measure" },
    ];

    return (
        <Box sx={{ width: '100%' }}>
            <Typography variant="h4" align="center" sx={{ fontFamily: 'SF-Pro-Display, sans-serif', color: theme.color }} gutterBottom>
                Vamos aprender os sons em inglês!
            </Typography>
            <Typography variant="body1" align="center" sx={{ mb: 4, fontFamily: 'SF-Pro-Text, sans-serif', color: theme.color }}>
                Treine o seu ouvido e aprenda a pronunciar os sons do inglês.
            </Typography>

            <Tabs value={activeTab} onChange={handleTabChange} centered sx={{ mb: 2 }}>
                <Tab
                    label="Sons das Vogais"
                    sx={{
                        color: activeTab === 0 ? 'inherit' : theme.color,
                    }}
                />
                <Tab
                    label="Sons das Consoantes"
                    sx={{
                        color: activeTab === 1 ? 'inherit' : theme.color,
                    }}
                />
            </Tabs>

            <Box sx={{ mt: 2 }}>
                {activeTab === 0 && <SoundGrid sounds={vowelSounds} speak={speak} theme={theme} />}
                {activeTab === 1 && <SoundGrid sounds={consonantSounds} speak={speak} theme={theme} />}
            </Box>
        </Box>
    );
};

export default function Sounds() {

    const [audioUrl, setAudioUrl] = useState('');
    const audioRef = useRef(null);

    const { theme, isDark } = useAllTheme();

    const speak = async (speak) => {
        const url = await sendTextToSpeechRequest(speak, "Danielle");
        setAudioUrl(url);
    };

    useEffect(() => {
        if (audioUrl && audioRef.current) {
            audioRef.current.play();
        }
    }, [audioUrl]);

    return (
        <div className={isDark ? 'allHome dark' : 'allHome'}>
            {audioUrl && (
                <audio
                    src={audioUrl}
                    ref={audioRef}
                    style={{ display: 'none' }}
                />
            )}
            <SimpleMenu />
            <Box className='home' sx={{ padding: '24px' }}>
                <Container sx={{ display: 'flex', gap: '48px', width: '100%' }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '41px' }}>
                        <EnglishSoundsPractice speak={speak} theme={theme} />
                    </Box>
                    <Box sx={{ width: '360px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px', position: 'sticky', top: 0, }}>
                            <HeadComponent />
                            <PayComponent />
                            <AddFriend />
                            <AdComponent />
                        </Box>
                    </Box>
                </Container>
            </Box>
        </div>
    );
}