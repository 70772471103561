import React, { useState, useEffect } from 'react';
import { styled, keyframes } from '@mui/material/styles';
import Button from '@mui/material/Button';

const glareAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const starAnimation = keyframes`
  0% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateY(-50px) scale(0);
    opacity: 0;
  }
`;

const CardContainer = styled(Button)(({ theme }) => ({
  borderRadius: '16px',
  background: '#4255FF',
  color: '#FFFFFF',
  padding: '6px',
  position: 'relative',
  overflow: 'hidden',
  minWidth: '300px',
  fontFamily: 'Din-Round-Bold, sans-serif',
  transition: 'all 0.1s ease-in-out',
  boxShadow: '0px 4px 0px #4525FF',
  border: '2px solid #4255FF',
  marginBottom: '8px',
  '&:hover': {
    background: '#4255FF',
  },
  '&:disabled': {
    background: '#FFFFFF',
    color: '#E5E5E5',
    boxShadow: '0px 0px 0px #E5E5E5',
    border: '2px solid #E5E5E5',
  },
  '&:active': {
    boxShadow: '0 0px 0 #4525FF',
    transform: 'translateY(4px)'
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '-50%',
    left: '-50%',
    width: '200%',
    height: '200%',
    background: 'linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,0) 100%)',
    transform: 'rotate(30deg)',
    animation: `${glareAnimation} 3s infinite`,
  }
}));

const Star = styled('span')({
  position: 'absolute',
  pointerEvents: 'none',
  width: '10px',
  height: '10px',
  background: 'white',
  borderRadius: '50%',
  opacity: 0,
  animation: `${starAnimation} 0.5s ease-out`,
});

const AnimatedButton = ({ children, ...props }) => {
  const [stars, setStars] = useState([]);

  const createStar = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const star = {
      id: Date.now(),
      left: e.clientX - rect.left,
      top: e.clientY - rect.top,
    };
    setStars((prevStars) => [...prevStars, star]);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setStars((prevStars) => prevStars.slice(1));
    }, 500);

    return () => clearTimeout(timer);
  }, [stars]);

  return (
    <CardContainer {...props} onMouseMove={createStar}>
      {children}
      {stars.map((star) => (
        <Star key={star.id} style={{ left: star.left, top: star.top }} />
      ))}
    </CardContainer>
  );
};

export default AnimatedButton;