import React, { useState, useRef, useEffect, useContext } from "react";
import "./style.css";
import { UserContext } from "../components/UserProvider";
import { useAllTheme } from "../components/themes/ThemeContext";
import { Box, Popover, Typography } from "@mui/material";
import BallTalkAI from "../componentsTalkIA/talkBallAI";
import UserAvatar from "../components/UserAvatar";
import ChatInteractions, { CommonButton } from "./ChatInteractions";
import emojiRegex from "emoji-regex";
import { useBallTalkContext } from "../componentsTalkIA/BallTalkContext";
import { sendTextToSpeechRequestMulti } from "../componentsTalkIA/IAtalk2ME/Talk2MeVoice";
import Lesson from "../lessons/LessonComponent";
import { sendVoiceToText } from "../componentsTalkIA/IAtalk2ME/Talk2MeSTT";
import Timer from "./Timer";
import ModalComponent from "./components/ModalComponent";
import LoadingComponent from "./components/LoadingComponent";
import axios from "axios";
import { startSession } from "./components/session";
import { sendClassModeRequest } from "../componentsTalkIA/IAtalk2ME/sendClassModeRequest";

export default function ClassMode() {

  const { user, photoURL, displayName, email } = useContext(UserContext)

  const messageRef = useRef(null);

  const { theme, isDark } = useAllTheme();

  const { setAudioUrl, setShowLoadingMessage, setInterrupt, scale, setScale } = useBallTalkContext();
  const [micOn, setMicOn] = useState(false);

  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);

  const [interactLesson, setInteractLesson] = useState(null)

  const [sessionId, setSessionId] = useState(null);
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendMessage = async (message, [], event, speak, lessonResponses = null) => {
    if (message.trim() !== '' || lessonResponses) {
      event.preventDefault();
      setShowLoadingMessage(true);
      setMessage("");

      try {
        if (!sessionId) {
          setLoading(true);
          try {
            const sessionData = await startSession(user.uid);
            if (sessionData.token) {
              localStorage.setItem('sessionToken', sessionData.token);
            }
            setSessionId(sessionData.sessionId);
          } catch (error) {
            setShowLoadingMessage(false);
            setLoading(false);
            return;
          } finally {
            setLoading(false);
          }
        }

        const newMessage = {
          role: "user",
          content: [
            {
              type: "text",
              text: message.trim()
            },
          ]
        };

        let updatedMessages = null
        if (lessonResponses) {
          updatedMessages = [...messages, lessonResponses];
        } else {
          updatedMessages = [...messages, newMessage];
        }

        setMessages(updatedMessages);

        const response = await sendClassModeRequest(updatedMessages);

        if (response.error) {
          setShowLoadingMessage(false);
          return;
        }

        const responseMessages = response.messages;

        if (responseMessages && responseMessages.length > 0) {
          setMessages(prevMessages => [...prevMessages, ...responseMessages]);
          setShowLoadingMessage(false);

          const lastMessage = responseMessages[responseMessages.length - 1];

          if (lastMessage.tool_calls && lastMessage.tool_calls.length > 0) {
            // Processando uma chamada de função
            const functionCallPart = lastMessage.tool_calls[0];
            const data = JSON.parse(functionCallPart.function.arguments);
            const formattedData = typeof data === 'object' && data !== null ? data : {};
            setInteractLesson({
              type: functionCallPart.function.name,
              data: formattedData,
              id: functionCallPart.id
            });

            if (formattedData.task_instruction && speak && !micOn) {
              let url;
              url = await sendTextToSpeechRequestMulti(formattedData.task_instruction, 'nova');
              setAudioUrl(url);
            }
          } else if (lastMessage.content && Array.isArray(lastMessage.content)) {
            const talkAiResponse = lastMessage.content
              .map(contentItem => contentItem.text || '')
              .join('\n');
            if (speak && !micOn) {
              const emojiRegEx = emojiRegex();
              const contentWithoutEmojis = talkAiResponse.replace(emojiRegEx, "");
              const urlRegEx = /(https?:\/\/[^\s]+)/g;
              const contentWithoutEmojisAndUrls = contentWithoutEmojis.replace(urlRegEx, "");
              const url = await sendTextToSpeechRequestMulti(contentWithoutEmojisAndUrls, 'nova');
              setAudioUrl(url);
            }
          }
        }
      } catch (error) {
        setShowLoadingMessage(false);
      }
    }
  };

  const actionVerifyButton = () => {
    setInteractLesson(null)
  }

  let first = false
  useEffect(() => {
    if (micOn) {
      setInterrupt(true)
      startRecording();
    } else {
      if (first) {
        first = true
        return
      }
      setInterrupt(false)
      stopRecording();
    }
  }, [micOn]);

  const handleKeyDown = (event) => {
    if (event.key === ' ' && !['INPUT', 'TEXTAREA', 'SELECT', 'CONTENTEDITABLE'].includes(event.target.tagName.toUpperCase()) && !(event.target.getAttribute("contenteditable") === "true")) {
      setMicOn(true);
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === ' ' && !['INPUT', 'TEXTAREA', 'SELECT', 'CONTENTEDITABLE'].includes(event.target.tagName.toUpperCase()) && !(event.target.getAttribute("contenteditable") === "true")) {
      setMicOn(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);


  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const analyserRef = useRef(null);
  const animationFrameRef = useRef(null);
  const streamRef = useRef(null);

  useEffect(() => {
    if (isRecording) {
      updateScale();
    } else {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    }
  }, [isRecording]);

  const updateScale = () => {
    if (analyserRef.current && isRecording) {
      const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
      analyserRef.current.getByteFrequencyData(dataArray);
      const average = dataArray.reduce((sum, value) => sum + value, 0) / dataArray.length;
      let newScale = 1 + (average / 128) * 0.9;
      setScale(newScale);
    } else {
      // console.log('Analyser ou gravação não disponível');
    }
    if (isRecording) {
      animationFrameRef.current = requestAnimationFrame(updateScale);
    }
  };

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        streamRef.current = stream;
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.start();
        setIsRecording(prevState => {
          return true;
        });
        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const source = audioContext.createMediaStreamSource(stream);
        analyserRef.current = audioContext.createAnalyser();
        analyserRef.current.fftSize = 256;
        source.connect(analyserRef.current);
      })
      .catch(err => console.error('Erro ao acessar dispositivos de mídia:', err));
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => {
          track.stop();
        });
      }
      mediaRecorderRef.current.onstop = async () => {
        setScale(1);
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
        audioChunksRef.current = [];
        if (audioBlob) {
          const response = await sendVoiceToText(audioBlob);
          handleSendMessage(response, [], { preventDefault: () => { } }, true)
        }
      };
    } else {
      // console.log('MediaRecorder não está ativo ou não existe');
    }
  };

  return (
    <div className='classRoom'>
      {loading && <LoadingComponent />}
      <Box sx={{ display: 'flex', gap: 5, position: 'relative', justifyContent: 'center' }}>
        {interactLesson
          ?
          <Box sx={{ width: '800px', maxHeight: '900px', borderRadius: '10px', overflow: 'hidden', border: `1px solid ${theme.border}` }}>
            <Lesson
              data={[interactLesson]}
              enableSkip={false}
              verifyButton={actionVerifyButton}
              messages={messages}
              setMessages={setMessages}
              handleSendMessage={handleSendMessage}
            />
          </Box>
          :
          <ChatInteractions messageRef={messageRef} messages={messages} setInteractLesson={setInteractLesson} theme={theme} handleSendMessage={handleSendMessage} message={message} setMessage={setMessage} micOn={micOn} setMicOn={setMicOn} />
        }
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <BallTalkAI
            color={isDark ? '#4255FF' : '#46C4FF'}
            size={200}
          />
          <Box className="boxUserClassRoom" sx={{ border: `3px solid ${isDark ? '#4255FF' : '#46C4FF'}` }}>
            {user && <UserAvatar userName={displayName} email={email} photoURL={photoURL} size={100} />}
          </Box>
        </Box>
      </Box>

      {sessionId && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute', bottom: 20, right: 20 }}>
          <Timer initialTime={15 * 60 * 1000} isDark={isDark} theme={theme} /> {/* 15 minutos em milissegundos */}
        </Box>
      )}

      <ModalComponent open={open} handleClose={handleClose} />
    </div>
  );
}