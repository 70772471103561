import React from 'react';
import { Card, CardContent, Button, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

import superIcon from '../../assets/icons/homeScreen/community.png'
import { Link } from 'react-router-dom';
import { useAllTheme } from '../../components/themes/ThemeContext';

const CardContainer = styled(Card)(({ theme }) => ({
    borderRadius: '16px',
    background: theme.background,
    color: theme.color,
    padding: 0,
    position: 'relative',
    overflow: 'hidden',
    minWidth: '300px',
    boxShadow: 'none',
    border: `2px solid ${theme.border}`,
}));

const SuperBadge = styled(Box)(({ theme }) => ({
    background: 'linear-gradient(135deg, #00E676 0%, #00C853 100%)',
    color: '#ffffff',
    padding: '6px 10px',
    borderRadius: '12px',
    display: 'inline-block',
    fontWeight: 'bold',
    fontFamily: 'Din-Round-Bold, sans-serif',
    marginBottom: '10px',
}));

const UpgradeButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#2962FF',
    color: '#FFFFFF',
    width: '100%',
    borderRadius: '15px',
    fontFamily: 'Din-Round-Bold, sans-serif',
    '&:hover': {
        backgroundColor: '#0039CB',
    },
}));

const ImageContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    '& img': {
        marginLeft: theme.spacing(1),
        width: '80px', // ajuste o tamanho da imagem conforme necessário
        height: '80px', // ajuste o tamanho da imagem conforme necessário
    },
}));

const PayComponent = () => {

    const { theme, isDark } = useAllTheme();

    return (
        <CardContainer theme={theme}>
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '16px',
                    boxShadow: 'none',
                    '&:last-child': {
                        paddingBottom: '16px',
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box>
                        <SuperBadge>PREMIUM</SuperBadge>
                        <Typography
                            variant="h5"
                            component="div"
                            gutterBottom
                            sx={{ fontFamily: 'Din-Round-Bold, sans-serif', color: theme.color }}
                        >
                            Suba de nível com o premium
                        </Typography>
                    </Box>
                    <ImageContainer>
                        <img src={superIcon} alt="Super Icon" />
                    </ImageContainer>
                </Box>
                <Typography
                    variant="body2"
                    sx={{ fontFamily: 'Din-Round-Light, sans-serif', marginBottom: '16px', color: theme.color }}
                >
                    Zero anúncios, prática personalizada e nível ilimitado!
                </Typography>
                <Link to='/settings/subscription/plans'>
                    <UpgradeButton variant="contained">VIRAR PREMIUM</UpgradeButton>
                </Link>
            </CardContent>
        </CardContainer>
    );
};

export default PayComponent;
