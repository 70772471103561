import { Box, IconButton, TextField } from '@mui/material';
import React, { useState } from 'react';
import ScrollMessage from '../../../talk2IA/ChatAI/scrollMessage';
import ArrowUpward from '@mui/icons-material/ArrowUpward';

export default function MainChat({ messageRef, messages, setMessage, message, theme, handleSendMessage }) {

    const [interactLesson, setInteractLesson] = useState(null)

    function handleKeyPress(e) {
        const inputValue = e.target.value;

        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();

            if (inputValue.trim() !== '') {
                handleSendMessage(inputValue, [], e, true);
            }
        }
    }

    const handleSend = (event) => {
        handleSendMessage(message, [], event, true);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', height: '100%', position: 'relative' }}>
            <ScrollMessage messageRef={messageRef} messages={messages} sx={{ overflowY: 'auto', flex: 1, padding: '75px 25px 60.8px 25px', display: 'flex', flexDirection: 'column-reverse', marginRight: '2px', position: 'relativa' }} boxes={[]} setInteractLesson={setInteractLesson} />
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    backdropFilter: 'blur(28.5px)',
                    padding: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    zIndex: 1,
                    borderTop: '1px solid #E5E5E5',
                    overflow: 'visible',
                }}
            >
                <TextField
                    multiline
                    placeholder="Type a message..."
                    variant="outlined"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e)}
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                onClick={(event) => handleSend(event)}
                                sx={{
                                    backgroundColor: theme.color,
                                    color: theme.border,
                                    borderRadius: '50%',
                                    width: '30px',
                                    height: '30px',
                                    '&:hover': {
                                        backgroundColor: theme.color
                                    }
                                }}
                            >
                                <ArrowUpward style={{ fontSize: '18px' }} />
                            </IconButton>
                        ),
                        style: { padding: '5px ', borderColor: '#E5E5E5 !important', borderRadius: '20px', outline: 'none' }
                    }}
                    sx={{
                        flexGrow: 1,
                        '& .MuiOutlinedInput-root': { padding: 0 },
                        '& .MuiInputBase-input': { paddingLeft: '10px' },
                        width: '100%'
                    }}
                />
            </Box>
        </Box>
    )
}