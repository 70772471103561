import React, { createContext, useState, useRef, useEffect, useContext } from 'react';

const VideoShareContext = createContext();

export const VideoShareProvider = ({ children }) => {
  const [isSharing, setIsSharing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const videoRef = useRef(null);
  const streamRef = useRef(null);

  const handleShareScreen = async () => {
    if (!isSharing) {
      setIsLoading(true);
      try {
        const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });
        streamRef.current = stream;
        setIsSharing(true);

        stream.getVideoTracks()[0].addEventListener('ended', () => {
          stopSharing();
        });
        setIsLoading(false);
      } catch (err) {
        console.error('Erro ao compartilhar tela:', err);
        setIsLoading(false);
      } finally {
        // setIsLoading(false);
      }
    } else {
      stopSharing();
    }
  };

  const stopSharing = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => {
        track.stop();
      });
      streamRef.current = null;
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
    setIsSharing(false);
  };

  useEffect(() => {
    if (isSharing && streamRef.current && videoRef.current) {
      videoRef.current.srcObject = streamRef.current;
    }
  }, [isSharing]);

  useEffect(() => {
    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  return (
    <VideoShareContext.Provider value={{ isSharing, isLoading, handleShareScreen, videoRef }}>
      {children}
    </VideoShareContext.Provider>
  );
};

export const useVideoShare = () => useContext(VideoShareContext);