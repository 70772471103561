import React, { createContext, useState, useContext, useEffect } from 'react';
import { themes } from '../index';

const ThemeContext = createContext();

export const AllThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(themes.light);
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    // Detecta o tema preferido do sistema (claro ou escuro)
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const initialTheme = darkModeMediaQuery.matches ? themes.dark : themes.light;
    setTheme(initialTheme);
    setIsDark(darkModeMediaQuery.matches);
    document.documentElement.setAttribute('data-theme', darkModeMediaQuery.matches ? 'dark' : 'light');

    // Atualiza o tema se o usuário mudar a preferência de tema do sistema
    const handleChange = (e) => {
      setTheme(e.matches ? themes.dark : themes.light);
      setIsDark(e.matches);
      document.documentElement.setAttribute('data-theme', e.matches ? 'dark' : 'light');
    };

    darkModeMediaQuery.addEventListener('change', handleChange);
    return () => darkModeMediaQuery.removeEventListener('change', handleChange);
  }, []);

  const toggleTheme = (themeName) => {
    setTheme(themes[themeName]);
    setIsDark(themeName === 'dark');
    document.documentElement.setAttribute('data-theme', themeName);
  };

  return (
    <ThemeContext.Provider value={{ theme, isDark, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useAllTheme = () => useContext(ThemeContext);
