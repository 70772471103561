import React from 'react';
import { Card, CardContent, Button, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

import test from '../../assets/download.svg'
import test01 from '../../assets/download01.png'
import { Link } from 'react-router-dom';

const CardContainer = styled(Card)(({ theme }) => ({
    borderRadius: '16px',
    background: 'linear-gradient(135deg, #004e92 0%, #000428 100%)',
    color: '#FFFFFF',
    padding: 0,
    position: 'relative',
    overflow: 'hidden',
    minWidth: '100%'
}));

const SuperBadge = styled(Box)(({ theme }) => ({
    background: '#1de9b6',
    color: '#000',
    padding: theme.spacing(0.5, 1),
    borderRadius: '12px',
    display: 'inline-block',
    fontWeight: 'bold',
    fontFamily: 'Din-Round-Bold, sans-serif',
}));

const UnlockButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#ffffff',
    color: '#000000',
    boxShadow: '0 4px 0 #9B91B9',
    marginTop: theme.spacing(2),
    transition: 'all 0.1s ease-in-out',
    '&:hover': {
        backgroundColor: '#ffffff',
        boxShadow: '0px 4px 0 #9B91B9'
    },
    '&:active': {
        boxShadow: '0 0px 0 #9B91B9',
        transform: 'translateY(4px)'
    }
}));

const ImageContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    '& img': {
        marginLeft: theme.spacing(1),
    }
}));

const ReviewCard = () => {
    return (
        <CardContainer>
            <CardContent sx={{
                display: 'flex',
                padding: 0,
                justifyContent: 'space-between',
                '&:last-child': {
                    paddingBottom: 0,
                }
            }}>
                <Box sx={{ padding: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Box>
                        {/* <SuperBadge>SUPER</SuperBadge> */}
                        <Typography variant="h5" component="div" gutterBottom sx={{ fontFamily: 'Din-Round-Bold, sans-serif', }}>
                            Pratique agora
                        </Typography>
                        <Typography variant="body2" sx={{ fontFamily: 'Din-Round-Bold, sans-serif', }}>
                            Pratique agora pra refrescar a memória!
                        </Typography>
                    </Box>
                    <Link to="/lexi/INY-Sdqa-ZeO-T" style={{ textDecoration: 'none' }}>
                        <UnlockButton variant="contained" sx={{ width: 200, borderRadius: '15px', color: '#4525FF', fontFamily: 'Din-Round-Bold, sans-serif' }} >DESBLOQUEAR</UnlockButton>
                    </Link>
                </Box>
                <ImageContainer>
                    {/* <img src={test} alt="Image" /> */}
                    <img src={test01} alt="Image" />
                </ImageContainer>
            </CardContent>
        </CardContainer>
    );
}

export default ReviewCard;
