import React from 'react';
import { useContext } from 'react';
import { context } from '../../index'

import reset from '../../sprites/reset.png'

const GameOver = () => {
  const { score, resetGame } = useContext(context);
  return (
    <div className="over">
      {score > 40 ? <span>Your score is {score}</span> : <span>You failed!</span>}
      <span onClick={resetGame}><img src={reset} alt="" /></span>
    </div>
  );
}

export default GameOver;