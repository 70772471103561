import React, { useRef, useEffect } from 'react';
import { useCapture } from '../CaptureContext';

export const CaptureWrapper = ({ children, name }) => {
    const ref = useRef(null);
    const { captureComponent } = useCapture();

    useEffect(() => {
        if (ref.current) {
            ref.current.captureComponent = () => captureComponent(ref, name);
        }
    }, [captureComponent, name]);

    return <div ref={ref} data-ref style={{ overflow: 'visible' }}>{children}</div>;
};
