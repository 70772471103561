import './style.css'
import React, { useEffect, useState, useContext, useRef } from 'react'
import { UserContext } from '../../components/UserProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faCheck, faEllipsisH, faInfoCircle, faChartSimple } from '@fortawesome/free-solid-svg-icons';


import ApexCharts from 'react-apexcharts';
import { db } from '../../auth/config/firebase';
import { doc, updateDoc } from 'firebase/firestore';
import UserAvatar from '../../components/UserAvatar';
import PostsProfile from '../../community/profile/postsProfile';

export default function EditProfile({ handleOpenEditPostOnCommunity, handleOpenPostOnCommunity }) {

    const { user, photoCommunity, newNameCommunity, bio, skills, postsCount, followingCount, followersCount } = useContext(UserContext)

    const [editName, setEditName] = useState(false)
    const [editBio, setEditBio] = useState(false)

    const [viewPostDetails, setViewPostDetails] = useState(null)

    const userRef = doc(db, 'community', user.uid);

    const editDataName = async (name, formatName) => {
        await updateDoc(userRef, {
            userNick: name,
            normalizedUserNick: formatName
        });
    }

    const editDataBio = async (bio) => {
        await updateDoc(userRef, {
            aboutMe: bio
        });
    }

    const containerRef = useRef(null);
    const [size, setSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        if (containerRef.current) {
            const { width, height } = containerRef.current.getBoundingClientRect();
            setSize({ width, height });
        }
    }, []);

    const allSkills = [
        "🎓 IELTS",
        "💼 business",
        "🎤 interview",
        "🔧 engineer",
        "💰 finance",
        "⚕️ medical",
        "✍ writing",
        "⚖️ law",
        "👥 human resources",
        "📈 marketing",
        "🎶 music",
        "🎨 art",
        "⚽ football",
        "✈️ aviation",
        "🔬 science",
        "🌠 anime",
        "🎨 design",
        "🌍 travel",
        "📜 history",
        "🎬 movie",
        "🏅 sports",
        "💻 information technology",
        "🏫 education",
        "🩺 health care",
        "📖 Literature",
        "🔭 Astronomy",
        "🏢 Real Estate",
        "🧪 Chemistry",
        "🔍 Forensic Science",
        "🍽️ Culinary Arts",
        "📷 Photography",
        "🧘 Yoga",
        "👗 Fashion",
        "🌱 Environment",
        "🏛️ Politics",
        "🧑‍🚒 Firefighting",
        "🚜 Agriculture",
        "🕵️ Detective",
        "🪙 Cryptocurrency",
        "🎭 Theater",
        "🧠 Psychology",
        "🐕 Pet Care",
        "📊 Statistics",
        "🏊 Swimming",
        "🙏 Gospel",
        "🎪 Circus Arts",
        "🪁 Kite Flying",
        "🛹 Skateboarding",
        "💃 Dance",
        "👾 Video Games",
        "🌠 Astronomy",
        "🛠️ DIY",
        "🔭 Physics",
        "🗺️ Geography",
        "🏞️ National Parks",
        "🧩 Puzzles",
        "📸 Photography",
        "🍽️ Gastronomy",
        "🍶 Ceramics",
        "🕍 Religious Studies",
        "🎎 Puppetry",
        "🌐 Environmentalism",
        "🧁 Baking",
        "📚 Literature",
        "🔍 Detective Stories",
        "🤖 Robotics",
        "👽 UFOlogy",
        "🚀 Space Exploration",
        "🍀 Gardening",
        "🍷 Winemaking",
        "🌱 Veganism",
        "🎯 Archery",
        "📻 Podcasting",
        "🏰 Medieval History",
        "💫 Astrology",
        "🦖 Paleontology",
        "🐍 Herpetology",
        "🎢 Roller Coasters",
        "💡 Philosophy",
        "🐦 Bird Watching",
        "🎖️ Military History",
        "🎲 Board Games",
        "🎣 Fishing",
        "🌋 Geology",
        "🧵 Sewing",
        "🔖 Bibliophile",
        "🦺 Safety & First Aid",
        "🦠 Microbiology",
        "🎌 Vexillology (study of flags)",
        "💎 Gemology",
        "🐟 Ichthyology",
        "🛶 Kayaking",
        "🎮 Game Development",
        "🏹 Hunting",
        "🧊 Ice Sculpting",
        "👩‍🌾 Homesteading",
        "👢 Cowboy Culture",
        "🍂 Autumn Enthusiast",
        "🌸 Flower Arranging",
        "🐝 Beekeeping"
    ]

    const series = [viewPostDetails?.likeCount, viewPostDetails?.commentCount, viewPostDetails?.viewCount, 0, viewPostDetails?.nicePostScore];

    const options = {
        chart: {
            type: 'polarArea',
        },
        labels: ['Curtidas', 'Comentários', 'Visualizações', 'Compartilhado', 'Score do post'],
        stroke: {
            colors: ['#fff']
        },
        fill: {
            opacity: 0.8
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    // width: 300
                    width: size.width - 60
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    const [name, setName] = useState('')
    const handleName = (e) => {
        const content = e.target.innerText;
        if (content.length <= 15) {
            setName(content);
        }
    }

    const [newBio, setNewBio] = useState('')
    const handleBio = (e) => {
        const content = e.target.innerText;
        if (content.length <= 500) {
            setNewBio(content);
        }
    }

    return (
        <div className='allEditProfile'>
            <header id='headerUserInfo'>
                <div className='paddingHorizontal'>
                    <div className='userInformation'>
                        <div className='userImageProfile'>
                            {user && <UserAvatar userName={newNameCommunity} photoURL={photoCommunity} size={150} />}
                        </div>
                        <div className='contentUser'>
                            <div className='contentCommunityInformation'>
                                <div className={editName ? 'editInfo active' : 'editInfo'}>
                                    <h1
                                        contentEditable={editName}
                                        className={editName ? 'enabled' : ''}
                                        role="textbox"
                                        spellCheck="true"
                                        tabIndex="0"
                                        data-lexical-editor="true"
                                        onInput={handleName}
                                        style={{ userSelect: 'text', whiteSpace: 'pre-wrap', wordBreak: 'break-word', outline: 'none' }}
                                    >
                                        {newNameCommunity.toLowerCase().replace(/\s+/g, '')}</h1>
                                    <div className='optionContainerMessageInfo'>
                                        <div className='optionMessageInfo' onClick={(e) => {
                                            if (name != "" && name?.toLowerCase()?.replace(/\s+/g, '') !== newNameCommunity?.toLowerCase()?.replace(/\s+/g, '')) {
                                                editDataName(name, name?.toLowerCase()?.replace(/\s+/g, ''))
                                                setEditName(!editName)
                                            }
                                            else {
                                                setEditName(!editName)
                                            }
                                        }}>
                                            <FontAwesomeIcon icon={editName ? faCheck : faPencil} />
                                        </div>
                                    </div>
                                </div>
                                <span>{newNameCommunity}</span>
                            </div>
                            <ul className='infoCountInteractions'>
                                <li>
                                    <h3>{postsCount}</h3>
                                    <span>posts</span>
                                </li>
                                <li>
                                    <h3>{followersCount}</h3>
                                    <span>Follwers</span>
                                </li>
                                <li>
                                    <h3>{followingCount}</h3>
                                    <span>Following</span>
                                </li>
                            </ul>
                            <div className='aboutUser'>
                                <div className={editBio ? 'editInfo active' : 'editInfo'}>
                                    <p
                                        contentEditable={editBio}
                                        className={editBio ? 'enabled' : ''}
                                        role="textbox"
                                        spellCheck="true"
                                        tabIndex="0"
                                        data-lexical-editor="true"
                                        onInput={handleBio}
                                        style={{ userSelect: 'text', whiteSpace: 'pre-wrap', wordBreak: 'break-word', outline: 'none' }}
                                    >
                                        {bio}</p>
                                    <div className='optionContainerMessageInfo'>
                                        <div className='optionMessageInfo'
                                        onClick={(e) => {
                                            if (newBio != "" && newBio?.toLowerCase()?.replace(/\s+/g, '') !== bio?.toLowerCase()?.replace(/\s+/g, '')) {
                                                editDataBio(newBio)
                                                setEditBio(!editBio)
                                            }
                                            else {
                                                setEditBio(!editBio)
                                            }
                                        }}
                                        >
                                            <FontAwesomeIcon icon={editBio ? faCheck : faPencil} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flexTopicsFilter" style={{ marginTop: 0, marginBottom: 0, paddingBottom: 0, borderBottom: 0 }}>
                                <div className='topics'>
                                    {skills.map(skill => (
                                        <li key={skill}>
                                            {skill}
                                        </li>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='containerActions'>
                        <div className='actionEdit'>
                            <FontAwesomeIcon icon={faEllipsisH} />
                        </div>
                    </div>
                </div>
            </header>

            <div className='postsInformations'>
                <div className='maxInfo'>
                    <PostsProfile handleOpenPostOnCommunity={handleOpenPostOnCommunity} handleOpenEditPostOnCommunity={handleOpenEditPostOnCommunity} userData={{ photoURL: photoCommunity, userNick: newNameCommunity, uid: user.uid }} setViewPostDetails={setViewPostDetails} />
                </div>
                <div className='infoPosts'>
                    <header>
                        <div className='paddingHorizontal'>
                            <span>About post</span>
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </div>
                    </header>
                    <div className='containerInfoPosts' ref={containerRef}>
                        {viewPostDetails
                            ?
                            <div className='graph'>
                                <ApexCharts options={options} series={series} type="polarArea" height={500} width={size.width - 60} />
                            </div>
                            :
                            <span>Descubra uma análise detalhada do post clicando no  <FontAwesomeIcon icon={faChartSimple} color='#00A3FF' />  ao lado.</span>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}