import React, { useEffect, useState, useRef } from "react";
import {
    AgoraVideoPlayer,
    createClient,
    createMicrophoneAndCameraTracks,
} from "agora-rtc-react";
import './styles.css';
import AgoraRTC from "agora-rtc-sdk-ng";
import { auth } from '../../auth/config/firebase';
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from '../../auth/config/firebase';
import UserAvatar from '../../components/UserAvatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faVideoSlash, faMicrophone, faMicrophoneSlash, faPhone, faEllipsis, faX, faSearch, faXmark, faLanguage, faBookAtlas, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import AgoraRTM from 'agora-rtm-sdk';
import { addNotification } from '../../components/notification';
import Alert from "../../components/alert";

import Translator from "../../componentsTalkIA/translator";
import UseDictionary from "../../componentsTalkIA/dictionary";
// import SendReport from "../../components/sendReport";

import { UserContext } from '../../components/UserProvider';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

const config = {
    mode: "rtc", codec: "vp8",
};

const appId = "74d46ca449094679b9139d6031aa9675";
const token = null;

export const useRTM = (appId) => {
    const [rtm, setRTM] = useState(null);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        const client = AgoraRTM.createInstance(appId);
        setRTM(client);

        const currentUser = auth.currentUser;  // Obtenha o usuário atual do Firebase

        if (currentUser) {
            client.login({ uid: currentUser.uid })  // Use o UID do usuário Firebase
                .then(() => {
                    setIsReady(true);
                })
                .catch(error => {
                    console.error("Failed to login to RTM", error);
                });
        } else {
            console.error("No user is authenticated in Firebase.");
        }

        return () => {
            client.logout();
        };
    }, [appId]);

    return [rtm, isReady];
};



const SessionCall = () => {
    const [inCall, setInCall] = useState(false);
    const [channelName, setChannelName] = useState("");

    const [showTranslator, setShowTranslator] = useState(false)
    const [showDictionary, setShowDictionary] = useState(false)
    const [showReport, setShowReport] = useState(false)
    const [firstTime, setFirstTime] = useState(true);

    return (
        <div className="allMeet">
            <div id="notifications3"></div>

            {/* {showReport && (
                <aside className="popupShow">
                    <div className="blurPopupTalkIA" onClick={() => setShowReport(false)}></div>
                    <SendReport />
                </aside>
            )} */}

            {showDictionary && (
                <aside className="popupShow">
                    <div className="blurPopupTalkIA" onClick={() => setShowDictionary(false)}></div>
                    <UseDictionary />
                </aside>
            )}

            {showTranslator && (
                <aside className="popupShow">
                    <div className="blurPopupTalkIA" onClick={() => setShowTranslator(false)}></div>
                    <Translator />
                </aside>
            )}

            {inCall ? (
                // <VideoCall setInCall={setInCall} channelName={channelName} showTranslator={showTranslator} setShowTranslator={setShowTranslator} setShowDictionary={setShowDictionary} showDictionary={showDictionary} setChannelName={setChannelName} showReport={showReport} setShowReport={setShowReport} />
                <VideoCall setInCall={setInCall} channelName={channelName} showTranslator={showTranslator} setShowTranslator={setShowTranslator} setShowDictionary={setShowDictionary} showDictionary={showDictionary} setChannelName={setChannelName} />
            ) : (
                <ChannelForm setInCall={setInCall} setChannelName={setChannelName} channelName={channelName} firstTime={firstTime} setFirstTime={setFirstTime} />
            )}
        </div>
    );
};

const useClient = createClient(config);
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();


const VideoCall = (props) => {
    // const { setInCall, channelName, setShowTranslator, setShowDictionary, setShowReport, showReport } = props;
    const { setInCall, channelName, setShowTranslator, setShowDictionary } = props;
    const [users, setUsers] = useState([]);
    const [start, setStart] = useState(false);
    const [trackState, setTrackState] = useState({ video: false, audio: true });
    const [screenTrack, setScreenTrack] = useState(null);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [callOpen, setCallOpen] = useState(false);
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
    const client = useClient();
    const { ready, tracks } = useMicrophoneAndCameraTracks();

    const [refreshKey, setRefreshKey] = useState(0);

    const { user, photoURL, newNameCommunity } = useContext(UserContext)

    useEffect(() => {
        setUsers(client.remoteUsers);
        setRefreshKey(prevKey => prevKey + 1);  // Adicione esta linha para incrementar a chave
    }, [client]);

    const startScreenShare = async () => {
        try {
            const screen = await AgoraRTC.createScreenVideoTrack();

            if (screen && tracks && tracks[1]) {
                await client.unpublish([tracks[1]]);
            }

            if (screen) {
                await client.publish([screen]);
                setScreenTrack(screen);
            }
        } catch (error) {
            console.error("Erro ao iniciar o compartilhamento de tela:", error);
        }
    };


    const stopScreenShare = async () => {
        try {
            if (screenTrack) {
                await client.unpublish(screenTrack);
                screenTrack.close();
                setScreenTrack(null);

                // Se a câmera estava ativa antes do compartilhamento de tela, republicar
                if (trackState.video && tracks[1]) {
                    await client.publish([tracks[1]]);
                    console.log('Camera track republished');
                } else {
                    await tracks[1].setEnabled(true);
                    await client.publish([tracks[1]]);
                    await tracks[1].setEnabled(false);
                    console.log('Camera is muted, not republishing');
                }

                console.log('Screen sharing stopped');
            } else {
                console.error('Screen track is not available');
            }
        } catch (error) {
            console.error("Erro ao parar o compartilhamento de tela:", error);
        }
    };


    useEffect(() => {
        if (screenTrack) {
            screenTrack.on("stop", () => {
                stopScreenShare();
            });
        }
    }, [screenTrack]);

    const extractUserNameAndPhotoURL = (combinedString) => {
        console.log("Combined String:", combinedString);  // Log adicional

        if (combinedString.includes('null')) {
            combinedString = combinedString.replace('null', '').trim();  // Removendo 'null' da string
        }

        const indexOfHttps = combinedString.indexOf('https://');
        if (indexOfHttps === -1) return { userName: combinedString, photoURL: '' };

        const userName = combinedString.substring(0, indexOfHttps).trim();
        const photoURL = combinedString.substring(indexOfHttps);

        console.log("Extracted userName and photoURL:", userName, photoURL);  // Log adicional

        return { userName, photoURL };
    };

    useEffect(() => {
        let init = async (name) => {

            if (client.connectionState === "CONNECTED" || client.connectionState === "CONNECTING") {
                console.log("Client já está conectado ou em processo de conexão");
                return;
            }

            try {
                await client.join(appId, name, token, newNameCommunity + photoURL);
                console.error(client.remoteUsers);
                setUsers(client.remoteUsers);

                client.on("user-published", async (user, mediaType) => {
                    await client.subscribe(user, mediaType);
                    console.log("subscribe success");
                    setUsers([...client.remoteUsers]);

                    if (mediaType === "audio") {
                        user.audioTrack?.play();
                    }
                });

                client.on("user-unpublished", (user, type) => {
                    console.error("unpublished", user, type);
                    if (type === "audio") {
                        user.audioTrack?.stop();
                    }
                    setUsers([...client.remoteUsers]);
                });

                client.on("user-left", (user) => {
                    addNotification(`User ${extractUserNameAndPhotoURL(user.uid).userName} left the call`, 'dark3');
                    // console.error("leaving", user.uid);
                    setUsers(prevUsers => prevUsers.filter(User => User.uid !== user.uid));
                });

                if (tracks) await client.publish([tracks[0], tracks[1]]);
                setStart(true);

                if (ready && tracks) {
                    console.log("init ready");

                    // Desativar áudio e vídeo se o estado inicial é desativado
                    if (!trackState.video) {
                        await tracks[1].setEnabled(false);  // Desativar a faixa de vídeo
                    }
                    // if (!trackState.audio) {
                    //     await tracks[0].setEnabled(false);  // Desativar a faixa de áudio
                    // }

                    await client.publish(tracks);  // Publicar as faixas
                    setStart(true);
                }

            }

            catch (error) {
                console.error("Erro ao se juntar ao canal:", error);
                return; // Para sair da função se houver um erro
            }
        };

        if (ready && tracks) {
            console.log("init ready");
            init(channelName);
        }

    }, [channelName, client, ready, tracks, trackState.video, trackState.audio]);

    useEffect(() => {
        setUsers(client.remoteUsers);
        console.error("Users set: ", client.remoteUsers); // Adicione esta linha
    }, [client]);

    useEffect(() => {
        console.error("Users array updated:", users);
    }, [users]);



    return (
        <div className="meet" key={refreshKey}>
            {ready && tracks && <UsersInCalll users={users} userUID={user.uid} setCallOpen={setCallOpen} callOpen={callOpen} photoURL={photoURL} />}
            <div className="flexOption">
                {start && tracks && <Videos users={users} tracks={tracks} screenTrack={screenTrack} isChatOpen={isChatOpen} trackState={trackState} userUID={user.uid} photoURL={photoURL} />}
                {ready && tracks && (<Chat isChatOpen={isChatOpen} setIsChatOpen={setIsChatOpen} channelName={channelName} nameUser={newNameCommunity} photoURL={photoURL} setUnreadMessagesCount={setUnreadMessagesCount} />)}
            </div>
            {ready && tracks && (
                <Controls
                    users={users}
                    tracks={tracks}
                    setStart={setStart}
                    setInCall={setInCall}
                    startScreenShare={startScreenShare}
                    stopScreenShare={stopScreenShare}
                    screenTrack={screenTrack}
                    setTrackState={setTrackState}
                    trackState={trackState}
                    isChatOpen={isChatOpen}
                    setIsChatOpen={setIsChatOpen}
                    channelName={channelName}
                    setCallOpen={setCallOpen}
                    callOpen={callOpen}
                    unreadMessagesCount={unreadMessagesCount}
                    nameUser={newNameCommunity}
                    setShowTranslator={setShowTranslator}
                    setShowDictionary={setShowDictionary}
                // setShowReport={setShowReport}
                // showReport={showReport}
                />

            )}
        </div>
    );
};

const Videos = (props) => {
    const { users, tracks, screenTrack, trackState, isChatOpen, userUID, photoURL } = props;

    // const showYourCamera = (users.length === 0 || trackState.video) && !screenTrack;
    const isScreenSharing = (users.length >= 1 && !screenTrack) || users.length === 0 && screenTrack;

    const isScreenSharingOrMultipleUsers = users.length >= 1 || screenTrack;
    const showAvatarInstead = !isScreenSharingOrMultipleUsers && users.length === 0 && !trackState.video;
    const showYourCamera = !isScreenSharingOrMultipleUsers && (users.length === 0 || trackState.video) && !showAvatarInstead;


    const [myProfile, setMyProfile] = useState([])

    const myProfileData = async (userUID) => {
        const docRef = doc(db, 'community', userUID);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setMyProfile(docSnap.data())
        }
    };

    useEffect(() => {
        myProfileData(userUID);
    }, []);


    useEffect(() => {
        console.error("isScreenSharingOrMultipleUsers", isScreenSharingOrMultipleUsers)
        console.error("users.length", users.length)
        console.error("showAvatarInstead", showAvatarInstead)
        console.error("showYourCamera", showYourCamera)
        console.error("screenTrack", screenTrack)
    }, [isScreenSharingOrMultipleUsers, users.length, screenTrack])

    const extractUserNameAndPhotoURL = (combinedString) => {
        console.log("Combined String:", combinedString);  // Log adicional

        if (combinedString.includes('null')) {
            combinedString = combinedString.replace('null', '').trim();  // Removendo 'null' da string
        }

        const indexOfHttps = combinedString.indexOf('https://');
        if (indexOfHttps === -1) return { userName: combinedString, photoURL: '' };

        const userName = combinedString.substring(0, indexOfHttps).trim();
        const photoURL = combinedString.substring(indexOfHttps);

        console.log("Extracted userName and photoURL:", userName, photoURL);  // Log adicional

        return { userName, photoURL };
    };

    useEffect(() => {
        console.error('Usuários mudaram:', users);
        // Aqui, você pode adicionar qualquer lógica adicional necessária quando os usuários mudam
    }, [users]);

    return (
        <div className={isChatOpen ? "continerVideos active" : "continerVideos"}>
            <div id="videos">
                {showYourCamera &&
                    <div className='vid'>
                        <FontAwesomeIcon id="iconAudio" icon={trackState.audio ? faMicrophone : faMicrophoneSlash} />
                        <AgoraVideoPlayer className='vid' videoTrack={tracks[1]} />
                    </div>
                }

                {showAvatarInstead && userUID &&
                    <div className='vidUser'>
                        <span id="userNick">{myProfile.userNick}</span>
                        <FontAwesomeIcon id="iconAudio" icon={trackState.audio ? faMicrophone : faMicrophoneSlash} />
                        <UserAvatar userName={myProfile.userNick} photoURL={photoURL} size={160} />
                    </div>
                }

                {isScreenSharing && (
                    <div className={!trackState.video ? 'userPopUp' : 'userPopUp camera'}>
                        {trackState.video
                            ?
                            <AgoraVideoPlayer className='vidUserPopUp' videoTrack={tracks[1]} />
                            :
                            <>
                                <FontAwesomeIcon id="iconAudio" icon={trackState.audio ? faMicrophone : faMicrophoneSlash} />
                                <UserAvatar userName={myProfile.userNick} photoURL={photoURL} size={60} />
                                <span id="userNickPopUP">{myProfile.userNick}</span>
                            </>
                        }
                    </div>
                )}

                {screenTrack && <AgoraVideoPlayer className='vid' videoTrack={screenTrack} />}

                {screenTrack ?
                    null :
                    (users && users.length > 0 && users.map((user) => {
                        console.error("Mapping user", user);

                        const { userName, photoURL } = extractUserNameAndPhotoURL(user.uid);

                        console.log("Processed userName and photoURL:", userName, photoURL);  // Log adicional

                        if (user.videoTrack) {
                            return <AgoraVideoPlayer className='vid' videoTrack={user.videoTrack} key={user.uid} />;
                        } else {
                            return (
                                <div className='vidUser background minWidth' key={user.uid}>
                                    <span id="userNick">{userName}</span>
                                    <UserAvatar userName={userName} photoURL={photoURL ? photoURL : ''} size={160} />
                                </div>
                            );
                        }
                    }))
                }

            </div>
        </div>
    );
};

export const Controls = (props) => {
    const client = useClient();
    // const { users, tracks, setStart, setInCall, screenTrack, stopScreenShare, startScreenShare, setIsChatOpen, isChatOpen, setTrackState, trackState, channelName, setCallOpen, callOpen, unreadMessagesCount, setShowDictionary, setShowTranslator, setShowReport } = props;
    const { users, tracks, setStart, setInCall, screenTrack, stopScreenShare, startScreenShare, setIsChatOpen, isChatOpen, setTrackState, trackState, channelName, setCallOpen, callOpen, unreadMessagesCount, setShowDictionary, setShowTranslator } = props;

    const mute = async (type) => {
        if (type === "audio") {
            await tracks[0].setEnabled(!trackState.audio);
            setTrackState(ps => ({ ...ps, audio: !ps.audio }));
        } else if (type === "video") {
            await tracks[1].setEnabled(!trackState.video);
            setTrackState(ps => ({ ...ps, video: !ps.video }));
        }
    };

    const leaveChannel = async () => {
        await client.leave();
        client.removeAllListeners();
        tracks[0].close();
        tracks[1].close();
        setStart(false);
        setInCall(false);
    };

    const [fullScreen, setFullScreen] = useState(false);

    const goFullscreen = () => {
        setFullScreen(!fullScreen)
        if (!document.fullscreenElement) {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) { /* Firefox */
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                document.documentElement.webkitRequestFullscreen();
            } else if (document.documentElement.msRequestFullscreen) { /* IE/Edge */
                document.documentElement.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { /* Firefox */
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE/Edge */
                document.msExitFullscreen();
            }
        }
    }

    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const updateClock = () => {
            setTime(new Date());
        }

        const interval = setInterval(updateClock, 1000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    const [dropDown, setDropDown] = useState(false);

    return (
        <div className="controls">
            <div className="leftSideControls">
                <span>{time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} <span id="barVertical">|</span> {channelName}</span>
            </div>
            <div className="controlsButtonAction">
                <div className={trackState.audio ? "buttonIcon on" : "buttonIcon"} onClick={() => mute("audio")}>
                    <FontAwesomeIcon icon={trackState.audio ? faMicrophone : faMicrophoneSlash} />
                </div>
                <div className={trackState.video ? "buttonIcon on" : "buttonIcon"} onClick={() => mute("video")}>
                    <FontAwesomeIcon icon={trackState.video ? faVideo : faVideoSlash} />
                </div>
                <div className={screenTrack ? "buttonIcon on" : "buttonIcon"} onClick={screenTrack ? stopScreenShare : startScreenShare}>
                    <span className="material-symbols-outlined">present_to_all</span>
                </div>
                <div className={fullScreen ? "buttonIcon on" : "buttonIcon"} onClick={goFullscreen}>
                    <span className="material-symbols-outlined">
                        open_in_full
                    </span>
                </div>
                <div className="menuDropDownLive">
                    <div className={dropDown ? "dropDownLive active" : "dropDownLive"}>
                        <div className="flexMenuDrop" onClick={() => setShowTranslator(true)}>
                            <div className="iconButton">
                                <FontAwesomeIcon icon={faLanguage} />
                            </div>
                            <div className="textButton">
                                <p>Open translator</p>
                            </div>
                        </div>
                        <div className="flexMenuDrop" onClick={() => setShowDictionary(true)}>
                            <div className="iconButton">
                                <FontAwesomeIcon icon={faBookAtlas} />
                            </div>
                            <div className="textButton">
                                <p>Open dictionary</p>
                            </div>
                        </div>
                        {/* <div className="flexMenuDrop" onClick={() => setShowReport(true)}> */}
                        <div className="flexMenuDrop">
                            <div className="iconButton">
                                <FontAwesomeIcon icon={faTriangleExclamation} />
                            </div>
                            <div className="textButton">
                                <p>Report a problem</p>
                            </div>
                        </div>
                    </div>
                    <div className="buttonIcon" onClick={() => setDropDown(!dropDown)}>
                        <FontAwesomeIcon icon={faEllipsis} />
                    </div>
                </div>

                <div className="buttonIcon offEnd" onClick={() => leaveChannel()}>
                    <FontAwesomeIcon icon={faPhone} id="endCall" />
                </div>

            </div>
            <div className="rightSideControls">
                <div className="buttonControls" onClick={() => setCallOpen(!callOpen)}>
                    <span
                        className="material-symbols-outlined"
                        style={{ color: callOpen ? '#8ab4f8' : '#FFFFFF', fontVariationSettings: callOpen ? "'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48" : 'normal' }}
                    >
                        people
                    </span>
                    {users.length > 0 && (
                        <div className="numberOfType">{users.length}</div>
                    )}
                </div>
                <div className="buttonControls" onClick={() => setIsChatOpen(!isChatOpen)}>
                    <span
                        className="material-symbols-outlined"
                        style={{ color: isChatOpen ? '#8ab4f8' : '#FFFFFF', fontVariationSettings: isChatOpen ? "'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48" : 'normal' }}
                    >
                        comment
                    </span>
                    {unreadMessagesCount > 0 && (
                        <div className="numberOfType on">{unreadMessagesCount > 100 ? "" : unreadMessagesCount}</div>
                    )}
                </div>
            </div>
        </div>

    );
};


export const Chat = (props) => {
    const { isChatOpen, setIsChatOpen, channelName, nameUser, setUnreadMessagesCount, photoURL } = props;

    const extractUserNameAndPhotoURL = (combinedString) => {
        console.log("Combined String:", combinedString);  // Log adicional

        if (combinedString.includes('null')) {
            combinedString = combinedString.replace('null', '').trim();  // Removendo 'null' da string
        }

        const indexOfHttps = combinedString.indexOf('https://');
        if (indexOfHttps === -1) return { userName: combinedString, photoURL: '' };

        const userName = combinedString.substring(0, indexOfHttps).trim();
        const photoURL = combinedString.substring(indexOfHttps);

        console.log("Extracted userName and photoURL:", userName, photoURL);  // Log adicional

        return { userName, photoURL };
    };

    const isChatOpenRef = useRef(isChatOpen);

    useEffect(() => {
        isChatOpenRef.current = isChatOpen;
        if (isChatOpen) {
            setUnreadMessagesCount(0);
        }
    }, [isChatOpen]);

    const [rtm, isReady] = useRTM(appId);

    useEffect(() => {
        if (!isReady) return;

        startListeningToMessages();

        return () => {
            rtmChannelRef.current?.leave();
        };
    }, [channelName, isReady, rtm]);

    const [messages, setMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState('');
    const messagesEndRef = useRef(null);
    const rtmChannelRef = useRef(null);

    async function startListeningToMessages() {
        if (!channelName) {
            console.error("Invalid channel name:", channelName);
            return;
        }

        rtmChannelRef.current = rtm.createChannel(channelName);
        await rtmChannelRef.current.join();


        rtmChannelRef.current.on('ChannelMessage', ({ text }, senderId) => {
            const { text: messageText, name } = JSON.parse(text);

            setMessages(prevMessages => [...prevMessages, { id: Date.now(), text: messageText, name }]);
            if (!isChatOpenRef.current) {
                addNotification("**" + extractUserNameAndPhotoURL(name).userName + ": **" + "" + messageText, 'dark3');
                setUnreadMessagesCount(count => count + 1);
            }
        });
    }

    async function sendMessageToAgora(message, nameUser) {
        if (!rtmChannelRef.current) {
            console.error("RTM channel is not initialized");
            return;
        }

        const rtmMessage = JSON.stringify({ text: message, name: nameUser });
        await rtmChannelRef.current.sendMessage({ text: rtmMessage });
    }

    const handleInputChange = (e) => {
        setCurrentMessage(e.target.value);
    };

    const sendMessage = async () => {
        if (currentMessage.trim() !== '') {
            await sendMessageToAgora(currentMessage, nameUser + photoURL);
            setMessages(prevMessages => [...prevMessages, { id: Date.now(), text: currentMessage, name: nameUser + photoURL }]);
            setCurrentMessage('');
        }
    };

    const handleEnterPress = (e) => {
        if (e.key === 'Enter') {
            sendMessage();
        }
    };

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <div className={isChatOpen ? "chatContainerCall active" : "chatContainerCall"}>
            <div className="headerChat">
                <h2>Chat</h2>
                <div>
                    <FontAwesomeIcon icon={faXmark} onClick={() => setIsChatOpen(!isChatOpen)} />
                </div>
            </div>
            <header className="chatHeader">
                Everyone in this call can see your messages.
            </header>

            <div className="chatMessagesLive" ref={messagesEndRef}>
                {messages.map(message => {
                    const { userName, photoURL } = extractUserNameAndPhotoURL(message.name);
                    return (
                        <div key={message.id} className={nameUser === userName ? 'containerMessageLive me' : 'containerMessageLive user'}>
                            {nameUser !== userName && (
                                <div className="containerHeaderMessageLive">
                                    <div className="headerMessageLiveLeft">
                                        <UserAvatar userName={userName} photoURL={photoURL ? photoURL : ''} size={30} />
                                        <span>{userName}</span>
                                    </div>
                                    <div>
                                        <span>{new Date(message.id).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
                                    </div>
                                </div>
                            )}
                            <div className="messageLive" key={message.id}>{message.text}</div>
                        </div>
                    );
                })}
            </div>

            <div className="chatInputContainer">
                <input
                    type="text"
                    placeholder="Type a message..."
                    value={currentMessage}
                    onChange={handleInputChange}
                    onKeyPress={handleEnterPress}
                />
                <button onClick={sendMessage}>Send</button>
            </div>
        </div>
    );
}


export const UsersInCalll = (props) => {

    const { users, userUID, setCallOpen, callOpen, photoURL = { photoURL } } = props;

    const [myProfile, setMyProfile] = useState([])

    const myProfileData = async (userUID) => {
        const docRef = doc(db, 'community', userUID);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setMyProfile(docSnap.data())
        }
    };

    useEffect(() => {
        myProfileData(userUID);
    }, []);

    const extractUserNameAndPhotoURL = (combinedString) => {
        console.log("Combined String:", combinedString);  // Log adicional

        if (combinedString.includes('null')) {
            combinedString = combinedString.replace('null', '').trim();  // Removendo 'null' da string
        }

        const indexOfHttps = combinedString.indexOf('https://');
        if (indexOfHttps === -1) return { userName: combinedString, photoURL: '' };

        const userName = combinedString.substring(0, indexOfHttps).trim();
        const photoURL = combinedString.substring(indexOfHttps);

        console.log("Extracted userName and photoURL:", userName, photoURL);  // Log adicional

        return { userName, photoURL };
    };

    return (
        <div className={callOpen ? "userCallContainer active" : "userCallContainer"}>
            <div className="closeCall">
                <div className="iconCloseCall" onClick={() => setCallOpen(!callOpen)}>
                    <FontAwesomeIcon icon={faX} />
                </div>
            </div>
            <div className="containerUsersInCall">
                <div className="containerButtonCall">
                    <input type="text" placeholder="Search" />
                    <FontAwesomeIcon icon={faSearch} id="search" />
                </div>

                <div className="userInCall">
                    <span>On the call</span>
                    <div className="containerUser">

                        <div className="userProfileContainer">
                            <div className="imageUserProfile">
                                <UserAvatar userName={myProfile.userNick} photoURL={photoURL} size={50} />
                            </div>
                            <div className="contentUserProfile">
                                <h3>{myProfile.userNick}</h3>
                                <span>@{(myProfile?.userNick || '').replace(/\s+/g, '').toLowerCase()}</span>
                            </div>
                        </div>

                        {users.map((user) => {
                            const { userName, photoURL } = extractUserNameAndPhotoURL(user?.uid);

                            return (
                                <div key={userName} className="userProfileContainer">
                                    <div className="imageUserProfile">
                                        <UserAvatar userName={userName} photoURL={photoURL ? photoURL : ''} size={50} />
                                    </div>
                                    <div className="contentUserProfile">
                                        <h3>{userName}</h3>
                                        <span>@{userName.replace(/\s+/g, '').toLowerCase()}</span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}



const ChannelForm = (props) => {
    const { setInCall, setChannelName, channelName, firstTime, setFirstTime } = props;

    const [showAlert, setShowAlert] = useState(false);
    const { callId } = useParams();

    if (callId && firstTime) {
        setChannelName(callId)
        setTimeout(() => {
            setInCall(true)
            setFirstTime(false)
        }, [2000])
    }

    return (
        <div className="allJoinMeet">

            <Alert
                title="Confirm to Enter"
                paragraph="Upon joining, your camera will be turned off and your audio will be on. This setting helps to streamline the joining process and ensures that you’re heard clearly while giving you a moment to get settled before turning on your video. Would you like to proceed with these settings?"
                buttons={[
                    { text: "Cancel", action: () => setShowAlert(false) },
                    { text: "Join", action: () => setInCall(true) }
                ]}
                showAlert={showAlert}
            />

            {callId && firstTime
                ?
                <div className="loadingMeet">
                    <svg aria-label="Loading..." class="xemfg65 xa4qsjk xs51kk x2a5n4e spin" role="img" viewBox="0 0 100 100">
                        <rect class="fade" style={{ animationDelay: '0s' }} fill="currentColor" height="10" rx="5" ry="5" transform="rotate(-90 50 50)" width="28" x="67" y="45"></rect>
                        <rect class="fade" style={{ animationDelay: '0.1s' }} fill="currentColor" height="10" rx="5" ry="5" transform="rotate(-45 50 50)" width="28" x="67" y="45"></rect>
                        <rect class="fade" style={{ animationDelay: '0.2s' }} fill="currentColor" height="10" rx="5" ry="5" transform="rotate(0 50 50)" width="28" x="67" y="45"></rect>
                        <rect class="fade" style={{ animationDelay: '0.3s' }} fill="currentColor" height="10" rx="5" ry="5" transform="rotate(45 50 50)" width="28" x="67" y="45"></rect>
                        <rect class="fade" style={{ animationDelay: '0.4s' }} fill="currentColor" height="10" rx="5" ry="5" transform="rotate(90 50 50)" width="28" x="67" y="45"></rect>
                        <rect class="fade" style={{ animationDelay: '0.5s' }} fill="currentColor" height="10" rx="5" ry="5" transform="rotate(135 50 50)" width="28" x="67" y="45"></rect>
                        <rect class="fade" style={{ animationDelay: '0.6s' }} fill="currentColor" height="10" rx="5" ry="5" transform="rotate(180 50 50)" width="28" x="67" y="45"></rect>
                        <rect class="fade" style={{ animationDelay: '0.7s' }} fill="currentColor" height="10" rx="5" ry="5" transform="rotate(225 50 50)" width="28" x="67" y="45"></rect>
                    </svg>
                </div>
                :
                <div className="infoJoinMeet">
                    <div className="iconJoinMeet">
                        <FontAwesomeIcon icon={faVideo} />
                    </div>
                    <div className="textJoinMeet">
                        <h3>Join a call</h3>
                        <span>Enter the code to join the conversation</span>
                        <form className="join">
                            <input type="text"
                                placeholder="Enter Channel Name"
                                onChange={(e) => setChannelName(e.target.value)}
                            />
                            <button onClick={(e) => {
                                e.preventDefault();
                                setShowAlert(true)
                            }}
                                disabled={!channelName}
                            >
                                Join
                            </button>
                        </form>
                    </div>
                </div>
            }

        </div>
    );
};


export default SessionCall;
