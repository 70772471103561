import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh } from '@fortawesome/free-solid-svg-icons';
import { sendTextToSpeechRequest } from '../../../../componentsTalkIA/IAtalk2ME/Talk2MeVoice';
import { AudioButton, StyledTextarea } from '../../StyledComponents';
import { useAllTheme } from '../../../../components/themes/ThemeContext';

function GapFillExercise({ exercicio, setCheckFunction, setProgress, setControls, controls, audio = true }) {
    const [resposta, setRespostaLocal] = useState('');
    const respostaRef = useRef('');

    useEffect(() => {
        setCheckFunction(() => check);        
    }, [setCheckFunction]);

    useEffect(() => {
        if (!controls.isChecked) {
            setRespostaLocal('');
            respostaRef.current = '';
        }
    }, [controls.isChecked]);

    const handleChange = (e) => {
        setRespostaLocal(e.target.value);
        respostaRef.current = e.target.value;
        setControls((prev) => ({ ...prev, isDisabled: false }));
    };

    const check = () => {
        const respostaAtual = respostaRef.current.toLowerCase().trim();
        const respostaCorreta = exercicio.data.correct_word.toLowerCase().trim();

        if (respostaAtual === respostaCorreta) {
            setProgress({
                type: 'correct',
                explanation: 'Parabéns! Você acertou a resposta.',
                userResponse: respostaAtual
            });
        } else {
            setProgress({
                type: 'incorrect',
                explanation: `A resposta correta é "${exercicio.data.correct_word}".`,
                userResponse: respostaAtual
            });
        }
        setControls((prev) => ({ ...prev, isChecked: true, isDisabled: true }));
    };

    const [audioUrl, setAudioUrl] = useState('');
    const audioRef = useRef(null);

    const speak = async () => {
        const url = await sendTextToSpeechRequest(exercicio.data.correct_word, "Danielle");
        setAudioUrl(url);
    };

    useEffect(() => {
        if (audioUrl && audioRef.current) {
            audioRef.current.play();
        }
    }, [audioUrl]);
    
    const { theme, isDark } = useAllTheme();

    return (
        <Box>
            {audioUrl && (
                <audio
                    src={audioUrl}
                    ref={audioRef}
                    style={{ display: 'none' }}
                />
            )}
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {audio
                    ? <AudioButton onClick={speak} sx={{ mb: 2 }}>
                        <FontAwesomeIcon icon={faVolumeHigh} size="50px" />
                    </AudioButton>
                    : <Typography variant="h4" sx={{ mb: 2, color: theme.color, fontFamily: "SF-Pro-Display, sans-serif", }}>
                        Fill in the gap
                    </Typography>
                }
                <Typography variant="h6" sx={{ mb: 2, color: theme.color, fontFamily: "SF-Pro-Text-Regular, sans-serif", }}>
                    {exercicio.data.sentence}
                </Typography>
                <StyledTextarea
                    theme={theme}
                    isDark={isDark}
                    value={resposta}
                    onChange={handleChange}
                    placeholder="Preencha a lacuna..."
                    disabled={controls.isDisabled}
                    minRows={5}
                />
            </Box>
        </Box>
    );
}

export default GapFillExercise;
