import React, { useContext, useState, useEffect } from 'react';
import { db } from '../../auth/config/firebase';
import { doc, getDoc, collection, getDocs, query, where, startAfter, limit } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import PostItem from '../componentsCommunity/PostItem';
import PostOnCommunity from '../componentsCommunity/postOnCommunity';
import HeaderCommunity from '../componentsCommunity/headerCommunity';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';
import './style.css'
import { UserContext } from '../../components/UserProvider';
import { usePost } from '../componentsCommunity/PostContext';
import Loading from '../../components/loading';

function PostDetails() {

    const { user } = useContext(UserContext)

    const { clickedPost } = usePost();

    const [showPostOnCommunity, setShowPostOnCommunity] = useState(false)

    const { username, postId } = useParams();
    const [post, setPost] = useState(null);

    const [parentPost, setParentPost] = useState(null);

    const [isFetchComment, setIsFetchComment] = useState(true);
    const [isFetchParentPostId, setIsFetchParentPostId] = useState(true);

    const location = useLocation();

    const fetchPost = async () => {
        if (location.pathname.includes('/community/') && location.pathname.includes('/post/') && clickedPost?.id !== postId) {
            try {
                const postRef = doc(db, 'posts', postId);
                const postDoc = await getDoc(postRef);

                if (postDoc.exists()) {
                    const postData = postDoc.data();
                    const userRef = doc(db, 'community', postData.userId);
                    const userDoc = await getDoc(userRef);

                    // Count the number of likes
                    const likesRef = collection(db, 'posts', postId, 'likes');
                    const likesSnapshot = await getDocs(likesRef);
                    const likeCount = likesSnapshot.size;

                    // Check if the user liked the post
                    const userLikeRef = doc(db, 'community', user.uid, 'likedPosts', postId);
                    const userLikeDoc = await getDoc(userLikeRef);

                    // Check if the user voted in the poll and what option they voted for
                    let isVoted = false;
                    let userVoteOption = null;
                    if (postData.poll) {
                        const pollVoteRef = doc(db, 'posts', postId, 'pollVotes', user.uid);
                        const pollVoteDoc = await getDoc(pollVoteRef);
                        if (pollVoteDoc.exists()) {
                            isVoted = true;
                            userVoteOption = pollVoteDoc.data().vote;
                        }
                    }

                    setPost({
                        ...postData,
                        id: postDoc.id,
                        user: userDoc.exists() ? userDoc.data() : null,
                        likeCount: likeCount,  // Real like count from the likes subcollection
                        isLiked: userLikeDoc.exists(),  // Check if current user has liked the post
                        isVoted: isVoted,  // Check if user has voted in the poll
                        userVote: userVoteOption  // Specific option user voted for
                    });

                } else {
                    setPost({})
                    setIsFetchComment(false)
                    // console.error('Post não encontrado');
                }
            } catch (error) {
                console.error('Erro ao buscar o post:', error);
            }
        }
    };

    const resetComponentState = () => {
        setPost(null);
        setParentPost(null);
        setComments([]);
        setLastCommentDoc(null);
        setHasMoreComments(true);
        setIsFetchComment(true);
        setIsFetchParentPostId(true);
    };

    useEffect(() => {
        resetComponentState();
        fetchPost();
    }, [postId, username]);

    const [selectedPost, setSelectedPost] = useState(null);

    const handleOpenPostOnCommunity = (selectedPost) => {
        setSelectedPost(selectedPost);
        setShowPostOnCommunity(true);  // abre o popup
    };

    const [comments, setComments] = useState([]);
    const [lastCommentDoc, setLastCommentDoc] = useState(null);
    const [hasMoreComments, setHasMoreComments] = useState(true);

    const fetchComments = async (afterDoc = null) => {
        let commentsRef = collection(db, 'posts');
        let q = query(commentsRef, where("parentPostId", "==", post.id), limit(5));

        if (afterDoc) {
            q = query(commentsRef, where("parentPostId", "==", post.id), startAfter(afterDoc), limit(5));
        }

        const commentsSnapshot = await getDocs(q);
        if (commentsSnapshot.docs.length > 0) {
            setLastCommentDoc(commentsSnapshot.docs[commentsSnapshot.docs.length - 1]);
        } else {
            setHasMoreComments(false);
        }

        const newComments = await Promise.all(commentsSnapshot.docs.map(async docSnap => {
            const commentData = docSnap.data();

            // Pegar o usuário pelo userId associado ao comentário
            const userRef = doc(db, 'community', commentData.userId);
            const userDoc = await getDoc(userRef);

            // Count the number of likes for the comment
            const likesRef = collection(db, 'posts', docSnap.id, 'likes');
            const likesSnapshot = await getDocs(likesRef);
            const likeCount = likesSnapshot.size;

            // Check if the current user liked the comment
            const userLikeRef = doc(db, 'community', user.uid, 'likedPosts', docSnap.id);
            const userLikeDoc = await getDoc(userLikeRef);

            let isVoted = false;
            let userVoteOption = null;
            if (commentData.poll) { // If the comment has a poll
                const pollVoteRef = doc(db, 'posts', docSnap.id, 'pollVotes', user.uid);
                const pollVoteDoc = await getDoc(pollVoteRef);
                if (pollVoteDoc.exists()) {
                    isVoted = true;
                    userVoteOption = pollVoteDoc.data().vote;  // Assuming "vote" holds the option voted
                }
            }

            return {
                ...commentData,
                id: docSnap.id,
                user: userDoc.exists() ? userDoc.data() : null,
                likeCount: likeCount, // Real like count from likes subcollection
                isLiked: userLikeDoc.exists(), // Determines if the current user has liked the comment
                isVoted: isVoted,  // Determines if the user voted on the comment's poll
                userVote: userVoteOption  // The specific option the user voted for in the comment's poll
            };
        }));

        setComments(prevComments => [...prevComments, ...newComments]);
        setIsFetchComment(false)
    };

    useEffect(() => {
        if (post && post.parentPostId) {
            const fetchParentPost = async () => {
                if (post.parentPostId) {
                    const parentPostRef = doc(db, 'posts', post.parentPostId);
                    const parentPostDoc = await getDoc(parentPostRef);

                    if (parentPostDoc.exists()) {
                        const parentPostData = parentPostDoc.data();

                        // Buscando as informações do usuário associado ao parentPost
                        const userRef = doc(db, 'community', parentPostData.userId);
                        const userDoc = await getDoc(userRef);

                        // Count the number of likes for the parent post
                        const likesRef = collection(db, 'posts', parentPostDoc.id, 'likes');
                        const likesSnapshot = await getDocs(likesRef);
                        const likeCount = likesSnapshot.size;

                        // Verificar se o usuário atual curtiu o parentPost
                        const userLikeRef = doc(db, 'community', user.uid, 'likedPosts', parentPostDoc.id);
                        const userLikeDoc = await getDoc(userLikeRef);

                        // Verificar se o usuário votou na enquete do parentPost e qual opção
                        let isVoted = false;
                        let userVoteOption = null;
                        if (parentPostData.poll) {
                            const pollVoteRef = doc(db, 'posts', parentPostDoc.id, 'pollVotes', user.uid);
                            const pollVoteDoc = await getDoc(pollVoteRef);
                            if (pollVoteDoc.exists()) {
                                isVoted = true;
                                userVoteOption = pollVoteDoc.data().vote;  // Supondo que "vote" mantém a opção votada
                            }
                        }

                        // Adicionando as informações do usuário e o UID do parentPost diretamente no parentPost, junto com a informação de like e voto
                        const parentPostWithUser = {
                            ...parentPostData,
                            id: parentPostDoc.id,  // Adicionando o UID da publicação
                            user: userDoc.exists() ? userDoc.data() : null,
                            likeCount: likeCount, // Real like count from likes subcollection
                            isLiked: userLikeDoc.exists(),  // Determines if the parent post was liked by the current user
                            isVoted: isVoted,  // Check if user has voted in the poll of the parent post
                            userVote: userVoteOption  // Specific option user voted for in the parent post's poll
                        };

                        setParentPost(parentPostWithUser);
                        setIsFetchParentPostId(false)
                    } else {
                        setIsFetchParentPostId(false)
                        setParentPost({});
                    }
                }
            };

            fetchParentPost();
        }
        else {
            setIsFetchParentPostId(false)
        }
    }, [post]);

    useEffect(() => {
        if (post) {
            setComments([]);
            setLastCommentDoc(null);
            setHasMoreComments(true);
            fetchComments();
        }
    }, [post]);

    const [selectedEditPost, setSelectedEditPost] = useState(null);

    const handleOpenEditPostOnCommunity = (selectedPost) => {
        setSelectedEditPost(selectedPost);
        setShowPostOnCommunity(true);
    };

    useEffect(() => {
        if (clickedPost && clickedPost.id === postId) {
            console.log("Novo B.o: ", clickedPost)
            setPost(clickedPost)
        }
    }, [postId, username])

    return (
        <div className="contentSocialFeed" id='contentSocialFeed'>

            <PostOnCommunity showPostOnCommunity={showPostOnCommunity} setShowPostOnCommunity={setShowPostOnCommunity} post={selectedPost} setSelectedPost={setSelectedPost} selectedEditPost={selectedEditPost} setSelectedEditPost={setSelectedEditPost} />

            <div className="basePosition"></div>
            <div className="positionFixed">
                <HeaderCommunity showPostOnCommunity={showPostOnCommunity} setShowPostOnCommunity={setShowPostOnCommunity} />
            </div>
            <div className='containerSocial'>
                <div className="containerCenterWidthSocial">

                    {(isFetchComment || isFetchParentPostId) && (
                        <div className='loadingInformations'>
                            <Loading size={20} color={'#AAAAAA'} />
                        </div>
                    )}

                    <div className="contentPostUser">
                        {parentPost && (
                            <PostItem posts={[parentPost]} handleOpenPostOnCommunity={handleOpenPostOnCommunity} showElements={true} showLine={true} handleOpenEditPostOnCommunity={handleOpenEditPostOnCommunity} />
                        )}

                        {post ? (
                            <PostItem posts={[post]} setPosts={setPost} handleOpenPostOnCommunity={handleOpenPostOnCommunity} showElements={true} commmentSection={true} handleOpenEditPostOnCommunity={handleOpenEditPostOnCommunity} />
                        ) : (
                            <div className='loaderPostUser'>
                                <Loading size={30} color={'#999999'} />
                            </div>
                        )}

                        {comments && post?.user && (
                            <div className="contentComments">
                                <InfiniteScroll
                                    dataLength={comments.length}
                                    next={() => fetchComments(lastCommentDoc)}
                                    hasMore={hasMoreComments}
                                    scrollableTarget="contentSocialFeed"
                                    loader={(
                                        <div className='loaderPostUser'>
                                            <Loading size={30} color={'#999999'} />
                                        </div>
                                    )}
                                >
                                    <PostItem posts={comments} setPosts={setComments} handleOpenPostOnCommunity={handleOpenPostOnCommunity} showElements={true} handleOpenEditPostOnCommunity={handleOpenEditPostOnCommunity} />
                                </InfiniteScroll>
                            </div>
                        )}
                    </div>
                    {/* </div> */}
                </div>
            </div>
        </div>
    );
}

export default PostDetails;
