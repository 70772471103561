import React, { useEffect } from 'react';
import { Box, Typography, Button, Snackbar } from '@mui/material';
import { Check as CheckIcon, Close as IncorrectIcon } from '@mui/icons-material';

const FeedbackSnackbar = ({ response, explanation, nextExercise, setResponse }) => {

    const handleNext = () => {
        setResponse('');
        nextExercise();
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && response !== '') {
                handleNext();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [response]);

    return (
        // <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', zIndex: 1300, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
        <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', zIndex: 1300 }}>
            <Snackbar
                open={response !== ''}
                onClose={() => {}}
                message={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box sx={{ backgroundColor: '#fff', borderRadius: '50%', minWidth: '60px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {response === "correct"
                                ? <CheckIcon sx={{ fontSize: '30px', color: '#58A700' }} />
                                : <IncorrectIcon sx={{ fontSize: '30px', color: '#EA2B2B' }} />}
                        </Box>
                        <Box>
                            <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '21px', textTransform: 'capitalize' }}>{response}!</Typography>
                            <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>{explanation}</Typography>
                        </Box>
                    </Box>
                }
                action={
                    <Button color="inherit" size="small" onClick={handleNext}>
                        Continue
                    </Button>
                }
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                ContentProps={{
                    className: response === "correct" ? 'showAnswer correct' : 'showAnswer incorrect'
                }}
            />
        </Box>
    );
};

export default FeedbackSnackbar;
