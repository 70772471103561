import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';

const CustomConfetti = ({ numberOfPieces, duration, position, colors }) => {
    const [explode] = useState(true);
    const [activePieces, setActivePieces] = useState(numberOfPieces);
    const { width, height } = useWindowSize();
    const [mousePosition, setMousePosition] = useState({ x: width / 2, y: height / 2 });

    useEffect(() => {
        if (position === 'followMouse') {
            const handleMouseMove = (event) => {
                setMousePosition({ x: event.clientX, y: event.clientY });
            };

            window.addEventListener('mousemove', handleMouseMove);
            return () => window.removeEventListener('mousemove', handleMouseMove);
        }
    }, [position]);

    useEffect(() => {
        if (explode) {
            setActivePieces(numberOfPieces);
            const timer = setTimeout(() => {
                setActivePieces(0); // Parar a emissão de confetes após o tempo especificado
            }, duration);

            return () => clearTimeout(timer);
        }
    }, [explode, numberOfPieces, duration]);

    useEffect(() => {
        // Resetar a emissão para o próximo clique
        if (!explode && activePieces === 0) {
            setActivePieces(numberOfPieces);
        }
    }, [explode, activePieces, numberOfPieces]);


    const { confettiSource, initialVelocityX, initialVelocityY } = getConfettiSource(position, width, height, mousePosition);

    return (
        <>
            <Confetti
                run={explode}
                width={width}
                height={height}
                numberOfPieces={activePieces}
                colors={colors}
                gravity={0.1}
                confettiSource={confettiSource}
                initialVelocityX={initialVelocityX}
                initialVelocityY={initialVelocityY}
            />
        </>
    );
};


function useWindowSize() {
    const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    useEffect(() => {
        const handleResize = () => {
            setSize({ width: window.innerWidth, height: window.innerHeight });
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return size;
}


function getConfettiSource(position, width, height, mousePosition) {
    switch (position) {
        case 'top':
            return {
                confettiSource: { x: 0, y: 0, w: width, h: 0 }, // Cobertura total do topo
                initialVelocityX: { min: -10, max: 10 }, // Menor dispersão horizontal
                initialVelocityY: { min: 5, max: 10 } // Queda mais suave
            };
        case 'bottom':
            return {
                confettiSource: { x: 0, y: height, w: width, h: 0 }, // Iniciando de 0 a width
                initialVelocityX: { min: -15, max: 15 }, // Dispersão horizontal
                initialVelocityY: { min: -15, max: -5 } // Movimento para cima
            };
        case 'left':
            return {
                confettiSource: { x: 0, y: height / 2, w: 10, h: height },
                initialVelocityX: { min: 5, max: 20 }, // Confetes movendo-se da esquerda para a direita
                initialVelocityY: { min: -20, max: 20 }
            };
        case 'right':
            return {
                confettiSource: { x: width, y: height / 2, w: 10, h: height },
                initialVelocityX: { min: -20, max: -5 }, // Confetes movendo-se da direita para a esquerda
                initialVelocityY: { min: -20, max: 20 }
            };
        case 'followMouse':
            return {
                confettiSource: { x: mousePosition.x, y: mousePosition.y, w: 0, h: 0 },
                initialVelocityX: { min: -10, max: 10 },
                initialVelocityY: { min: -10, max: 10 }
            };
        default:
            return {
                confettiSource: { x: width / 2, y: height / 2, w: 0, h: 0 },
                initialVelocityX: { min: -10, max: 10 },
                initialVelocityY: { min: -10, max: 10 }
            };
    }
}

export default CustomConfetti;
