import React, { useEffect, useState } from 'react';
import "./style.css"
import { sendChatGPTRequest } from '../IAtalk2ME';
import { collection, addDoc, getDocs, query, where, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from "../../auth/config/firebase"
import { auth } from "../../auth/config/firebase"
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

function Reading() {

    const [level, setLevel] = useState('beginner');
    const [inputValue, setInputValue] = useState('');
    const [chatTitle, setChatTitle] = useState('Practice your reading');
    const [chatContent, setChatContent] = useState('Create and study personalized English text generated by TalkIA in seconds to improve your English reading.');
    const [isGenerating, setIsGenerating] = useState(false);
    const [selectedWords, setSelectedWords] = useState([]);
    const [screen, setScreen] = useState('main');
    const [selectedSavedWords, setSelectedSavedWords] = useState([]);

    const [buttonTimeout, setButtonTimeout] = useState(0);

    useEffect(() => {
        let timeoutId;
        if (buttonTimeout > 0) {
            timeoutId = setTimeout(() => setButtonTimeout(buttonTimeout - 1), 1000);
        }
        return () => clearTimeout(timeoutId);  // Limpe o timeout ao desmontar o componente ou se buttonTimeout mudar
    }, [buttonTimeout]);

    const handleChange = (e) => {
        setInputValue(e.target.value);
    }

    const handleLevelChange = (e) => {
        setLevel(e.target.value);
    }

    const typeWriterEffect = (text, setText, callback) => {
        setChatContent('')
        let i = 0;
        const speed = 3; // Adjust the typing speed here
        function typeWriter() {
            if (i < text.length) {
                setText((prevText) => prevText + text.charAt(i));
                i++;
                setTimeout(typeWriter, speed);
            } else {
                callback();
            }
        }
        setText(text.charAt(0)); // Set the first character before starting the typewriter effect.
        typeWriter();
    }

    const generateText = async () => {
        if (buttonTimeout > 0) return;  // Se o tempo de espera não tiver acabado, não faça nada
        setIsGenerating(true);
        setButtonTimeout(60);

        setIsGenerating(true);
        setChatTitle('Please wait');
        setChatContent('Generating text...');
        const messagesTitle = [{ role: "user", content: `Please generate a short and concise title for a ${level} level text with the words: ${inputValue}` }];
        const messagesContent = [{ role: "user", content: `Now, generate ${level} level text so that people with level ${level} can understand normally with the words: ${inputValue} related to the title: ${chatTitle}` }];
        const personality = "You are a language learning enthusiast AI.";

        const chatTitleResponse = await sendChatGPTRequest(messagesTitle, personality);
        setChatTitle(chatTitleResponse);
        const chatContentResponse = await sendChatGPTRequest(messagesContent, personality);
        typeWriterEffect(chatContentResponse, setChatContent, () => setIsGenerating(false));
    }

    const handleWordClick = (word) => {
        const wordsList = screen === 'main' ? selectedWords : selectedSavedWords;
        const setWords = screen === 'main' ? setSelectedWords : setSelectedSavedWords;
        setWords((prevWords) => {
            const normalizedPrevWords = prevWords.map((w) =>
                w.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            );
            const normalizedWord = word.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            if (normalizedPrevWords.includes(normalizedWord)) {
                return prevWords.filter(
                    (w) =>
                        w.normalize("NFD").replace(/[\u0300-\u036f]/g, "") !== normalizedWord
                );
            } else {
                return [...prevWords, word];
            }
        });
    };


    const toggleScreen = () => {
        setScreen(screen === 'main' ? 'alternate' : 'main');
    }

    const handleSaveClick = async () => {
        if (screen === 'main') {
            await saveWords(auth.currentUser.uid, selectedWords);
            setSelectedWords([]);
        } else {
            await deleteSelectedWords(auth.currentUser.uid, selectedSavedWords);
            setSelectedSavedWords([]);
        }
    };

    const saveWords = async (userId, words) => {
        try {
            const date = new Date();
            // Salve a data e as palavras como um objeto
            const wordsWithDate = { date, words: words.map(word => ({ word })) };

            await addDoc(collection(db, "savedWords"), {
                userId: userId,
                wordsGroup: wordsWithDate,
            });
            console.log("Words saved successfully!");
        } catch (error) {
            console.error("Error saving words: ", error);
        }
    };



    const [savedWords, setSavedWords] = useState([]);

    useEffect(() => {
        if (screen === 'alternate') {
            loadSavedWords(auth.currentUser.uid);
        }
    }, [screen]);

    const loadSavedWords = async (userId) => {
        const q = query(collection(db, "savedWords"), where("userId", "==", userId));
        const querySnapshot = await getDocs(q);
        const wordsGroups = [];
        querySnapshot.forEach((doc) => {
            wordsGroups.push(doc.data().wordsGroup);
        });
        setSavedWords(wordsGroups);
    };

    const deleteSelectedWords = async (userId, words) => {
        const q = query(collection(db, "savedWords"), where("userId", "==", userId));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach(async (doc) => {
            const docWordsGroup = doc.data().wordsGroup;
            const newDocWords = docWordsGroup.words.filter(wordObj => !words.includes(wordObj.word));

            if (newDocWords.length > 0) {
                const newWordsGroup = { ...docWordsGroup, words: newDocWords };
                await updateDoc(doc.ref, { wordsGroup: newWordsGroup });
            } else {
                await deleteDoc(doc.ref);
            }
        });

        await loadSavedWords(userId);
    }

    return (
        <div className="popupReading">
            <div className='flexHeaderReading'>
                <h2 className="popup-title">{screen === 'main' ? chatTitle : 'Your words in English'}</h2>
                {/* <i className="fa-solid fa-eye" onClick={toggleScreen}></i> */}
                <FontAwesomeIcon icon={faEye} onClick={toggleScreen} />
            </div>

            {screen === 'main' ?
                <div>
                    <p className="popup-description">
                        {chatContent.split(/(\s|\,|\.|\;|\:|\?|\!|\-)/).map((part, index) => {
                            const isWord = part.trim() !== '' && !part.match(/(\s|\,|\.|\;|\:|\?|\!|\-)/);
                            return (
                                <span
                                    key={index}
                                    className={isWord && selectedWords.includes(part) ? 'selected-word' : ''}
                                    onClick={() => isWord && handleWordClick(part)}
                                >
                                    {part}
                                </span>
                            )
                        })}
                    </p>
                    <div className="options-container">
                        <select className="level-select" value={level} onChange={handleLevelChange}>
                            <option value="beginner">Beginner</option>
                            <option value="intermediary">Intermediary</option>
                            <option value="advanced">Advanced</option>
                        </select>
                        <input
                            className="phrase-input"
                            type="text"
                            placeholder="Words that will appear in the text"
                            value={inputValue}
                            onChange={handleChange}
                        />
                        <button className="generate-button" onClick={generateText}>
                            {buttonTimeout > 0 ? `Wait ${buttonTimeout}s` : 'Generate Text'}
                        </button>
                    </div>
                    {selectedWords.length > 0 && screen === 'main' && (
                        <div className="selected-words-container">
                            <p className='selected-words'>Selected words: {selectedWords.join(', ')}</p>
                            <button onClick={handleSaveClick} className='save-button'>Save Words</button>
                        </div>
                    )}
                </div>
                :
                <div className='flexContainerWordsSaved'>
                    <div className='containerWordsSaved'>
                        {(!savedWords || savedWords.length === 0) ? (
                            <span>Oops! Looks like you don't have any words saved</span>
                        ) : (
                            savedWords.sort((a, b) => b.date.toDate() - a.date.toDate()).map((wordGroup, index) => {
                                let dateString = '';
                                if (wordGroup.date) {
                                    const date = wordGroup.date.toDate();
                                    dateString = `${date.toLocaleDateString('en-US')} ${date.toLocaleTimeString('en-US')}`;
                                }
                                return (
                                    <div key={index} className='wordsGroup'>
                                        <p className='dateWord'>{dateString}</p>
                                        {wordGroup.words.map((wordObj, wordIndex) => (
                                            <div key={wordIndex} className='nameWord'>
                                                <span
                                                    className={selectedSavedWords.includes(wordObj.word) ? 'selected-word' : ''}
                                                    onClick={() => handleWordClick(wordObj.word)}
                                                >
                                                    {wordObj.word}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                )
                            })
                        )}
                    </div>
                    {selectedSavedWords.length > 0 && screen === 'alternate' && (
                        <div className="selected-words-container">
                            <p className='selected-words'>Selected words: {selectedSavedWords.join(', ')}</p>
                            <button onClick={handleSaveClick} className='delete-button'>Delete Words</button>
                        </div>
                    )}
                </div>
            }
        </div>
    )
}

export default Reading;