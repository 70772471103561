import React from 'react';
import './style.css'

const ProgressBar = ({ progress }) => {
    return (
        <div className="progressBarContainer">
            <div className="progressBar" style={{ width: `${progress}%` }}></div>
        </div>
    );
};

export default ProgressBar;
