/* global adsbygoogle */
import { Box, Button } from '@mui/material';
import React, { useEffect } from 'react';
import { useAllTheme } from '../../components/themes/ThemeContext';

const AdComponent = () => {
  useEffect(() => {
    // Inicializar o bloco de anúncios quando o componente for montado
    try {
      (adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error('AdSense error: ', e);
    }
  }, []);

  const { theme, isDark } = useAllTheme();

  return (
    <Box sx={{ border: `2px solid ${theme.border}`, display: 'flex', flexDirection: 'column', gap: '6px', padding: '16px', borderRadius: '20px', height: '300px' }}>
      <div className="adsense-container" style={{ display: 'block', textAlign: 'center', backgroundColor: 'pink', height: '100%' }}>
        <ins className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-1197387038086005"
          data-ad-slot="8733855504"
          // data-ad-format="auto"
          data-full-width-responsive="true"></ins>
      </div>

      <Button sx={{ color: '#007AFF', padding: 0, '&:hover': { backgroundColor: 'transparent' }, marginTop: '6px', fontFamily: 'SF-Pro-Display, sans-serif', fontWeight: 600 }}>Remover anuncio</Button>
    </Box>
  );
};

export default AdComponent;
